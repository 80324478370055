import { Injectable, Inject } from '@angular/core';
import { Http, RequestOptionsArgs, Headers, Response, ResponseContentType } from '@angular/http';
import { EnvironmentProvider } from './browser-providers';
import { Environment } from '../../environments/enviroment.model';
import { Cliente } from '../cliente/cliente.model';
import { Observable } from 'rxjs/Observable';
import { Producto } from '../productos/models/producto.model';
import { Banner } from '../shared-components/banners/banner.model';
import {Pedido} from '../productos/resultado-producto/models/pedido.model';

@Injectable()
export class ClienteService {

  private tokenId = 'jwtToken';
  protected backendUrl: string;
  constructor(
    protected http: Http,
    @Inject(EnvironmentProvider) env: Environment,
  ) {this.backendUrl = env.endpoints.apiFront;
  }

  getImagenById(id: number): Observable<Blob> {
    return this.http.get(this.buildUrl('/productos/imagen/' + id),
    this.buildRequestOptions({ responseType: ResponseContentType.Blob }))
    .map((res: Response) => res.blob());
  }
  getFileById(id: number): Observable<Blob> {
    return this.http.get(this.buildUrl( Producto.new_path + 'imagenes-productos/' + id),
      this.buildRequestOptions({ responseType: ResponseContentType.Blob }))
      .map((res: Response) => res.blob());
  }
  getLogoById(id: number): Observable<Blob> {
    return this.http.get(this.buildUrl('/producto-acuerdo/logo/' + id),
    this.buildRequestOptions({ responseType: ResponseContentType.Blob }))
    .map((res: Response) => res.blob());
  }
  getRenderById(id: number): Observable<Blob> {
    return this.http.get(this.buildUrl( Pedido.new_path + 'imagen-render/'+ id),
      this.buildRequestOptions({ responseType: ResponseContentType.Blob }))
      .map((res: Response) => res.blob());
  }
  getImagenPedidoById(id: number): Observable<Blob> {
    return this.http.get(this.buildUrl('/producto-pedido/imagen/' + id),
    this.buildRequestOptions({ responseType: ResponseContentType.Blob }))
    .map((res: Response) => res.blob());
  }

  getBannerById(id: number): Observable<Blob> {
    return this.http.get(this.buildUrl(Banner.path + 'imagen/' + id),
    this.buildRequestOptions({ responseType: ResponseContentType.Blob }))
    .map((res: Response) => res.blob());
  }

  public buildUrl(path: string) {
    return this.backendUrl + encodeURI(path);
  }

  getPresupuesto(id: number): Observable<Blob> {
    return this.http.get(this.buildUrl( Pedido.new_path + 'presupuesto/'+ id),
      this.buildRequestOptions({ responseType: ResponseContentType.Blob }))
      .map((res: Response) => res.blob());
  }

  private buildRequestOptions(options: RequestOptionsArgs = {}) {
    options.headers = options.headers || new Headers();
    options.headers.append('Content-Type', 'application/ld+json');
    const token = this.token;
    if (token) {
      options.headers.append('Authorization', 'Bearer ' + token);
    }
    return options;
  }

  get token() {
    return this.getCookie(this.tokenId);
  }

  getCookie(value:string) {
    const cookies = document.cookie.split(';');
    const myCookie = cookies.find(cookie => cookie.trim().startsWith(value +'='));
    
    if (myCookie) {
      const value = myCookie.split('=')[1];
      return value;
    } 
    return myCookie;
  }


}
