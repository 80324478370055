import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeEsAr from '@angular/common/locales/es-AR';

import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { RegistroComponent } from './registro/registro.component';
import { AppRoutingModule } from './router/app-routing.module';
import { FormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { Paso4Component } from './registro/paso4/paso4.component';
import { CorreoComponent } from './registro/correo/correo.component';
import { CorreoResolver } from './registro/correo/correo.resolver';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpModule } from '@angular/http';
import { ContactoComponent } from './contacto/contacto.component';
import { VendorModule } from './core/vendor.module';
import { CoreModule } from './core/core.module';
import { SharedServicesModule } from './shared-services/shared-services.module';
import { SeguridadModule } from './seguridad/seguridad.module';
import { MailFormComponent } from './shared-components/mail-form/mail-form.component';
import { TelefonoFormComponent } from './shared-components/telefono-form/telefono-form.component';
import { HomeComponentComponent } from './home-component/home-component.component';
import { LandingPageComponentComponent } from './landing-page-component/landing-page-component.component';
import { LayoutRegistroLoginComponent } from './shared-components/layout-registro-login/layout-registro-login.component';
import { HeaderComponent } from './shared-components/header/header.component';
import { PublicBackgroundComponent } from './shared-components/public-background/public-background.component';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CuentaCreadaComponent } from './registro/cuenta-creada/cuenta-creada.component';
import { LinkInvalidoComponent } from './link-invalido/link-invalido.component';
import { CompletarDocumentacionComponent } from './registro/completar-documentacion/completar-documentacion.component';
import { DatosPersonalesComponent } from './registro/datos-personales/datos-personales.component';
import {NgxMaskModule} from 'ngx-mask';
import { DatosContactoComponent } from './registro/datos-contacto/datos-contacto.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { RegistroSatisfactorioComponent } from './registro/registro-satisfactorio/registro-satisfactorio.component';
import { CurrenciesComponent } from './landing-page-component/currencies/currencies.component';
import { FooterComponent } from './shared-components/footer/footer.component';
import { DashboardComponent } from './shared-components/dashboard/dashboard.component';
import { OwlNativeDateTimeModule, OwlDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { UltimasSesionesComponent } from './shared-components/ultimas-sesiones/ultimas-sesiones.component';
import { UltimasOperacionesComponent } from './shared-components/ultimas-operaciones/ultimas-operaciones.component';
import { BilleteraComponent } from './shared-components/billetera/billetera.component';
import { ListadoOperacionesComponent } from './shared-components/listado-operaciones/listado-operaciones.component';
import { ListadoSesionesComponent } from './shared-components/listado-sesiones/listado-sesiones.component';
import { ListadoAnunciosComponent } from './shared-components/listado-anuncios/listado-anuncios.component';
import { TycTextComponent } from './shared-components/tyc-text/tyc-text.component';
import { EstadoVerificacionComponent } from './shared-components/estado-verificacion/estado-verificacion.component';
import { AnunciosImportantesComponent } from './shared-components/anuncios-importantes/anuncios-importantes.component';
import { AnunciosCompletosComponent } from './shared-components/anuncios-completos/anuncios-completos.component';
import { AnunciosDetallesComponent } from './shared-components/anuncios-detalles/anuncios-detalles.component';
import { AnuncioResolver } from './core/anuncio.resolver';
import { AnunciosVistaComponent } from './shared-components/anuncios-vista/anuncios-vista.component';
import { AnunciosComponent } from './shared-components/anuncios/anuncios.component';
import { MediosPrensaComponent } from './shared-components/medios-prensa/medios-prensa.component';
import { FuncionalidadNoImplementadaComponent } from './shared-components/funcionalidad-no-implementada/funcionalidad-no-implementada.component';
import { SolidHeaderComponent } from './shared-components/solid-header/solid-header.component';
import { BackgroundLessComponent } from './shared-components/background-less/background-less.component';
import { ComisionesComponent } from './shared-components/comisiones/comisiones.component';
import { CuentaCongeladaComponent } from './registro/cuenta-congelada/cuenta-congelada.component';
import { CuentaCongeladaResolver } from './registro/cuenta-congelada/cuentaCongelada.resolver';
import { ConfiguracionComponent } from './shared-components/configuracion/configuracion.component';
import { Google2faComponent } from './shared-components/google2fa/google2fa.component';
import { NotificacionesComponent } from './shared-components/notificaciones/notificaciones.component';
import { DesactivarCuentaComponent } from './shared-components/desactivar-cuenta/desactivar-cuenta.component';
import { Google2faModalComponent } from './shared-components/google2fa-modal/google2fa-modal.component';
import { ClienteBloqueadoComponent } from './cliente-bloqueado/cliente-bloqueado.component';
import { TycModalComponent } from './shared-components/modals/tyc-modal/tyc-modal.component';
import { ConfirmModalComponent } from './shared-components/modals/confirm-modal/confirm-modal.component';
import { NoImplementadaComponent } from './shared-components/modals/no-implementada/no-implementada.component';
import { MensajeRecuperarPasswordComponent } from './shared-components/mensaje-recuperar-password/mensaje-recuperar-password.component';
import { SolicitudMasInfoComponent } from './shared-components/modals/solicitud-mas-info/solicitud-mas-info.component';
import { NotificacionComponent } from './shared-components/modals/notificaciones/notificacion.component';
import { SideBarComponent } from './shared-components/side-bar/side-bar.component';
import { ListaProductosComponent } from './productos/lista-productos/lista-productos.component';
import { LoginResolver } from './seguridad/login/login.resolver';
registerLocaleData(localeEsAr, 'es-Ar');
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AngularDraggableModule } from 'angular2-draggable';
import { MisPedidosComponent } from './shared-components/mis-pedidos/mis-pedidos.component';
import { PedidoDetalleComponent } from './shared-components/mis-pedidos/pedido-detalle/pedido-detalle.component';
import { PrevisualizacionComponent } from './shared-components/modals/previsualizacion/previsualizacion.component';
import { EnvioMailComponent } from './shared-components/modals/envio-mail/envio-mail.component';
import { AceptarComponent } from './shared-components/modals/aceptar/aceptar.component';
import { ReplaceComaPipe } from './shared-services/replaceComa.pipe';
import { TutorialModalComponent } from './shared-components/modals/tutorial-modal/tutorial-modal.component';
import { PerfilUsuarioComponent } from './shared-components/perfil-usuario/perfil-usuario.component';
import {ProductoModule} from './productos/producto.module';
import { FaqsComponent } from './faqs/faqs.component';
import { RegistroEmpresaComponent } from './registro/registro-empresa/registro-empresa.component';
import { TipoImpresionComponent } from './faqs/tipo-impresion/tipo-impresion.component';
import { PersonalizarComponent } from './personalizar/personalizar.component';
import { PerfilDireccionComponent } from './shared-components/perfil-usuario/perfil-direccion/perfil-direccion.component';
import { NuevaDireccionComponent } from './shared-components/perfil-usuario/perfil-direccion/nueva-direccion/nueva-direccion.component';
import { AllNotificationsComponent } from './notificaciones/all-notifications/all-notifications.component';
import {ListaVaciaComponent} from './productos/lista-vacia/lista-vacia.component';
import { GtagModule } from 'angular-gtag';
import {environment} from '../environments/environment';
import { TarjetaComponent } from './shared-components/modals/tarjeta/tarjeta.component';
import { CarritoVacioComponent } from './productos/carrito-vacio/carrito-vacio.component';
import { SelectCountryComponent } from './select-country/select-country.component';
import { SelectorRegistroComponent } from './registro/selector-registro/selector-registro.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RegistroVerificarSmsComponent } from './registro/registro-telefono/registro-verificar-sms/registro-verificar-sms.component';
import { DatosPersonalesModalComponent } from './shared-components/modals/datos-personales-modal/datos-personales-modal.component';
import { LoginModalComponent } from './shared-components/modals/login-modal/login-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    RegistroComponent,
    Paso4Component,
    CorreoComponent,
    ContactoComponent,
    MailFormComponent,
    TelefonoFormComponent,
    HomeComponentComponent,
    LandingPageComponentComponent,
    LayoutRegistroLoginComponent,
    HeaderComponent,
    PublicBackgroundComponent,
    CuentaCreadaComponent,
    LinkInvalidoComponent,
    CompletarDocumentacionComponent,
    DatosPersonalesComponent,
    DatosContactoComponent,
    RegistroSatisfactorioComponent,
    CurrenciesComponent,
    FooterComponent,
    DashboardComponent,
    UltimasSesionesComponent,
    UltimasOperacionesComponent,
    BilleteraComponent,
    AnunciosVistaComponent,
    ListadoOperacionesComponent,
    ListadoSesionesComponent,
    ListadoAnunciosComponent,
    TycTextComponent,
    EstadoVerificacionComponent,
    AnunciosImportantesComponent,
    AnunciosCompletosComponent,
    AnunciosDetallesComponent,
    AnunciosComponent,
    MediosPrensaComponent,
    FuncionalidadNoImplementadaComponent,
    SolidHeaderComponent,
    BackgroundLessComponent,
    ComisionesComponent,
    CuentaCongeladaComponent,
    Google2faModalComponent,
    ConfiguracionComponent,
    Google2faComponent,
    NotificacionesComponent,
    DesactivarCuentaComponent,
    ClienteBloqueadoComponent,
    TycModalComponent,
    ConfirmModalComponent,
    NoImplementadaComponent,
    MensajeRecuperarPasswordComponent,
    SolicitudMasInfoComponent,
    NotificacionComponent,
    SideBarComponent,
    ListaProductosComponent,
    MisPedidosComponent,
    PedidoDetalleComponent,
    PrevisualizacionComponent,
    EnvioMailComponent,
    AceptarComponent,
    ReplaceComaPipe,
    TutorialModalComponent,
    PerfilUsuarioComponent,
    FaqsComponent,
    RegistroEmpresaComponent,
    TipoImpresionComponent,
    PersonalizarComponent,
    PerfilDireccionComponent,
    NuevaDireccionComponent,
    AllNotificationsComponent,
    ListaVaciaComponent,
    TarjetaComponent,
    CarritoVacioComponent,
    SelectCountryComponent,
    SelectorRegistroComponent,
    RegistroVerificarSmsComponent,
    DatosPersonalesModalComponent,
    LoginModalComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ProductoModule,
    SharedServicesModule,
    HttpModule,
    BrowserModule,
    AppRoutingModule,
    SeguridadModule,
    FormsModule,
    VendorModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    PasswordStrengthBarModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 10000,
      preventDuplicates: true,
    }),
    CoreModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ArchwizardModule,
    NgxSpinnerModule,
    NgxSmartModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    SlickCarouselModule,
    AngularDraggableModule,
    GtagModule.forRoot({ trackingId: environment.googleAnalyticsId, trackPageviews: false, debug: true }),
    InfiniteScrollModule,
  ],
  providers: [
    CorreoResolver,
    AnuncioResolver,
    CuentaCongeladaResolver,
    Google2faModalComponent,
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es-AR' },
    LoginResolver
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    TycModalComponent,
    ConfirmModalComponent,
    NoImplementadaComponent,
    SolicitudMasInfoComponent,
    NotificacionComponent,
    EnvioMailComponent,
    AceptarComponent,
    TutorialModalComponent,
    PrevisualizacionComponent,
    TarjetaComponent,
    DatosPersonalesModalComponent,
    LoginModalComponent
  ]

})
export class AppModule { }
