import { Component, OnInit, Input } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ApiService } from '../../core/api.service';
import { BackendService } from '../../core/backend.service';
import { SeguridadService } from '../../seguridad/seguridad.service';

@Component({
  selector: 'app-solid-header',
  templateUrl: './solid-header.component.html',
  styleUrls: ['./solid-header.component.scss']
})
export class SolidHeaderComponent implements OnInit {

  @Input() showLogin = true;
  @Input() showSignIn = true;

  user: any;

  private killTrigger: Subject<void> = new Subject();

  constructor(private apiService: ApiService,
    private backendService: BackendService,
    private seguridadService: SeguridadService,
  ) { }

  ngOnInit() {
    this.user = this.seguridadService.user;
  }

}
