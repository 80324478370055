import { Component, OnInit, Input } from '@angular/core';
import { Operacion } from '../../core/operacion.model';

@Component({
  selector: 'app-ultimas-operaciones',
  templateUrl: './ultimas-operaciones.component.html',
  styleUrls: ['./ultimas-operaciones.component.scss']
})
export class UltimasOperacionesComponent implements OnInit {

  @Input() rows: Operacion[];

  constructor() { }

  ngOnInit() {
  }

}
