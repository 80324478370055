import { Component, OnInit, Input } from '@angular/core';
import { Anuncio } from '../../core/anuncio.model';

@Component({
  selector: 'app-anuncios-completos',
  templateUrl: './anuncios-completos.component.html',
  styleUrls: ['./anuncios-completos.component.scss']
})
export class AnunciosCompletosComponent implements OnInit {

  @Input() anunciosList: Anuncio[];
  @Input() mostrarLista = false;
  constructor() { }

  ngOnInit() {
  }
}
