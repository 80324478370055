export class Foto {

  file: File;
  path: string;
  id: number;
  name: string;
  archivoId: number;
  url?: any;

  constructor(file: File) {
    this.file = file;
  }

}
