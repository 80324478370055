import { Component, Input, OnInit } from '@angular/core';
import {ProductoParticularPedido} from '../../models/producto-particular-pedido.model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Subject} from 'rxjs';
import {ProductoCarrito} from '../../models/producto-carrito';
import {Router} from '@angular/router';
import {BackendService} from '../../../core/backend.service';
import {Producto} from '../../models/producto.model';
import {ToastrService} from 'ngx-toastr';
import {ProductoService} from '../../service/producto.service';
import {NgxSpinnerService} from 'ngx-spinner';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { Pedido } from '../../resultado-producto/models/pedido.model';
import { NotificacionesCarrito } from 'src/app/shared-services/notificacion-carrito.service';
import { ModalsService } from 'src/app/shared-services/modals.service';
import { ConfirmarTiempoDeEntregaModalComponent } from '../confirmar-tiempo-de-entrega-modal/confirmar-tiempo-de-entrega-modal.component';
import {ToastService} from '../../../shared-services/toast/toast.service';
import { Gtag } from 'angular-gtag';
import { PaisService } from 'src/app/shared-services/pais.service';


@Component({
  selector: 'app-elegir-tipo-producto-modal',
  templateUrl: './elegir-tipo-producto-modal.component.html',
  styleUrls: ['./elegir-tipo-producto-modal.component.scss']
})
export class ElegirTipoProductoModalComponent implements OnInit {
  productoCarrito: ProductoCarrito;
  cantidadMinima: number;
  pedido: Pedido;
  selectPais: string;
  tipoProducto:number;

  hayProductos: number;
  public onClose: Subject<boolean>;
  constructor(public bsModalRef: BsModalRef,
              public bsModalRefTiempoEntrega: BsModalRef,
              private router: Router,
              private modalService: BsModalService,
              public notificacionesCarrito: NotificacionesCarrito,
              private backendService: BackendService,
              private toastr: ToastrService,
              private productoService:ProductoService,
              private spinner: NgxSpinnerService,
              private seguridadService: SeguridadService,
              private toastService: ToastService,
              private gtag: Gtag,
              private paisService: PaisService) {
  }
  async ngOnInit() {
    this.onClose = new Subject();

    this.selectPais = localStorage.getItem('pais');

    if(this.selectPais == null){
      await this.paisService.detectCountry().then(a=>{
        this.selectPais = localStorage.getItem('pais');
      });
    }
  }
  confirma() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  open(componente, configuraciones) {
    return this.modalService.show(componente, configuraciones);
  }

  confirmarTiempoDeEntrega() {
   this.bsModalRefTiempoEntrega = this.open(ConfirmarTiempoDeEntregaModalComponent, {class: 'modal-lg', backdrop: 'static', keyboard: false,centered: true});
   this.bsModalRefTiempoEntrega.content.diasProduccion = this.productoCarrito.tiempoEntrega;
   return this.bsModalRefTiempoEntrega.content.onClose;

  }
  async armarPedido() {
    try {
      this.bsModalRef.hide();
      //const res = this.confirmarTiempoDeEntrega();
      //res.subscribe(async (value) => {
      const value = true;
      if(value){
        this.spinner.show();
        if(!this.seguridadService.user){
          //  await this.router.navigateByUrl('/login' + this.router.url);
          this.spinner.hide();
          this.onClose.next(false);
          this.bsModalRef.hide();
          return;
        }
      await this.backendService.create(Producto.new_path + "agregar-carrito", this.productoCarrito);
      this.addToCart(this.productoCarrito);
      this.spinner.hide();
      //await this.router.navigateByUrl('/previsualizacion-compra');

      if(this.selectPais=="ARGENTINA"){
        window.location.href = '/previsualizacion-compra';
      }else if (this.selectPais=="CHILE"){
        window.location.href = '/previsualizacion-compra-cl';
      };
      this.toastService.openSuccessGenerico("PRODUCTO AGREGADO AL CARRITO CORRECTAMENTE");
      this.confirma();
      }else{this.spinner.hide();}

    //})

    } catch (ex) {
      console.log(ex);
    }
  }
  async redirectToProductoLogo(){
    /*if(this.productoCarrito.cantidad < this.cantidadMinima){
      this.toastService.openSuccessGenerico("LA CANTIDAD MÍNIMA PARA BONIFICAR TU LOGO ES: "+this.cantidadMinima + ", SE TE COBRARÁ UN ADICIONAL.");
      //this.toastService.openErrorGenerico("LA CANTIDAD MÍNIMA PARA BONIFICAR TU LOGO ES: "+this.cantidadMinima);
      //this.confirma();
      //return;
    }*/


    if(!this.seguridadService.user){
      //MODAL DE REGISTRO SIMPLE
      //await this.router.navigateByUrl('/login' + this.router.url);
      this.onClose.next(false);
      this.bsModalRef.hide();
      return;
    }
    this.productoService.emitChange(this.productoCarrito);
    this.router.navigateByUrl('/producto-logo/' + this.productoCarrito.slug);
    this.confirma();
  }

  confirmaOtro(){
    this.bsModalRef.hide();
  }

  addToCart(producto){
    this.gtag.event('add_to_cart', {
      items: [{
        item_name: producto.infoProducto.nombre,
        item_id: producto.infoProducto.id,
        price: producto.infoProducto.precio,
        item_category: producto.infoProducto.categorias[0],
        item_variant: producto.varianteSeleccionada,
        quantity: producto.cantidad
      }]
    });
  }

  seleccion(s:number){
    this.tipoProducto = s;
  }

  confirmaEjecucion(){
    if(this.tipoProducto == 1){
      this.gtag.event('select_item_logo', {
      items: [{
        item_name: "producto con logo"
      }]
    });
      this.redirectToProductoLogo();
    }else{
      this.gtag.event('select_item_no_logo', {
        items: [{
          item_name: "producto sin logo"
        }]
      });
      this.armarPedido();
    }
  }
}
