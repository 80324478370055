import { Component, OnInit, Input } from '@angular/core';
import { Pedido } from '../../../productos/resultado-producto/models/pedido.model';
import { BackendService } from '../../../core/backend.service';
import { ActivatedRoute, Router } from '../../../../../node_modules/@angular/router';
import { ModalsService } from '../../../shared-services/modals.service';
import { PedidoSimple } from 'src/app/productos/resultado-producto/models/pedido-simple.model';
import { ProductoSimple } from 'src/app/productos/resultado-producto/models/producto-simple.model';
import { DescuentoPedido } from 'src/app/productos/resultado-producto/models/descuento-pedido.model';
import { Cliente } from 'src/app/cliente/cliente.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Render } from 'src/app/productos/producto-particular-logo/model/render';
import { ClienteService } from 'src/app/core/cliente.service';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { PaisEnum } from 'src/app/core/constants/pais-enum';

@Component({
  selector: 'app-pedido-detalle',
  templateUrl: './pedido-detalle.component.html',
  styleUrls: ['./pedido-detalle.component.scss']
})
export class PedidoDetalleComponent implements OnInit {

  public pedido: Pedido;
  public pedidoSimple:PedidoSimple;
  public productoSimple:ProductoSimple;
  public productoList: ProductoSimple[];
  private id: number;
  private productoSubtotal: number;
  private editable = false;
  public descuentoAplicablePedido: DescuentoPedido;
  user: any;

  public costoEnvioIva:number;
  public valorSituacionIvaId;
  public precioFinalActualizado: number = 0;

  //IVA
  public iva: number = 0;
  
  public selectPais:string;

  constructor(
    private backendService: BackendService,
    private route: ActivatedRoute,
    private modalService: ModalsService,
    private spinner: NgxSpinnerService,
    private clienteService: ClienteService,
    private router: Router,
    private seguridadService: SeguridadService
  ) { }

  async ngOnInit() {

    this.selectPais = localStorage.getItem('pais');

    this.route.data.subscribe(data => {
      this.pedido = data.pedido;

      if(this.pedido.pais.id == PaisEnum.CHILE){
      this.iva = 1.19
    }else{
      this.iva = 1.21
    }
      
    });
    try{
      await this.backendService.get(PedidoSimple, Pedido.new_path + 'pedido-simple/' + this.pedido.id).toPromise().then(a =>{
        this.pedidoSimple = a as PedidoSimple;
        this.productoList = this.pedidoSimple.productos;
      })
    }catch(ex){
        this.user = this.seguridadService.user;
        if(this.user){
          this.router.navigateByUrl("/mis-pedidos");
        }else{
          this.router.navigateByUrl("/");
        }

    }
    
    this.costoEnvioIva = this.getPrecioConIva(this.pedido.costoEnvio);

    this.valorSituacionIvaId = await this.backendService.getNumber(Cliente.path + 'obtener-situacionIva/' + this.pedido.id);

    if(this.pedido.descuentoPedido){
      this.verificarCodigoDescuento();
    }
    this.getPrecioFinalAdicionales();
  }

  getColoresDesc(colores) {
    return colores.map(c => c.description).join(', ');
  }

  getTotal() {
    if (this.valorSituacionIvaId == 14){
      return this.productoList.reduce((acum, producto) =>
      acum + (((producto.precioUnitario) + (producto.precioAdicional
        * producto.cantidadLogos)) * producto.cotizacionMoneda * producto.cantidad), 0); 
    } else {
      return this.productoList.reduce((acum, producto) =>
      acum + (((producto.precioConIva) + (producto.precioAdicional
        * producto.cantidadLogos)) * producto.cotizacionMoneda * producto.cantidad), 0);
    }    
  }

  async openModal(productoId) {
    this.modalService.previsualizarModal(productoId);
  }

  abrirModal(src, producto: ProductoSimple){
    
        //producto.nombre = producto.producto.nombre;
    this.modalService.abrirImagenResultadoProducto(src, producto);
  }

  getPrecioConIva(precio:number){
    
    var numeroIva = this.round(precio * this.iva)
    
    return numeroIva

  }
  
  
  round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
  }

  async verificarCodigoDescuento(){
   
    try{

        this.descuentoAplicablePedido = this.pedido.descuentoPedido;
  
        var descuento = ((this.descuentoAplicablePedido.descuento*-1 /100) +1);
  
        this.descuentoAplicablePedido.productosAplicablesId.forEach(e => {
          
          this.productoList.forEach(prod =>{
            
             if(e == prod.id){
               prod.precioConIva = this.round(prod.precioConIva * descuento);
               prod.precioUnitario = this.round(prod.precioUnitario * descuento);
             }
          })
        
      })

    }catch(ex){

    }    
  }
  //Calcula el precio final con iva en caso de adicionales 
  getPrecioFinalAdicionales(){
    this.productoList.forEach(p => {
      this.precioFinalActualizado = (this.precioFinalActualizado) + (p.precioConIva * p.cantidad);
    
    })
    this.precioFinalActualizado = this.precioFinalActualizado + this.costoEnvioIva;
    // @ts-ignore
    if(this.precioFinalActualizado>(this.pedido.precioFinal)){
      // @ts-ignore
      this.pedido.precioFinal = this.precioFinalActualizado;
    }
  }

  descargarFactura(pedidoId){
    try{
      this.spinner.show();
      this.backendService.getPdf(Pedido.new_path +'factura/'+ pedidoId, null).toPromise().then(a =>{
        this.createBlob(a);
        })
    }catch(ex){
        console.log("Error Factura")
    }finally{
      this.spinner.hide();
    }
   
    
  }
  createBlob(pdf: any) {
    const blob = new Blob([pdf], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  downloadRenders(renders){
    for(let i = 0; i < renders.length; i++){
      this.getFiles(renders[i]);
    }
  }
  async getFiles(d: Render) {
    await this.clienteService.getRenderById(d.id).subscribe(data => {
      const link = document.createElement('a');
      const blob = new Blob([data], {type: data.type});
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = "render.jpg";
      link.click();
    }, error => {
      console.log(error);
    });
  }

  roundPrice(unitPrice, quantity){
    return Math.ceil((unitPrice*quantity)*this.iva);
  }

}
