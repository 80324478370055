import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { BackendService } from "src/app/core/backend.service";
import { ToastService } from "src/app/shared-services/toast/toast.service";
import { ProductoParticularPedido } from "../../models/producto-particular-pedido.model";
import { PedidoSimple } from "../models/pedido-simple.model";
import { Pedido } from "../models/pedido.model";

@Injectable()
export class PagoAdicionalResolver implements Resolve<Pedido>{

    constructor(
        protected router: Router,
        protected backendService: BackendService,
      ) {}
    
      async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
        try {
          const id = route.params.id;
    
          return await  this.backendService.get(Pedido, Pedido.new_path + id).toPromise();
        } catch (error) {
          this.router.navigate(['/']);
        }
      }

}