import { BackendModel } from "src/app/core/backend.service";



export class RegistroTemporal extends BackendModel{

  static path = "/registro-temporal"

  username:string;
  password: string;
  code: number;
    
  }
  