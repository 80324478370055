import { BackendModel, BackendService } from "../../core/backend.service";
import { Parametric } from "../../cliente/parametric.model";
import { Imagen } from "../viejo/imagen.model";
import {Categoria} from '../../shared-components/dashboard/categoria.model';
import {TipoImpresion} from './tipo-impresion';
import { Pais } from "src/app/shared-services/models/pais.model";


export class Producto extends BackendModel {

    static path = '/productos/';
    static new_path = '/new-productos/';

    id: number;
    sku: number;
    nombre: string;
    detalle: string;
    descripcion: string;
    activo: boolean;
    medidaProducto: string;
    medidaPackaging: string;
    volumen: string;
    peso: string;
    unidades: number;
    tallesNumericos: Parametric[];
    proveedorId: number;
    clienteId: number;
    imagenes: Imagen[];
    talles: Parametric[];
    tiposImpresion: TipoImpresion[];
    precioDefault: number;
    precio: number;
    categorias:Categoria[];
    cantidadMinimaOrden: number;
    precioFinal: number;
    pais:Pais;

    constructor(protected backendService: BackendService, model?: Producto) {
        super(backendService, model);
    }
}
