import { BackendModel, BackendService } from "src/app/core/backend.service";

export class Proveedor extends BackendModel {

    static path = '/productos/proveedores/';
  
    id: number;
    razonSocial: string;
    cuit: string;
    observaciones: string;
  
    constructor(protected backendService: BackendService, model?: Proveedor) {
      super(backendService, model);
    }
  }