import { Component, OnInit, Input } from '@angular/core';
import { Anuncio } from '../../core/anuncio.model';

@Component({
  selector: 'app-anuncios-vista',
  templateUrl: './anuncios-vista.component.html',
  styleUrls: ['./anuncios-vista.component.scss']
})
export class AnunciosVistaComponent implements OnInit {

  @Input() rows: Anuncio[];

  constructor() { }

  ngOnInit() {
  }

}
