import { Producto } from '../models/producto.model';
import { Parametric } from '../../cliente/parametric.model';
import { BackendModel, BackendService } from '../../core/backend.service';
import { Color } from '../color.model';


export class Imagen extends BackendModel {

  static path = 'subir_imagen';
  file: File;
  path: string;
  id: number;
  name: string;
  archivoId: number;
  url?: any;
  color: Color;
  colores: Color[];
  tipoImagen: Parametric;
  width_imagen: number;
  height_imagen: number;
  resource: File;

  constructor(protected backendService: BackendService, model?: Producto) {
    super(backendService, model);
  }
}
