import { Injectable, Inject } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Correo } from './correo.model';
import { Http, Response, Headers } from '@angular/http';
import { Environment } from '../../../environments/enviroment.model';
import { EnvironmentProvider } from '../../core/browser-providers';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Registro } from '../registro.model';

@Injectable()
export class CorreoResolver implements Resolve<Correo> {

  private backendUrl: string;
  private clientesPath: string;


  constructor(
    @Inject(EnvironmentProvider) env: Environment,
    protected router: Router,
    protected http: Http,
  ) {
    this.backendUrl = env.endpoints.apiFront;
    this.clientesPath = env.endpoints.clientes;
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const hash = route.params.hash;

    this.validateHash(Correo.path + hash).then((data) => {
      return;
    })
    .catch((ex) => {
      if (ex.status === 404) {
        this.router.navigate(['/login/link-invalido']);
      }
      if (ex.status === 400) {
        this.router.navigate(['/login/cliente-bloqueado']);
      }
    });
    return null;
  }

  async validateHash(path) {
    const url = this.backendUrl + this.clientesPath + Registro.path + path;

    return this.http.post(url, null, { headers: this.loginHeaders() }).map(res => {
      return res.status === 200;
    }).catch(res => {
      // console.log(res);
      throw res;
    }).toPromise();
  }

  private loginHeaders() {

    return new Headers(
      { 'Content-Type': 'application/x-www-form-urlencoded;' }
    );
  }
}
