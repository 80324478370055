import { BackendModel } from "src/app/core/backend.service";

export class PaymovilPago{
    tarjetaNum:string;
    tarjetaCode:string;
    tarjetaExpiration:string;
    titularName:string;
    titularDni:string;
    amount:string;
    cuotas:string;
    metodoPago:string;
    numPedido:string;
}