export class Mail {

  tipoEmailId: number;
  direccion: string;
  principal: boolean;
  id: number;

  constructor(tipoId: number, direccion: string, principal: boolean) {
    this.tipoEmailId = tipoId;
    this.direccion = direccion;
    this.principal = principal;
  }

}
