import { BackendService, BackendModel } from '../core/backend.service';
import { Parametric } from '../cliente/parametric.model';


export class Contacto {

  static path = '/contacto/';

  email: string;
  nombre: string;
  apellido: string;
  telefono: string;
  mensaje: string;
  problema: Parametric;

}
