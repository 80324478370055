import { Injectable } from '@angular/core';
import { Cliente } from '../cliente/cliente.model';
import { BackendService } from '../core/backend.service';
import { Router } from '@angular/router';
import { PaisEnum } from '../core/constants/pais-enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaisService {

  private checkCarritoPais = new BehaviorSubject<any>(undefined);

  public pais = this.checkCarritoPais.asObservable();
  constructor(
    private backendService: BackendService,
    private router: Router
  ) { }

  async detectCountry(){
    var selectPais = '';
    try{
      await this.backendService.getNumber(Cliente.path +'detectar-pais').then(a=>{
        var idPais = a as Number
        if(PaisEnum.ARGENTINA == idPais){
            selectPais = 'ARGENTINA';
            localStorage.setItem('pais', selectPais);
        }else if(PaisEnum.CHILE == idPais){
            selectPais = 'CHILE';
            localStorage.setItem('pais', selectPais);
        }else{
          this.router.navigateByUrl('/select-country');
        }
      });
    }catch(ex){
      this.router.navigateByUrl('/select-country');
    }
  }
  emitChangePais(tradingData: any) {
    localStorage.setItem('pais', tradingData);
    this.checkCarritoPais.next(tradingData);
  }
}
