import { BackendModel, BackendService } from '../../core/backend.service';

export class TipoEnvio extends BackendModel {

  static path = '/tipos-envio/';

  description: string;
  costo: number;

  constructor(protected backendService: BackendService, model?: TipoEnvio) {
      super(backendService, model);
  }
}
