import {Parametric} from '../../../cliente/parametric.model';
import {Telefono} from '../../../cliente/telefono.model';
import {BackendModel, BackendService} from '../../../core/backend.service';
import {Direccion} from '../../../cliente/direccion.model';


export class Perfil extends BackendModel {
    static path = '/perfil/';

    name: string;
    lastname: string;
    fechaNacimiento: string;
    tipoDocumentoId: number;
    documento: string;
    telefonoPrincipal: number;
    telefonoAlternativo: number;
    telefonoOtro: number;
    genero: number;
    direccion: string;
    cp: number;
    numero: number;
    direcciones: Direccion[];
    piso: string;
    dpto: string;
    provincia: string;
    partido: string;
    localidad: string;
    entreCalle1: string;
    entreCalle2: string;
    //Empresa
    cuit:string;
    razonSocial:string;
    situacionIva:number;
    tipoUsuario:number;
    empresa:string;

  constructor(protected backendService: BackendService, model?: Parametric) {
    super(backendService, model);
  }
}
