import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap';
import {Color} from '../../../color.model';
import {ColorModal} from './model/color-modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LogoTexto} from './model/logo-texto';

@Component({
  selector: 'app-add-text-modal',
  templateUrl: './add-text-modal.component.html',
  styleUrls: ['./add-text-modal.component.scss']
})
export class AddTextModalComponent implements OnInit {

  logoTexo : LogoTexto;
  fonts: string[] = [];
  sizes: number[] = [];
  colores: ColorModal[] = [];

  @ViewChild('canvas') canvas;
  public onClose: Subject<LogoTexto>;

  ctx:CanvasRenderingContext2D;
  canvasSend: HTMLCanvasElement;

  fuente:string = "Arial";
  size: number = 70;
  color: string = "Black";
  texto : string = "";

  textomostrado: string;
  form: FormGroup;

  textos: string[] = [];
  contenedorCanvasWidth;
  constructor(public bsModalRef: BsModalRef,
              public bsModalRefTiempoEntrega: BsModalRef,
              private formBuilder: FormBuilder,
              ) { }


  ngOnInit() {
    this.logoTexo = new LogoTexto();
    this.onClose = new Subject();

    this.form = this.formBuilder.group({
      texto: [undefined, [Validators.required]],
      size: [70],
      color: ["Black"],
      fuente: ["Arial"],
    });

    this.fonts.push("Arial");
    this.fonts.push("Roboto");
    this.fonts.push("Helvetica");
    this.fonts.push("Time New Roman");
    this.fonts.push("Gotham");
    this.fonts.push("Aero");


    for(let i = 30; i < 71;i++){
      this.sizes.push(i);
    }

    var negro : ColorModal= new ColorModal();
    negro.color ="Negro";
    negro.colorIngles= "Black";

    var blanco : ColorModal = new ColorModal();
    blanco.color ="Blanco";
    blanco.colorIngles= "White";

    var rojo : ColorModal= new ColorModal();
    rojo.color ="Rojo";
    rojo.colorIngles= "red";

    var amarillo : ColorModal = new ColorModal();
    amarillo.color ="Amarillo";
    amarillo.colorIngles= "Yellow";

    var azul : ColorModal = new ColorModal();
    azul.color ="Azul";
    azul.colorIngles= "Blue";

    var verde : ColorModal = new ColorModal();
    verde.color ="Verde";
    verde.colorIngles= "Green";

    var marron : ColorModal = new ColorModal();
    marron.color ="Marron";
    marron.colorIngles= "Brown";

    var gris : ColorModal = new ColorModal();
    gris.color ="Gris";
    gris.colorIngles= "grey";

    this.colores.push(negro);
    this.colores.push(blanco);
    this.colores.push(rojo);
    this.colores.push(amarillo);
    this.colores.push(azul);
    this.colores.push(verde);
    this.colores.push(marron);
    this.colores.push(gris);


    this.form.valueChanges.subscribe( data =>{
      let documento = document.getElementById("contenedorCanvas");
      this.contenedorCanvasWidth = documento.offsetWidth;
      this.cambiarTexto(data);
    });


  }
  confirma() {
    this.logoTexo.canvasSend = this.canvasSend;
    this.logoTexo.fuente = this.fuente;
    this.logoTexo.color = this.color;
    this.logoTexo.size = this.size;
    this.logoTexo.texto = this.textomostrado;
    //Solucionar este seteo: this.logoTexo.nombrefile = this.textomostrado.replace(' ', "_");
    this.logoTexo.nombrefile = "";
    this.onClose.next(this.logoTexo);
    this.bsModalRef.hide();
  }
  agregarTexto(event){
    //this.texto = event.target.value;
    //this.cambiarTexto();
  }
  cerrar(){
    this.onClose.next(undefined);
    this.bsModalRef.hide();
  }
  cambiarTexto(data){
    let posicion = 0;

    if(data.texto==null)
      return;

    this.textomostrado = data.texto;

    this.canvasSend = this.canvas.nativeElement;
    this.ctx = this.canvasSend.getContext('2d');

    this.canvasSend.height= data.size;
    this.canvasSend.width= data.size * data.texto.length;

    this.ctx.clearRect(0,0,this.canvasSend.width,this.canvasSend.height);
    this.ctx.fillStyle = "grey";
    this.ctx.fillRect(0, 0, this.canvasSend.width, this.canvasSend.height);
    this.ctx.font = data.size+"px" + " " + data.fuente;
    this.ctx.fillStyle = data.color;
    this.ctx.textBaseline = 'top';
    this.ctx.fillText(data.texto, 0, 0);

    this.canvasSend.width=this.ctx.measureText(data.texto).width;
    this.ctx.clearRect(0,0,this.canvasSend.width,this.canvasSend.height);
    this.ctx.font = data.size+"px" + " " + data.fuente;
    this.ctx.fillStyle = data.color;
    this.ctx.textBaseline = 'top';
    this.ctx.lineCap="butt";
    this.ctx.fillText(data.texto, 0, 0);
  }
  public errorHandling = (control: string, error: string) => {
    return this.form.controls[control].hasError(error);
  }
}
