import {BackendModel, BackendService} from '../../core/backend.service';
import {Imagen} from '../../productos/viejo/imagen.model';

export class ProductoUnicaImagen extends BackendModel {
  nombre: string;
  imagen: Imagen;
  precioDefault: number;
  precio: number;
  precioIva:number;
  listaImagenes: Imagen[];
  numImagen: Number;
  precioFinalAnterior: number;

  constructor(protected backendService: BackendService, model?: ProductoUnicaImagen) {
    super(backendService, model);
  }
}
