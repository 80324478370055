import { BackendModel, BackendService } from "src/app/core/backend.service";

export class TemporalToken extends BackendModel {

    static path = '/clientes/registro/send-phone';

    temporalToken: string;  
    constructor(protected backendService: BackendService, model?: TemporalToken) {
      super(backendService, model);
    }
  
  }