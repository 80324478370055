import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Anuncio } from '../../core/anuncio.model';
import { BackendService } from '../../core/backend.service';

@Component({
  selector: 'app-anuncios-importantes',
  templateUrl: './anuncios-importantes.component.html',
  styleUrls: ['./anuncios-importantes.component.scss']
})
export class AnunciosImportantesComponent implements OnInit {

  public anunciosList: Anuncio[];
  public lastAnuncio: Anuncio;
  constructor(private router: Router,
  private backendService: BackendService) { }

  ngOnInit() {
    this.getAnunciosList();
  }

  async getAnunciosList() {
    this.anunciosList = await this.backendService.get(Anuncio, Anuncio.path + 'importantes/all', null).toPromise();
    if (this.anunciosList.length > 0) {
      this.lastAnuncio = this.anunciosList[0];
    } else {
      this.router.navigate(['/']);
    }
  }

  continue() {
    this.router.navigate(['/']);
  }

}
