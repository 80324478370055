import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { Gtag } from 'angular-gtag';
import { BsModalRef } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { Cliente } from 'src/app/cliente/cliente.model';
import { BackendService } from 'src/app/core/backend.service';
import { UsuarioService } from 'src/app/core/usuario.service';
import { DatosPersonalesModel } from 'src/app/productos/resultado-producto/models/datos-personales.model';
import { UserCredentials } from 'src/app/seguridad/login/models/user-credentials';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { FormValidatorServiceService } from 'src/app/shared-services/form-validator-service.service';
import { ToastService } from 'src/app/shared-services/toast/toast.service';

@Component({
  selector: 'app-datos-personales-modal',
  templateUrl: './datos-personales-modal.component.html',
  styleUrls: ['./datos-personales-modal.component.scss']
})
export class DatosPersonalesModalComponent implements OnInit {

  public usuario = { nombreCompleto: '', empresa: '',email: '', numTel: ''};

  @ViewChild('datosPersonalesForm') public datosPersonalesForm: NgForm;

  public onClose: Subject<boolean>;
  selectPais: string;
  textoPattern= "[A-Za-z]+[ A-za-z]*";
  datosPersonales: DatosPersonalesModel;
  isValidFormSubmitted:boolean;
  randomPassword: string;

  constructor(
    public bsModalRef: BsModalRef,
    private backendService: BackendService,
    private formValidatorService: FormValidatorServiceService,
    private spinner: NgxSpinnerService,
    private seguridadService: SeguridadService,
    private toast: ToastService,
    private usuarioService :UsuarioService,
    private gtag:Gtag
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
    this.selectPais = localStorage.getItem('pais');
    this.datosPersonales = new DatosPersonalesModel();
  }

  onFormSubmit(){
    if(this.validateForm()){
      this.gtagContinuar();
      this.spinner.show();
      this.saveDatosPersonales();
    }
  }

  cerrar(){
    this.gtagCierraModal();
    this.bsModalRef.hide();
  }

  validateForm(){
    return this.formValidatorService.isFormValid(this.datosPersonalesForm);
  }

  async saveDatosPersonales(){
    this.setDatosPersonales();
    try{
    await this.backendService.create(Cliente.path + 'datos-personales-temp-user/' + this.seguridadService.user.id, this.datosPersonales).then( a => {
      // @ts-ignore
      this.randomPassword = a._body as string;
    });
    var credentials =  new UserCredentials();
    credentials.username = this.datosPersonales.email;
    credentials.password = this.randomPassword;
    const retorno = await this.seguridadService.login(credentials).then(a=>{
      this.onClose.next(false);
      this.bsModalRef.hide();
      this.spinner.hide();
    });
  }
    catch(ex){
      this.spinner.hide();
      if(ex.message == "El usuario ya esta registrado"){
        this.usuarioService.setUsuarioCliente(this.datosPersonales.email);
        this.onClose.next(true);
        this.bsModalRef.hide();
      }
      this.toast.openErrorGenerico(ex.message)
    }
    
  }

  setDatosPersonales(){
    /*const nombreCompleto = (<HTMLInputElement>document.getElementById("nombreCompleto")).value;
    const email = (<HTMLInputElement>document.getElementById("email")).value;
    const empresa = (<HTMLInputElement>document.getElementById("empresa")).value;
    const numTel = (<HTMLInputElement>document.getElementById("numTel")).value;
    this.datosPersonales.nombreCompleto = nombreCompleto;
    this.datosPersonales.email = email;
    this.datosPersonales.empresa = empresa;
    this.datosPersonales.numTel = numTel;
    */
    this.datosPersonales.nombreCompleto = this.usuario.nombreCompleto;
    this.datosPersonales.email = this.usuario.email;
    this.datosPersonales.empresa = this.usuario.empresa;
    this.datosPersonales.numTel = this.usuario.numTel;
  }

  completarMasTarde(){
    this.gtagCompletarMasTarde();
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  habilitarLoginModal(){
    this.setDatosPersonales();
    this.gtagInicioSesion();
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  gtagCompletarMasTarde(){
    this.gtag.event('completa_mas_tarde_modal', {
      items: [{
        item_name: "Completa mas tarde modal"}]
    });
  }

  gtagContinuar(){
    this.gtag.event('continua_modal_datos_personales', {
      items: [{
        item_name: "Continua modal datos personales"}]
    });
  }

  gtagCierraModal(){
    this.gtag.event('cierra_modal_datos_personales', {
      items: [{
        item_name: "cierra modal datos personales"}]
    });
  }

  gtagInicioSesion(){
    this.gtag.event('iniciar_sesion_modal_datos_personales', {
      items: [{
        item_name: "iniciar sesion modal datos personales"}]
    });
  }

}
