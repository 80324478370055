import { ApiService } from '../../core/api.service';
import { BackendService } from '../../core/backend.service';
import { Component, OnInit, Input, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ModalsService } from 'src/app/shared-services/modals.service';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss']
})
export class NotificacionesComponent implements OnInit {

  @Input() public cliente: any;
  private operationalConfig = {};

  constructor(
    private apiService: ApiService,
    private backendService: BackendService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: ModalsService
  ) { }

  ngOnInit() {
    this.operationalConfig = this.cliente.configuraciones;
  }

  async save() {
    const confirm = await this.modalService.openConfirmModal('Confirma que desea modificar la configuración de las notificaciones?',
                                        'Notificaciones', 'Si', 'No');

    confirm.subscribe(async result => {
      if (result) {
        try {
          this.spinner.show();
          await this.backendService.saveConfig('/configuraciones/cambiar', this.operationalConfig);
        } catch (error) {
          this.spinner.hide();
          this.toastr.error('Error al guardar la configuración');
          console.log(error);
        } finally {
          this.spinner.hide();
          this.toastr.info('Configuración de notificaciones guardadas!');
        }
      }
    });
  }

}
