import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import { DireccionCompleta } from 'src/app/cliente/direccionCompleta.model';
import { BackendService } from 'src/app/core/backend.service';
@Injectable()
export class DireccionInfoResolver {

    direccion:DireccionCompleta;

  constructor(
    protected router: Router,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    try {
      const id = route.params.id;

      this.direccion = await  this.backendService.get(DireccionCompleta, '/perfil/direccion/' + id).toPromise();


      return this.direccion

    } catch (error) {
      this.router.navigate(['/']);
    }
  }
}
