import { Component, OnInit } from '@angular/core';
import { Subject } from '../../../../../node_modules/rxjs';
import { BsModalRef } from '../../../../../node_modules/ngx-bootstrap';

@Component({
  selector: 'app-aceptar',
  templateUrl: './aceptar.component.html',
  styleUrls: ['./aceptar.component.scss']
})
export class AceptarComponent implements OnInit {

  titulo: string;
  mensaje: string;
  yes: string;

  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  confirma() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
}
