import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BackendService} from "../../core/backend.service";
import {FormValidatorServiceService} from "../../shared-services/form-validator-service.service";
import {StrengthPasswordService} from "../../shared-services/strength-password.service";
import {ToastrService} from "ngx-toastr";
import {ModalsService} from "../../shared-services/modals.service";
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {Cliente} from "../../cliente/cliente.model";
import {ChangePassword} from "./models/change-password";
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/shared-services/toast/toast.service';

@Component({
  selector: 'app-cambiar-password',
  templateUrl: './cambiar-password.component.html',
  styleUrls: ['./cambiar-password.component.scss']
})
export class CambiarPasswordComponent implements OnInit {

  hash: any;
  showPassword= false;
  passwordStrengthColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#3ADCD6'];
  visibilidad:any;
  visibilidadConfimar:any;
  private passwordValidations = [
    
    {
      check: (password) => (password.length > 7 && password.length < 17),
      error: "Error: La contraseña debe tener entre 8 y 16 caracteres"
    },
    {
      check: (password) => (/[A-Za-z]+/.test(password)),
      error: "Error: En la contraseña faltan letras"
    },
    {
      check: (password) => (/[0-9]+/.test(password)),
      error: "Error: En la contraseña faltan números"
    },
    /* VERIFICACION CARACTERES ESPECIALES
    {
      check: (password) => (/[!\"$%&/()=?@~`\\.\';:+=^*_-]+/.test(password)),
      error: "Error: En la contraseña faltan caracteres especiales"
    },*/
    ]

  form: FormGroup;

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              private backendService: BackendService,
              private formBuilder: FormBuilder,
              private spinner: NgxSpinnerService,
              private formValidatorService: FormValidatorServiceService,
              @Inject(StrengthPasswordService) private passwordStrength,
              private toastr: ToastrService,
              private toast: ToastService,
              public modalService: ModalsService) {
  }

  ngOnInit() {

    this.visibilidad = document.getElementById("login-form-username");
    this.visibilidadConfimar = document.getElementById("login-form-repass");

    this.route.data.subscribe(data => {
      this.hash = data.hash;
    });
    this.form = this.formBuilder.group({
      password: [undefined, [Validators.required,Validators.minLength(7)]],
      re_password: [undefined, [Validators.required,Validators.minLength(7)]]
    });
  }

  async save() {
    
    this.spinner.show();
    
    this.saveRole();
     
  }

  async saveRole() {
      
      var password =  Object.assign(new ChangePassword(), this.form.value);
      const fuerzaPassword = this.passwordStrength.measureStrength(password.password);

      const validationNotFulfilled = 
      this.passwordValidations.find(validation =>
      !validation.check(password.password)
      );
    
    if(validationNotFulfilled){
      this.toast.openErrorGenerico(validationNotFulfilled.error)
      this.spinner.hide()
      return
    }
      if (fuerzaPassword <= 2) {
        this.spinner.hide();
        this.toast.openErrorGenerico('La contraseña ingresada debe tener mayor nivel de seguridad.');
      } else {
        try {
          password.password = password.password;
          
          await this.backendService.update(Cliente.path_seguridad + 'reset-password/' + this.hash, password);
          this.toast.openSuccessGenerico('Clave modificada correctamente');
          this.router.navigate(['/login/recuperar-password/exitoso']);
          this.spinner.hide();
        } catch (ex) {
          this.spinner.hide();
          this.toast.openErrorGenerico(ex.message);
          this
        }
      }
    
  }

  public errorHandling = (control: string, error: string) => {
    return this.form.controls[control].hasError(error);
  }
  passwordIguales(){
    return this.form.value.password==this.form.value.re_password;
  }

  passwordVisible(){

    if (this.visibilidad.type === "password") {
      this.visibilidad.type = "text";
    } else {
      this.visibilidad.type = "password";
    }
      
  }

  passwordVisible2(){

    if (this.visibilidadConfimar.type === "password") {
      this.visibilidadConfimar.type = "text";
    } else {
      this.visibilidadConfimar.type = "password";
    }
      
  }

}
