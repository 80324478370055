import { BackendModel, BackendService } from './backend.service';
import { DecimalPipe } from '@angular/common';


export class Sesion extends BackendModel {

    static path = '/sesiones/';

    usuario: any;
    fechaHora: Date;
    ip: string;
    latitud: string;
    longitud: string;
    ciudad: string;
    pais: string;
    sistemaOperativo: string;
    browser: string;
    userAgent: string;
    id: any;

  constructor(protected backendService: BackendService, model?: Sesion) {
    super(backendService, model);
  }

}
