import { Injectable } from "../../../node_modules/@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "../../../node_modules/@angular/router";
import { BackendService } from "../core/backend.service";
import { ProductoParticularPedido } from "./models/producto-particular-pedido.model";

@Injectable()
export class ProductoResolver {

    constructor(
        protected router: Router,
        protected backendService: BackendService,
      ) {}

      async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        try {
          const id = route.params.id;

          return await  this.backendService.get(ProductoParticularPedido, '/producto-acuerdo/' + id).toPromise();
        } catch (error) {
          this.router.navigate(['/']);
        }
      }

}
