import { Component, OnInit, ViewChild } from '@angular/core';
import { BackendService } from '../core/backend.service';
import { Router } from '@angular/router';
import { Contacto } from './contacto.model';
import { NavigationService } from '../shared-services/navigation.service';
import { ResourceLoader } from '@angular/compiler';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../core/api.service';
import { Parametric } from '../cliente/parametric.model';
import { Observer, Observable } from 'rxjs';
import { ToastService } from '../shared-services/toast/toast.service';
import { NgForm } from '@angular/forms';

interface Filters {
  name: string;
  page: number;
  size: number;
  email: string;
  search?: string;
}

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  public contacto: Contacto;
  public problemas: Parametric[];

  @ViewChild('contactoForm') public contactoForm: NgForm;


  constructor(
    private router: Router,
    private backendService: BackendService,
    private apiService: ApiService,
    private navigationService: NavigationService,
    private toastr: ToastrService,
    private toast: ToastService
  ) { }

  ngOnInit() {
    this.contacto = new Contacto();
    this.getProblemas();
  }

  async getProblemas() {
    const parametricPromise = await this.apiService.get(Parametric, Parametric.path + 'tipos_problemas/', null).toPromise();
    (async () => {
      try {
        this.problemas = await parametricPromise;
        return this.problemas;
      } catch (error) {
        return [];
      }
      finally {}
    })();
  }

  async enviarContacto() {
    try {
      this.problemas.forEach(element => {
        if (element.description === 'Otro') {
          this.contacto.problema = element;
        }
      });
      await this.backendService.create(Contacto.path, this.contacto);
      //this.toastr.success('Su mensaje ha sido enviado correctamente, a la brevedad nos contactaremos con usted', 'Contacto');
      this.toast.openSuccessGenerico('Su mensaje ha sido enviado correctamente, a la brevedad nos contactaremos con usted')

    } catch (ex) {
      this.toastr.error(ex.message);
    } finally {
      this.contactoForm.reset();
    }
  }

  back() {
    this.navigationService.back();
  }
}
