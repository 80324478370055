import { Directive, ElementRef, Renderer2, Input, OnInit, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { ScrollComunicationService } from './scroll-comunication.service';

@Directive({
  selector: '[appScroll]'
})

export class ScrollDirective implements OnInit, OnDestroy {
  @Input() element: ElementRef;
  @Input() className: string;
  @Output() onAddClass = new EventEmitter<boolean>();
  @Output() onRemoveClass = new EventEmitter<boolean>();
  subscription: Subscription;
  private posYLimite = 0;

  constructor(
    private renderer: Renderer2,
    private scrollComunicationService: ScrollComunicationService,
  ) {}

  ngOnInit() {

    this.subscription = this.scrollComunicationService.onScroll.debounceTime(25).subscribe(event => {

      if (this.element.nativeElement.offsetTop > 0) {
        this.posYLimite = this.element.nativeElement.offsetTop;
      }

      if (this.classNotApplied()) {
        if (event.srcElement.scrollTop > this.posYLimite) {
          if (!this.heightIsValid(event)) {
            return;
          }

          this.renderer.addClass(this.element.nativeElement, this.className);
          this.onAddClass.emit();
        }
      } else {
        if (event.srcElement.scrollTop === 0) {
          this.renderer.removeClass(this.element.nativeElement, this.className);
          this.onRemoveClass.emit();
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private classNotApplied(): boolean {
    return !this.element.nativeElement.classList.contains(this.className);
  }

  // Esta función valida que la página tenga una altura mínima, para evitar que el scrollTop sea 0 al aplicar la clase.
  private heightIsValid(event: any): boolean {
    const alturaParcial = event.srcElement.clientHeight;
    const alturaHeader = this.element.nativeElement.getBoundingClientRect().height;
    const alturaTotal = event.srcElement.scrollHeight;

    return alturaParcial + alturaHeader <= alturaTotal;
  }
}
