import { BackendModel, BackendService } from "../../core/backend.service";
import { ImagenBanner } from "./imagenBanner.model";

export class Banner extends BackendModel {

    static path = '/banner/';
    nombre: string;
    fechaDesde: Date;
    fechaHasta: Date;
    activo: boolean;
    imagen: ImagenBanner;

    constructor(protected backendService: BackendService, model?: Banner) {
        super(backendService, model);
    }
}
