import { Producto } from "./producto.model";
import { BackendService, BackendModel } from "../../core/backend.service";
import { Parametric } from "../../cliente/parametric.model";
import { Imagen } from "../viejo/imagen.model";
import { Logo } from "./logo.model";
import { Tarifario } from "../viejo/tarifario.model";
import { Color } from "../color.model";
import { Cliente } from "../../cliente/cliente.model";
import {Categoria} from '../../shared-components/dashboard/categoria.model';

export class ProductoParticularPedido extends BackendModel {

    static path = '/producto-acuerdo/';

    id: number;
    cantidadMax: number;
    cantidadMin: number;
    cantidad: number;
    colores: Color[];
    producto: Producto;
    precio: number;
    precioIva:number;
    imagenes: Imagen[];
    logos: Imagen[];
    tarifario: Tarifario;
    cliente: Cliente;
    maximoAGastar: number;
    observaciones: string;
    customizaLogos: boolean;
    categorias: Categoria[];
    precioFinalAnterior: number;
    slug:string;

    constructor(protected backendService: BackendService, model?: Producto) {
        super(backendService, model);
    }

}
