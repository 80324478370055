import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormValidatorServiceService } from '../../shared-services/form-validator-service.service';
import { BackendService } from '../../core/backend.service';
import { Sesion } from '../../core/sesion.model';
import { Observer, Observable } from 'rxjs';
import { Operacion } from 'src/app/core/operacion.model';
import { Anuncio } from 'src/app/core/anuncio.model';
import { Saldo } from 'src/app/core/saldo.model';

export class ResultHomeResolver {
  sesiones: Sesion[];
  operaciones: Operacion[];
  anuncios: Anuncio[];
  billetera: Saldo[];
}

@Component({
  selector: 'app-ultimas-sesiones',
  templateUrl: './ultimas-sesiones.component.html',
  styleUrls: ['./ultimas-sesiones.component.scss']
})
export class UltimasSesionesComponent implements OnInit {

  @Input() rows: Sesion[];
  // sesiones: any;

  constructor(private backendService: BackendService,
    private formValidatorService: FormValidatorServiceService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    /*console.log(typeof (this.rows).sesiones);
    console.log(this.rows.sesiones);*/

  }


}
