export class DireccionCompletaDto {

    direccion: string;
    numero: string;
    piso: string;
    departamento: string;
    codigoPostal: string;
    provincia: string;
    entreCalle1:string;
    entreCalle2:string;
    partido:string;
    localidad:string;
        
}