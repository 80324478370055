import { Component, OnInit } from '@angular/core';
import { Pedido } from '../../resultado-producto/models/pedido.model';

@Component({
  selector: 'app-base-checkout',
  templateUrl: './base-checkout.component.html',
  styleUrls: ['./base-checkout.component.scss']
})
export class BaseCheckoutComponent implements OnInit {

  pasoUno:boolean = true;
  pasoDos:boolean = false;
  pasoTres:boolean=false;
  pedido:Partial<Pedido>;

  constructor() { }

  ngOnInit() {
  }

  continuarCompra(pedido){
    this.pedido = pedido;
    this.pasoUno = false;
    this.pasoDos = true;
  }
  continuarCompraDos(pedido){
    this.pedido = pedido;
    this.pasoUno = false;
    this.pasoDos = false;
    this.pasoTres = true;
  }

  volverAPasoUno(boolean){
    this.pasoUno = true;
    this.pasoDos = false;
    this.pasoTres = false;
  }

}
