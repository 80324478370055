import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaisService } from '../shared-services/pais.service';

@Component({
  selector: 'app-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss']
})
export class SelectCountryComponent implements OnInit {

  constructor(
    private router: Router,
    private paisService:PaisService
  ) { }

  ngOnInit() {
    if(localStorage.getItem('pais') != null){
      this.router.navigateByUrl('/');
    }
  }

  seleccionaPais(pais: string){
    localStorage.setItem('pais', pais);
    var dato = localStorage.getItem('pais');
    debugger
    this.paisService.emitChangePais(pais);
    debugger
    this.router.navigateByUrl('/')
  }

}
