import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificacionesCarrito } from 'src/app/shared-services/notificacion-carrito.service';
import { Pedido } from '../../resultado-producto/models/pedido.model';
import { BackendService } from 'src/app/core/backend.service';
import { Subject } from 'rxjs';
import { Route, Router } from '@angular/router';
import { PaisService } from 'src/app/shared-services/pais.service';

declare const $: any;

@Component({
  selector: 'app-confirmar-tiempo-de-entrega-modal',
  templateUrl: './confirmar-tiempo-de-entrega-modal.component.html',
  styleUrls: ['./confirmar-tiempo-de-entrega-modal.component.scss']
})
export class ConfirmarTiempoDeEntregaModalComponent implements OnInit {

  public onClose: Subject<boolean>;

  pedido: Pedido;
  hayProductos: number;

  diasProduccion:number;
  public selectPais:string;
  constructor(public bsModalRef: BsModalRef,
    private spinner: NgxSpinnerService,
    public backendService: BackendService,
    public notificacionesCarrito: NotificacionesCarrito,
    public router: Router,
    public paisService: PaisService) { }
  
  async ngOnInit() {
    this.spinner.hide()
    this.onClose = new Subject();
    this.selectPais = localStorage.getItem('pais');
    if(this.selectPais == null){
      await this.paisService.detectCountry().then(a=>{
        this.selectPais = localStorage.getItem('pais');
      });
    }
  }

  async actualizarCarrito(){
    await this.backendService.getNumber(Pedido.new_path + 'estado-activo-simple/' + this.selectPais).then(cantidad =>{ 
      this.hayProductos = cantidad as number;
    });
      this.notificacionesCarrito.emitChange(this.hayProductos);
  }

  confirmar(){
    this.spinner.show()
    this.onClose.next(true);
    this.hayProductos = this.notificacionesCarrito.getCantidadProductosCarrito()
    this.notificacionesCarrito.emitChange(this.hayProductos + 1);
    this.bsModalRef.hide();
  }

  cancelar(){
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

}
