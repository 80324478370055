import { Injectable, Inject } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Anuncio } from './anuncio.model';
import { BackendService } from './backend.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AnuncioResolver implements Resolve<Anuncio> {
  constructor(
    protected router: Router,
    protected backendService: BackendService,
    private toastr: ToastrService
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const id = route.params.id;

      return await this.backendService.get(Anuncio, Anuncio.path + id).toPromise();
    } catch (error) {
      this.toastr.error(error.message);
      this.router.navigate(['/anuncios']);
    }
  }
}
