import { NumberValueAccessor } from "@angular/forms/src/directives";
import { ProductoParticularPedido } from "../../models/producto-particular-pedido.model";
import { Parametric } from "../../../cliente/parametric.model";
import { ProductoEnviar } from "../../viejo/productoEnviar.model";
import { BackendService, BackendModel } from "../../../core/backend.service";
import { Acuerdo } from "../../viejo/acuerdo.model";
import { Direccion } from "src/app/cliente/direccion.model";
import {Render} from '../../producto-particular-logo/model/render';
import { ProductoSimple } from "./producto-simple.model";

export class PedidoSimple  extends BackendModel {

    id: number;
    estado: Parametric;
    fecha: Date;
    productos: ProductoSimple[];
    direccion: string;
    tipoEnvio: string;
    tiempoEntrega: number;

    constructor(protected backendService: BackendService, model?: PedidoSimple) {
        super(backendService, model);
    }
}
