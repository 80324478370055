import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-no-implementada',
  templateUrl: './no-implementada.component.html',
  styleUrls: ['./no-implementada.component.scss']
})
export class NoImplementadaComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
  }

}
