import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-selector-registro',
  templateUrl: './selector-registro.component.html',
  styleUrls: ['./selector-registro.component.scss']
})
export class SelectorRegistroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
