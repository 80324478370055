import { Component, OnInit, Inject, AfterViewInit, AfterContentInit } from '@angular/core';
import { RegistroPaso4 } from '../../../registro/paso4/registroPaso4.model';
import { Parametric } from '../../../cliente/parametric.model';
import { EnvironmentProvider } from '../../../core/browser-providers';
import { Environment } from '../../../../environments/enviroment.model';
import { ApiService } from '../../../core/api.service';
import { BackendService } from '../../../core/backend.service';
import { ToastrService } from 'ngx-toastr';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioService } from '../../../core/usuario.service';
import { Cliente } from 'src/app/cliente/cliente.model';
import { NotificacionesComunicationService } from 'src/app/shared-services/notificaciones-comunication.service';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-documentacion',
  templateUrl: './documentacion.component.html',
  styleUrls: ['./documentacion.component.scss']
})
export class DocumentacionComponent implements OnInit{

  public tiposDocumentacion: Parametric[];
  private hash: string;
  private archivosList = new Array<RegistroPaso4>();
  private clientesPath: string;

  private cantidadInicial = 0;

  constructor(
    @Inject(EnvironmentProvider) env: Environment,
    private apiService: ApiService,
    private backendService: BackendService,
    private toastr: ToastrService,
    private toast: ToastService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private usuarioService: UsuarioService,
    private notificacionesComunicationService: NotificacionesComunicationService,
  ) {
    this.clientesPath = env.endpoints.clientes;
  }


  ngOnInit() {

    this.hasDocumentacionPendiente();

    if (!this.hasDocumentacionPendiente) {
      this.router.navigate(['/']);
    } else {
      this.getTiposDocumentacion();
    }

  }

  async getTiposDocumentacion() {
    this.tiposDocumentacion = await this.backendService.get(Parametric, Cliente.path + 'get_documentacion_pendiente/', null).toPromise();

    this.tiposDocumentacion.sort((a, b) => {
      return a.id - b.id;
    });
    for (const tipoDocumentacion of this.tiposDocumentacion) {
      this.archivosList.push(new RegistroPaso4(undefined, tipoDocumentacion.id, tipoDocumentacion.description));
      this.cantidadInicial++;
    }

  }

  async hasDocumentacionPendiente() {
    this.hasDocumentacionPendiente = await this.backendService.hasDocumentacionPendiente.toPromise();
  }

  addFile(event, index) {
    this.archivosList[index].file = event.target.files[0];
  }

  isFirst(index) {
    if (index < this.cantidadInicial) {
      return true;
    }
    return false;
  }
  maxLength(tipoDocId) {
    let cont = 0;
    for (let i = 0; i < this.archivosList.length; i++) {
      if (this.archivosList[i].tipoDocumentacion === tipoDocId) {
        cont++;
      }
    }
    if (cont === 3) {
      return false;
    }
    return true;
  }

  agregarArchivo(tipoDoc) {
    this.archivosList.push(new RegistroPaso4(undefined, tipoDoc.id, tipoDoc.description));
  }
  quitarArchivo(index: number) {
    this.archivosList.splice(index, 1);
  }

  validForm() {
    if (this.archivosList.length === 0) {
      this.toast.openErrorGenerico('Debe cargar al menos un archivo para poder continuar.');
    } else {
      if (this.soloImagenesOPdf()) {
        this.save();
      }
    }
  }

  soloImagenesOPdf() {
    const re = /(?:\.([^.]+))?$/;
    let ext;

    for (let i = 0; i < this.archivosList.length; i++) {

      if (this.archivosList[i].file === undefined && this.archivosList[i].tipoDocumentacion !== 5) { // DESHARDCODEAR
        this.toast.openErrorGenerico('Falta subir por lo menos un archivo del tipo ' + this.archivosList[i].descDocumentacion);
        return false;
      }

      if (this.archivosList[i].file !== undefined) { // DESHARDCODEAR
        ext = re.exec(this.archivosList[i].file.name)[1];
        ext = ext.toLowerCase();
        if (ext !== 'png' && ext !== 'jpg' && ext !== 'pdf') {
          this.toast.openErrorGenerico('Solo estan permitidos archivos de tipo ".png", ".jpg" o ".pdf"');
          return false;
        }
      }
    }

    return true;

  }

async save() {
  try {
    this.spinner.show();

    const archivos = this.archivosList;
    const formdata: FormData = new FormData();
    const response = await this.usuarioService.getHashForUpload();
    this.hash = response.text();
    for (let i = 0; i < archivos.length; i++) {
      if (archivos[i].file !== undefined) {
        formdata.append('file', archivos[i].file);
        formdata.append('tipoDocumentacion', archivos[i].tipoDocumentacion.toString());
        formdata.append('hash', this.hash);
        await this.backendService.createFile(this.clientesPath + RegistroPaso4.path, formdata);
        formdata.delete('file');
        formdata.delete('tipoDocumentacion');
        formdata.delete('hash');
      }
    }
    await this.backendService.create(this.clientesPath + '/finalizar_documentacion', this.hash);
    this.toast.openSuccessGenerico('Documentación actualizada correctamente');
    this.router.navigate(['/perfil-usuario']);
    window.scrollTo(0, 0);
  } catch (ex) {
    this.toast.openErrorGenerico(ex.message);
  } finally {
    this.spinner.hide();
  }
}
}
