import { Component, OnInit } from '@angular/core';
import { Operacion } from '../../core/operacion.model';
import { BackendService } from '../../core/backend.service';

@Component({
  selector: 'app-listado-operaciones',
  templateUrl: './listado-operaciones.component.html',
  styleUrls: ['./listado-operaciones.component.scss']
})
export class ListadoOperacionesComponent implements OnInit {

  public operacionesList: Operacion[];
  constructor(private backendService: BackendService) { }

  ngOnInit() {
    this.getOperacionesList();
  }

  async getOperacionesList() {
    const operacionesPromise = await this.backendService.get(Operacion, Operacion.path + 'all', null).toPromise();

    const obj = await (async () => {
      try {
        return await operacionesPromise;
      } catch (error) {
        return [];
      }
      finally {}
    })();

    this.operacionesList = Object.keys(obj).map(key => (obj[key]));

  }

}
