import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgForm } from '@angular/forms';
import { BackendService } from 'src/app/core/backend.service';
import { EnvironmentProvider } from 'src/app/core/browser-providers';
import { Environment } from 'src/environments/enviroment.model';
import { FormValidatorServiceService } from 'src/app/shared-services/form-validator-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { RegistroEmpresa } from './registro-empresa.model';
import { ModalsService } from 'src/app/shared-services/modals.service';
import { Parametric } from 'src/app/cliente/parametric.model';
import { ApiService } from 'src/app/core/api.service';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { UserCredentials } from 'src/app/seguridad/login/models/user-credentials';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { PaisService } from 'src/app/shared-services/pais.service';

@Component({
  selector: 'app-registro-empresa',
  templateUrl: './registro-empresa.component.html',
  styleUrls: ['./registro-empresa.component.scss']
})
export class RegistroEmpresaComponent implements OnInit {

  public passwordStrengthColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#3ADCD6'];
  selectPais: string;
  public visibilidad:any;
  public visibilidad2:any;

  public situacionesIva:Parametric[];

  static path = '/newParametricas/';

  @ViewChild('registroForm') public userForm: NgForm;

  public usuario = {username: '', cuit: '', razonSocial: '', situacionIva: '0', email: '', password: '', retryPassword: '', TYC: '', captcha: ''};
  private usuarioPaso1 = new RegistroEmpresa();
  private clientesPath: string;
  public captcha: boolean;
  private errorPassword = "Las Contraseñas no coinciden, vuelve a intentar !";
  private passwordValidations = [
    {
      check: () => (!(this.usuario.retryPassword != this.usuario.password)),
      error: "Error: Las contraseñas deben ser iguales"
    },
    {
      check: (password) => (password.length > 7 && password.length < 17),
      error: "Error: La contraseña debe tener entre 8 y 16 caracteres"
    },
    {
      check: (password) => (/[A-Za-z]+/.test(password)),
      error: "Error: En la contraseña faltan letras"
    },
    {
      check: (password) => (/[0-9]+/.test(password)),
      error: "Error: En la contraseña faltan números"
    },
    /* VERIFICACION CARACTERES ESPECIALES
    {
      check: (password) => (/[!\"$%&/()=?@~`\\.\';:+=^*_-]+/.test(password)),
      error: "Error: En la contraseña faltan caracteres especiales"
    },*/
    {
      check: () => (this.formValidatorService.isFormValid(this.userForm)),
      error: "Error: El formulario es invalido"
    }]

  constructor(
    @Inject(EnvironmentProvider) env: Environment,
    private router: Router,
    private modalService: ModalsService,
    private backendService: BackendService,
    private formValidatorService: FormValidatorServiceService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private seguridadService: SeguridadService,
    private paisService: PaisService,
    private toast : ToastService) {
      this.clientesPath = env.endpoints.clientes;
      this.captcha = env.captcha;
    }

  async ngOnInit() {

    this.selectPais = localStorage.getItem('pais');
    if(this.selectPais == null){
        await this.paisService.detectCountry().then(a=>{
        this.selectPais = localStorage.getItem('pais');
      });
    }

    this.situacionesIva = await this.apiService.get(Parametric, '/newParametricas/' + 'situaciones-iva/', null).toPromise();
    //this.situacionesIva = await this.apiService.get(Parametric, Parametric.path + 'situaciones_iva/', null).toPromise();
    this.visibilidad = document.getElementById("registerPass");
    this.visibilidad2 = document.getElementById("registerPass2");

  }

  openTyCModal() {
    this.modalService.openTyC();
  }

  async save() {

    let password = this.usuario.password;

    const validationNotFulfilled = 
    this.passwordValidations.find(validation =>
      !validation.check(password)
    );
    
    if(validationNotFulfilled){
      this.toast.openErrorGenerico(validationNotFulfilled.error)
    } 
    else{this.invokeSave();}
    
  }

  validateForm() {
    
    return this.formValidatorService.isFormValid(this.userForm)
    
  }

  private async invokeSave() {

    if (this.validateForm()) {
      this.spinner.show();
      this.saveUsuarioPaso1();
    }

    return;
  }

  public resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response ${captchaResponse}:`);
  }

  async saveUsuarioPaso1() {
  
    
    this.usuarioPaso1.username = this.usuario.email;
    this.usuarioPaso1.cuit = this.usuario.cuit;
    this.usuarioPaso1.razonSocial = this.usuario.razonSocial;
    this.usuarioPaso1.situacionIva = this.usuario.situacionIva;
    
    this.usuarioPaso1.email = this.usuario.email;
    this.usuarioPaso1.password = this.usuario.password;

    try {
      await this.backendService.create(this.clientesPath + "/registro-empresa" + "/" + this.selectPais, this.usuarioPaso1);
      await this.autoLogin();
    } catch (ex) {
      console.log(ex);
      if (ex instanceof Array) {
          ex.forEach(element => {
            this.toast.openErrorGenerico(element.details);
          });
      } else {
        this.toast.openErrorGenerico(ex.message);
      }
    } finally {
      this.spinner.hide();
    }
  }

  passwordVisible(){

  if (this.visibilidad.type === "password") {
    this.visibilidad.type = "text";
  } else {
    this.visibilidad.type = "password";
  }
    
  }

  passwordVisible2(){

    if (this.visibilidad2.type === "password") {
      this.visibilidad2.type = "text";
    } else {
      this.visibilidad2.type = "password";
    }
      
  }

  async autoLogin(){
    var credentials =  new UserCredentials();
      credentials.username = this.usuarioPaso1.username;
      credentials.password =this.usuarioPaso1.password;
      const retorno = await this.seguridadService.login(credentials).then(a=>{
        this.spinner.hide();
        //this.router.navigateByUrl('/producto-logo/' + productoCarrito.infoProducto.id);
        this.router.navigate(['/registro/cuenta-creada']);
      });
  }

}
