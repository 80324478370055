export class DatosPersonales {

  static path = '/registro/paso_2';

  nombre: string;
  apellido: string;
  fechaNacimiento: string;
  documento: string;
  generoId: number;
  public situacionIvaId: number;
  cuitCuil: string;

}
