import { Component, OnInit, Input } from '@angular/core';
import { Saldo } from '../../core/saldo.model';
import { BackendService } from '../../core/backend.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ModalsService } from 'src/app/shared-services/modals.service';

@Component({
  selector: 'app-billetera',
  templateUrl: './billetera.component.html',
  styleUrls: ['./billetera.component.scss']
})
export class BilleteraComponent implements OnInit {

  @Input() rows: Saldo[];

  constructor(private backendService: BackendService,
    public ngxSmartModalService: NgxSmartModalService,
    public modalServices: ModalsService) {
  }

  ngOnInit() {
  }

}
