import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-tyc-modal',
  templateUrl: './tyc-modal.component.html',
  styleUrls: ['./tyc-modal.component.scss']
})
export class TycModalComponent implements OnInit{

  constructor(public bsModalRef: BsModalRef) {}

  public onClose: Subject<boolean>;
  async ngOnInit() {
    
    this.onClose = new Subject();
  }
  noConfirma() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
  
  confirma() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }


}
