import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {BackendService} from "../../../core/backend.service";

@Injectable()
export class ClienteResetPasswordResolver implements Resolve<any> {

  constructor(
    protected router: Router,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const hash = route.params.hash || 'error';

      if (hash === 'error') {
        this.router.navigate(['/login/link-invalido']);
      }

      return this.validateHash(hash).then((data) => {
        return hash;
      })
        .catch((ex) => {
          if (ex.status === 404) {
            this.router.navigate(['/login/link-invalido']);
          }
        });

    } catch (error) {
      this.router.navigate(['/login']);
    }
  }

  async validateHash(hash) {

    const path = '/seguridad_clientes/reset-password/' + hash;

    return await this.backendService.consumirHash(path, hash).toPromise();
  }

  private loginHeaders() {

    return new Headers(
      { 'Content-Type': 'application/x-www-form-urlencoded;' }
    );
  }
}
