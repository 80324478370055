import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { Categoria } from '../dashboard/categoria.model';
import { NotificacionesComunicationService } from '../../shared-services/notificaciones-comunication.service';
import { Router } from '../../../../node_modules/@angular/router';
import { Subscription } from '../../../../node_modules/rxjs';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit, AfterViewInit {

  @Input() categoria: Categoria;
  subscriptions: Subscription[] = [];

  constructor(
    public dashboard: DashboardComponent,
    public notificacionesComunicationService: NotificacionesComunicationService,
    public router: Router,
  ) { }

  async ngOnInit() {
    await this.subscriptions.push(this.notificacionesComunicationService.onCambioCategoria.subscribe(async cat => {
      this.markActive(cat);
    }));
  }

  ngAfterViewInit() {
    if (this.categoria) {
      const categoriaMarked: HTMLElement = document.getElementById('sidebar' + this.categoria.id);
      categoriaMarked.style.backgroundColor = '#e2e2e2';
      categoriaMarked.getElementsByTagName('a')[0].style.color = '#753bbd';
    }
  }

  collapse(id: number) {
    let boton = document.getElementById('boton' + id);
    let simbolo = document.getElementById('simbolo' + id);
    let collapse = document.getElementById('collapse' + id);
    if (simbolo.classList.contains("fa-plus-circle") && !collapse.classList.contains('show') && !collapse.classList.contains('collapsing')) {
      simbolo.classList.remove("fa-plus-circle"); //remove the class
      simbolo.classList.add("fa-minus-circle"); //add the class
      return;
    } else if (simbolo.classList.contains("fa-minus-circle") && collapse.classList.contains('show')) {
      simbolo.classList.remove("fa-minus-circle"); //remove the class
      simbolo.classList.add("fa-plus-circle"); //add the class
      return;
    }
  }

  markActive(categoria) {
    for (let i = 0; i < this.dashboard.categorias.length; i++) {
      document.getElementById('sidebar' + this.dashboard.categorias[i].id).style.backgroundColor = 'white';
      document.getElementById('sidebar' + this.dashboard.categorias[i].id).getElementsByTagName('a')[0].style.color = '#8a8a8d';
    }
    if (categoria) {
      const categoriaMarked: HTMLElement = document.getElementById('sidebar' + categoria.id);
      categoriaMarked.style.backgroundColor = '#e2e2e2';
      categoriaMarked.getElementsByTagName('a')[0].style.color = '#753bbd';
      this.notificacionesComunicationService.filtersChange(categoria.description);
    } else {
      this.notificacionesComunicationService.filtersChange(null);
    }
  }
}
