


export class RegistroSimple{

    static path = "/registro-simple"

    email:string;
    tipoUsuario: string;
    
  }
  