import { Injectable, Inject } from '@angular/core';
import { HttpModule, Http } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';

import { Environment } from '../../environments/enviroment.model';
import { EnvironmentProvider, StorageProvider } from '../core/browser-providers';
import { HttpHeaders } from '@angular/common/http';

import * as moment from 'moment';
import {BackendService} from "../core/backend.service";
import {UserCredentials} from "./login/models/user-credentials";

export interface SignInCredential {
  username: string;
  password: string;
  app: number;
}

@Injectable()
export class SeguridadService {

  private tokenId = 'jwtToken';
  private jwtTokenExpiredData = 'jwtTokenExpiredData';
  private backendUrl: string;

  constructor(@Inject(EnvironmentProvider) env: Environment,
              @Inject(StorageProvider) private storage: Storage,
              private http: Http,
              private router: Router,
              private backendService: BackendService,
              ) {
    this.backendUrl = env.endpoints.api;
  }

  public logged(): Boolean {

    if (!this.token || !this.isValidToken()) {
      return false;
    }

    return true;
  }

  get token() {
    //return localStorage.getItem(this.tokenId);
    return this.getCookie(this.tokenId);

  }

  get user() {
    if (!this.logged()) {
      return;
    }

    const data = JSON.parse(atob(this.token.split('.')[1]));

    const names = [data.name, data.lastname];

    return {
      id: data.id,
      email: data.sub,
      path: data.path,
      name: names.every(n => n) ? names.join(' ') : names.find(n => n) || '--',
    };
  }

  login(credentials: UserCredentials) {
    const credenciales = {app: 1, password: credentials.password, username: credentials.username, code: credentials.code};
    
    const url = this.backendUrl + '/login/';
    return this.http.post(url, credenciales, { headers: this.loginHeaders() }).map(res => {
      if (res.json().loginCorrecto) {
        const token = res.json().token;
        //Cambio de local storage a cookie
        //localStorage.setItem(this.tokenId, token);
        document.cookie = this.tokenId + '=' + token + ";path=/;";
        const tokenData = this.tokenData(token);
        const expiresAt = moment.utc('1970-01-01', 'YYYY-MM-DD').add(tokenData.exp, 'second');
        //localStorage.setItem(this.jwtTokenExpiredData, JSON.stringify(expiresAt.valueOf()));
        document.cookie = this.jwtTokenExpiredData + '=' + JSON.stringify(expiresAt.valueOf())+";path=/;" ;
        return token as string;
      } else {
        if (res.json().requiereCode) {
          return res.json().token;
        }
      }


    }).catch(res => {
      throw res;
    }).toPromise();

  }

  async recuperarPassword(username) {

    const url = '/seguridad_clientes/recover-password';
    await this.backendService.sendEmailRecoverPassword( url, username);
  }

  logout() {
    this.deleteCookie(this.tokenId); 
    this.router.navigateByUrl('/');
  }

  logoutTemporalSesion(){
    this.deleteCookie(this.tokenId);
  }

  deleteCookie(name: string) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  private loginHeaders() {

    return new Headers(
      { 'Content-Type': 'application/ld+json' }
    );
  }

  cambiarPassword() {
    const tokenData = this.tokenData(this.token);
    return tokenData.cambiarPassword;
  }

  authHeaders() {
    return new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
  }

  private tokenData(token) {
    return JSON.parse(atob(token.split('.')[1]));
  }

  private isValidToken() {
    return moment().isBefore(this.getExpiration());
  }

  private getExpiration() {
    //const expiration = localStorage.getItem(this.jwtTokenExpiredData);
    const expiration = this.getCookie(this.jwtTokenExpiredData);

    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  redirectToDefault() {
    this.router.navigate(['']);
  }

  getCookie(value:string) {
    const cookies = document.cookie.split(';');
    const myCookie = cookies.find(cookie => cookie.trim().startsWith(value +'='));
    
    if (myCookie) {
      const value = myCookie.split('=')[1];
      return value;
    } 
    return myCookie;
  }

}
