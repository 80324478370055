import { Mail } from "./mail.model";
import { Telefono } from "./telefono.model";
import { Role } from "./role.model";
import { BackendModel, BackendService } from "../core/backend.service";

export class Usuario extends BackendModel{

  static path = '/usuarios_internos/';

  username: string;
  name: string;
  lastname: string;
  emails: Mail[];
  telefonos: Telefono[];
  roles: Role[];
  enable: boolean;

  constructor(protected backendService: BackendService, model?: Usuario) {
    super(backendService, model);
  }
}
