import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Pedido } from '../../resultado-producto/models/pedido.model';
import { ActivatedRoute } from '@angular/router';
import { ProductoSimple } from '../../resultado-producto/models/producto-simple.model';
import { PedidoSimple } from '../../resultado-producto/models/pedido-simple.model';
import { BackendService } from 'src/app/core/backend.service';
import { Gtag } from 'angular-gtag';
import { PaisEnum } from 'src/app/core/constants/pais-enum';
import { DescuentoPedido } from '../../resultado-producto/models/descuento-pedido.model';
import { ToastService } from 'src/app/shared-services/toast/toast.service';

@Component({
  selector: 'app-checkout-uno-adicionales',
  templateUrl: './checkout-uno-adicionales.component.html',
  styleUrls: ['./checkout-uno-adicionales.component.scss']
})
export class CheckoutUnoAdicionalesComponent implements OnInit {

  @Output() continuarCompraEvent = new EventEmitter<Partial<Pedido>>();

  pedido: Pedido;
  pedidoSimple: PedidoSimple;
  subtotal: number;
  productos: ProductoSimple[];
  iva: number;

  constructor(
    private route: ActivatedRoute,
    private backendService: BackendService,
    private gtag: Gtag,
    private toast: ToastService
  ) { }

  async ngOnInit() {
    await this.getPedidoData();
    await this.setDatosAdicionales();
    if(this.pedido.descuentoPedido){
      this.verificarCodigo();
    }else{
      this.getSubtotal();
    }
    
  }

  async getPedidoData(){
    this.route.data.subscribe(data => {
      this.pedido = data.pedido as Pedido;
      this.pedido.subtotal = this.pedido.precioFinal;
      console.log(this.pedido);
    });
    await this.backendService.get(PedidoSimple, Pedido.new_path + 'pedido-simple/'+ this.route.snapshot.paramMap.get("id")).toPromise().then(a =>{
      this.pedidoSimple = a as PedidoSimple;
    });
  }

  setDatosAdicionales(){
    this.subtotal = this.pedido.precioFinal;
    this.productos = this.pedidoSimple.productos;
  }
  setIva(){
    if(this.pedido.pais.id == PaisEnum.CHILE){
      this.iva = 1.19
    }else{
      this.iva = 1.21
    }
  }
  async getSubtotal() {
    this.setIva();
    this.subtotal = 0;
    for (let i = 0; i < this.productos.length; i++) {
      this.subtotal = this.subtotal + (this.productos[i].cantidad * this.productos[i].precioConIva) + (this.productos[i].precioAdicional * this.productos[i].cantidadLogos);
    }
      this.subtotal = (this.pedido.costoEnvio * this.iva) + this.subtotal;
      this.pedido.subtotal = this.subtotal;
      this.pedido.precioFinal = this.subtotal;
  }

  getDescriptions(colores) {
    if(colores)
      return colores.map(c => c.description).join(', ');

    return "No hay colores."
  }

  continuarCompra(){
    this.continuarCompraEvent.emit(this.pedido);
  }

  wspEventAr(){
    this.gtag.event('boton_whatsapp_argentina', {
      items: [{
        item_name: "boton whatsapp argentina"
      }]
    });
  }

  async verificarCodigo(){
    try{
      debugger
        var descuento = ((this.pedido.descuentoPedido.descuento*-1 /100) +1);
        debugger
        this.pedido.descuentoPedido.productosAplicablesId.forEach(e => {
          this.productos.forEach(prod =>{  
             if(e == prod.id){
               prod.precioConIva = this.round(prod.precioConIva * descuento);
               prod.precioUnitario = this.round(prod.precioUnitario * descuento);
             }
          })
          debugger
        this.subtotal = 0;
        this.getSubtotal();
      })
      //this.toast.openSuccessGenerico("Código de Descuento Aplicado con Exito " + this.descuentoAplicablePedido.descuento + "%");
    }catch(ex){
      this.toast.openErrorGenerico(ex.message);
    }finally{
      //this.spinner.hide();
    } 
  }
  
  round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
  }

}

