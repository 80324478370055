import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-layout-registro-login',
  templateUrl: './layout-registro-login.component.html',
  styleUrls: ['./layout-registro-login.component.scss']
})
export class LayoutRegistroLoginComponent implements OnInit {

  private mypURL = '/mediosyprensa';
  private soporteURL = '/contacto';
  private comisionesURL = '/comisiones';
  showSignIn: boolean;
  showLogin: boolean;
  background: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.snapshot.children.forEach(r => {
      if (r.data.showSignIn) {
        this.showSignIn = r.data.showSignIn;
      }

      if (r.data.showLogin) {
        this.showLogin = r.data.showLogin;
      }

      if (r.data.background) {
        this.background = r.data.background;
      }
    });

  }

  ngOnInit() {
  }

}
