import { Injectable } from '@angular/core';

import { Router, NavigationStart } from '@angular/router';

@Injectable()
export class NavigationService {
  previousURL: string = undefined;

  constructor(
    private router: Router,
  ) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.previousURL = this.router.url;
      }
    });
  }

  async back(defaultURL?: string) {
    await this.router.navigateByUrl(this.previousURL !== '/' ? this.previousURL : (defaultURL || '/'));
  }
}
