import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../core/api.service';
import { SeguridadService } from '../../seguridad/seguridad.service';
import { ToastrService } from '../../../../node_modules/ngx-toastr';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';
import { ModalsService } from '../../shared-services/modals.service';
import { NgForm } from '../../../../node_modules/@angular/forms';
import { NgxSmartModalService } from '../../../../node_modules/ngx-smart-modal';
import { FormValidatorServiceService } from '../../shared-services/form-validator-service.service';
import { UsuarioService } from '../../core/usuario.service';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.scss']
})
export class ConfiguracionComponent implements OnInit {

  private cliente: any;
  confirmacion = false;
  protected QRurl: string;
  public showQR = false;
  public showCode = false;
  private activar = 'Activar';
  private desac = 'Desactivar';
  public perfilOperational = { twoFAHabilitado: false, code: undefined };

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private seguridadService: SeguridadService,
    private toastr: ToastrService,
    private toast: ToastService,
    private spinner: NgxSpinnerService,
    private modalService: ModalsService,
    private usuarioService: UsuarioService,
    private formValidatorService: FormValidatorServiceService,
    public ngxSmartModalService: NgxSmartModalService,
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.cliente = data.cliente;
    });
    this.perfilOperational.twoFAHabilitado = this.cliente.twoFAHabilitado;
  }

  async asd() {
    if (this.confirmacion) {
      await this.desactivar();
    } else if (this.perfilOperational.twoFAHabilitado !== this.cliente.twoFAHabilitado) {
      await this.invokeTwoFaUpdate();
    }
  }

  async desactivar() {

    const confirm = await this.modalService.openConfirmModal(
      // tslint:disable-next-line:max-line-length
      '¿Confirma que desea desactivar su cuenta? Recuerde que esta es una acción permanente, en caso de querer deshacerla se deberá comunicar con soporte.',
    'Desactivación de cuenta', 'Si', 'No');

    confirm.subscribe( async result =>{
      if (result) {
        this.spinner.show();
        try {
          await this.apiService.update('/frontend/subclientes/desactivar_cuenta', null);
          this.seguridadService.logout();
          this.toast.openSuccessGenerico('Su cuenta ha sido desactivada con exito.');
        } catch (ex) {
          this.toast.openErrorGenerico(ex.message);
        } finally {
          this.spinner.hide();
          this.confirmacion = false;
        }
      } else {
        this.confirmacion = false;
      }
    });
  }

  validateTwoFaForm() {
      if (this.perfilOperational.code) {
        return true;
      } else {
        this.toast.openErrorGenerico('Ingrese el código 2FA.');
        return false;
      }
  }

  private async invokeTwoFaUpdate() {
    if (this.validateTwoFaForm()) {
      this.updateTwoFa();
    }
  }

  // Se habilita/deshabilita el 2fa
  async updateTwoFa() {
    this.spinner.show();
    try {
      await this.apiService.create('/usuarios/validate2FA', this.perfilOperational);
      this.showQR = false;
      this.showCode = false;
      this.cliente.twoFAHabilitado = this.perfilOperational.twoFAHabilitado;
      this.perfilOperational.code = undefined;
      this.toast.openSuccessGenerico('Configuración de 2FA actualizada');
    } catch (ex) {
      this.toast.openErrorGenerico(ex.message);
    }
    finally {
      this.spinner.hide();
    }
  }

  async onCheckboxChange() {
    if (!this.cliente.twoFAHabilitado) {
      if (this.perfilOperational.twoFAHabilitado) {
        const response = await this.usuarioService.getQRurl();
        this.QRurl = response.text();
        this.showQR = true;
        this.showCode = true;
      } else {
        this.showQR = false;
        this.showCode = false;
      }
    } else {
      if (this.perfilOperational.twoFAHabilitado) {
        this.showCode = false;
        this.showQR = false;
      } else {
        this.showCode = true;
      }
    }
  }

}
