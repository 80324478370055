import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pedido } from '../../resultado-producto/models/pedido.model';
import { Subject } from 'rxjs';
import { ProductoService } from '../../service/producto.service';
import { Gtag } from 'angular-gtag';
import { NgxSpinnerService } from 'ngx-spinner';
import { BackendService } from 'src/app/core/backend.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { ModalsService } from 'src/app/shared-services/modals.service';
import { DescuentoPedido } from '../../resultado-producto/models/descuento-pedido.model';
import { MercadoPagoPreferenceDto } from '../../resultado-producto/models/mercado-pago-preference-dto';
import { WebpayUrlToken } from '../../resultado-producto/webpay-urlToken.model';
import { MathService } from 'src/app/shared-services/math.service';
import { ProductoSimple } from '../../resultado-producto/models/producto-simple.model';
import { CostoEnvio } from '../../resultado-producto/models/costo-envio';
import { ProductoEnviar } from '../../viejo/productoEnviar.model';
import { PedidoSimple } from '../../resultado-producto/models/pedido-simple.model';
import { UsuarioService } from 'src/app/core/usuario.service';
import { ScrollerService } from 'src/app/core/services/scroller.service';

@Component({
  selector: 'app-checkout-tres',
  templateUrl: './checkout-tres.component.html',
  styleUrls: ['./checkout-tres.component.scss']
})
export class CheckoutTresComponent implements OnInit {

  @Input() paisId: number;
  @Input() pedido: Partial<Pedido>;
  @Output() volverAPasoUnoEvent = new EventEmitter<Boolean>();
  
  //pedido:Pedido;
  public onClose: Subject<boolean>;
  selectPais: string;
  metodoSeleccionado: boolean;
  metodo:string;
  public subtotal = 0;
  public descuentoAplicablePedido: DescuentoPedido = null;
  costoEnvio: number;
  public costoEnvioIva: number;
  webpayUrlToken: WebpayUrlToken;
  public cantidadDeLogos: number = 0;
  public subioLogosCurvas: boolean = false;
  public productos: ProductoEnviar[];
  public pedidoSimple: PedidoSimple;


  constructor(
    private productoService: ProductoService,
    private gtag: Gtag,
    private spinner: NgxSpinnerService,
    private backendService: BackendService,
    private router: Router,
    private toast: ToastService,
    private modalService: ModalsService,
    private mathService: MathService,
    private usuarioService:UsuarioService,
    private scrollerService:ScrollerService
  ) { }

  async ngOnInit() {
    
    this.scrollerService.scrollToTop();

    await this.usuarioService.carritoVolver.subscribe(u => {
      if(u){
        this.volverAPasoUnoEvent.emit(true);
      }
    })

    this.selectPais = localStorage.getItem('pais');
    this.metodoSeleccionado = false;
    
    //this.pedido.id = this.pedidoSimple.id;
    this.productos = this.pedido.productos;

    this.cambiarCostoEnvio(this.pedido.cp);
    this.actualizarCantidadLogosConCurvas();
  }

  seleccionarMetodo(metodo: string) {
    this.metodoSeleccionado = true;
    this.metodo=metodo;
    //this.productoService.setMetodoDePago(metodo);
    //this.gtagMetodoPago(metodo)
    
  }

  gtagMetodoPago(metodo) {
    this.gtag.event('metodo_de_pago_' + metodo, {
      items: [{
        item_name: metodo
      }]
    });
  }

  purchase() {
    let itemsPurchase: any[] = [];
    this.pedidoSimple.productos.forEach((producto)=>{
      itemsPurchase.push({
        item_name: producto.nombre,
        item_id: producto.id,
        price: producto.precioUnitario,
        quantity: producto.cantidad
      });
    });
    this.gtag.event('purchase', {
      transaction_id: this.pedido.id,
      value: this.subtotal + this.costoEnvio,
      shipping: this.costoEnvio,
      currency: "ARS",
      items: itemsPurchase
    });
  }

  async confirmaMetodo(){
    await this.backendService.get(PedidoSimple, Pedido.new_path + 'pedido-simple/'+ this.pedido.id).toPromise().then(a =>{
      this.pedidoSimple = a as PedidoSimple;
    });
    if(this.metodo == "transferencia"){
      this.pagoPorTransferencia();
    }else if(this.metodo == "paymovil"){
      this.pagoConPaymovil();
    }else if(this.metodo == "webpay"){
      this.pagoConWebPay();
    }else if(this.metodo == "mercadoPago"){
      this.pagoPorMercadoPago();
    }
  }

  async pagoPorTransferencia() {

    this.pedido.transferenciaAprobada = false;
    this.spinner.show();

    try {
      /*await this.backendService.update(Pedido.new_path + 'cerrar-pedido', this.pedido).then(a => {
        this.pedido = a as Pedido;
      });*/

      const ress = await this.modalService.transferenciaBancaria(this.pedido, this.pedido.nombreEnvio + " " + this.pedido.apellidoEnvio, null)
      ress.subscribe(async (value) => {

        if (value) {
          this.purchase();
          this.actualizarCantidadLogosConCurvas();
          if (this.cantidadDeLogos > 0) {
            const confirmado = await this.modalService.OpenModalSubirLogoConCurvas(this.pedido.id, this.cantidadDeLogos)
            await confirmado.subscribe(async (value) => {
              if (value) {
                this.subioLogosCurvas = value;
              }
              this.router.navigateByUrl('/mis-pedidos');
            })
          } else {
            this.modalService.openModalComprobanteSubido();
            this.router.navigateByUrl('/mis-pedidos');
          }

        }
      })
    }
    catch (ex) {
      if (ex[0]) {
        this.toast.openErrorGenerico(ex[0].details);
      } else if (ex.errors) {
        this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
      } else {
        this.toast.openErrorGenerico(ex.message);
      }

    }
    finally {
      this.scrollerService.scrollToTop();
      this.spinner.hide();
    }
  }

  async pagoPorMercadoPago() {
    try {
      this.spinner.show();
      await this.backendService.update(Pedido.new_path + 'cerrar-pedido/mercado-pago', this.pedido).then((data: MercadoPagoPreferenceDto) => {
        this.purchase();
        window.location.href = data.initPoint;
      });
    } catch (ex) {
      if (ex[0]) {
        this.toast.openErrorGenerico(ex[0].details);
      } else if (ex.errors) {
        this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
      } else {
        this.toast.openErrorGenerico(ex.message);
      }
    } finally {
      this.scrollerService.scrollToTop();
      this.spinner.hide();
    }
  }

  async pagoConPaymovil() {
    this.scrollerService.scrollToTop();
    const ress = await this.modalService.tarjetaModal(this.mathService.round(this.pedido.subtotal), this.pedido);
    ress.subscribe(async (value2) => {
      if (value2) {
        this.purchase();
        this.actualizarCantidadLogosConCurvas();
        if (this.cantidadDeLogos > 0) {
          this.backendService.update("/new-pedidos/checkout-success/" + this.pedido.id + "/" + 12, null);
          const confirmado = await this.modalService.OpenModalSubirLogoConCurvas(this.pedido.id, this.cantidadDeLogos)
          await confirmado.subscribe(async (value) => {
            if (value) {
              this.subioLogosCurvas = value;
              this.router.navigateByUrl('/mis-pedidos');
            }
            this.router.navigateByUrl('/mis-pedidos');
          })
        } else {
          this.backendService.update("/new-pedidos/checkout-success/" + this.pedido.id + "/" + 8, null);
          this.router.navigateByUrl('/mis-pedidos');
        }
      }
    });
  }
  async pagoConWebPay() {
    try {
      await this.backendService.get(WebpayUrlToken, '/webpay/create/' + this.pedido.id).toPromise().then(a => {
        this.webpayUrlToken = a as WebpayUrlToken;
        window.location.href = this.webpayUrlToken.url + '?token_ws=' + this.webpayUrlToken.token;
      });
    } catch (ex) {
      console.log(ex.message);
    }
    finally {
      this.scrollerService.scrollToTop();
      this.spinner.hide();
    }
  }

  actualizarCantidadLogosConCurvas() {
    this.cantidadDeLogos = 0;
    if(this.pedidoSimple){
      this.pedidoSimple.productos.forEach(prod => {
        this.cantidadDeLogos = prod.cantidadLogos + this.cantidadDeLogos;
      })
    }
    
  }

  cambiarCostoEnvio(codPostal){
    this.spinner.show();
    if(codPostal != 0){
    this.backendService.get(CostoEnvio,CostoEnvio.path+codPostal).toPromise()
      .then((costo: CostoEnvio) =>{
        this.costoEnvio = costo.costoEnvio;
        var iva = 1.21;
        this.costoEnvioIva = this.mathService.round(this.costoEnvio * iva);
      })
      .catch((e) =>{
        this.costoEnvio= null;
        this.toast.openErrorGenerico("CÓDIGO POSTAL INVALIDO");
      });
    }
    this.spinner.hide();
  }

  volver(){
    this.volverAPasoUnoEvent.emit(true);
  }

  gtagContinuar3(){
    this.gtag.event('continua_paso_3_checkout', {
      items: [{
        item_name: "Continua paso 3 checkout"}]
    });
  }

  wspEventAr(){
    this.gtag.event('boton_whatsapp_argentina', {
      items: [{
        item_name: "boton whatsapp argentina"
      }]
    });
  }

}
