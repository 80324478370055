import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cliente-bloqueado',
  templateUrl: './cliente-bloqueado.component.html',
  styleUrls: ['./cliente-bloqueado.component.scss']
})
export class ClienteBloqueadoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
