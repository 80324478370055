import { Injectable } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TycModalComponent } from '../shared-components/modals/tyc-modal/tyc-modal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ConfirmModalComponent } from '../shared-components/modals/confirm-modal/confirm-modal.component';
import { Subject, Observable } from 'rxjs';
import { NoImplementadaComponent } from '../shared-components/modals/no-implementada/no-implementada.component';
import { SolicitudMasInfoComponent } from '../shared-components/modals/solicitud-mas-info/solicitud-mas-info.component';
import { NotificacionComponent } from '../shared-components/modals/notificaciones/notificacion.component';
import { EnvioMailComponent } from '../shared-components/modals/envio-mail/envio-mail.component';
import { AceptarComponent } from '../shared-components/modals/aceptar/aceptar.component';
import { TutorialModalComponent } from '../shared-components/modals/tutorial-modal/tutorial-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { PrevisualizacionComponent } from '../shared-components/modals/previsualizacion/previsualizacion.component';
import {ElegirTipoProductoModalComponent} from '../productos/producto-particular/elegir-tipo-producto-modal/elegir-tipo-producto-modal.component';
import { MetodoDePagoModalComponent } from '../productos/metodo-de-pago-modal/metodo-de-pago-modal.component';
import { TransferenciaBancariaModalComponent } from '../productos/transferencia-bancaria-modal/transferencia-bancaria-modal.component';
import { ConfirmarTiempoDeEntregaModalComponent } from '../productos/producto-particular/confirmar-tiempo-de-entrega-modal/confirmar-tiempo-de-entrega-modal.component';
import { ConfirmarMuestraModalComponent } from '../productos/producto-particular/confirmar-muestra-modal/confirmar-muestra-modal.component';
import { ResultadoProductoModalComponent } from '../productos/resultado-producto/modals/resultado-producto-modal/resultado-producto-modal.component';
import {AddLogoModalComponent} from '../productos/producto-particular-logo/modals/add-logo-modal/add-logo-modal.component';
import {AddTextModalComponent} from '../productos/producto-particular-logo/modals/add-text-modal/add-text-modal.component';
import { SeleccionDireccionModalComponent } from '../productos/resultado-producto/modals/seleccion-direccion-modal/seleccion-direccion-modal.component';
import { ComprobanteSubidoModalComponent } from '../productos/transferencia-bancaria-modal/comprobante-subido-modal/comprobante-subido-modal.component';
import { SubirLogosConCurvasComponent } from '../productos/resultado-producto/modals/subir-logos-con-curvas/subir-logos-con-curvas.component';
import { TarjetaComponent } from '../shared-components/modals/tarjeta/tarjeta.component';
import { AdvertenciaRenderModalComponent } from '../productos/resultado-producto/modals/advertencia-render-modal/advertencia-render-modal.component';
import { ConfirmacionAdicionalesModalComponent } from '../productos/resultado-producto/modals/confirmacion-adicionales-modal/confirmacion-adicionales-modal.component';
import { RegistroSimpleModalComponent } from '../productos/producto-particular/modals/registro-simple-modal/registro-simple-modal.component';
import { DatosPersonalesModalComponent } from '../shared-components/modals/datos-personales-modal/datos-personales-modal.component';
import { LoginModalComponent } from '../shared-components/modals/login-modal/login-modal.component';
import { SeleccionarVarianteModalComponent } from '../productos/producto-particular/modals/seleccionar-variante-modal/seleccionar-variante-modal.component';
import { SeleccionarTalleModalComponent } from '../productos/producto-particular/modals/seleccionar-talle-modal/seleccionar-talle-modal.component';
@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  closeResult: string;
  bsModalRef: BsModalRef;
  contador = 0;

  constructor(private modalService: BsModalService,
    private _sanitizer: DomSanitizer) {}

  openTyC() {
    this.open(TycModalComponent, {class: 'modal-lg'});
  }

  openNoImplementada() {
    this.open(NoImplementadaComponent, {class: 'modal-sm'});
  }

  masInformacion() {
    this.open(SolicitudMasInfoComponent, {class: 'modal-sm'});
  }

  tarjetaModal(precio, pedido){
    this.bsModalRef = this.open(TarjetaComponent, {class:'modal-content-fix modal-md'});
    this.bsModalRef.content.amount = precio;
    this.bsModalRef.content.pedido = pedido;
    return this.bsModalRef.content.onClose;
  }

  async openConfirmModal(mensaje, titulo, si, no)  {
    const initialState = { keyboard: false, ignoreBackdropClick: true };
    this.bsModalRef = this.open(ConfirmModalComponent, initialState);
    this.bsModalRef.content.titulo = titulo;
    this.bsModalRef.content.mensaje = mensaje;
    this.bsModalRef.content.yes = si;
    this.bsModalRef.content.no = no;

    return this.bsModalRef.content.onClose;
  }

  open(componente, configuraciones) {
    return this.modalService.show(componente, configuraciones);
  }

  async notificacionesPopup(notificaciones, masInfo) {
    const confirm = await this.notificacion(notificaciones[this.contador].id,
                                            notificaciones[this.contador].asunto,
                                            notificaciones[this.contador].texto);

    confirm.subscribe(async result => {
      if (result) {
        try {
        } catch (error) {
          console.log(error);
        } finally {
          this.contador ++;
          if (notificaciones[this.contador]) {
            this.notificacionesPopup(notificaciones, masInfo);
          } else if (masInfo) {
            this.masInformacion();
            this.contador = 0;
          }
        }
      }
    });

  }

  notificacion(id, asunto, texto) {
    this.bsModalRef = this.open(NotificacionComponent, {class: 'modal-lg', backdrop: 'static', keyboard: false});
    this.bsModalRef.content.id = id;
    this.bsModalRef.content.asunto = asunto;
    this.bsModalRef.content.texto = texto;

    return this.bsModalRef.content.onClose;
  }

  async envioMail(mensaje, titulo, si, no) {
    const initialState = { keyboard: false, ignoreBackdropClick: true };
    this.bsModalRef = this.open(EnvioMailComponent, initialState);
    this.bsModalRef.content.titulo = titulo;
    this.bsModalRef.content.mensaje = mensaje;
    this.bsModalRef.content.yes = si;
    this.bsModalRef.content.no = no;

    return this.bsModalRef.content.onClose;
  }

  async aceptarModal(mensaje, titulo, si) {
    const initialState = { keyboard: false, ignoreBackdropClick: true };
    this.bsModalRef = this.open(AceptarComponent, initialState);
    this.bsModalRef.content.titulo = titulo;
    this.bsModalRef.content.mensaje = mensaje;
    this.bsModalRef.content.yes = si;

    return this.bsModalRef.content.onClose;
  }

  async tutorialModal(titulo, width, height, videoURL, allow) {
    const initialState = { keyboard: false, ignoreBackdropClick: true };
    this.bsModalRef = this.open(TutorialModalComponent, initialState);
    this.bsModalRef.content.titulo = titulo;
    this.bsModalRef.content.iframeWidth = width;
    this.bsModalRef.content.iframeHeight = height;
    this.bsModalRef.content.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(videoURL);
    this.bsModalRef.content.allowOptions = allow;
  }

  async previsualizarModal(id) {
    const initialState = { keyboard: false, ignoreBackdropClick: false, initialState: {id: id}};
    this.bsModalRef = this.open(PrevisualizacionComponent, initialState);

    return this.bsModalRef.content.onClose;
  }

  elegirTipoProducto(productoCarrito, cantidadMinima) {
    this.bsModalRef = this.open(ElegirTipoProductoModalComponent, {class:'modal-content-fix modal-md'});
    this.bsModalRef.content.productoCarrito = productoCarrito;
    this.bsModalRef.content.cantidadMinima = cantidadMinima;
    return this.bsModalRef.content.onClose;
  }

  elegirMetodoDePago(paisId){
    this.bsModalRef = this.open(MetodoDePagoModalComponent, {class:'modal-content-fix modal-md'});
    this.bsModalRef.content.paisId = paisId;
    return this.bsModalRef.content.onClose;

  }

  transferenciaBancaria(pedido, nomb, dni){
    this.bsModalRef = this.open(TransferenciaBancariaModalComponent, {class:'modal-content-fix modal-md'});
    this.bsModalRef.content.pedido = pedido;
    this.bsModalRef.content.nomb = nomb;
    this.bsModalRef.content.dni = dni;
    return this.bsModalRef.content.onClose;

  }

  openSeleccionarVariante(variantes){
    this.bsModalRef = this.open(SeleccionarVarianteModalComponent, {class:'modal-content-fix modal-md'});
    this.bsModalRef.content.variantes = variantes;

    return this.bsModalRef.content.onClose;

  }
  openSeleccionarTalle(varianteSeleccionada){
    this.bsModalRef = this.open(SeleccionarTalleModalComponent, {class:'modal-content-fix modal-md'});
    this.bsModalRef.content.varianteSeleccionada = varianteSeleccionada;

    return this.bsModalRef.content.onClose;

  }

  confirmarMuestra() {
    this.bsModalRef = this.open(ConfirmarMuestraModalComponent, {class: 'modal-lg', backdrop: 'static', keyboard: false,centered: true});
    return this.bsModalRef.content.onClose;
  }

  confirmarTiempoDeEntrega(diasProduccion) {
    this.bsModalRef = this.open(ConfirmarTiempoDeEntregaModalComponent, {class: 'modal-lg', backdrop: 'static', keyboard: false,centered: true});
    this.bsModalRef.content.diasProduccion = diasProduccion;
    return this.bsModalRef.content.onClose;
  }

  abrirImagenResultadoProducto(src, productoModal) {
    this.bsModalRef = this.open(ResultadoProductoModalComponent, {class: 'modal-lg', backdrop: 'static', keyboard: false,centered: true});
    this.bsModalRef.content.src = src;
    this.bsModalRef.content.productoModal = productoModal;
    return this.bsModalRef.content.onClose;
  }
  openModalAddLogo() {
    this.bsModalRef = this.open(AddLogoModalComponent, {class:'modal-content-fix modal-md'});
    return this.bsModalRef.content.onClose;
  }

  openAddTextModal() {
    this.bsModalRef = this.open(AddTextModalComponent, {class:'modal-content-fix modal-md'});
    return this.bsModalRef.content.onClose;
  }

  openModalSeleccionarDireccion() {
    this.bsModalRef = this.open(SeleccionDireccionModalComponent, {class: 'modal-dialog-centered modal-lg xxl', backdrop: 'static', centered: true, size:'xxl'});
    return this.bsModalRef.content.onClose;
  }

  //pantalla de confirmacion
  openModalComprobanteSubido(){
    this.bsModalRef = this.open(ComprobanteSubidoModalComponent, {class:'modal-content-fix modal-md'})
    return this.bsModalRef.content.onClose;
  }

  OpenModalSubirLogoConCurvas(pedidoId,cantidadDeLogos){
    this.bsModalRef = this.open(SubirLogosConCurvasComponent, {class:'modal-content-fix modal-md', backdrop: 'static'});
    this.bsModalRef.content.pedidoId = pedidoId;
    this.bsModalRef.content.cantidadDeLogos = cantidadDeLogos;
    return this.bsModalRef.content.onClose;

  }

  OpenModalAdvertenciaRender(){
    this.bsModalRef = this.open(AdvertenciaRenderModalComponent, {class: 'modal-md', backdrop: 'static', keyboard: false,centered: true});
    return this.bsModalRef.content.onClose;
  }

  OpenModalConfirmacionAdicionales(){
    this.bsModalRef = this.open(ConfirmacionAdicionalesModalComponent, {class:'modal-content-fix'});
    return this.bsModalRef.content.onClose;
  }

  OpenModalRegistroSimple(){
    this.bsModalRef = this.open(RegistroSimpleModalComponent, {class:'modal-content-fix',backdrop: 'static', keyboard: false,centered: true});
    return this.bsModalRef.content.onClose;
  }

  openDatosPersonalesModal(){
    this.bsModalRef = this.open(DatosPersonalesModalComponent, {class:'modal-dialog modal-vc modal-dialog-centered modal-md modalVC modal-conten', backdrop: 'static'});
    return this.bsModalRef.content.onClose;
  }

  openLoginModal(){
    this.bsModalRef = this.open(LoginModalComponent, {class:'modal-dialog modal-vc modal-dialog-centered modal-md modalVC modal-conten', backdrop: 'static'});
    return this.bsModalRef.content.onClose;
  }

}
