import { Component, OnInit } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';
import {Subject} from 'rxjs';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { BackendService } from 'src/app/core/backend.service';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Logo } from '../../model/logo';

@Component({
  selector: 'app-add-logo-modal',
  templateUrl: './add-logo-modal.component.html',
  styleUrls: ['./add-logo-modal.component.scss']
})
export class AddLogoModalComponent implements OnInit {
  
  public logos: Logo[];
  public onClose: Subject<any>;

  constructor(public bsModalRef: BsModalRef,
              private toast: ToastService,
              private backendService: BackendService,
              private seguridadService: SeguridadService,
              private spinner: NgxSpinnerService,) { }

  async ngOnInit() {
    this.spinner.show();
    this.onClose = new Subject();
    if(this.seguridadService.user){
      this.logos = await this.backendService.get(Logo, '/logos/all', null).toPromise();
    }

    this.spinner.hide();
  }
  selectFile(event) {
    let logo;
    if( !event.name){
      logo = event;
      var mimeType = event[0].type;
      if (!mimeType.match(/image\/(png|jpeg|jpg|ai)/)) {
        this.toast.openErrorGenerico("Formato incorrecto de logo. Solo se permiten archivos PNG, JPG, JPEG y AI.");
        return;
      }
      if (!event[0] || event[0].length == 0) {
        return;
      }
      
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
      if (event[0].type.replace(/(.*)\//g, '') == "svg+xml") {
        this.toast.openErrorGenerico("Formato incorrecto de logo.");
        return;
      }
      
    } else {
      logo = event;
    }
       
    this.onClose.next(logo);
    this.bsModalRef.hide();
  }  
  cerrar(){
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
  borrarLogo(id:number){
    this.backendService.delete('/logos/' + id);
    this.bsModalRef.hide();
  }
}
