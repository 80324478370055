import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProductoService} from './service/producto.service';
import { NotificacionesCarrito } from '../shared-services/notificacion-carrito.service';
import {ResultadoProductoComponent} from './resultado-producto/resultado-producto.component';
import {ProductoParticularLogoComponent} from './producto-particular-logo/producto-particular-logo.component';
import {ProductoParticularComponent} from './producto-particular/producto-particular.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ElegirTipoProductoModalComponent} from './producto-particular/elegir-tipo-producto-modal/elegir-tipo-producto-modal.component';
import { LogoResizableComponentComponent } from './producto-particular-logo/logo-resizable-component/logo-resizable-component.component';
import { MetodoDePagoModalComponent } from './metodo-de-pago-modal/metodo-de-pago-modal.component';
import { TransferenciaBancariaModalComponent } from './transferencia-bancaria-modal/transferencia-bancaria-modal.component';
import { AreasImpresionComponent } from './producto-particular-logo/areas-impresion/areas-impresion.component';
import {CheckoutComponent} from './checkout/checkout.component';
import { ConfirmarTiempoDeEntregaModalComponent } from './producto-particular/confirmar-tiempo-de-entrega-modal/confirmar-tiempo-de-entrega-modal.component';
import { ConfirmarMuestraModalComponent } from './producto-particular/confirmar-muestra-modal/confirmar-muestra-modal.component';
import { ResultadoProductoModalComponent } from './resultado-producto/modals/resultado-producto-modal/resultado-producto-modal.component';
import { AddLogoModalComponent } from './producto-particular-logo/modals/add-logo-modal/add-logo-modal.component';
import { AddTextModalComponent } from './producto-particular-logo/modals/add-text-modal/add-text-modal.component';
import { SeleccionDireccionModalComponent } from './resultado-producto/modals/seleccion-direccion-modal/seleccion-direccion-modal.component';
import { ComprobanteSubidoModalComponent } from './transferencia-bancaria-modal/comprobante-subido-modal/comprobante-subido-modal.component';
import { SubirLogosConCurvasComponent } from './resultado-producto/modals/subir-logos-con-curvas/subir-logos-con-curvas.component'; 
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdvertenciaRenderModalComponent } from './resultado-producto/modals/advertencia-render-modal/advertencia-render-modal.component';
import { ConfirmacionAdicionalesModalComponent } from './resultado-producto/modals/confirmacion-adicionales-modal/confirmacion-adicionales-modal.component';
import { ResultadoProductoPagoAdicionalesComponent } from './resultado-producto/resultado-producto-pago-adicionales/resultado-producto-pago-adicionales.component';
import { RegistroSimpleModalComponent } from './producto-particular/modals/registro-simple-modal/registro-simple-modal.component';
import { ResultadoProductoChileComponent } from './resultado-producto/resultado-producto-chile/resultado-producto-chile.component';
import { ResultadoProductoPagoAdicionalesClComponent } from './resultado-producto/resultado-producto-chile/resultado-producto-pago-adicionales-cl/resultado-producto-pago-adicionales-cl.component';
import { BaseCheckoutComponent } from './checkout/base-checkout/base-checkout.component';
import { CheckoutTresComponent } from './checkout/checkout-tres/checkout-tres.component';
import { CheckoutDosComponent } from './checkout/checkout-dos/checkout-dos.component';
import { CheckoutUnoComponent } from './checkout/checkout-uno/checkout-uno.component';
import { RouterModule } from '@angular/router';
import { BaseCheckoutAdicionalesComponent } from './checkout-adicionales/base-checkout-adicionales/base-checkout-adicionales.component';
import { CheckoutUnoAdicionalesComponent } from './checkout-adicionales/checkout-uno-adicionales/checkout-uno-adicionales.component';
import { ProductoParticularService } from './service/producto-particular.service';
import { SeleccionarVarianteModalComponent } from './producto-particular/modals/seleccionar-variante-modal/seleccionar-variante-modal.component';
import { SeleccionarTalleModalComponent } from './producto-particular/modals/seleccionar-talle-modal/seleccionar-talle-modal.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    RouterModule
  ],
  declarations: [
    ResultadoProductoComponent,
    ProductoParticularLogoComponent,
    ProductoParticularComponent,
    ElegirTipoProductoModalComponent,
    LogoResizableComponentComponent,
    AreasImpresionComponent,
    CheckoutComponent,
    MetodoDePagoModalComponent,
    TransferenciaBancariaModalComponent,
    ConfirmarTiempoDeEntregaModalComponent,
    ConfirmarMuestraModalComponent,
    ResultadoProductoModalComponent,
    AddLogoModalComponent,
    AddTextModalComponent,
    SeleccionDireccionModalComponent,
    ComprobanteSubidoModalComponent,
    SubirLogosConCurvasComponent,
    AdvertenciaRenderModalComponent,
    ConfirmacionAdicionalesModalComponent,
    ResultadoProductoPagoAdicionalesComponent,
    RegistroSimpleModalComponent,
    ResultadoProductoChileComponent,
    ResultadoProductoPagoAdicionalesClComponent,
    BaseCheckoutComponent,
    CheckoutDosComponent,
    CheckoutUnoComponent,
    CheckoutTresComponent,
    BaseCheckoutAdicionalesComponent,
    CheckoutUnoAdicionalesComponent,
    SeleccionarVarianteModalComponent,
    SeleccionarTalleModalComponent
  ],
  providers: [
    ProductoService,
    NotificacionesCarrito,
    ProductoParticularService
  ],
  entryComponents: [
    ElegirTipoProductoModalComponent,
    MetodoDePagoModalComponent,
    TransferenciaBancariaModalComponent,
    ConfirmarTiempoDeEntregaModalComponent,
    ConfirmarMuestraModalComponent,
    ResultadoProductoModalComponent,
    AddLogoModalComponent,
    AddTextModalComponent,
    SeleccionDireccionModalComponent,
    ComprobanteSubidoModalComponent,
    SubirLogosConCurvasComponent,
    AdvertenciaRenderModalComponent,
    ConfirmacionAdicionalesModalComponent,
    RegistroSimpleModalComponent,
    SeleccionarVarianteModalComponent,
    SeleccionarTalleModalComponent
  ]
})
export class ProductoModule { }
