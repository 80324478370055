export class RegistroPaso4 {

  static path = '/registro/subir_documentacion';
  file: File;
  tipoDocumentacion: number;
  descDocumentacion: string;

  constructor(file: File, tipoDocumentacion: number, descDocumentacion: string) {
    this.file = file;
    this.tipoDocumentacion = tipoDocumentacion;
    this.descDocumentacion = descDocumentacion;
  }
}
