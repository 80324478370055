import { Component, OnInit, ViewChild, Inject, EventEmitter, Output, Input } from '@angular/core';
import { Parametric } from '../../cliente/parametric.model';
import { DatosPersonales } from './datosPersonales.model';
import { NgForm } from '@angular/forms';
import { EnvironmentProvider } from '../../core/browser-providers';
import { Environment } from '../../../environments/enviroment.model';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BackendService } from '../../core/backend.service';
import { ApiService } from '../../core/api.service';
import { FormValidatorServiceService } from '../../shared-services/form-validator-service.service';
import { DatePipe } from '@angular/common';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { Cliente } from 'src/app/cliente/cliente.model';

@Component({
  selector: 'app-datos-personales',
  templateUrl: './datos-personales.component.html',
  styleUrls: ['./datos-personales.component.scss'],
})
export class DatosPersonalesComponent implements OnInit {

  private paises: Parametric[];
  private paisesExcArg: Parametric[];
  private generos: Parametric[];
  private tiposDocumentoArg: Parametric[];
  private tiposDocumentoExt: Parametric[];
  public situacionesIVA: Parametric[];
  private operationalFechaNacimiento: string;
  private fechaNacimiento: Date = new Date();
  private clientesPath: string;
  public usuario = new DatosPersonales();
  private usuarioCopia = new DatosPersonales();

  @ViewChild('paso2Form') public userForm: NgForm;
  @Output() change = new EventEmitter<number>();
  @Input() clientePromise: Promise<Cliente>;

  private proximoPaso = 1;

  constructor(
    @Inject(EnvironmentProvider) env: Environment,
    public ngxSmartModalService: NgxSmartModalService,
    private backendService: BackendService,
    private apiService: ApiService,
    private formValidatorService: FormValidatorServiceService,
    private toast: ToastService) {
      this.clientesPath = env.endpoints.clientes;
    }

  ngOnInit() {
    this.getGeneros();
    this.getSituacionesIva();
    this.getUserData();
  }

  async getUserData() {
    this.clientePromise.then(
      cliente => {
        this.usuario.apellido = cliente.lastname ? cliente.lastname : undefined;
        this.usuario.nombre = cliente.name ? cliente.name : undefined;
        this.usuario.situacionIvaId = cliente.situacionIva ? cliente.situacionIva.id : undefined;
        this.usuario.cuitCuil = cliente.cuitCuil ? cliente.cuitCuil : undefined;
        this.usuario.generoId = cliente.genero ? cliente.genero.id : undefined;
        //this.usuario.fechaNacimiento = cliente.fechaNacimiento ? cliente.fechaNacimiento : undefined;
        if (this.usuario.fechaNacimiento) {
          this.operationalFechaNacimiento = this.usuario.fechaNacimiento.toString().substring(8, 10) + '/' +
          this.usuario.fechaNacimiento.toString().substring(5, 7) + '/' + this.usuario.fechaNacimiento.toString().substring(0, 4);
        }
      });
  }

  async getGeneros() {
    this.generos = await this.apiService.get(Parametric, Parametric.path + 'generos/', null).toPromise();
  }
  async getSituacionesIva() {
    this.situacionesIVA = await this.apiService.get(Parametric, Parametric.path + 'situaciones_iva/', null).toPromise();
  }

  async save() {
    this.usuarioCopia = this.usuario;
    if (this.operationalFechaNacimiento) {
      const fNacimiento = this.operationalFechaNacimiento.toString().substring(0, 2) + '/' +
        this.operationalFechaNacimiento.toString().substring(2, 4) + '/' + this.operationalFechaNacimiento.toString().substring(4, 8);
      this.usuarioCopia.fechaNacimiento = fNacimiento;
    }

    if (this.validateForm()) {
      this.invokeSave();
    }
  }

  validateForm() {
    let validationsOK = false;
    if (this.formValidatorService.isFormValid(this.userForm)) {
      validationsOK = true;
    }
    return validationsOK;
  }

  private async invokeSave() {
    if (this.validateForm()) {
      this.saveUsuarioPaso2();
    }
    return;
  }

  async saveUsuarioPaso2() {
    try {
      await this.backendService.create(this.clientesPath + DatosPersonales.path, this.usuarioCopia);
      this.change.emit(this.proximoPaso);
    } catch (ex) {
      console.log(ex);
      if (ex[0]) {
        this.toast.openErrorGenerico(ex[0].details);
      } else if (ex.errors) {
        this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
      } else {
        this.toast.openErrorGenerico(ex.message);
      }
    } finally {
    }
  }

}
