import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recuperar-clave-exitoso',
  templateUrl: './recuperar-clave-exitoso.component.html',
  styleUrls: ['./recuperar-clave-exitoso.component.scss']
})
export class RecuperarClaveExitosoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
