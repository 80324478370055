import { Component, OnInit, Input } from '@angular/core';
import { BackendService } from '../../core/backend.service';
import { Usuario } from '../../cliente/usuario.model';
import { ContactoUsuario } from '../../seguridad/usuario/contacto-usuario.component';
import { Imagen } from '../viejo/imagen.model';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';
import { UsuarioService } from '../../core/usuario.service';
import { Foto } from './foto.model';
import { Subcliente } from '../../cliente/subcliente.model';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'app-lista-vacia',
  templateUrl: './lista-vacia.component.html',
  styleUrls: ['./lista-vacia.component.scss']
})
export class ListaVaciaComponent implements OnInit {

  private user: ContactoUsuario;
  private fotos: Foto[];
  sugerencia: string = '';
  nombre: string = '';
  telefono: string = '';

  formValid: boolean = false;

  nombreRequired: boolean = false;
  telefonoRequired: boolean = false;
  sugerenciaRequired: boolean = false;
  telefonoInvalido: boolean = false;

  // Variables para rastrear si los campos han sido tocados
  nombreTouched = false;
  telefonoTouched = false;
  sugerenciaTouched = false;

  // Región seleccionada
  selectPais: string = '';

  constructor(
    private userService: UsuarioService,
    private backendService: BackendService,
    private sanitizer: DomSanitizer,
    private gtg: Gtag
  ) { }

  async ngOnInit() {

    this.selectPais = localStorage.getItem('pais');
    this.setInitialPhoneNumber();

    await this.backendService.get(ContactoUsuario, Usuario.path ).toPromise().then(
      data => this.user = data
    );
    this.fotos = this.user.fotos;

    if (this.user.fotos.length === 0) {
      this.agregarImagen();
    }
    const imgs = [];
    this.fotos.forEach(i => {
      const img = new Foto(null);
      img.id = i.id;
      img.name = i.name;
      img.path = i.path;
      if (i.path && i.path.indexOf('http') < 0) {
       this.getImages(img);
      } else {
        img.url = i.path + i.name;
      }

      imgs.push(img);
    });
    this.fotos.splice(0);
    imgs.forEach(i => this.fotos.push(i));
  }

  agregarImagen() {
    this.fotos.push(new Imagen(undefined));
  }

  async getImages(imagen: Foto) {
    await this.userService.getFotoById(imagen.id).subscribe(data => {
      const urlCreator = window.URL;
      const safeUrl: any = this.sanitizer.bypassSecurityTrustUrl(
          urlCreator.createObjectURL(data));
      imagen.url = safeUrl;
    });
  }

  async enviarSugerencia() {
    if (this.formValid) {
      await this.backendService.update('/new-productos/' + 'producto-no-encontrado/' + this.sugerencia + "/" + this.nombre + "/" + this.telefono, null);
    }
  }

  wspEventAr(){
    this.gtg.event('boton_whatsapp_argentina_empty_search', {
      items: [{
        item_name: "boton whatsapp argentina empty search"
      }]
    });
  }

  checkFields() {
    this.nombreRequired = !this.nombre;
    this.telefonoRequired = !this.telefono;
    this.sugerenciaRequired = !this.sugerencia;

    // Resetear la validación del teléfono al principio de la función
    this.telefonoInvalido = false; // Asumimos que el teléfono es válido al principio

    // Validar el número de teléfono si el campo no está vacío
    if (this.telefono) {
        const regexArg = /^\+54 9\d{2,4} \d{6,8}$/; // Ejemplo de formato +54 9XX XXXXXXX
        const regexChile = /^\+56 9\d{8}$/; // Ejemplo de formato +56 9XXXXXXXX
        const isValidArg = regexArg.test(this.telefono);
        const isValidChile = regexChile.test(this.telefono);

        if (!isValidArg && !isValidChile) {
            this.telefonoInvalido = true; // El teléfono no cumple con los formatos de ARG o CHL
        }
    }

    // Actualiza el estado de validez del formulario teniendo en cuenta la nueva validación
    this.formValid = !this.nombreRequired && !this.telefonoRequired && !this.sugerenciaRequired && !this.telefonoInvalido;
  }

  setTouched(field: string) {
    if (field === 'nombre') {
      this.nombreTouched = true;
    } else if (field === 'telefono') {
      this.telefonoTouched = true;
    } else if (field === 'sugerencia') {
      this.sugerenciaTouched = true;
    }
    // Puedes llamar a checkFields() aquí si quieres que la validación se ejecute también al tocar
    this.checkFields();
  }

  formatPhone(value: string, country: string) {
    let prefix = country === 'ARGENTINA' ? '+54 9' : '+56 9';
    // Eliminamos el prefijo y cualquier caracter no numérico del valor actual.
    let currentNumbers = value.slice(prefix.length).replace(/\D/g, '');

    if (country === 'ARGENTINA') {
        if (currentNumbers.startsWith('11')) {
            // Para números que comienzan con "11", formateamos como "+54 911 XXXXXXXX".
            this.telefono = `${prefix}11 ${currentNumbers.slice(2)}`;
        } else {
            // Si el número no comienza con "11", lo tratamos como un caso general.
            this.telefono = prefix + ' ' + currentNumbers;
        }
    } else if (country === 'CHILE') {
        // Formato esperado para Chile: +56 9 XXXX XXXX, dejamos espacio después de 9.
        this.telefono = prefix + ' ' + currentNumbers.replace(/^(\d{4})(\d+)$/, '$1 $2');
    }
  }

  setInitialPhoneNumber() {
    if (this.selectPais === 'ARGENTINA') {
      this.telefono = '+54 9';
    } else if (this.selectPais === 'CHILE') {
      this.telefono = '+56 9';
    }
  }

  preventPrefixRemoval(event: KeyboardEvent, country: string) {
    const prefixMap = {
      'ARGENTINA': '+54 9',
      'CHILE': '+56 9'
    };
    let prefix = prefixMap[country];
    
    // Prevenir la eliminación si se intenta borrar el prefijo
    if (this.telefono.length <= prefix.length && (event.key === 'Backspace' || event.key === 'Delete')) {
      event.preventDefault();
    }
  }

}
