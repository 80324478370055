import { Component, OnInit } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';
import {Subject} from 'rxjs';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { BackendService } from 'src/app/core/backend.service';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Direccion } from 'src/app/cliente/direccion.model';
import { DireccionCompleta } from 'src/app/cliente/direccionCompleta.model';


@Component({
  selector: 'app-seleccion-direccion-modal',
  templateUrl: './seleccion-direccion-modal.component.html',
  styleUrls: ['./seleccion-direccion-modal.component.scss']
})
export class SeleccionDireccionModalComponent implements OnInit {

  direcciones:Direccion[];
  public onClose: Subject<DireccionCompleta>;
  public direccionesCompletas: DireccionCompleta[];

  constructor(
    public bsModalRef: BsModalRef,
              private toast: ToastService,
              private backendService: BackendService,
              private seguridadService: SeguridadService,
              private spinner: NgxSpinnerService,
  ) { }

  async ngOnInit() {
    this.spinner.show();
    this.onClose = new Subject();
    await this.backendService.get(DireccionCompleta, "/perfil/direcciones",null).toPromise().then(
      data => this.direccionesCompletas = data
      );;
    this.spinner.hide();
    
  }

  selectDireccion(direccion:DireccionCompleta){
    this.onClose.next(direccion);
    this.bsModalRef.hide();
  }

  cerrar(){
    this.onClose.next();
    this.bsModalRef.hide();
  }

}
