import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BackendService } from '../../../core/backend.service';
import { Http, Headers } from '@angular/http';

@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.component.html',
  styleUrls: ['./notificacion.component.scss']
})
export class NotificacionComponent implements OnInit {

  notificaciones: any[] = [];
  id: number;
  asunto: string;
  texto: string;

  public onClose: Subject<boolean>;

  constructor(protected backendService: BackendService, public bsModalRef: BsModalRef,
    private router: Router, protected http: Http) {}

  ngOnInit() {
    this.onClose = new Subject();
  }

  marcarLeida() {
    this.asunto = null;
    this.texto = null;
    this.updateLeida('/novedades/marcar_leida/' + this.id);
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  async updateLeida(path) {
    const url = this.backendService.buildUrl(path);
    const options = this.backendService.buildRequestOptions();
    return this.http.put(url, null, options).map(res => {
      return res.status === 200;
    }).catch(res => {
      throw res.json();
    }).toPromise();
  }

  noConfirma() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  confirma() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

}
