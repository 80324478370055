export class RegistroEmpresa{
    static path = '/registro/empresa';


  username: string;
  cuit: string;

  razonSocial:string;
  
  situacionIva:string;
  
  email: string;
  password: string;

  /*
  constructor(protected backendService: BackendService, model?: RegistroPaso1) {
    super(backendService, model);
  }
  */
}