import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ProductoService } from '../service/producto.service';
import { ProductoCarrito } from '../models/producto-carrito';
import { ActivatedRoute, Router } from '@angular/router';
import { Producto } from '../models/producto.model';
import { BackendService } from '../../core/backend.service';
import { LogoPedido } from '../viejo/logo-pedido.model';
import { Logo } from '../models/logo.model';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../../core/api.service';
import { TipoImpresion } from '../models/tipo-impresion';
import { AreasImpresion } from '../models/areas-impresion';
import { LogoAreaImpresion } from '../models/logo-area-impresion';
import { NgxSpinnerService } from 'ngx-spinner';
import { getFilters } from './helper/color-helper';
import { Render } from './model/render';
import { ModalsService } from 'src/app/shared-services/modals.service';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { ToastService } from '../../shared-services/toast/toast.service';
import { Imagen } from '../viejo/imagen.model';
import { ClienteService } from 'src/app/core/cliente.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LogoTexto } from './modals/add-text-modal/model/logo-texto';
import { Gtag } from 'angular-gtag';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { clearCache, HexToCssConfiguration } from 'hex-to-css-filter/dist/es2015';
import { Proveedor } from '../models/proveedor.model';
import { Pedido } from '../resultado-producto/models/pedido.model';
import { Location } from '@angular/common';
import { LocalStorageService } from 'src/app/shared-services/local-storage/local-storage.service';
import { Cliente } from 'src/app/cliente/cliente.model';
import { NotificacionesCarrito } from 'src/app/shared-services/notificacion-carrito.service';

declare const $: any;

@Component({
  selector: 'app-producto-particular-no-logo',
  templateUrl: './producto-particular-logo.component.html',
  styleUrls: ['./producto-particular-logo.component.scss']
})
export class ProductoParticularLogoComponent implements OnInit, AfterViewInit {
  productoCarrito: ProductoCarrito;
  url;
  logoSubido: LogoPedido;
  tiposImpresionFiltrados: TipoImpresion[] = [];
  tiposImpresionFiltradosExtendido: TipoImpresion[] = [];
  tipoImpresionSeleccionadaFiltrada: TipoImpresion;

  preTipoImpresion: TipoImpresion;

  areaImpresionSeleccionada: AreasImpresion;
  areasImpresionSeleccionadas: AreasImpresion[] = [];

  //IVA
  public precioFinalIva: number;

  private proveedor: Proveedor;

  public precioFinalSinIva: number;
  imagenLogo = new Image();

  public variosTiposImpresion: Boolean = false;

  public proveedorIdZecat = 1;

  public muestraAprobada: boolean;
  cantidadDeLogos: number = 0;
  angleRotated = 0;
  lastAngle = 0;
  logosAreas: LogoAreaImpresion[] = [];
  imagenesFiles: Render[] = [];
  indice: number = 0;
  compro = false;
  zoomLevel = 0.3;
  maxZoom = 10;

  @ViewChild('boxImagen') boxImagen;
  @ViewChild('canvas') canvas;
  @ViewChild('canvaslogo') canvaslogo;
  @ViewChild('canvasFusion') canvasFusion;
  @ViewChild('imagen_grande') imagen_grande;
  @ViewChild('imagen_Fondo') imagen_Fondo;

  colorAnterior: string = "";

  cargoLogo: Boolean = false;
  opcionSeleccionada: Boolean = true;
  seleccionado: boolean = true;

  files: File[] = [];
  formTipoImpresion: FormGroup;
  diasProduccion: number = 0;

  filtrosElegidos;

  iva: number = 0;

  selectPais: string;

  config: HexToCssConfiguration = {
    acceptanceLossPercentage: 10
  };

  tempUser: boolean;

  logoDefault = {
    name: "default",
    path: 'assets/img/tulogoweb2.png',
  };


  constructor(private toast: ToastService,
    private productoService: ProductoService,
    private route: ActivatedRoute,
    private router: Router,
    private backendService: BackendService,
    public renderer: Renderer2,
    public notificacionesCarrito: NotificacionesCarrito,
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private modalService: ModalsService,
    private seguridadService: SeguridadService,
    private clienteService: ClienteService,
    private formBuilder: FormBuilder,
    private gtag: Gtag,
    private location: Location) {
  }

  async ngOnInit() {

    this.selectPais = localStorage.getItem('pais');
    this.getTempUser();
    if (this.selectPais == "CHILE") {
      this.iva = 1.19
    } else {
      this.iva = 1.21
    }

    this.formTipoImpresion = this.formBuilder.group({
      tipoImpresion: [undefined, [Validators.required]]
    });

    const logo = new Logo();
    this.logoSubido = new LogoPedido(logo);

    await this.productoService.producto.subscribe(p => {
      this.productoCarrito = p;
      if (this.productoCarrito == undefined) {
        this.router.navigateByUrl('/');
      }

      this.productoCarrito.infoProducto.tiposImpresion.forEach(ti => {
        if (this.tiposImpresionFiltrados.filter(ti2 => ti2.nombre == ti.nombre).length == 0) {
          this.tiposImpresionFiltrados.push(ti);
        }
      });
      this.cambiarTipoImpresionFiltro(this.tiposImpresionFiltrados[0].id);

      this.hayVariosTiposImpresion();

      this.formTipoImpresion.patchValue({
        tipoImpresion: this.tiposImpresionFiltrados[0].id,
      });

      this.preTipoImpresion = this.tiposImpresionFiltrados[0];

      this.tiposImpresionFiltrados.forEach(a => {
        if (a.code == "FullColor" || a.code == "StickerFullColor") {
          this.formTipoImpresion.patchValue({
            tipoImpresion: a.id,

          });
          this.preTipoImpresion = a;
        }
      })

      this.areaImpresionSeleccionada = this.productoCarrito.varianteSeleccionada.variantes[0].areaImpresion[0];
      this.productoCarrito.varianteSeleccionada.variantes[0].areaImpresion.forEach(imagen => {
        let logoarea: LogoAreaImpresion = new LogoAreaImpresion();
        logoarea.esTexto = true;
        this.logosAreas.push(logoarea);
      })

      this.logosAreas[this.indice].area = this.areaImpresionSeleccionada;

    })

    if (this.productoCarrito == undefined) {
      this.router.navigateByUrl('/');
    }

    this.proveedor = await this.backendService.get(Proveedor, "/new-productos/proveedor/" + this.productoCarrito.infoProducto.id).toPromise();

    await this.backendService.getNumber("/logos/").then(result => this.cantidadDeLogos = result as number);

    this.filtrarTiposImpresion(this.preTipoImpresion.id);
    this.productoCarrito.tipoImpresionSeleccionada.colorHexa = "#FFFFFF";

    this.getPrecioConIva();
    LocalStorageService.removeObject('routerReferenceProduct');
    LocalStorageService.removeObject('cantidadSeleccionada');
    LocalStorageService.removeObject('selectedColorId');

    this.selectLogo(this.logoDefault);

  }

  async getTempUser(){
    await this.backendService.getBoolean(Cliente.path + 'getTempUser').then(a => {
      this.tempUser = a
    });
  }

  cambiarTipoImpresionFiltro(id) {
    this.tiposImpresionFiltradosExtendido.forEach(filtro => {
      if (filtro.id == id) {
        this.productoCarrito.tipoImpresionSeleccionada = filtro;
      }
    })
  }

  filtrarTiposImpresion(id) {

    this.tiposImpresionFiltrados.forEach(t => {
      if (t.id == id) {
        this.tipoImpresionSeleccionadaFiltrada = t;
      }
    });
    this.tiposImpresionFiltradosExtendido = [];
    this.productoCarrito.infoProducto.tiposImpresion.forEach(ti => {
      if (ti.nombre == this.tipoImpresionSeleccionadaFiltrada.nombre) {
        this.tiposImpresionFiltradosExtendido.push(ti);
      }
    });
    this.productoCarrito.tipoImpresionSeleccionada = this.tiposImpresionFiltradosExtendido[0];

    var now = new Date();

    var tiempo = (this.productoCarrito.tipoImpresionSeleccionada.base_time + this.productoCarrito.tipoImpresionSeleccionada.ocupacion + (this.productoCarrito.tipoImpresionSeleccionada.day_factor * this.productoCarrito.cantidad));

    //AGREGO 3 dÃ­as mas de produccion de forma provisional
    if (now.getHours() >= 15) {

      this.diasProduccion = parseInt((tiempo + 1).toString()) + 3;

    } else {
      this.diasProduccion = parseInt(tiempo.toString()) + 3;
    }

    if (this.diasProduccion > 12) {
      this.diasProduccion = 12 + 3;
    }

    if (this.productoCarrito.tipoImpresionSeleccionada.nombre == "Laser") {
      this.filtrosElegidos = "grayscale(100%)";
    } else {
      this.filtrosElegidos = "grayscale(0%)";
    }

    if (this.logosAreas[this.indice].logo != null) {
      this.dibujarLogos(this.logosAreas[this.indice].urlOriginal, this.areaImpresionSeleccionada.imagen.path + "?1", this.logosAreas[this.indice].logo.posicion_x, this.logosAreas[this.indice].logo.posicion_y, this.logosAreas[this.indice].logo.width, this.logosAreas[this.indice].logo.height);

    }
  }

  procesaPropagar(logoPedido) {
    if (this.logosAreas[this.indice].esTexto) {
      logoPedido.texto = this.logosAreas[this.indice].logo.texto;
      logoPedido.size = this.logosAreas[this.indice].logo.size;
      logoPedido.fuente = this.logosAreas[this.indice].logo.fuente;
      logoPedido.color = this.logosAreas[this.indice].logo.color;
    }
    this.logosAreas[this.indice].logo = logoPedido;

    this.logosAreas[this.indice].logo.widthcm = this.logosAreas[this.indice].logo.width * 0.026458;
    this.logosAreas[this.indice].logo.heighthcm = this.logosAreas[this.indice].logo.height * 0.026458;
    this.dibujarLogos(this.logosAreas[this.indice].url, this.areaImpresionSeleccionada.imagen.path + "?1", this.logosAreas[this.indice].logo.posicion_x, this.logosAreas[this.indice].logo.posicion_y, this.logosAreas[this.indice].logo.width, this.logosAreas[this.indice].logo.height);
  }

  removeLogo() {
    if (!this.muestraAprobada) {
      let indice_temporal = 0;
      let imagenesFilesTemporal: Render[] = [];
      let logosAreasTemporal: LogoAreaImpresion[] = [];

      this.imagenesFiles.forEach(logo => {
        if (indice_temporal != this.indice) {
          imagenesFilesTemporal.push(logo);
        }
        indice_temporal++;
      });
      this.imagenesFiles = imagenesFilesTemporal;
      indice_temporal = 0;

      this.logosAreas[this.indice].logo = null;
      this.logosAreas[this.indice].url = null;
      this.logosAreas[this.indice].urlOriginal = null;
    }

  }

  seleccionarColor(color) {
    this.colorAnterior = this.productoCarrito.tipoImpresionSeleccionada.colorHexa;
    this.productoCarrito.tipoImpresionSeleccionada.colorHexa = color.target.value;

    this.dibujarLogos(this.logosAreas[this.indice].url, this.areaImpresionSeleccionada.imagen.path + "?1", this.logosAreas[this.indice].logo.posicion_x, this.logosAreas[this.indice].logo.posicion_y, this.logosAreas[this.indice].logo.width, this.logosAreas[this.indice].logo.height);
  }

  async armarPedido(comprarAhora: boolean) {
    try {
      this.spinner.show();
      this.productoCarrito.logosAreas = this.logosAreas;
      this.productoCarrito.tiempoEntrega = this.diasProduccion;

      if (!this.seleccionado) {
        this.toast.openErrorGenerico("SELECCIONE UN TIPO DE IMPRESIÃ“N");
        this.tipoImpresionLogoGtag();
        this.spinner.hide();
        return;
      }

      var tieneLogos = false;
      this.productoCarrito.logosAreas.forEach(logo => {
        if (logo.logo != null) {
          tieneLogos = true;
        }
      })

      if (!tieneLogos) {
        this.toast.openErrorGenerico("Debe cargar minimamente un logo.");
        this.logoErrorGtag();
        this.spinner.hide();
        return;
      }

      this.productoCarrito.renderes = this.imagenesFiles;


      if (comprarAhora) {
        this.comprarAhoraLogoGtag();
        if (this.tempUser) {
          this.spinner.hide();
          const resDatosPersonales = await this.modalService.openDatosPersonalesModal();
          resDatosPersonales.subscribe(async value => {
            if (value) {
              const resLogin = await this.modalService.openLoginModal();
              resLogin.subscribe(async value => {
                if (value) {
                  this.agregarProductoCarrito();
                }
              })
            } else {
              this.agregarProductoCarrito();
            }
            /*
            await this.backendService.create(Producto.new_path + "agregar-carrito", this.productoCarrito);
            this.addToCart(this.productoCarrito);
            this.toast.openSuccessGenerico("PRODUCTO AGREGADO AL CARRITO CORRECTAMENTE");
            if (this.selectPais=="ARGENTINA"){
              window.location.href = '/previsualizacion-compra';}
            else if (this.selectPais=="CHILE"){
              window.location.href = '/previsualizacion-compra-cl';}

              */
          })
        } else {
          this.agregarProductoCarrito();
        }
      } else {
        await this.backendService.create(Producto.new_path + "agregar-carrito", this.productoCarrito);
        this.addToCart(this.productoCarrito);
        var hayProductos = this.notificacionesCarrito.getCantidadProductosCarrito();
        this.notificacionesCarrito.emitChange(hayProductos +1);
        this.toast.openSuccessGenerico("PRODUCTO AGREGADO AL CARRITO CORRECTAMENTE");
        this.router.navigateByUrl('/lista-productos');
      }
      this.spinner.hide();

      /*const res = this.modalService.confirmarTiempoDeEntrega(this.diasProduccion);
      res.subscribe(async (value) => {
        if (value) {
          await this.backendService.create(Producto.new_path + "agregar-carrito", this.productoCarrito);
          this.addToCart(this.productoCarrito);
          if (comprarAhora) {
            if (this.selectPais=="ARGENTINA")
              window.location.href = '/previsualizacion-compra';
            else if (this.selectPais=="CHILE")
              window.location.href = '/previsualizacion-compra-cl';
          } else
              window.location.href = '/lista-productos'; 

          this.spinner.hide();
          this.toast.openSuccessGenerico("PRODUCTO AGREGADO AL CARRITO CORRECTAMENTE");
        } else {
          this.spinner.hide();
        }
      });*/
    } catch (ex) {
      console.log(ex);
    }
  }

  changeZoom(event) {
    this.zoomLevel = event.target.value;
  }

  async abrirModalAddLogo() {
    const confirm = await this.modalService.openModalAddLogo();
    confirm.subscribe(async (result) => {
      if (result) {
        if (!result.name) {
          this.selectFile(result);
        } else {
          this.selectLogo(result);
        }
      }

      if (this.proveedor.id != 1) {
        this.centrarLogoInicial();
      } else {
        this.centrarLogo();
      }
    });
  }

  centrarLogoInicial() {
    this.logosAreas[this.indice].logo.posicion_x = this.areaImpresionSeleccionada.coordenadaX + this.areaImpresionSeleccionada.ancho / 3;
    this.logosAreas[this.indice].logo.posicion_y = this.areaImpresionSeleccionada.coordenadaY + this.areaImpresionSeleccionada.altura / 3;
  }

  centrarLogo() {
    if (!this.muestraAprobada) {
      this.logosAreas[this.indice].logo.posicion_x = this.areaImpresionSeleccionada.coordenadaX + this.areaImpresionSeleccionada.ancho / 2 - this.logosAreas[this.indice].logo.width / 2;
      this.logosAreas[this.indice].logo.posicion_y = this.areaImpresionSeleccionada.coordenadaY + this.areaImpresionSeleccionada.altura / 2 - this.logosAreas[this.indice].logo.height / 2;
    }
  }

  selectFile(event) {

    if (!event[0] || event[0].length == 0)
      return;

    var mimeType = event[0].type;
    if (mimeType.match(/image\/*/) == null)
      return;

    if (event[0].type.replace(/(.*)\//g, '') == "svg+xml") {
      this.toast.openErrorGenerico("Formato incorrecto de logo.");
      return;
    }

    var reader = new FileReader();
    try {
      this.spinner.show();
      reader.readAsDataURL(event[0]);

      this.imagenLogo = new Image();

      reader.onload = (e: any) => {
        this.imagenLogo.src = reader.result as string;

        this.url = reader.result;
        this.logosAreas[this.indice].esTexto = false;
        this.logosAreas[this.indice].url = reader.result;
        this.logosAreas[this.indice].urlOriginal = reader.result;
        this.logoSubido.logo.url = reader.result;
        this.logoSubido.logo.name = event[0].name;
        this.logosAreas[this.indice].name = event[0].name;
        if (this.areaImpresionSeleccionada.altura > this.areaImpresionSeleccionada.ancho) {
          this.logoSubido.width = this.areaImpresionSeleccionada.ancho / 2;
        } else {
          this.logoSubido.width = this.areaImpresionSeleccionada.altura;
        }
        this.logoSubido.height = this.areaImpresionSeleccionada.altura;
        if ((this.logoSubido.height > 600) || (this.logoSubido.width > 600)) {
          this.logoSubido.height = 200;
          this.logoSubido.width = 200;
        }
        this.logoSubido.posicion_x = (this.areaImpresionSeleccionada.coordenadaX + this.areaImpresionSeleccionada.altura) / 2;
        this.logoSubido.posicion_y = (this.areaImpresionSeleccionada.coordenadaY + this.areaImpresionSeleccionada.ancho) / 2;
        this.logosAreas[this.indice].logo = this.logoSubido;


        this.logosAreas[this.indice].logo.widthcm = this.logosAreas[this.indice].logo.width * 0.026458;
        this.logosAreas[this.indice].logo.heighthcm = this.logosAreas[this.indice].logo.height * 0.026458;

        this.dibujarLogos(null, this.areaImpresionSeleccionada.imagen.path + "?1", this.areaImpresionSeleccionada.coordenadaX, this.areaImpresionSeleccionada.coordenadaY, this.areaImpresionSeleccionada.ancho, this.areaImpresionSeleccionada.altura);
        if (this.proveedor.id == 1) {
          this.centrarLogo();
        } else this.centrarLogoInicial();
        this.spinner.hide();
        //this.toast.openSuccessGenerico("IMAGEN SUBIDA EXITOSAMENTE!");
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  private isFullColor() {
    return this.productoCarrito.tipoImpresionSeleccionada.nombre.includes('HD') ||
      this.productoCarrito.tipoImpresionSeleccionada.nombre.includes('Uv') ||
      this.productoCarrito.tipoImpresionSeleccionada.nombre.includes('Transfer') ||
      this.productoCarrito.tipoImpresionSeleccionada.nombre.includes('Full');
  }

  private isGrayScale() {
    return this.productoCarrito.tipoImpresionSeleccionada.nombre.includes('Laser') ||
      this.productoCarrito.tipoImpresionSeleccionada.nombre.includes('Hot stamping');
  }

  dibujarLogos(urlLogo, urlimagenFondo, posicionlogoX, posicionlogoY, anchoLogo, altoLogo) {

    let canvas_fondo: HTMLCanvasElement = this.canvas.nativeElement;
    let context_fondo = canvas_fondo.getContext('2d');

    let canvas_logo = this.canvaslogo.nativeElement;
    let context_logo = canvas_logo.getContext('2d');

    let canvas_Fusion = this.canvasFusion.nativeElement;
    let context_fusion = canvas_Fusion.getContext('2d');

    canvas_fondo.width = this.imagen_Fondo.nativeElement.offsetWidth;
    canvas_fondo.height = this.imagen_Fondo.nativeElement.offsetHeight;

    canvas_logo.width = anchoLogo;
    canvas_logo.height = altoLogo;

    var urlImagenConvertida;
    let img1 = new Image();

    img1.crossOrigin = "Anonymous";

    img1.onload = async () => {
      context_logo.clearRect(0, 0, anchoLogo, altoLogo);
      context_fondo.clearRect(0, 0, canvas_fondo.width, canvas_fondo.height);
      context_fondo.drawImage(img1, 0, 0, canvas_fondo.width, canvas_fondo.height);

      //this.logosAreas[this.indice].url = this.logosAreas[this.indice].urlOriginal;

      this.imagenLogo.onload = async () => {

        if (this.isFullColor() || this.isGrayScale()) {
          this.logosAreas[this.indice].url = this.logosAreas[this.indice].urlOriginal;
        }
        if (this.isGrayScale()) {
          context_logo.filter = "grayscale(1)"
        }

        if (this.lastAngle != this.angleRotated) {
          context_logo.translate(canvas_logo.width / 2, canvas_logo.height / 2);
          context_logo.rotate(30 * Math.PI / 180);
          context_logo.drawImage(this.imagenLogo, -canvas_logo.width / 2, -canvas_logo.height / 2, canvas_logo.width, canvas_logo.height);
          //this.logosAreas[this.indice].url = canvas_logo.toDataURL();
          this.lastAngle = this.angleRotated;
        }
        else {
          context_logo.translate(canvas_logo.width / 2, canvas_logo.height / 2);
          context_logo.drawImage(this.imagenLogo, -canvas_logo.width / 2, -canvas_logo.height / 2, canvas_logo.width, canvas_logo.height);
          //this.logosAreas[this.indice].url = canvas_logo.toDataURL();
        }
        this.logosAreas[this.indice].url = canvas_logo.toDataURL();
        if (this.isGrayScale()) {
          this.logosAreas[this.indice].url = canvas_logo.toDataURL();
        }

        if (this.logosAreas[this.indice].url != null && this.productoCarrito.tipoImpresionSeleccionada.colorHexa != null && !this.isFullColor()) {
          context_logo.filter = 'none';
          this.colorAnterior = this.productoCarrito.tipoImpresionSeleccionada.colorHexa;
          context_logo.globalCompositeOperation = 'source-atop';
          context_logo.fillStyle = this.productoCarrito.tipoImpresionSeleccionada.colorHexa;
          context_logo.fillRect(0, 0, anchoLogo, altoLogo);
          this.logosAreas[this.indice].url = canvas_logo.toDataURL();
        }

        canvas_Fusion.width = this.imagen_Fondo.nativeElement.offsetWidth;
        canvas_Fusion.height = this.imagen_Fondo.nativeElement.offsetHeight;

        context_fusion.drawImage(canvas_fondo, 0, 0);
        context_fusion.drawImage(canvas_logo, posicionlogoX, posicionlogoY);

        urlImagenConvertida = canvas_Fusion.toDataURL('image/png');
        if (this.indice >= this.imagenesFiles.length) {
          let render: Render = new Render();
          render.src = urlImagenConvertida;
          canvas_Fusion.toBlob((blob: any) => {
            let file = new File([blob], 'render', { type: 'image/png' })
            this.files.push(file);
          }, 'image/png');
          this.imagenesFiles.push(render);
        } else {
          this.imagenesFiles[this.indice].src = urlImagenConvertida;
        }
      }
      this.imagenLogo.src = urlLogo;
    }
    img1.src = urlimagenFondo;
  }

  selectLogo(logo) {
    try {
      this.spinner.show();
      this.url = logo.path;
      this.logosAreas[this.indice].esTexto = false;
      this.logosAreas[this.indice].url = logo.path;
      this.logosAreas[this.indice].urlOriginal = logo.path;
      this.logoSubido.logo.url = logo.path;
      this.logoSubido.logo.name = logo.name;
      this.logosAreas[this.indice].name = logo.name;
      if (this.areaImpresionSeleccionada.altura > this.areaImpresionSeleccionada.ancho) {
        this.logoSubido.width = this.areaImpresionSeleccionada.ancho / 2;
      } else {
        this.logoSubido.width = this.areaImpresionSeleccionada.altura;
      }
      this.logoSubido.height = this.areaImpresionSeleccionada.altura;
      if ((this.logoSubido.height > 600) || (this.logoSubido.width > 600)) {
        this.logoSubido.height = 200;
        this.logoSubido.width = 200;
      }
      this.logoSubido.posicion_x = (this.areaImpresionSeleccionada.coordenadaX + this.areaImpresionSeleccionada.altura) / 2;
      this.logoSubido.posicion_y = (this.areaImpresionSeleccionada.coordenadaY + this.areaImpresionSeleccionada.ancho) / 2;
      this.logosAreas[this.indice].logo = this.logoSubido;

      this.logosAreas[this.indice].logo.widthcm = this.logosAreas[this.indice].logo.width * 0.026458;
      this.logosAreas[this.indice].logo.heighthcm = this.logosAreas[this.indice].logo.height * 0.026458;

      this.spinner.hide();
      //this.toast.openSuccessGenerico("IMAGEN SUBIDA EXITOSAMENTE!");
      this.dibujarLogos(null, this.areaImpresionSeleccionada.imagen.path + "?1", this.areaImpresionSeleccionada.coordenadaX, this.areaImpresionSeleccionada.coordenadaY, this.areaImpresionSeleccionada.ancho, this.areaImpresionSeleccionada.altura)
      this.centrarLogo();
    } catch (e) {
      console.log(e.message);
    }
  }

  async downloadRender() {
    let i = 0;

    for (const render of this.imagenesFiles) {
      await this.delay(1000);
      console.log("Descargando imagen: " + i);
      const link = document.createElement('a');
      link.href = render.src;
      link.download = "imagen_" + i + ".jpg";
      link.click();
      console.log("Termino imagen: " + i);
      i++;
    }

  }

  async confirmRender() {
    try {
      if (!this.seguridadService.user) {
        await this.router.navigateByUrl('/login/producto/' + this.productoCarrito.infoProducto.id);
        return;
      }
      this.productoCarrito.logosAreas = this.logosAreas;
      if (!this.productoCarrito.tipoImpresionSeleccionada) {
        this.toast.openErrorGenerico("SELECCIONE UN TIPO DE IMPRESIÃ“N");
        this.spinner.hide();
        return;
      }


      var tieneLogos = false;
      this.productoCarrito.logosAreas.forEach(logo => {
        if (logo.logo != null) {
          tieneLogos = true;
        }
      })
      if (!tieneLogos) {
        this.toast.openErrorGenerico("Debe cargar minimamente un logo.");
        this.spinner.hide();
        return;
      }
      const res = this.modalService.confirmarMuestra();
      res.subscribe(async (value) => {
        if (value) {
          this.muestraAprobada = true;
        } else {
          this.muestraAprobada = false;
        }
      });
    } catch (ex) {
      console.log(ex);
    }
  }

  cambiarImagenDisplay(index: number) {
    this.indice = index;
    this.areaImpresionSeleccionada = this.productoCarrito.varianteSeleccionada.variantes[0].areaImpresion[index];
    this.logosAreas[this.indice].area = this.areaImpresionSeleccionada;

    let img1 = new Image();
    this.imagenLogo = new Image();

    this.imagenLogo.src = this.logosAreas[this.indice].url;

    var re = /https/gi;
    var str = this.areaImpresionSeleccionada.imagen.path;

    img1.crossOrigin = "Anonymous";

    let canvas: HTMLCanvasElement = this.canvas.nativeElement;
    let context = canvas.getContext('2d');

    canvas.width = this.boxImagen.nativeElement.offsetWidth;
    canvas.height = this.boxImagen.nativeElement.offsetHeight;

    var urlImagenConvertida;
    img1.onload = async () => {
      context.drawImage(img1, 0, 0, canvas.width, canvas.height);
      context.drawImage(this.imagenLogo, 125 + this.logosAreas[this.indice].logo.posicion_x, 125 + this.logosAreas[this.indice].logo.posicion_y, this.logosAreas[this.indice].logo.width, this.logosAreas[this.indice].logo.height);

      urlImagenConvertida = canvas.toDataURL('image/jpeg');
      if (this.indice >= this.imagenesFiles.length) {
        let render: Render = new Render();
        render.src = urlImagenConvertida;
        this.imagenesFiles.push(render);
      } else {
        this.imagenesFiles[this.indice].src = urlImagenConvertida;
      }
    }
    img1.src = this.areaImpresionSeleccionada.imagen.path + "?1";//img1.src = str.replace(re, "http");
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      const slider = document.getElementById('myRange');
      slider.addEventListener('input', handleChange);
      //const select = document.getElementById('tipo_impresion_final');

      const select_anterior = document.getElementById('select_anterior');
      select_anterior.addEventListener('change', cambiarEstado);

      const boton_comprar = document.getElementById('boton_comprar');
      //boton_comprar.addEventListener('click', merge);
      const canvas = document.getElementById('canvas') as HTMLCanvasElement;


      const contenedor_color = document.getElementById('contenedor_input_color');

      let mostrado = false;

      function cambiarEstado() {
        //const contenedor = document.getElementById('contenedor_select');
        //contenedor.style.display = "block";

        // @ts-ignore
        const value = select_anterior.options[select_anterior.selectedIndex].value;

        /*if (value == 6 || value == 8 || value == 10 || value == 12) {
          const contenedor_input_color = document.getElementById('contenedor_input_color');
          contenedor_input_color.style.display = "block";
          mostrado = true;
        } else {
          if (mostrado) {
            const contenedor_input_color = document.getElementById('contenedor_input_color');
            contenedor_input_color.style.display = "none";
          }
        }*/
      }

      function merge() {
        const logo = document.getElementById('contenedor_logo');
        const imagen_grande = document.getElementById('imagen_grande') as HTMLImageElement;
        if (logo) {
          const imagen = logo.children.item(0).children.item(1).firstElementChild as HTMLImageElement;
          if (imagen) {
            var context = canvas.getContext('2d');
            canvas.width = imagen_grande.offsetWidth;
            canvas.height = imagen_grande.offsetHeight;

            context.drawImage(imagen_grande, 0, 0);
            context.drawImage(imagen, 0, 0);

          }
        }
      }

      function handleChange(e) {
        let blue_square = {
          x: parseInt(slider.getAttribute("data-x")),
          y: parseInt(slider.getAttribute("data-y")),
          width: parseInt(slider.getAttribute("data-ancho")),
          height: parseInt(slider.getAttribute("data-alto")),
        };


        let imagen = document.getElementById("imagen_Fondo");

        let centro_container = [imagen.offsetWidth / 2, imagen.offsetHeight / 2];

        let centro_azul = [blue_square.x + blue_square.width, blue_square.y - blue_square.height / 2];


        const img = document.getElementById("imagen");
        var rangeOriginal = e.target.value;
        var range = rangeOriginal * 2 / 20;
        var posicion_x = (centro_azul[0] - centro_container[0]) * range;
        var posicion_y = (centro_container[1] - centro_azul[1]) * range;
        //"scale(:scale)".replace(':scale', range.toString());
        const transform = "translate(:xpx, :ypx) scale(:scale)".replace(':scale', range.toString()).replace(':x', "" + (posicion_x)).replace(':y', "" + (posicion_y));
        img.style.transform = transform;
        img.style.transformOrigin = "center";
      }

      var bigimage = $("#big");
      var thumbs = $("#thumbs");
      var syncedSecondary = true;

      bigimage
        .owlCarousel({
          items: 1,
          slideSpeed: 2000,
          nav: false,
          // autoplay: true,
          dots: false,
          loop: true,
          responsiveRefreshRate: 200
        })
        .on("changed.owl.carousel", syncPosition);

      thumbs
        .on("initialized.owl.carousel", function () {
          thumbs
            .find(".owl-item")
            .eq(0)
            .addClass("current");
        })
        .owlCarousel({
          items: 4,
          dots: true,
          nav: true,
          navText: [
            '<span aria-label="' + 'Previous' + '"><div class="owlPrevSmall"></div></span>',
            '<span aria-label="' + 'Next' + '"><div class="owlNextSmall"></div></span>'
          ],
          navClass: [
            'owl-prev sm',
            'owl-next sm'
          ],
          smartSpeed: 200,
          slideSpeed: 500,
          slideBy: 1,
          responsiveRefreshRate: 100
        })
        .on("changed.owl.carousel", syncPosition2);

      function syncPosition(el) {
        var count = el.item.count - 1;
        var current = Math.round(el.item.index - el.item.count / 2 - 0.5);

        if (current < 0) {
          current = count;
        }
        if (current > count) {
          current = 0;
        }
        thumbs
          .find(".owl-item")
          .removeClass("current")
          .eq(current)
          .addClass("current");
        var onscreen = thumbs.find(".owl-item.active").length - 1;
        var start = thumbs
          .find(".owl-item.active")
          .first()
          .index();
        var end = thumbs
          .find(".owl-item.active")
          .last()
          .index();
        if (current > end) {
          thumbs.data("owl.carousel").to(current, 100, true);
        }
        if (current < start) {
          thumbs.data("owl.carousel").to(current - onscreen, 100, true);
        }
      }

      function syncPosition2(el) {
        if (syncedSecondary) {
          var number = el.item.index;
          bigimage.data("owl.carousel").to(number, 100, true);
        }
      }

      thumbs.on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).index();
        bigimage.data("owl.carousel").to(number, 300, true);
      });
    });
    $('.owl-products').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 3
        },
        1000: {
          items: 4
        }
      }
    })
    //ROTAR LOGO
    /*var CANVAS_WIDTH = 500;
    var CANVAS_HEIGHT = 500;
    var cx = CANVAS_WIDTH / 2;
    var cy = CANVAS_HEIGHT / 2;
    var image_width = this.logoSubido.width;
    var image_height = this.logoSubido.height
    var r = image_width / 2;
    var maxrad = Math.PI * 2;
  
    var canvas = document.querySelector('canvas');
    var context = canvas.getContext('2d');

    var image = new Image();
    var state = {
      isDown: false,
      lastAngle: 0,
      angleStart: 0,
      angle: 0
    };

    function angleFromEvent(e){
      const x = e.clientX - canvas.offsetLeft;
      const y = e.clientY - canvas.offsetTop;

      return Math.atan2(
        (x - cx) /r,
        (y - cy) /r
      );
    }

    function draw(){
      context.clearRect(0,0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.translate(cx, cy);
      context.rotate(-state.angle);
      context.drawImage(image, -image_width /2, -image_height /2, image_width, image_height);

      context.setTransform(1, 0, 0, 1, 0, 0);
    }

    canvas.onmousedown = function(e){
      const angle = angleFromEvent(e);
      state.angleStart = angle;
      state.isDown = true;
      canvas.onmousemove(e);
    }

    canvas.onmousemove = function(e){
      if(!state.isDown){
        return;
      }

      state.angle = angleFromEvent(e) + state.lastAngle - state.angleStart;
      draw();
    }

    canvas.onmouseup = function(e){
      state.lastAngle = state.angle;
      state.isDown = false;
      draw();
    }

    image.onload = function(){
      draw();
    }

    image.src = this.logosAreas[this.indice].url;*/

  }

  async getFiles(d: Imagen) {
    await this.clienteService.getFileById(d.id).subscribe(data => {
      this.createImageFromBlob(data, d);
    }, error => {
      console.log(error);
    });
  }

  async createImageFromBlob(image: Blob, d: Imagen) {
    const reader = new FileReader();
    await reader.addEventListener('load', () => {
      d.url = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  async openAddTexto() {
    const confirm = await this.modalService.openAddTextModal();
    confirm.subscribe(async (logotexto: LogoTexto) => {
      if (logotexto) {
        var result = logotexto.canvasSend;
        let canvas: HTMLCanvasElement = this.canvas.nativeElement;
        let context = canvas.getContext('2d');
        this.imagenLogo = new Image();
        this.imagenLogo.src = result.toDataURL();

        this.url = this.imagenLogo.src;
        this.logosAreas[this.indice].esTexto = true;
        this.logosAreas[this.indice].url = this.imagenLogo.src;
        this.logosAreas[this.indice].urlOriginal = this.imagenLogo.src;
        this.logoSubido.logo.url = this.imagenLogo.src;
        this.logoSubido.logo.name = logotexto.nombrefile + ".jpg";
        this.logoSubido.texto = logotexto.texto;
        this.logoSubido.size = logotexto.size;
        this.logoSubido.fuente = logotexto.fuente;
        this.logoSubido.color = logotexto.color;

        this.logosAreas[this.indice].name = this.logoSubido.logo.name;
        this.logoSubido.width = this.areaImpresionSeleccionada.ancho / 2;
        this.logoSubido.height = this.areaImpresionSeleccionada.altura;
        this.logoSubido.posicion_x = this.areaImpresionSeleccionada.coordenadaX;
        this.logoSubido.posicion_y = this.areaImpresionSeleccionada.coordenadaY;
        this.logosAreas[this.indice].logo = this.logoSubido;


        this.logosAreas[this.indice].logo.widthcm = this.logosAreas[this.indice].logo.width * 0.026458;
        this.logosAreas[this.indice].logo.heighthcm = this.logosAreas[this.indice].logo.height * 0.026458;
        this.imagenLogo.onload = () => {
          let img1 = new Image();
          var re = /https/gi;
          var str = this.areaImpresionSeleccionada.imagen.path;


          img1.crossOrigin = "Anonymous";

          canvas.width = this.imagen_Fondo.nativeElement.offsetWidth;
          canvas.height = this.imagen_Fondo.nativeElement.offsetHeight;

          var urlImagenConvertida;
          img1.onload = async () => {

            context.drawImage(img1, 0, 0, canvas.width, canvas.height);

            context.drawImage(this.imagenLogo, this.areaImpresionSeleccionada.coordenadaX, this.areaImpresionSeleccionada.coordenadaY, this.areaImpresionSeleccionada.ancho, this.areaImpresionSeleccionada.altura);
            urlImagenConvertida = canvas.toDataURL('image/jpeg');

            if (this.indice >= this.imagenesFiles.length) {
              let render: Render = new Render();
              render.src = urlImagenConvertida;
              canvas.toBlob((blob: any) => {
                let file = new File([blob], 'render', { type: 'image/jpeg' })
                this.files.push(file);
              }, 'image/jpeg');
              this.imagenesFiles.push(render);
            } else {
              this.imagenesFiles[this.indice].src = urlImagenConvertida;
            }
          }
          img1.src = this.areaImpresionSeleccionada.imagen.path + "?1";//img1.src = str.replace(re, "http");

          context.beginPath();
          context.strokeStyle = "red";
          context.lineWidth = 4;
          context.rect(125 + this.areaImpresionSeleccionada.coordenadaX, 125 + this.areaImpresionSeleccionada.coordenadaY, this.areaImpresionSeleccionada.ancho, this.areaImpresionSeleccionada.altura);
          context.stroke();

        }
      }
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  hayVariosTiposImpresion() {
    if (this.tiposImpresionFiltrados.length != 1) {
      this.variosTiposImpresion = true;
    } else {
      this.productoCarrito.tipoImpresionSeleccionada = this.tiposImpresionFiltrados[0];
    }
  }

  getPrecioConIva() {
    //var precioBase = this.productoCarrito.precioFinal / this.productoCarrito.cantidad;
    var precioBase = this.productoCarrito.precioIva;
    var numeroIva = this.round(precioBase * this.iva);
    this.precioFinalIva = numeroIva * this.productoCarrito.cantidad;
    this.precioFinalSinIva = this.round(precioBase) * this.productoCarrito.cantidad;

  }

  round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
  }

  addToCart(producto) {
    this.gtag.event('add_to_cart', {
      items: [{
        item_name: producto.infoProducto.nombre,
        item_id: producto.infoProducto.id,
        price: producto.infoProducto.precio,
        item_category: producto.infoProducto.categorias[0],
        item_variant: producto.varianteSeleccionada,
        quantity: producto.cantidad
      }]
    });
  }

  createBlob(pdf: any) {
    const blob = new Blob([pdf], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  async obtenerPdfPresupuesto() {

    var productoId = this.productoCarrito.infoProducto.id;
    var cantidad = this.productoCarrito.cantidad;

    if (!cantidad) {
      cantidad = 1;
    }

    this.spinner.show();

    await this.backendService.getPresupuesto(Pedido.new_path + 'presupuesto-producto/' + productoId + "/" + cantidad, null).toPromise().then(a => {
      this.createBlob(a);
      this.spinner.hide();
    })
  }

  rotarLogo(angle) {
    const logo = document.getElementById('contenedor_logo');
    this.angleRotated += angle;
    this.angleRotated %= 360;
    logo.style.transform = 'rotate(' + this.angleRotated + 'deg)'
  }

  goBack() {
    localStorage.removeItem('selectedColorId');
    localStorage.removeItem('cantidadSeleccionada');
    localStorage.removeItem('routerReferenceProduct');
    this.location.back();
  }

  comprarAhoraLogoGtag() {
    this.gtag.event('comprar_ahora_checkout', {
      items: [{
        item_name: "comprar_ahora_logo"
      }]
    });
  }

  logoErrorGtag(){
    this.gtag.event('logo_error', {
      items: [{
        item_name: "ERROR producto cargado sin logo"
      }]
    });
  }

  tipoImpresionLogoGtag(){
    this.gtag.event('tipo_impresion_error', {
      items: [{
        item_name: "ERROR producto sin tipo de impresiÃ³n"
      }]
    });
  }

  async agregarProductoCarrito() {
    this.spinner.show();
    await this.backendService.create(Producto.new_path + "agregar-carrito", this.productoCarrito);
    this.addToCart(this.productoCarrito);
    this.toast.openSuccessGenerico("PRODUCTO AGREGADO AL CARRITO CORRECTAMENTE");
    if (this.selectPais == "ARGENTINA") {
      this.router.navigateByUrl('/previsualizacion-compra');
    }
    else if (this.selectPais == "CHILE") {
      this.router.navigateByUrl('/previsualizacion-compra-cl');
    }
    this.spinner.hide();
  }

}

