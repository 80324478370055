import { Component, OnInit, Inject, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Parametric } from '../../cliente/parametric.model';
import { ApiService } from '../../core/api.service';
import { BackendService } from '../../core/backend.service';
import { Environment } from '../../../environments/enviroment.model';
import { EnvironmentProvider } from '../../core/browser-providers';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegistroPaso4 } from './registroPaso4.model';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { Router } from '@angular/router';
import { UsuarioService } from '../../core/usuario.service';
import { Direccion } from '../../cliente/direccion.model';
import { Cliente } from 'src/app/cliente/cliente.model';
import { FormValidatorServiceService } from 'src/app/shared-services/form-validator-service.service';
import { NgForm } from '@angular/forms';
import { PaisEnum } from 'src/app/core/constants/pais-enum';

@Component({
  selector: 'app-paso4',
  templateUrl: './paso4.component.html',
  styleUrls: ['./paso4.component.scss']
})
export class Paso4Component implements OnInit {
  private paises: Parametric[];
  private provincias: Parametric[];
  public direcciones = new Array<Direccion>();
  private clientesPath: string;

  @ViewChild('paso4Form') public userForm: NgForm;
  @Output() previous = new EventEmitter<number>();
  @Input() clientePromise: Promise<Cliente>;

  private pasoAnterior = 1;

  constructor(
    @Inject(EnvironmentProvider) env: Environment,
    private apiService: ApiService,
    private backendService: BackendService,
    private toast: ToastService,
    private router: Router,
    private formValidatorService: FormValidatorServiceService,
    private spinner: NgxSpinnerService,
    private usuarioService: UsuarioService
  ) {
    this.clientesPath = env.endpoints.clientes;
  }

  ngOnInit() {
    this.getPaises();
    this.getProvincias();
    this.clientePromise.then(cliente => {
      if (cliente.direcciones.length === 0) {
        this.direcciones.push(new Direccion());
      } else {
        this.direcciones = cliente.direcciones;
      }
    });
  }

  async getPaises() {
    this.paises = await this.apiService.get(Parametric, Parametric.path + 'paises/', null).toPromise();
  }
  async getProvincias() {
    this.provincias = await this.apiService.get(Parametric, Parametric.path + 'provincias/'+PaisEnum.ARGENTINA, null).toPromise();
  }

  isFirst(index) {
    if (index === 0 || index === 1 || index === 2 || index === 3 || index === 4 || index === 5) {
      return true;
    }
    return false;
  }

  async save() {
    if (this.validateForm()) {
      this.invokeSave();
    }
  }
  validateForm() {
    let validationsOK = false;
    if (this.formValidatorService.isFormValid(this.userForm)) {
      validationsOK = true;
    }
    return validationsOK;
  }

  async invokeSave() {

    try {
      window.scrollTo(0, 0);
      this.spinner.show();
      try {
        await this.backendService.create(this.clientesPath + '/registro/direcciones', this.direcciones);
        await this.backendService.create(this.clientesPath + '/registro/finalizar', null);
        this.router.navigate(['/registro/registro-satisfactorio']);
        window.scrollTo(0, 0);
      } catch (ex) {
        this.toast.openErrorGenerico(ex.message);
      }
    } catch (ex) {
      this.spinner.hide();
      this.toast.openErrorGenerico(ex.message);
    } finally {
      this.spinner.hide();
    }
  }

  public volverPasoAnterior() {
    this.previous.emit();
  }
}
