import { BackendModel, BackendService } from "../../core/backend.service";

export class Correo extends BackendModel {

  static path = '/confirmarCorreo/';

  description: string;
  
  constructor(protected backendService: BackendService, model?: Correo) {
    super(backendService, model);
  }
  
}
