import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-funcionalidad-no-implementada',
  templateUrl: './funcionalidad-no-implementada.component.html',
  styleUrls: ['./funcionalidad-no-implementada.component.scss']
})
export class FuncionalidadNoImplementadaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
