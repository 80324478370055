import { Component, OnInit } from '@angular/core';
import { Anuncio } from '../../core/anuncio.model';
import { BackendService } from '../../core/backend.service';

@Component({
  selector: 'app-listado-anuncios',
  templateUrl: './listado-anuncios.component.html',
  styleUrls: ['./listado-anuncios.component.scss']
})
export class ListadoAnunciosComponent implements OnInit {

  public anunciosList: Anuncio[];
  constructor(private backendService: BackendService) { }

  ngOnInit() {
    this.getAnunciosList();
  }

  async getAnunciosList() {
    this.anunciosList = await this.backendService.get(Anuncio, Anuncio.path + 'all', null).toPromise();
  }
}
