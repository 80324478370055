import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BackendService } from 'src/app/core/backend.service';
import { Categoria } from 'src/app/shared-components/dashboard/categoria.model';
import { LocalStorageService } from 'src/app/shared-services/local-storage/local-storage.service';
import { TemporalToken } from '../dtos/temporalToken';

@Injectable({
  providedIn: 'root'
})
export class RegistroManualService {

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private backendService: BackendService
  ) { }

  registroTelefono(form: { phoneNumber: string, countryId: number}) {
    
    this.spinner.show();
    return this.backendService.update(TemporalToken.path, form).then((value: { temporalToken: string }) => {
        //LocalStorageService.saveLocalStorage(RextClientRegisterEnum.TOKEN_TEMP, value.temporalToken);
        //LocalStorageService.saveLocalStorage(RextClientRegisterEnum.PHONE_NUMBER, form.phoneNumber);
        //LocalStorageService.saveLocalStorage(RextClientRegisterEnum.PHONE_NUMBER_COUNTRY, form.countryId.toString());*/

        //this.router.navigateByUrl('/auth/register/verify-code');
      })
      
  }
}
