import { Component, OnInit } from '@angular/core';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-error-generico',
  templateUrl: './error-generico.component.html',
  styleUrls: ['./error-generico.component.scss']
})
export class ErrorGenericoComponent extends Toast {

  public message;
  constructor(protected toastrService: ToastrService,
              public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

}
