import {Logo} from './logo.model';
import {AreasImpresion} from './areas-impresion';
import {LogoPedido} from '../viejo/logo-pedido.model';

export class LogoAreaImpresion {
  url: string;
  urlOriginal: string;
  name: string;
  logo : LogoPedido;
  area : AreasImpresion;

  logoCurvas: Logo;

  esTexto: boolean;
}
