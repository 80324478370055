import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, ActivatedRoute, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { SeguridadService } from './seguridad.service';

@Injectable()
export class LoguedGuardService implements CanActivate, CanActivateChild {

  constructor(public securityService: SeguridadService,
              public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const url = route.url.join('');

    if (url === '' || url === 'home') {

      if (this.securityService.logged()) {
        return true;
      }

      this.securityService.logout();
      return false;

    }

    const expectedPermission = route.firstChild !== null ? route.firstChild.data.expectedPermission : route.data.expectedPermission;

    if (!this.securityService.logged()) {
      this.securityService.logout();
      return false;
    }

    return true;

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }

}
