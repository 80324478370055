import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-solicitud-mas-info',
  templateUrl: './solicitud-mas-info.component.html',
  styleUrls: ['./solicitud-mas-info.component.scss']
})
export class SolicitudMasInfoComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef,
    private router: Router) {}

  ngOnInit() {
  }

  irA() {
    this.router.navigate(['/documentacion']);
    this.bsModalRef.hide();
  }

}
