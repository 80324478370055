import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistroComponent } from '../registro/registro.component';
import { ContactoComponent } from '../contacto/contacto.component';
import { LoginComponent } from '../seguridad/login/login.component';
import { LayoutRegistroLoginComponent } from '../shared-components/layout-registro-login/layout-registro-login.component';
import { CuentaCreadaComponent } from '../registro/cuenta-creada/cuenta-creada.component';
import { CorreoComponent } from '../registro/correo/correo.component';
import { CorreoResolver } from '../registro/correo/correo.resolver';
import { LinkInvalidoComponent } from '../link-invalido/link-invalido.component';
import { CompletarDocumentacionComponent } from '../registro/completar-documentacion/completar-documentacion.component';
import { RegistroSatisfactorioComponent } from '../registro/registro-satisfactorio/registro-satisfactorio.component';
import { DashboardComponent } from '../shared-components/dashboard/dashboard.component';
import { HomeComponentComponent } from '../home-component/home-component.component';
import { ClienteDetailsComponent } from '../seguridad/usuario/details/cliente-details.component';
import { ClienteResolver } from '../seguridad/usuario/details/cliente.resolver';
import { RedirectGuard } from './redirect.guard.service';
import { LoguedGuardService } from '../seguridad/logued-guard.service';
import { AnunciosImportantesComponent } from '../shared-components/anuncios-importantes/anuncios-importantes.component';
import { CuentaCongeladaComponent } from '../registro/cuenta-congelada/cuenta-congelada.component';
import { CuentaCongeladaResolver } from '../registro/cuenta-congelada/cuentaCongelada.resolver';
import { ConfiguracionComponent } from '../shared-components/configuracion/configuracion.component';
import { ClienteBloqueadoComponent } from '../cliente-bloqueado/cliente-bloqueado.component';
import { HomeResolver } from '../home-component/home.resolver';
import { DocumentacionComponent } from '../seguridad/usuario/documentacion/documentacion.component';
import { RecuperarClaveComponent } from '../seguridad/recuperar-clave/recuperar-clave.component';
import { MensajeRecuperarPasswordComponent } from '../shared-components/mensaje-recuperar-password/mensaje-recuperar-password.component';
import { LoginResolver } from '../seguridad/login/login.resolver';
import { DashboardResolver } from '../shared-components/dashboard/dashboard.resolver';
import { ProductoPedidoResolver } from '../productos/viejo/productoPedido.resolver';
import { PerfilUsuarioComponent } from '../shared-components/perfil-usuario/perfil-usuario.component';
import {ResetPasswordGuard} from '../core/services/guards/reset-password.guard';
import {ClienteResetPasswordResolver} from '../seguridad/cambiar-password/services/cliente-reset-password-resolver';
import {CambiarPasswordComponent} from '../seguridad/cambiar-password/cambiar-password.component';
import { ListaProductosComponent } from '../productos/lista-productos/lista-productos.component';
import { ListaProductosResolver } from '../productos/lista-productos/lista-productos.resolver';
import {ResultadoProductoComponent} from '../productos/resultado-producto/resultado-producto.component';
import {CarritoResolver} from '../productos/resultado-producto/resolver/carrito.resolver';
import {MisPedidosComponent} from '../shared-components/mis-pedidos/mis-pedidos.component';
import {MisPedidosResolver} from '../shared-components/mis-pedidos/mis-pedidos.resolver';
import {PedidoDetalleComponent} from '../shared-components/mis-pedidos/pedido-detalle/pedido-detalle.component';
import {ProductoParticularComponent} from '../productos/producto-particular/producto-particular.component';
import {ProductoParticularLogoComponent} from '../productos/producto-particular-logo/producto-particular-logo.component';
import { RecuperarClaveExitosoComponent } from '../seguridad/recuperar-clave/recuperar-clave-exitoso/recuperar-clave-exitoso.component';
import {ProductoInfoResolverService} from '../productos/resolver/producto-info-resolver.service';
import {ProductoLogoResolverService} from '../productos/resolver/producto-logo-resolver.service';
import { CheckoutComponent } from '../productos/checkout/checkout.component';
import { FaqsComponent } from '../faqs/faqs.component';
import { RegistroEmpresaComponent } from '../registro/registro-empresa/registro-empresa.component';
import { TipoImpresionComponent } from '../faqs/tipo-impresion/tipo-impresion.component';
import { PersonalizarComponent } from '../personalizar/personalizar.component';
import { PerfilDireccionComponent } from '../shared-components/perfil-usuario/perfil-direccion/perfil-direccion.component';
import { DireccionInfoResolver } from '../shared-components/perfil-usuario/resolver/direccion-info-resolver.service';
import { NuevaDireccionComponent } from '../shared-components/perfil-usuario/perfil-direccion/nueva-direccion/nueva-direccion.component';
import { AllNotificationsComponent } from '../notificaciones/all-notifications/all-notifications.component';
import { CarritoVacioComponent } from '../productos/carrito-vacio/carrito-vacio.component';
import { ResultadoProductoPagoAdicionalesComponent } from '../productos/resultado-producto/resultado-producto-pago-adicionales/resultado-producto-pago-adicionales.component';
import { PagoAdicionalResolver } from '../productos/resultado-producto/resolver/pago-adicional.resolver';
import { SelectCountryComponent } from '../select-country/select-country.component';
import { ResultadoProductoChileComponent } from '../productos/resultado-producto/resultado-producto-chile/resultado-producto-chile.component';
import { ResultadoProductoPagoAdicionalesClComponent } from '../productos/resultado-producto/resultado-producto-chile/resultado-producto-pago-adicionales-cl/resultado-producto-pago-adicionales-cl.component';
import { SelectorRegistroComponent } from '../registro/selector-registro/selector-registro.component';
import { BaseCheckoutComponent } from '../productos/checkout/base-checkout/base-checkout.component';
import { BaseCheckoutAdicionalesComponent } from '../productos/checkout-adicionales/base-checkout-adicionales/base-checkout-adicionales.component';
const routes: Routes = [
  {
    path: 'login', component: LayoutRegistroLoginComponent,
    children: [
      {
        path: '', component: LoginComponent,
        data: { background: true, showSignIn: true, showLogin: false }, resolve: { logeado: LoginResolver },
      },
      {
        path: 'producto/:id', component: LoginComponent,
        data: { background: true, showSignIn: true, showLogin: false }, resolve: { logeado: LoginResolver },
      },
      {
        path: 'recuperar-password', component: RecuperarClaveComponent,
        data: { background: true, showSignIn: true, showLogin: false },
      },
      {
        path: 'recuperar-password/mensaje', component: MensajeRecuperarPasswordComponent,
        data: { background: true, showSignIn: true, showLogin: false },
      },
      {
        path: 'recuperar-password/exitoso', component: RecuperarClaveExitosoComponent,
        data: { background: true, showSignIn: true, showLogin: false },
      },
      {
        path: 'reset-password/:hash', component: CambiarPasswordComponent,
        canActivate: [ResetPasswordGuard],
        data: { background: true, showSignIn: true, showLogin: false },
        resolve: { hash: ClienteResetPasswordResolver }
      },
      {
        path: 'congelar-cuenta/:hash', component: CuentaCongeladaComponent,
        data: { background: true, showSignIn: false, showLogin: true }, resolve: { cliente: CuentaCongeladaResolver }
      },
      {
        path: 'link-invalido', component: LinkInvalidoComponent,
        data: { background: true, showSignIn: true, showLogin: true },
      },
      {
        path: 'cliente-bloqueado', component: ClienteBloqueadoComponent,
        data: { background: true, showSignIn: true, showLogin: true }
      },

      {
        path: 'contacto', component: ContactoComponent,
        data: { background: true, showSignIn: true, showLogin: true },
      },
      {
        path: 'anuncios/importantes', component: AnunciosImportantesComponent,
        canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService],
        data: { background: true }
      },
    ]
  },
  {
    path: 'registro', component: LayoutRegistroLoginComponent,
    children: [
      {
        path: 'selector', component: SelectorRegistroComponent,
        data: { background: true, showSignIn: false, showLogin: true },
      },
      {
        path: 'personal', component: RegistroComponent,
        resolve: {
          //countries: CountryPhoneResolver,
        },
        data: { background: true, showSignIn: false, showLogin: true },
      },
      {
        path: 'empresa', component: RegistroEmpresaComponent,
        data: { background: true, showSignIn: false, showLogin: true },
      },
      {
        path: 'cuenta-creada', component: CuentaCreadaComponent,
        data: { background: true, showSignIn: false, showLogin: true },
      },
      {
        path: 'confirmar-email/:hash', component: CorreoComponent,
        data: { background: true, showSignIn: false, showLogin: true }, resolve: { correo: CorreoResolver }
      },
      {
        path: 'completar-documentacion', component: CompletarDocumentacionComponent,
        canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService],
        data: { background: true },
      },
      {
        path: 'registro-satisfactorio', component: RegistroSatisfactorioComponent,
        canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService],
        data: { background: true },
      }
    ],
  },
  {
    path: 'checkout-success/:id', component: CheckoutComponent, data : {success : true},
    canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService]
  },
  {
    path: 'checkout-fail/:id', component: CheckoutComponent, data : {success : false},
    canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService]
  },
  {
    path: 'select-country', component: SelectCountryComponent
  },
  {
    path: '', component: DashboardComponent,
    resolve: { categorias: DashboardResolver},
    children: [
      {
        path: '', component: HomeComponentComponent,
        resolve: { informacion: HomeResolver },
      },
      {
        path: 'perfil', component: PerfilUsuarioComponent,
        canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService],
      },
      {
        path: 'nueva-direccion', component: NuevaDireccionComponent,
        data: { background: true } ,
      },
      {
        path: 'perfil-direccion/:id', component: PerfilDireccionComponent,
        resolve: { direccion: DireccionInfoResolver }
      },
      {
        path: 'contacto', component: ContactoComponent,
      },
      {
        path: 'configuracion', component: ConfiguracionComponent,
        canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService],
        data: { background: true }, resolve: { cliente: ClienteResolver},
      },
      {
        path: 'perfil-usuario', component: ClienteDetailsComponent,
        canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService],
        data: { background: true } , resolve: { cliente: ClienteResolver},
      },
      {
        path: 'documentacion', component: DocumentacionComponent,
        data: { background: true },
      },
      {
        path: 'producto/:slug', component: ProductoParticularComponent,
        resolve: { producto: ProductoInfoResolverService }
      },
      {
        path: 'producto-logo/:slug', component: ProductoParticularLogoComponent,
      },
      {
        path: 'lista-productos', component: ListaProductosComponent,
        resolve: { productos: ListaProductosResolver }
      },
      {
        path: 'previsualizacion-compra', component: BaseCheckoutComponent,
        canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService],
      },
      {
        path: 'previsualizacion-compra-cl', component: ResultadoProductoChileComponent,
        canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService],
      },
      {
        path: 'previsualizacion-compra-adicionales/:id', component: BaseCheckoutAdicionalesComponent,
        //canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService],
        resolve: { pedido: PagoAdicionalResolver},
      },
      {
        path: 'previsualizacion-compra-adicionales-cl/:id', component: ResultadoProductoPagoAdicionalesClComponent,
        //canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService],
        resolve: { pedido: PagoAdicionalResolver},
      },
      {
        path: 'mis-pedidos', component: MisPedidosComponent,
        canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService],
        resolve: { pedidos: MisPedidosResolver },
      },
      {
        path: 'mis-pedidos/:id', component: PedidoDetalleComponent,
        canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService],
        resolve: { pedido: MisPedidosResolver }
      },
      {
        path: 'faqs', component: FaqsComponent
      },
      {
        path: 'tipo-impresion', component: TipoImpresionComponent
      },
      {
        path: 'personalizar', component: PersonalizarComponent
      },
      {
        path: 'notificaciones', component: AllNotificationsComponent,
        canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService],
      },
      {
        path: 'carrito-vacio', component: CarritoVacioComponent
      },
      
    ],
  },
];

@NgModule({
  exports: [ RouterModule ],
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})
  ],
  declarations: [],
  providers: [
    RedirectGuard,
    HomeResolver
  ]
})
export class AppRoutingModule {}
