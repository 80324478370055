import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, HostListener } from '@angular/core';

import { timer, of, Observable, Subject, Observer, Subscription } from 'rxjs';

import { ApiService } from '../../core/api.service';
import 'rxjs/add/observable/interval';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { Router, NavigationEnd, ActivatedRouteSnapshot, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { BackendService } from 'src/app/core/backend.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { Pedido } from '../../productos/resultado-producto/models/pedido.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificacionesCarrito } from 'src/app/shared-services/notificacion-carrito.service';
import { NotificacionesComunicationService } from '../../shared-services/notificaciones-comunication.service';
import { Color } from '../../productos/color.model';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { Cliente } from '../../cliente/cliente.model';
import { CantidadesTotal } from './cantidades-total.model';
import { MinimoMaximo } from './minimo-maximo.model';
import { ModalsService } from 'src/app/shared-services/modals.service';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';
import { PerfilBase } from '../perfil-usuario/models/perfil-base.model';
import { NotificacionService } from 'src/app/notificaciones/services/notificacion.service';
import { ProductosBuscados } from './productosBuscados.model';
import { FormControl } from '@angular/forms';
import { containsElement } from '@angular/animations/browser/src/render/shared';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { getLocale } from 'ngx-bootstrap';
import { PaisEnum } from 'src/app/core/constants/pais-enum';
import { PaisService } from 'src/app/shared-services/pais.service';
import { Gtag } from 'angular-gtag';
import { UsuarioService } from 'src/app/core/usuario.service';


declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() showLogin = true;
  @Input() showSignIn = true;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('results') results: ElementRef;

  user: any;
  public productoBuscado = '';
  productos:ProductosBuscados[] = [];
  hayProductos:number;
  subscriptions: Subscription[] = [];
  selectPais: string;
  showBoolean:boolean = false;
  hideBoolean:boolean = true;
  isDropdownOpen = false;
  hideFilters = true;
  isMobile: boolean = false;
  rangoSeleccionado: MinimoMaximo = {minimo: undefined, maximo: undefined, seleccionado: true };
  color: string;
  colores: any;
  link = '/lista-productos?';
  previousUrl: string;
  rangos: MinimoMaximo[] = [{minimo: 0, maximo: 999, seleccionado: false}, {minimo: 1000, maximo: 1999, seleccionado: false},
    {minimo: 2000, maximo: 2999, seleccionado: false }, {minimo: 3000, maximo: 3999, seleccionado: false },
    {minimo: 4000, maximo: undefined, seleccionado: false }, ];

  rangoParticular: MinimoMaximo = {minimo: undefined, maximo: undefined, seleccionado: false };

  cantTotalGasto: CantidadesTotal;
  subcategoria: number;

  home: boolean;
  focus: boolean;

  pedido: Pedido;

  perfilBase: PerfilBase;
  cantidadNotificaciones: number = 0;

  general={
    id:0,
    description:"Todas las categorías"
  }
  

  searchControl = new FormControl();

  constructor(
    public seguridadService: SeguridadService,
    public router: Router,
    private apiService: ApiService,
    private backendService: BackendService,
    public dashboard: DashboardComponent,
    public notificacionesComunicationService: NotificacionesComunicationService,
    public notificacionesCarrito:NotificacionesCarrito,
    private toast: ToastService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalsService,
    private _sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private notificacionService: NotificacionService,
    private renderer: Renderer2,
    private paisService: PaisService,
    private elementRef: ElementRef,
    private gtag: Gtag,
    private usuarioService:UsuarioService
  ) {
    /*this.renderer.listen('window', 'click', (e:Event) =>{
      
      if(e.target !== this.searchInput.nativeElement && e.target !== this.results.nativeElement){
        this.focus = false;
      }
    })*/
  }

  async ngOnInit() {
    console.log(this.isDropdownOpen);
    this.checkDeviceSize();
    await this.usuarioService.user.subscribe(u => {
      this.user = u;
      if(u){
        this.cargarDatosUser();
      }
    })

    this.user = this.seguridadService.user;
    this.selectPais = localStorage.getItem('pais');

    if(this.selectPais == null){
      await this.paisService.detectCountry().then(a=>{
        this.selectPais = localStorage.getItem('pais');
        this.paisService.emitChangePais(this.selectPais);
        if(!this.selectPais){
          this.router.navigateByUrl('/select-country')
        }
      });
    }

    if(this.user){
      await this.cargarDatosUser();
    }    

    this.activatedRoute.queryParams.subscribe(params => {
      this.productoBuscado = params['search'];
      this.subcategoria = params['subCat'];
      this.color = params['color'];
      this.rangoSeleccionado.minimo = params['precioMin'];
      this.rangoSeleccionado.maximo = params['precioMax'];
    });

    /*     await this.getColores();

        //await this.getMaximoGasto();
        this.colores.forEach(c => {

          document.addEventListener("DOMContentLoaded", function () { 
            
            const color = document.getElementById('header' + c.id);
            if(color.style){
              color.style.backgroundColor = c.code;
            }
         });          

        });
     
    if (this.color) {
      this.colores.map(c => c.colorSeleccionado = false);
      const colorSeleccionado = this.color.replace('-', ' ');
      const color = this.colores.find(c => c.description === colorSeleccionado);
      color.colorSeleccionado = true;
      const colo = document.getElementById('header' + color.id);
      if(colo.style){
        colo.style.backgroundImage = 'url(/assets/images/check-icn.svg)';
        colo.style.backgroundRepeat = 'no-repeat';
        colo.style.backgroundPosition = 'center';
      }
    }*/
    if (this.rangoSeleccionado.minimo || this.rangoSeleccionado.maximo) {
      const rang = this.rangos.find
            (rango => rango.minimo === +this.rangoSeleccionado.minimo && rango.maximo === +this.rangoSeleccionado.maximo);
      if (rang) {
        rang.seleccionado = true;
      } else {
        this.rangoParticular.minimo = this.rangoSeleccionado.minimo;
        this.rangoParticular.maximo = this.rangoSeleccionado.maximo;
        this.rangoParticular.seleccionado = true;
      }
    }

  }

  async logout() {
    this.user = null;
    await this.seguridadService.logoutTemporalSesion();
    this.usuarioService.emitChange(null);
    this.router.navigateByUrl('/');
    
  }

  keyPress(event) {
    this.productoBuscado = (<HTMLInputElement>document.getElementById("searchText")).value;
    if (event.keyCode === 13) {
      var tb = document.getElementById('basic-addon1');
      this.searchInput.nativeElement.blur();
      this.focus = false;
      tb.click();
    }
    else{
      /*if(event.key === "Backspace"){
        this.productoBuscado = this.productoBuscado.substring(0, this.productoBuscado.length -1)
      }else{
        this.productoBuscado += event.key;
      }*/
      if(this.productoBuscado.length>2){
        this.buscadorProductos();
      }else{
        this.productos=[];
      }
    }

  }
  
  notFocus(){
    this.focus=false;
  }
  async getColores() {
    await this.backendService.getNumber(Cliente.path + 'colores').then(
      data => {
        this.colores = data as Color[];
        this.colores.forEach(element => {
          element.colorSeleccionado = false;
        });
      }
    );
  }

  async onSearch() {
    this.link = '/lista-productos?';
    this.link += this.productoBuscado ? 'search=' + this.productoBuscado + '&' : '';
    const reload = this.router.url.startsWith('/lista-productos');

    await this.router.navigateByUrl(this.link);
    this.gtag.event('search_product', {
      items: [{
        item_name: "producto buscado" + this.productoBuscado
      }]
    });
    if (reload) {
      const filtros = this.productoBuscado + '&' + undefined + '&' + undefined + '&'
      + undefined + '&' + undefined;
      
      this.notificacionesComunicationService.filtersChange(filtros);
    }
  }

  async onSearchCategoria() {
    this.link = '/lista-productos?';
    this.link += this.subcategoria ? 'subCat=' + this.subcategoria + '&' : '';
    const reload = this.router.url.startsWith('/lista-productos');

    await this.router.navigateByUrl(this.link);
    if (reload) {
      const filtros = undefined + '&' + this.subcategoria + '&' + undefined + '&'
        + undefined + '&' + undefined;
      this.notificacionesComunicationService.filtersChange(filtros);
    }
  }
  /*async getMaximoGasto() {
    await this.backendService.get(CantidadesTotal, Pedido.path + 'maximo-gasto').toPromise().then(
      data => this.cantTotalGasto = data
    );
  }*/

  borrarTodo() {
    this.clearColor();
    this.clearPrecio();
  }

  clearColor() {
    this.color = undefined;
    this.colores.forEach(color => {
      color.colorSeleccionado = false;
      const colo = document.getElementById('header' + color.id);
      if(colo.style){
        colo.style.backgroundImage = 'none';
        colo.style.backgroundColor = color.code;
      }

    });
    this.onSearch();
  }

  clearPrecio() {
    this.rangoSeleccionado.minimo = undefined;
    this.rangoSeleccionado.maximo = undefined;
    this.rangos.forEach(rango => rango.seleccionado = false);
    this.rangoParticular.seleccionado = false;
    this.rangoParticular.maximo = undefined;
    this.rangoParticular.minimo = undefined;
    for (let i = 0; i < this.rangos.length; i++) {
      const rango = <HTMLInputElement><any> document.getElementById('radio-' + i);
      rango.checked = false;
    }
    this.onSearch();
  }


  async previsualizacion() {
    try {
      await this.backendService.get(Pedido, Pedido.new_path + 'estado-activo').toPromise();
      this.router.navigate(['/previsualizacion-compra']);
    } catch (ex) {
      this.toast.openErrorGenerico(ex.message);
    }
  }

  listarProductos() {
    const url = this.router.url.split('?')[0];
    if ('/lista-productos' === url) {
      this.notificacionesComunicationService.filtersChange(null);
      this.notificacionesComunicationService.categoriaChange(null);
    }
    this.router.navigate(['/lista-productos']);
  }

  markActive(categoria) {
    this.notificacionesComunicationService.filtersChange(categoria.description);
    this.notificacionesComunicationService.categoriaChange(categoria);
  }
  seleccionarCategoria(id: number){
    this.subcategoria=  undefined;
    this.subcategoria = id;
  }
  abrirContainer(number) {
    if(document.getElementById('container' + number)){
      document.getElementById('container' + number).style.display = 'inherit';
    }
  }

  cerrarContainer(number) {
    if(document.getElementById('container' + number)){
      document.getElementById('container' + number).style.display = 'none';
    }
  }

  async hayProductosCarrito(){
    await this.backendService.getNumber(Pedido.new_path + 'estado-activo-simple/'+ this.selectPais).then(cantidad =>{ 
      this.hayProductos = cantidad as number;
      this.notificacionesCarrito.emitChange(this.hayProductos);
    });
    if(this.hayProductos > 0){
      if(this.selectPais=="ARGENTINA"){
        this.router.navigateByUrl('/previsualizacion-compra');
        this.usuarioService.emitChangeVolver(true);
      }else if (this.selectPais=="CHILE"){
        this.router.navigateByUrl('/previsualizacion-compra-cl');
      }
    }else{
      this.router.navigateByUrl("/carrito-vacio")
    }
  }
  
  async buscadorProductos(){
    this.productos = await this.backendService.get(ProductosBuscados, ProductosBuscados.path + this.productoBuscado + "/" +this.selectPais, null).toPromise();
  }

  seleccionaPais(pais: string){
    //document.getElementById('dropdownMenuButtonCate').innerHTML = pais;
    this.paisService.emitChangePais(pais);
    localStorage.setItem('pais', pais);
    window.location.href = '/';
  }

  login(){
    this.router.navigateByUrl("login")
  }

  showOrHideToggler(){
    this.showBoolean = !this.showBoolean;
    this.hideBoolean = !this.hideBoolean;
  }

  onlyHideToggler(){
    if (this.showBoolean)
      this.showOrHideToggler();
    
  }

  @HostListener('document:click', ['$event'])
  onClick(event: any) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.focus = false;
    }
  }

  isFocus() {
    this.focus = true;
  }

  onBlur() {
    this.searchInput.nativeElement.blur();
  }

  onButtonClick() {
    this.focus = false; 
  }

  onCategoryClick(categoria) {
    this.gtag.event('select_category', {
      items: [{
        item_name: categoria.description,
        item_id: categoria.id,
      }]
    });
  }

  gtagProductResult(){
    this.gtag.event('click_product_result', {
      items: [{
        item_name: "click_product_result",
      }]
    });
  }

  async cargarDatosUser(){
    this.perfilBase = await this.backendService.get(PerfilBase, "/perfil/perfil-base").toPromise();
      await this.backendService.getNumber(Pedido.new_path + 'estado-activo-simple/'+this.selectPais).then(cantidad =>{ 
        this.hayProductos = cantidad as number;
      });
      await this.backendService.getNumber('/notificaciones/estado-notificaciones').then(cantidad =>{ 
        this.cantidadNotificaciones = cantidad as number;
      });

      //this.pedido = await this.backendService.get(Pedido, Pedido.new_path + 'estado-activo').toPromise();
      
      /*if(this.pedido.id){
        this.hayProductos= this.pedido.productos.length;
      } else {
        this.hayProductos = 0;
      }*/
      
      this.notificacionesCarrito.emitChange(this.hayProductos);

      this.notificacionService.emitChange(this.cantidadNotificaciones);

      this.hayProductos = this.notificacionesCarrito.getCantidadProductosCarrito();

      this.cantidadNotificaciones = this.notificacionService.getCantidadNotificacionesSinLeer();

      this.notificacionesCarrito.producto.subscribe(val => {
        this.hayProductos = val;
      });

      this.notificacionService.notificacion.subscribe(val =>{
        this.cantidadNotificaciones = val;
      })
  }

  toggleDropdown(open: boolean): void {
    this.isDropdownOpen = !this.isDropdownOpen;
    console.log(this.isDropdownOpen);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkDeviceSize();
  }

  private checkDeviceSize() {
    this.isMobile = window.innerWidth < 768;
  }

}
