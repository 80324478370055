import { Component, OnInit } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: 'app-tipo-de-impresion-toast',
  templateUrl: './tipo-de-impresion-toast.component.html',
  styleUrls: ['./tipo-de-impresion-toast.component.scss']
})
export class TipoDeImpresionToastComponent extends Toast {

  constructor(protected toastrService: ToastrService,
    public toastPackage: ToastPackage) {
super(toastrService, toastPackage);
}

action(event: Event) {
event.stopPropagation();
this.toastPackage.triggerAction();
return false;
}

}
