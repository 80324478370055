import { BackendModel, BackendService } from './backend.service';
import { DecimalPipe } from '@angular/common';


export class Precio extends BackendModel {

  static path = '/precios/';

  lastSeller: DecimalPipe;
  lastBuyer: DecimalPipe;
  currencie: String;
  variation: DecimalPipe;
  volumen: DecimalPipe;

  constructor(protected backendService: BackendService, model?: Precio) {
    super(backendService, model);
  }

}
