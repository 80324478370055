import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { Pedido } from '../resultado-producto/models/pedido.model';
import { ProductoService } from '../service/producto.service';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'app-metodo-de-pago-modal',
  templateUrl: './metodo-de-pago-modal.component.html',
  styleUrls: ['./metodo-de-pago-modal.component.scss']
})
export class MetodoDePagoModalComponent implements OnInit {

  @Input() paisId:number;

  pedido: Pedido;
  public onClose: Subject<boolean>;
  selectPais:string;
  metodoSeleccionado:boolean;

  constructor(public bsModalRef: BsModalRef,
    private productoService: ProductoService,
    private gtag: Gtag,
    ) {
  }

  ngOnInit() {
    this.selectPais = localStorage.getItem('pais');
    this.onClose = new Subject();
    this.metodoSeleccionado = false;
  }

  seleccionarMetodo(metodo: string){
    this.productoService.setMetodoDePago(metodo);
    this.gtagMetodoPago(metodo)
    this.metodoSeleccionado = true;
  }

  confirma() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  cerrar(){
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  gtagMetodoPago(metodo){
    this.gtag.event('metodo_de_pago_' + metodo, {
      items: [{
        item_name: metodo
      }]
    });
  }

}
