import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { BackendService } from '../../core/backend.service';
import { ProductoEnviar } from './productoEnviar.model';
import { ToastService } from 'src/app/shared-services/toast/toast.service';

@Injectable()
export class ProductoPedidoResolver implements Resolve<any> {

    constructor(
        protected router: Router,
        protected backendService: BackendService,
        private toast: ToastService
      ) {}

      async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        try {
          const id = route.params.id;

          return await this.backendService.get(ProductoEnviar, ProductoEnviar.path + 'detalles-' + id).toPromise();
        } catch (error) {
          this.toast.openErrorGenerico('Producto no encontrado');
          this.router.navigate(['/']);
        }
      }

}
