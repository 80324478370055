import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { BackendService } from 'src/app/core/backend.service';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { ProductoService } from '../../../service/producto.service';

@Component({
  selector: 'app-subir-logos-con-curvas',
  templateUrl: './subir-logos-con-curvas.component.html',
  styleUrls: ['./subir-logos-con-curvas.component.scss']
})
export class SubirLogosConCurvasComponent implements OnInit {

  pedidoId: number;
  cantidadDeLogos:number;
  
  public onClose: Subject<boolean>;
  formData: FormData;
  logoConCurvas: File;
  logosCurvas:File[]=[];
  
  logosPorEnviar:boolean=true;

  link : string = '/new-pedidos/logo-success/';

  textoUltimoLogo: string = "";

  constructor(
    public bsModalRef: BsModalRef,
    private backendService: BackendService,
    private toastService: ToastService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private productoService: ProductoService
    
  ) {  
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  noConfirma() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  noConfirmaAceptado() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
  
  confirma() {
    this.onClose.next(true);
    
    this.productoService.setSubioLogosCurvas(true);
    this.bsModalRef.hide();
  }

  selectFile(event) {
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }
    var mimeType = event.target.files[0].type;
    //if (mimeType.match(/image\/*/) == null) {
    //  return;
    //}accept=".png, .jpg, .jpeg, .ai"

    if (!mimeType.match(/image\/(eps|cdr|jpg|ai|jpeg|png)/)) {
      this.toastService.openErrorGenerico("Formato incorrecto de logo. Solo se permiten archivos ESP, CDR, AI, PNG, JPG y JPEG.");
      return;
    }
    this.logoConCurvas = event.target.files[0];
    debugger
    this.textoUltimoLogo = this.logoConCurvas.name;

    this.toastService.openSuccessGenerico("ARCHIVO SUBIDO EXITOSAMENTE!");
    this.logosCurvas.push(this.logoConCurvas)
  }

  async subirLogo(){
    this.spinner.show()
    try{
      await this.enviarLogosCurvas().then(()=>{
        debugger
        this.backendService.update(this.link + this.pedidoId, null).then(a=>{
          debugger
          this.logosPorEnviar = false;
          this.onClose.next(true);
          this.spinner.hide();
          this.bsModalRef.hide();
        })
        
      });
    } catch(e){
      console.log(e.message);
      this.spinner.hide();
    }finally{
      
    }
    
  }

  eliminarArchivo(index){


    this.logosCurvas.splice(index,1)
    /*this.logosCurvas= this.logosCurvas.filter(a=>{
      a != logo
    })*/
  }

  async enviarLogosCurvas(){

    
    for(var i=0; i<this.logosCurvas.length; i++){

      this.formData = new FormData();
      this.formData.append('file', this.logosCurvas[i]);

      await this.backendService.createFile('/new-pedidos/guardar-logo-curvas/' + this.pedidoId, this.formData)
    }

    /*await this.logosCurvas.forEach(async(logo) =>{

      this.formData = new FormData();
      this.formData.append('file', logo);

      await this.backendService.createFile('/new-pedidos/guardar-logo-curvas/' + this.pedidoId, this.formData)

    })*/
  }

}
