import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PrecioService } from '../../shared-services/precio.service';
import { Precio } from '../../core/precio.model';
import { ApiService } from '../../core/api.service';

@Component({
  selector: 'app-currencies',
  templateUrl: './currencies.component.html',
  styleUrls: ['./currencies.component.scss']
})
export class CurrenciesComponent implements OnInit {

  public precios: Precio[];

  private killTrigger: Subject<void> = new Subject();

  private sub = Observable.interval(10000)
  .subscribe((val) => { this.getPrecio(); });

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.getPrecio();
  }

  async getPrecio() {
    this.precios = await this.apiService.get(Precio, Precio.path + 'currencies', null).toPromise();
  }

}
