import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { UsuarioService } from '../../core/usuario.service';
import { ApiService } from '../../core/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormValidatorServiceService } from '../../shared-services/form-validator-service.service';
import { NgForm } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-google2fa',
  templateUrl: './google2fa.component.html',
  styleUrls: ['./google2fa.component.scss']
})
export class Google2faComponent implements OnInit {

  @Input() private cliente: any;
  protected QRurl: string;
  public showQR = false;
  public showCode = false;
  private activar = 'Activar';
  private desactivar = 'Desactivar';
  public perfilOperational = { twoFAHabilitado: false, code: undefined };
  @ViewChild('twoFaForm') public twoFaForm: NgForm;
  constructor(
    private apiService: ApiService,
    private usuarioService: UsuarioService,
    private toastr: ToastrService,
    private toast: ToastService,
    private spinner: NgxSpinnerService,
    private formValidatorService: FormValidatorServiceService,
    public ngxSmartModalService: NgxSmartModalService,
  ) { }

  ngOnInit() {
    this.perfilOperational.twoFAHabilitado = this.cliente.twoFAHabilitado;
  }
  validateTwoFaForm() {
    if (this.formValidatorService.isFormValid(this.twoFaForm)) {
      return true;
    }
    return false;
  }

  private async invokeTwoFaUpdate() {
    if (this.validateTwoFaForm()) {
      this.updateTwoFa();
    }
  }

  // Se habilita/deshabilita el 2fa
  async updateTwoFa() {
    this.spinner.show();
    try {
      await this.apiService.create('/usuarios/validate2FA', this.perfilOperational);
      this.showQR = false;
      this.showCode = false;
      this.cliente.twoFAHabilitado = this.perfilOperational.twoFAHabilitado;
      this.perfilOperational.code = undefined;
      this.toast.openSuccessGenerico('Configuración de 2FA actualizada');
    } catch (ex) {
      this.toastr.error(ex.message);
    }
    finally {
      this.spinner.hide();
    }
  }

  async onCheckboxChange() {
    if (!this.cliente.twoFAHabilitado) {
      if (this.perfilOperational.twoFAHabilitado) {
        const response = await this.usuarioService.getQRurl();
        this.QRurl = response.text();
        this.showQR = true;
        this.showCode = true;
      } else {
        this.showQR = false;
        this.showCode = false;
      }
    } else {
      if (this.perfilOperational.twoFAHabilitado) {
        this.showCode = false;
        this.showQR = false;
      } else {
        this.showCode = true;
      }
    }
  }
}
