import { BackendModel, BackendService } from './backend.service';
import { DecimalPipe } from '@angular/common';
import { Parametric } from '../cliente/parametric.model';
import { TipoModena } from './tipo-moneda.model';

export class Operacion extends BackendModel {

    static path = '/operaciones/';

    monto: Float32Array;
    monedaOrigen: TipoModena;
    monedaDestino: TipoModena;
    fechaHoraOperacion: Date;
    tipoOperacion: Parametric;
    formaOperacion: Parametric;
    precio: Float32Array;
    cantidad: Float32Array;
    estadoOperacion: Parametric;
    id: any;

    constructor(protected backendService: BackendService, model?: Operacion) {
        super(backendService, model);
    }
}
