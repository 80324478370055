import { Injectable, Inject } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Cliente } from '../../../cliente/cliente.model';
import { BackendService } from '../../../core/backend.service';
import { Subcliente } from 'src/app/cliente/subcliente.model';

@Injectable()
export class ClienteResolver implements Resolve<Subcliente> {
  constructor(
    protected router: Router,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      return await this.backendService.get(Subcliente, Subcliente.path + 'perfil').toPromise();
    } catch (error) {
      alert(error);
      this.router.navigate(['/clientes']);
    }
  }
}
