import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BackendService } from 'src/app/core/backend.service';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { NotificacionesCarrito } from 'src/app/shared-services/notificacion-carrito.service';
import { NotificacionesComunicationService } from 'src/app/shared-services/notificaciones-comunication.service';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { Gtag } from 'angular-gtag';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DescuentoPedido } from '../../resultado-producto/models/descuento-pedido.model';
import { ProductoSimple } from '../../resultado-producto/models/producto-simple.model';
import { PedidoSimple } from '../../resultado-producto/models/pedido-simple.model';
import { Pedido } from '../../resultado-producto/models/pedido.model';
import { ProductoEnviar } from '../../viejo/productoEnviar.model';
import { PaisEnum } from 'src/app/core/constants/pais-enum';
import { Producto } from '../../models/producto.model';
import { PaisService } from 'src/app/shared-services/pais.service';
import { ProductoUnicaImagen } from 'src/app/home-component/model/producto-unica-imagen';
import { UsuarioService } from 'src/app/core/usuario.service';
import { ProductoStockSimple } from '../../dtos/producto-stock-simple';
import { ScrollerService } from 'src/app/core/services/scroller.service';

@Component({
  selector: 'app-checkout-uno',
  templateUrl: './checkout-uno.component.html',
  styleUrls: ['./checkout-uno.component.scss']
})
export class CheckoutUnoComponent implements OnInit {

  @Output() continuarCompraEvent = new EventEmitter<Partial<Pedido>>();

  public pedido: Partial<Pedido> = {};
  public pedidoSimple: PedidoSimple;
  private hayProductos: number;
  public productos: ProductoSimple[];
  public subtotal = 0;
  public cantidadDeLogos:number = 0;
  public selectPais:string;
  precioInicial:number;
  formCodigoDescuento: FormGroup;
  hayTalles: Boolean = false;
  codigoDescuentoEnviar: string;
  public descuentoAplicablePedido: DescuentoPedido = null;
  codigoIngresado:boolean = true;
  public top5Productos: ProductoUnicaImagen[] = [];

  constructor(
    private backendService: BackendService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private router: Router,
    public notificacionesCarrito: NotificacionesCarrito,
    private notificacionesComunicationService: NotificacionesComunicationService,
    private seguridadService: SeguridadService,
    private toast: ToastService,
    private gtag: Gtag,
    private paisService:PaisService,
    private usuarioService:UsuarioService,
    private scrollerService: ScrollerService
  ) { }

  async ngOnInit() {
    this.spinner.show();
    this.scrollerService.scrollToTop();
    const user = this.seguridadService.user;
    if(!user){
      this.router.navigateByUrl('/');
    }
    await this.createFormDescuento();
    await this.getPais();
    await this.getCantidadProductosCarrito();
    await this.getPedidoAndProductos();
    if (this.productos.length !== 0) {
      await this.getSubtotal();
      this.precioInicial = this.subtotal;
    }
    this.top5Productos = await this.backendService.get(ProductoUnicaImagen, Producto.new_path + 'top-producto-particular/' + this.selectPais, null).toPromise();
    this.spinner.hide();
  }

  

  round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
  }

  createBlob(pdf: any) {
    const blob = new Blob([pdf], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  async getPais(){
    this.selectPais = localStorage.getItem('pais');
    if(this.selectPais == null){
      await this.paisService.detectCountry().then(a=>{
        this.selectPais = localStorage.getItem('pais');
      });
    }
  }

  createFormDescuento(){
    this.formCodigoDescuento = this.formBuilder.group({
      codigoDescuento:[undefined, ],
    });
  }

  async getPedidoAndProductos(){
    try{
      await this.backendService.get(PedidoSimple, Pedido.new_path + 'estado-activo').toPromise().then(a =>{
        this.pedidoSimple = a as PedidoSimple;
      });
    }catch(ex){
      this.router.navigateByUrl('/');
    }
    this.pedido.id = this.pedidoSimple.id;
    this.productos = this.pedidoSimple.productos;
    await this.productos.forEach(prod => {
      
       this.backendService.get(ProductoStockSimple, '/producto-pedido/stock/' + prod.id + '/' + prod.colores[0].id).toPromise().then(data =>{
        prod.stock = data.stock;
        prod.idProdStock = data.id;
      });
      /*this.backendService.getNumber('/producto-pedido/stock/' + prod.id + '/' + prod.colores[0].id).then(data => {
        prod.stock = data as number;
      });*/
    });
    this.actualizarCantidadLogosConCurvas();
    this.productos.forEach(prod =>{
      if(prod.talle != null){
        this.hayTalles = true;
      }
    });
  }

  actualizarCantidadLogosConCurvas(){
    this.cantidadDeLogos =0;
    this.productos.forEach(prod =>{
      this.cantidadDeLogos = prod.cantidadLogos + this.cantidadDeLogos;
    })
  }

  async getSubtotal() {
    this.subtotal = 0;
    for (let i = 0; i < this.productos.length; i++) {
      this.subtotal = this.subtotal + (this.productos[i].cantidad * this.productos[i].precioConIva) + (this.productos[i].precioAdicional * this.productos[i].cantidadLogos);
    }
  }

  async obtenerPdfPresupuesto(){
    if(this.descuentoAplicablePedido){
     this.pedido.descuentoPedido = this.descuentoAplicablePedido;
     this.spinner.show();
     await this.backendService.getPresupuesto(Pedido.new_path +'presupuestoConDescuento/'+ this.pedido.id, this.pedido).toPromise().then(a =>{
     this.createBlob(a);
     this.spinner.hide();
     })
   }else{
     this.spinner.show();
     await this.backendService.getPresupuesto(Pedido.new_path +'presupuestoConDescuento/'+ this.pedido.id, this.pedido).toPromise().then(a =>{
     this.createBlob(a);
     this.spinner.hide();
    })}
    await this.backendService.update(Pedido.new_path + 'presupuesto-descargado/' + this.pedido.id, Boolean);
     this.gtag.event('budget_checkout', {
       items: [{
         item_name: "pdf_checkout"
       }]
     });
 }

 async updateCantidad(productoPedido:ProductoSimple, productoCantidad){
  this.spinner.show();
  if(productoCantidad > 0){
    if(productoCantidad <= productoPedido.stock){
      await this.backendService.get(PedidoSimple, Pedido.new_path + 'update-cantidad-producto/' + productoPedido.id + '/' + productoCantidad + '/' + PaisEnum.ARGENTINA).toPromise().then(a => {
        this.pedidoSimple = a as PedidoSimple;
      });
      this.pedido.id = this.pedidoSimple.id;
      this.productos = this.pedidoSimple.productos;
      await this.productos.forEach(prod => {
        this.backendService.get(ProductoStockSimple, '/producto-pedido/stock/' + prod.id + '/' + prod.colores[0].id).toPromise().then(data =>{
          prod.stock = data.stock;
          prod.idProdStock = data.id;
        });
        /*this.backendService.getNumber('/producto-pedido/stock/' + prod.id + '/' + prod.colores[0].id).then(data => {
          prod.stock = data as number;
        });*/
      });
      this.getSubtotal();
      if(this.codigoDescuentoEnviar) {
        this.verificarCodigo();
        this.precioInicial = this.subtotal;
      }}else{
        this.toast.openErrorGenerico("No hay stock suficiente");
      }
    this.spinner.hide();
  }else{
    productoPedido.cantidad = 0;
    //this.toast.openErrorGenerico("Ingrese una cantidad mayor a 0");
    this.spinner.hide();
  }
}

async deleteProd(id: number) {
  await this.borrar(id);
  await this.backendService.getNumber(Pedido.new_path + 'estado-activo-simple/'+ this.selectPais).then(cantidad =>{ 
    this.hayProductos = cantidad as number;
  });
  this.hayProductos = this.notificacionesCarrito.getCantidadProductosCarrito();
  this.notificacionesCarrito.emitChange(this.hayProductos-1);
  this.actualizarCantidadLogosConCurvas()
}

async borrar(id) {
  try {
    let borrado;
    this.spinner.show();
    await this.backendService.delete(ProductoEnviar.path + id).then((res: Response) => {
      borrado = res.json();
    });
    if (borrado) {
      this.router.navigateByUrl('/');
    } else {
      this.pedidoSimple = await this.backendService.get(PedidoSimple, Pedido.new_path + 'estado-activo').toPromise();
      this.productos = this.pedidoSimple.productos;
      this.subtotal = 0;
      await this.getSubtotal();
      await this.productos.forEach(prod => {
        this.backendService.get(ProductoStockSimple, '/producto-pedido/stock/' + prod.id + '/' + prod.colores[0].id).toPromise().then(data =>{
          prod.stock = data.stock;
          prod.idProdStock = data.id;
        });
        /*
        this.backendService.getNumber('/producto-pedido/stock/' + prod.id + '/' + prod.colores[0].id).then(data => {
          prod.stock = data as number;
        });*/
      });
    }
    this.notificacionesComunicationService.notificacionesChange();
  } catch (error) {
  } finally {
    this.spinner.hide();
  }
}

async handleKeyUp(event: KeyboardEvent, product: ProductoSimple, quantityValue: string) {
  await this.updateCantidad(product, quantityValue);
}

checkoutView(){
  this.gtag.event('view_checkout', {
    items: [{
      item_name: "checkout_argentina"
    }]
  });
}

  getDescriptions(colores) {
    if(colores)
      return colores.map(c => c.description).join(', ');
    return "No hay colores."
  }

  async verificarCodigo(){
    this.codigoDescuentoEnviar = this.formCodigoDescuento.controls.codigoDescuento.value;
    this.spinner.show();
    try{
      await this.backendService.get(DescuentoPedido, "/new-pedidos/codigo-valido/" + this.pedidoSimple.id + "/"+this.codigoDescuentoEnviar + "/" + this.selectPais).toPromise().then(part =>{
        this.descuentoAplicablePedido = part;
        var descuento = ((part.descuento*-1 /100) +1);
        part.productosAplicablesId.forEach(e => {
          this.productos.forEach(prod =>{  
             if(e == prod.id){
               prod.precioConIva = this.round(prod.precioConIva * descuento);
               prod.precioUnitario = this.round(prod.precioUnitario * descuento);
             }
          })
        });
        this.subtotal = 0;
        this.getSubtotal();
      })
      this.pedido.descuentoPedido = this.descuentoAplicablePedido;
      this.toast.openSuccessGenerico("Código de Descuento Aplicado con Exito " + this.descuentoAplicablePedido.descuento + "%");
      this.codigoIngresado = false;
    }catch(ex){
      this.toast.openErrorGenerico(ex.message);
    }finally{
      this.spinner.hide();
    } 
  }

  async continuarCompra(){
    try{
      this.spinner.show();
      if(this.validarCantidades()){
        await this.validarPrecios();
        this.pedido.subtotal = this.subtotal;
        if(this.pedido.subtotal < 20000){
          this.toast.openErrorGenerico("Recordá que nuestra compra mínima es de $20.000. si querés comprar otros productos agregalos a tu carrito");
          return;
        }
        this.gtagContinuar();
        this.usuarioService.emitChangeVolver(false);
        this.pedido.tiempoEntrega = this.pedidoSimple.tiempoEntrega;
        this.continuarCompraEvent.emit(this.pedido);
      }
    }catch{

    }finally{
      this.spinner.hide();
    }
    
    
  }

  async validarPrecios(){
    if(this.codigoIngresado){
      try{
        this.validarCantidades();
        await this.backendService.update(Producto.new_path + 'validar-precios', this.productos);
        this.toast.openSuccessGenerico("Validación concretada");
      }catch (ex) {
        if (ex[0]) {
        } else if (ex.errors) {
        } else {}
        this.scrollerService.scrollToTop();
      }}
  }

  wspEventAr(){
    this.gtag.event('boton_whatsapp_argentina_checkout', {
      items: [{
        item_name: "boton whatsapp argentina checkout"
      }]
    });
  }

  async getCantidadProductosCarrito(){
    await this.backendService.getNumber(Pedido.new_path + 'estado-activo-simple/'+ this.selectPais).then(cantidad =>{ 
      this.hayProductos = cantidad as number;
    });
    this.notificacionesCarrito.emitChange(this.hayProductos);
  }

  onInputChange(event: any,producto:ProductoSimple) {
    let inputValue: string = event.target.value;
  
    inputValue = inputValue.replace(/\D/g, '');
  
    if (inputValue === '0') {
      inputValue = ''; 
    }
  
    producto.cantidad = Number(inputValue);
  }

  validarCantidades(){
    let valor = true;
    this.productos.forEach(prod =>{

      if(prod.cantidad > 0){
        this.productos.forEach(prod2=>{
          if(prod.idProdStock == prod2.idProdStock){
            if(prod.id != prod2.id){
              if(prod.stock < (prod.cantidad + prod2.cantidad)){
                this.spinner.hide();
                this.toast.openErrorGenerico("El producto " + prod.nombre + " no tiene stock suficiente");
                valor = false;
              }     
            }
          }
        })
        if(prod.stock < prod.cantidad){
          this.spinner.hide();
          this.toast.openErrorGenerico("El producto " + prod.nombre + " no tiene stock suficiente");
          valor = false;
        }
      }else{
        valor = false;
        this.toast.openErrorGenerico("Ingrese una cantidad mayor a 0 para " + prod.nombre);
      }
    })
    return valor;
  }

  async modificarProducto(productoPedido:ProductoSimple){
    this.spinner.show();
    let slug;
    let productoId;
    await this.backendService.getString(Producto.new_path + 'producto-id-by-producto-pedido-id/' + productoPedido.id).then( a => {
      // @ts-ignore
      productoId = a._body;
    });
    await this.backendService.getString(Producto.new_path + 'slug-producto/' + productoId).then(a => {
      // @ts-ignore
      slug = a._body;
    })
    this.router.navigate(['/producto/' + slug, {edit:productoPedido.id, quantity: productoPedido.cantidad, color:productoPedido.colores[0].id}]);
  }

  gtagContinuar(){
    this.gtag.event('continua_paso_1_checkout', {
      items: [{
        item_name: "Continua paso 1 checkout"}]
    });
  }

}