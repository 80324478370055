import { Injectable, Inject } from '@angular/core';
import { Http, RequestOptionsArgs, Headers, ResponseContentType, Response } from '@angular/http';
import { EnvironmentProvider } from './browser-providers';
import { Environment } from '../../environments/enviroment.model';
import { BehaviorSubject, Observable } from '../../../node_modules/rxjs';
import { Usuario } from '../cliente/usuario.model';

@Injectable()
export class UsuarioService {

  private checkSubject = new BehaviorSubject<any>(undefined);
  private checkCarritoVolver = new BehaviorSubject<any>(undefined);
  private usuarioCliente: string;
  private tokenId = 'jwtToken';
  protected QRurl: string;
  protected hashUrl: string;
  public user = this.checkSubject.asObservable();
  public carritoVolver = this.checkCarritoVolver.asObservable();
  constructor(
    protected http: Http,
    @Inject(EnvironmentProvider) env: Environment,
  ) {
    this.QRurl = env.endpoints.api;
    this.hashUrl = env.endpoints.apiFront;
  }

  getQRurl() {
    return this.http.get(this.buildUrl('/usuarios/getQRUrl'),
    this.buildRequestOptions()).toPromise();
  }

  getHashForUpload() {
    return this.http.get(this.buildHashUrl('/clientes/documentacion/hash'),
    this.buildRequestOptions()).toPromise();
  }

  private buildUrl(path: string) {
    return this.QRurl + encodeURI(path);
  }

  private buildHashUrl(path: string) {
    return this.hashUrl + encodeURI(path);
  }

  private buildRequestOptions(options: RequestOptionsArgs = {}) {
    options.headers = options.headers || new Headers();
    options.headers.append('Content-Type', 'application/ld+json');
    const token = this.token;
    if (token) {
      options.headers.append('Authorization', 'Bearer ' + token);
    }
    return options;
  }

  get token() {
    return this.getCookie(this.tokenId);
  }

  getCookie(value:string) {
    const cookies = document.cookie.split(';');
    const myCookie = cookies.find(cookie => cookie.trim().startsWith(value +'='));
    
    if (myCookie) {
      const value = myCookie.split('=')[1];
      return value;
    } 
    return myCookie;
  }

  getFotoById(id: number): Observable<Blob> {
    return this.http.get(this.buildUrl('/frontend' + Usuario.path + 'foto/' + id),
    this.buildRequestOptions({ responseType: ResponseContentType.Blob }))
    .map((res: Response) => res.blob());
  }

  setUsuarioCliente(usuario: string){
    this.usuarioCliente = usuario;
  }

  getUsuarioCliente(){
    return this.usuarioCliente;
  }

  emitChange(tradingData: any) {
    this.checkSubject.next(tradingData);
  }

  emitChangeVolver(tradingData: any) {
    this.checkCarritoVolver.next(tradingData);
  }

}
