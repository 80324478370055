import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-link-invalido',
  templateUrl: './link-invalido.component.html',
  styleUrls: ['./link-invalido.component.scss']
})
export class LinkInvalidoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
