import { Component, OnInit, Testability, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductoEnviar } from '../../viejo/productoEnviar.model';
import { BackendService } from 'src/app/core/backend.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Pedido } from '../models/pedido.model';
import { ModalsService } from 'src/app/shared-services/modals.service';
import { NotificacionesComunicationService } from 'src/app/shared-services/notificaciones-comunication.service';
import { NotificacionesCarrito } from 'src/app/shared-services/notificacion-carrito.service';
import { CantidadesTotal } from 'src/app/shared-components/header/cantidades-total.model';
import { TipoEnvio } from 'src/app/shared-components/header/tipo-envio';
import { Parametric } from 'src/app/cliente/parametric.model';
import {FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import { ApiService } from 'src/app/core/api.service';
import { MercadoPagoPreferenceDto } from '../models/mercado-pago-preference-dto';
import { Perfil } from 'src/app/shared-components/perfil-usuario/models/perfil-usuario.model';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { ProductoService } from '../../service/producto.service';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { CostoEnvio } from '../models/costo-envio';
import { Imagen } from '../../viejo/imagen.model';
import { ClienteService } from 'src/app/core/cliente.service';
import { Direccion } from 'src/app/cliente/direccion.model';
import { DireccionCompleta } from 'src/app/cliente/direccionCompleta.model';
import { PedidoSimple } from '../models/pedido-simple.model';
import { ProductoSimple } from '../models/producto-simple.model';
import { Gtag } from 'angular-gtag';
import { Localidad } from '../models/localidades.model';
import { Partido } from '../models/partidos.model';
import { PaymovilResponse } from '../models/paymovil-response-dto';
import { PaymovilPago } from '../models/tarjeta-pago-dto';
import { Cliente } from 'src/app/cliente/cliente.model';
import { DescuentoPedido } from '../models/descuento-pedido.model';
import { Producto } from '../../models/producto.model';
import { WebpayUrlToken } from '../webpay-urlToken.model';
import { PaisEnum } from 'src/app/core/constants/pais-enum';


declare const swal: any;
enum CheckBoxType {CONSUMIDOR_FINAL = 1, EMPRESA = 4, NONE= null};

@Component({
  selector: 'app-resultado-producto-pago-adicionales',
  templateUrl: './resultado-producto-pago-adicionales.component.html',
  styleUrls: ['./resultado-producto-pago-adicionales.component.scss']
})
export class ResultadoProductoPagoAdicionalesComponent implements OnInit {

  public subioLogosCurvas: boolean = false;
  
  public pedido: Partial<Pedido> = {};
  public pedidoSimple: PedidoSimple;
  public pedidoCompletado: Pedido;
  public productos: ProductoSimple[];
  public subtotal = 0;
  public subtotalSinIva = 0;
  public direcciones: string[] = [];
  public provincias: Parametric[];
  public tiposDocumentos: Parametric[];
  public tipoDocumentoId: number;
  public documentoSize: number;
  public direccionesPerfil: Direccion[];
  public costoEnvioIva: number;
  public localidades: Localidad[];
  public partidos: Partido[];
  public cantidadDeLogos:number = 0;
  public direccionElegida: DireccionCompleta;
  webpayUrlToken: WebpayUrlToken;
  
  tarjetaPago: PaymovilPago = new PaymovilPago();

  //IVA


  public pagaEnvio: Boolean = false;
  public tiposEnvio: TipoEnvio[] = [];
  public perfil: Perfil;
  public tipoUsuario;
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,3}\s*$/;
  //^([0-9a-zA-Z]([-\.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/
  ///^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  public valorSituacionIvaId;

  check_box_type = CheckBoxType;
  currentlyChecked: CheckBoxType;

  linkProductoAgregado : string = '/new-pedidos/checkout-success/';

  estadoAdicionales:number = 14;
  estadoPagado:number=8;

  numberPattern = /^([0-9][0-9]*)$/;

  costoEnvio: number;

  precioTotal:number;

  form: FormGroup;
  formEmpresa: FormGroup;
  formCodigoDescuento: FormGroup;
  telefonoPattern = "^\\d+$";
  textoPattern= "[A-Za-z]+[ A-za-z]*";
  situacionesIva:Parametric[];

  hayTalles: Boolean = false;

  response:PaymovilResponse;

  codigoDescuentoEnviar: string;

  public descuentoAplicablePedido: DescuentoPedido = null;

  public codigoIngresado = true;
  public tipoUsuarioNotSelected = true;

  //IVA
  public iva:number = 1.21;

  public selectPais:string;

  constructor(
    private route: ActivatedRoute,
    private backendService: BackendService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: ModalsService,
    private apiService: ApiService,
    public notificacionesCarrito: NotificacionesCarrito,
    private notificacionesComunicationService: NotificacionesComunicationService,
    private seguridadService: SeguridadService,
    private productoService: ProductoService,
    private toast: ToastService,
    private clienteService: ClienteService,
    private gtag: Gtag
  ) { }

  async ngOnInit() {

    this.spinner.show();
    
    
    this.form = this.formBuilder.group({
      nombreEnvio: [undefined, [Validators.required,Validators.pattern(this.textoPattern)]],
      apellidoEnvio: [undefined, [Validators.required,Validators.pattern(this.textoPattern)]],
      email:  [undefined, [Validators.required,Validators.pattern(this.emailPattern)]],
      tipoDocumentoId: [undefined, [Validators.required]],
      documento: [undefined, [Validators.required,Validators.pattern(this.numberPattern),Validators.minLength(5)]],
      telefono: [undefined, [Validators.required,Validators.pattern(this.numberPattern),Validators.minLength(6)]],
      direccion: [undefined, [Validators.required]],
      altura:  [undefined, [Validators.required]],
      piso:[undefined, ],
      dpto:[undefined, ],
      cp: [undefined, [Validators.required,Validators.pattern(this.telefonoPattern),Validators.minLength(4)]],
      entreCalle1:[undefined, [Validators.required]],
      entreCalle2:[undefined, [Validators.required]],
      prov:[undefined, [Validators.required]],
      localidad: [undefined, [Validators.required]],
      obs: [undefined, ],
      partido: [undefined, ]
    });

    this.formEmpresa = this.formBuilder.group({
      cuit: [undefined, [Validators.required,Validators.pattern(this.numberPattern)]],
      //razonSocial: [undefined, [Validators.required,Validators.pattern(this.textoPattern)]],
      razonSocial: [undefined, [Validators.required]],

      email:  [undefined, [Validators.required,Validators.pattern(this.emailPattern)]],
      situacionIva: [undefined, [Validators.required]],
      telefono: [undefined, [Validators.required,Validators.pattern(this.numberPattern),Validators.minLength(6)]],
      direccion: [undefined, [Validators.required]],
      altura:  [undefined, [Validators.required]],
      piso:[undefined, ],
      dpto:[undefined, ],
      cp: [undefined, [Validators.required,Validators.pattern(this.telefonoPattern),Validators.minLength(4)]],
      entreCalle1:[undefined, [Validators.required]],
      entreCalle2:[undefined, [Validators.required]],
      prov:[undefined, [Validators.required]],
      localidad: [undefined, [Validators.required]],
      obs: [undefined, ],
      partido: [undefined, ]
    });

    this.formCodigoDescuento = this.formBuilder.group({
      
      codigoDescuento:[undefined, ],
      
      
    });

    //(!this.pedido.cp || this.costoEnvio== null)


    const user = this.seguridadService.user;

    
    /*this.route.data.subscribe(data => {
      this.pedido = data.productos as Pedido;
      this.pagaEnvio = false;

    });*/
    //this.pedido = await this.backendService.get(Pedido, Pedido.new_path + 'estado-activo').toPromise();

    this.route.data.subscribe(data => {
      this.pedidoCompletado = data.pedido as Pedido;
    });

    await this.backendService.get(PedidoSimple, Pedido.new_path + 'pedido-simple/'+ this.route.snapshot.paramMap.get("id")).toPromise().then(a =>{
      this.pedidoSimple = a as PedidoSimple;
    });
    this.pedido.id = this.pedidoSimple.id;
    this.productos = this.pedidoSimple.productos

    this.valorSituacionIvaId = await this.backendService.getNumber(Cliente.path + 'obtener-situacionIva/' + this.pedido.id);

    this.actualizarCantidadLogosConCurvas();

    this.productos.forEach(prod =>{
      
      if(prod.talle != null){
        this.hayTalles = true;
      }

    })

    this.perfil = await this.backendService.get(Perfil, Perfil.path).toPromise();

    this.direccionesPerfil = this.perfil.direcciones;
    this.tipoUsuario = this.perfil.tipoUsuario;

    await this.getDepartamentos(this.perfil.provincia);
    await this.getLocalidades(this.perfil.partido);

    if(this.tipoUsuario == 1){

      this.tiposDocumentos = await this.apiService.get(Parametric, Parametric.path + 'tipos_documentos/', null).toPromise();

      this.form.patchValue({
        nombreEnvio :  this.perfil.name,
        apellidoEnvio : this.perfil.lastname,
        telefono : this.pedidoCompletado.telefono!=null ? this.pedidoCompletado.telefono.toString() : '',
        email : this.pedidoCompletado.email,
        direccion : this.pedidoCompletado.direccion,
        altura : this.pedidoCompletado.altura,
        cp : this.pedidoCompletado.cp !=null ? this.pedidoCompletado.cp.toString() : '',
        piso : this.pedidoCompletado.piso,
        dpto : this.pedidoCompletado.dpto,
        localidad : this.pedidoCompletado.localidad,
        partido : this.pedidoCompletado.partido,
        prov : this.pedidoCompletado.provincia,
        entreCalle1 : this.pedidoCompletado.entreCalle1,
        entreCalle2 : this.pedidoCompletado.entreCalle2,
        documento : this.perfil.documento,
        tipoDocumentoId : this.perfil.tipoDocumentoId!=null ? this.perfil.tipoDocumentoId.toString() : '0',
        });

        this.seteoDatosPedido();

    }else if(this.tipoUsuario == 4){

      this.situacionesIva = await this.apiService.get(Parametric, '/newParametricas/' + 'situaciones-iva/', null).toPromise();

      this.formEmpresa.patchValue({
        cuit :  this.perfil.cuit,
        razonSocial : this.perfil.razonSocial,
        telefono : this.pedidoCompletado.telefono!=null ? this.pedidoCompletado.telefono.toString() : '',
        email : this.pedidoCompletado.email,
        direccion : this.pedidoCompletado.direccion,
        altura : this.pedidoCompletado.altura,
        cp : this.pedidoCompletado.cp !=null ? this.pedidoCompletado.cp.toString() : '',
        piso : this.pedidoCompletado.piso,
        dpto : this.pedidoCompletado.dpto,
        localidad : this.pedidoCompletado.localidad,
        partido : this.pedidoCompletado.partido,
        prov : this.pedidoCompletado.provincia,
        entreCalle1 : this.pedidoCompletado.entreCalle1,
        entreCalle2 : this.pedidoCompletado.entreCalle2,
        situacionIva : this.perfil.situacionIva,
        });

        this.seteoDatosPedidoEmpresa();

    }


      this.cambiarCostoEnvio(this.perfil.cp !=null ? this.perfil.cp : 0);

    /*this.productos.forEach(prod => {
      prod.imagenes.forEach(imagen => {
        this.getFiles(imagen.imagen);
      })
    })*/

    await this.getSubtotal()
    //await this.getMaximoGasto();
    await this.getTiposEnvio();
    await this.getProvincias();
    /*if(this.pedido.direcciones){
      this.pedido.direcciones.forEach(dir => {
        let direccion = '';
        direccion += dir.direccion + ' ' + dir.numero + (dir.piso ? ' ' + dir.piso : '') + (dir.departamento ? dir.departamento : '') + ', '
          + dir.ciudad + (dir.provincia !== null ? ', ' + dir.provincia.nombre : '') + ', ' + dir.pais.description;
        this.direcciones.push(direccion);
      });
    }*/

    if(this.pedidoCompletado.descuentoPedido){
      this.aplicarDescuento(this.pedidoCompletado.descuentoPedido)
    }
    


    this.spinner.hide();
  }

  nonZero(control: AbstractControl): { [key: string]: any; } | null {
    if (Number(control.value) <= 0) {
      return {nonZero: true};
    } else {
      return null;
    }
  }

  async getSubtotal() {
    for (let i = 0; i < this.productos.length; i++) {
      //this.subtotal = this.subtotal + this.productos[i].cantidad * ((this.productos[i].precioUnitario)*this.iva + (this.productos[i].precioAdicional * this.productos[i].cantidadLogos));
      //this.subtotalSinIva = this.subtotalSinIva + this.productos[i].cantidad * (this.productos[i].precioUnitario + (this.productos[i].precioAdicional * this.productos[i].cantidadLogos));
      this.subtotal = this.subtotal + (this.productos[i].cantidad * this.productos[i].precioConIva) + (this.productos[i].precioAdicional * this.productos[i].cantidadLogos);
    }
  }

  getCostoEnvio() {
    return this.pedido && this.pedido.tipoEnvio && this.pedido.tipoEnvio.split('$')[1].split(')')[0] + ' $';
  }

  async finalizarPedido() {

    const tipo = this.pedido && this.pedido.tipoEnvio && this.tiposEnvio &&
                 this.tiposEnvio.find(t => `${t.description} ($${t.costo})` === this.pedido.tipoEnvio);

    this.pedido.tipoEnvio = tipo && tipo.description || null;
    this.pedido.costoEnvio = this.costoEnvio;
    this.pedido.subtotal = this.subtotal + this.costoEnvioIva;
    if(this.pedido.subtotal <25000){
      this.toast.openErrorGenerico("Recordá que nuestra compra mínima es de $25.000. si querés comprar otros productos agregalos a tu carrito");
      return;
    }

    var t = ""+ this.pedido.documento;
    this.documentoSize = t.length


  if(t.length < 5 || t.length>20){
    return ;
  }
    if (this.pedido.direccion !== undefined && this.pedido.direccion !== '' && this.pedido.direccion !== null) {
            

      this.procesoPagoFinalizarPedido(tipo);
      

    } else {
      this.toast.openErrorGenerico('Por favor, introduzca la dirección donde se enviará el pedido.');
    }

  }

  procesoPagoFinalizarPedido(tipo){
    const res = this.modalService.elegirMetodoDePago(PaisEnum.ARGENTINA);
      res.subscribe(async (value) => {
        if(value){
          const metodoDePago = this.productoService.getMetodoDepago();
          if(metodoDePago == "transferencia"){
            this.pedido.transferenciaAprobada = false;
            this.spinner.show();

            try{
              await this.backendService.update(Pedido.new_path + 'cerrar-pedido-adicionales', this.pedido);

              const ress = await this.modalService.transferenciaBancaria(this.pedido, this.pedido.nombreEnvio + " " + this.pedido.apellidoEnvio, null)
            ress.subscribe(async (value) =>{

              if(value){
                this.purchase();
                this.actualizarCantidadLogosConCurvas();
                if(this.cantidadDeLogos > 0){
                  const confirmado = await this.modalService.OpenModalSubirLogoConCurvas(this.pedido.id, this.cantidadDeLogos)
                  await confirmado.subscribe(async (value) =>{
                  if(value){
                    this.subioLogosCurvas = value;
                    //this.modalService.openModalComprobanteSubido();
                    
                    //this.router.navigateByUrl('/mis-pedidos');
                  }
                  this.router.navigateByUrl('/mis-pedidos');
                })
                }else{
                  this.modalService.openModalComprobanteSubido();
                  //window.location.href='/mis-pedidos';
                  this.router.navigateByUrl('/mis-pedidos');
                }
                                
              }
            })
            }
            catch (ex) {
              if (ex[0]) {
                this.toast.openErrorGenerico(ex[0].details);
              } else if (ex.errors) {
                this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
              } else {
                this.toast.openErrorGenerico(ex.message);
              }
              
            }
            finally{
              window.scrollTo(0,0);
              this.spinner.hide();
            }

            
          } else if(metodoDePago == "mercadoPago"){
            try {
              this.pedido.tipoEnvio = tipo && tipo.id.toString();
              
              this.spinner.show();
              
              await this.backendService.update(Pedido.new_path + 'cerrar-pedido-adicionales', this.pedido).then((data: Pedido) => {
                this.pedido = data;
                this.pedido.subtotal = this.subtotal;
                if(this.descuentoAplicablePedido){
                  this.pedido.descuentoPedido = this.descuentoAplicablePedido;
                }
              });

              await this.backendService.update(Pedido.new_path +'cerrar-pedido/mercado-pago', this.pedido).then((data: MercadoPagoPreferenceDto) =>
              {
                this.purchase();
                //this.backendService.update(Pedido.new_path + 'informar-pedido-nuevo/' + this.pedido.id, null);
                window.location.href= data.initPoint;
              });
              /*this.notificacionesComunicationService.notificacionesChange();
              await this.router.navigateByUrl('/mis-pedidos/' + this.pedido.id);
                this.spinner.hide();
                this.finalizarPedidoModal();
                */
              } catch (ex) {
                if (ex[0]) {
                  this.toast.openErrorGenerico(ex[0].details);
                } else if (ex.errors) {
                  this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
                } else {
                  this.toast.openErrorGenerico(ex.message);
                }
              } finally {
                window.scrollTo(0,0);
                this.spinner.hide();
              }
          }

          //ESTO ES DE CHILE
          /*else if( metodoDePago == 'webpay'){

            window.scrollTo(0, 0);
            this.spinner.show();

            try{
              await this.backendService.update(Pedido.new_path + 'cerrar-pedido', this.pedido).then((data: Pedido) => {
                this.pedido = data;
              });
            }catch (ex) {
              if (ex[0]) {
                this.toast.openErrorGenerico(ex[0].details);
              } else if (ex.errors) {
                this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
              } else {
                this.toast.openErrorGenerico(ex.message);
              }
            }

            try{
                await this.backendService.get(WebpayUrlToken,'/webpay/create/' + this.pedido.id).toPromise().then(a =>{
                  this.webpayUrlToken = a as WebpayUrlToken;
                  window.location.href = this.webpayUrlToken.url+'?token_ws='+this.webpayUrlToken.token;
                });
            }catch(ex){
              console.log(ex.message);
            }   
            finally{
              window.scrollTo(0, 0);
              this.spinner.hide();
            }         
          }*/

          else if(metodoDePago == 'paymovil'){
            this.spinner.show();
            
            try{
              await this.backendService.update(Pedido.new_path + 'cerrar-pedido-adicionales', this.pedido).then((data: Pedido) => {
                this.pedido = data;
              });
            }
            catch (ex) {
              if (ex[0]) {
                this.toast.openErrorGenerico(ex[0].details);
              } else if (ex.errors) {
                this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
              } else {
                this.toast.openErrorGenerico(ex.message);
              }
            }
            finally{
              this.spinner.hide();
            }
            const ress = await this.modalService.tarjetaModal(this.round(this.subtotal + this.costoEnvioIva), this.pedido);

            ress.subscribe(async (value2) =>{
              if(value2){
                this.purchase();
                this.actualizarCantidadLogosConCurvas();
                if(this.cantidadDeLogos > 0){
                this.backendService.update("/new-pedidos/checkout-success/" + this.pedido.id + "/" + 12, null);
                const confirmado = await this.modalService.OpenModalSubirLogoConCurvas(this.pedido.id, this.cantidadDeLogos)
                await confirmado.subscribe(async (value) =>{

                  if(value){
                  this.subioLogosCurvas = value;
                //this.modalService.openModalComprobanteSubido();
                
                this.router.navigateByUrl('/mis-pedidos');
              }
              this.router.navigateByUrl('/mis-pedidos');
            })
            }else{
              this.backendService.update("/new-pedidos/checkout-success/" + this.pedido.id + "/" + 8, null);
              this.router.navigateByUrl('/mis-pedidos');
            }
          }
          });
          }

        }
      })
  }

  finalizarPedidoEmpresa(){

    const tipo = this.pedido && this.pedido.tipoEnvio && this.tiposEnvio &&
                 this.tiposEnvio.find(t => `${t.description} ($${t.costo})` === this.pedido.tipoEnvio);

    this.pedido.tipoEnvio = tipo && tipo.description || null;
    this.pedido.costoEnvio = this.costoEnvio;
    this.pedido.subtotal = this.subtotal + this.costoEnvioIva;
    if(this.pedido.subtotal <25000){
      this.toast.openErrorGenerico("Recordá que nuestra compra mínima es de $25.000. si querés comprar otros productos agregalos a tu carrito");
      return;
    }


  if(this.validateCuit(this.pedido.cuit)){
    if (this.pedido.direccion !== undefined && this.pedido.direccion !== '' && this.pedido.direccion !== null) {

      

      this.procesoPagoFinalizarPedidoEmpresa(tipo);

    } else {
      this.toast.openErrorGenerico('Por favor, introduzca la dirección donde se enviará el pedido.');
    }
  }else{

    this.toast.openErrorGenerico("EL NÚMERO DE CUIT ES INVALIDO")

  }

  }

  procesoPagoFinalizarPedidoEmpresa(tipo){

    const res = this.modalService.elegirMetodoDePago(PaisEnum.ARGENTINA);
      res.subscribe(async (value) => {
        if(value){
          const metodoDePago = this.productoService.getMetodoDepago();
          if(metodoDePago == "transferencia"){
            this.pedido.transferenciaAprobada = false;
            this.spinner.show();

            try{
              
            await this.backendService.update(Pedido.new_path + 'cerrar-pedido-adicionales', this.pedido).then(()=> this.spinner.hide());
            const ress = await this.modalService.transferenciaBancaria(this.pedido, null, this.pedido.cuit)
            ress.subscribe(async (value) =>{

              if(value){
                this.purchase();
                this.actualizarCantidadLogosConCurvas();
                if(this.cantidadDeLogos > 0){
                  const confirmado = await this.modalService.OpenModalSubirLogoConCurvas(this.pedido.id, this.cantidadDeLogos)
                  confirmado.subscribe(async (value) =>{
                  if(value){
                    this.subioLogosCurvas = value;
                    //this.modalService.openModalComprobanteSubido();
                    //window.location.href='/mis-pedidos';
                    this.router.navigateByUrl('/mis-pedidos');
                  }
                })
                }else{
                  this.modalService.openModalComprobanteSubido();
                    this.router.navigateByUrl('/mis-pedidos');
                }
                                
              }
            })
            } catch (ex) {
              if (ex[0]) {
                this.toast.openErrorGenerico(ex[0].details);
              } else if (ex.errors) {
                this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
              } else {
                this.toast.openErrorGenerico(ex.message);
              }
            } finally {
              window.scrollTo(0,0);
              this.spinner.hide();
            }            
            
            
          } else if(metodoDePago == "mercadoPago"){
            try {
              this.pedido.tipoEnvio = tipo && tipo.id.toString();
              window.scrollTo(0, 0);
              this.spinner.show();

              await this.backendService.update(Pedido.new_path + 'cerrar-pedido-adicionales', this.pedido).then((data: Pedido) => {
                this.pedido = data;
                this.pedido.subtotal = this.subtotal;
                if(this.descuentoAplicablePedido){
                  this.pedido.descuentoPedido = this.descuentoAplicablePedido;
                }
              });
              await this.backendService.update(Pedido.new_path +'cerrar-pedido/mercado-pago', this.pedido).then((data: MercadoPagoPreferenceDto) =>
              {
                this.purchase();
                //this.backendService.update(Pedido.new_path + 'informar-pedido-nuevo/' + this.pedido.id, null);
                window.location.href= data.initPoint;
              });
              /*this.notificacionesComunicationService.notificacionesChange();
              await this.router.navigateByUrl('/mis-pedidos/' + this.pedido.id);
                this.spinner.hide();
                this.finalizarPedidoModal();
                */
              } catch (ex) {
                if (ex[0]) {
                  this.toast.openErrorGenerico(ex[0].details);
                } else if (ex.errors) {
                  this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
                } else {
                  this.toast.openErrorGenerico(ex.message);
                }
              } finally {
                window.scrollTo(0,0);
                this.spinner.hide();
              }
          }

          else if( metodoDePago == 'webpay'){
            
            window.scrollTo(0, 0);
            this.spinner.show();

            try{
              await this.backendService.update(Pedido.new_path + 'cerrar-pedido', this.pedido).then((data: Pedido) => {
                this.pedido = data;
              });
            }catch (ex) {
              if (ex[0]) {
                this.toast.openErrorGenerico(ex[0].details);
              } else if (ex.errors) {
                this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
              } else {
                this.toast.openErrorGenerico(ex.message);
              }
            }

            try{
                await this.backendService.get(WebpayUrlToken,'/webpay/create/' + this.pedido.id).toPromise().then(a =>{
                  this.webpayUrlToken = a as WebpayUrlToken;
                  window.location.href = this.webpayUrlToken.url+'?token_ws='+this.webpayUrlToken.token;
                });
            }catch(ex){
              console.log(ex.message);
            }   
            finally{
              window.scrollTo(0, 0);
              this.spinner.hide();
            }         
          }

          else if(metodoDePago == 'paymovil'){
            this.spinner.show();
            try{
              await this.backendService.update(Pedido.new_path + 'cerrar-pedido-adicionales', this.pedido).then((data: Pedido) => {
                this.pedido = data;
              });
            }
            catch (ex) {
              if (ex[0]) {
                this.toast.openErrorGenerico(ex[0].details);
              } else if (ex.errors) {
                this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
              } else {
                this.toast.openErrorGenerico(ex.message);
              }
            }
            finally{
              window.scrollTo(0,0);
              this.spinner.hide();
            }
            const ress = await this.modalService.tarjetaModal(this.round(this.subtotal + this.costoEnvioIva), this.pedido);

            ress.subscribe(async (value2) =>{
            
              if(value2){
                this.purchase();
                this.actualizarCantidadLogosConCurvas();
                if(this.cantidadDeLogos > 0){
                this.backendService.update("/new-pedidos/checkout-success/" + this.pedido.id + "/" + 12, null);
                const confirmado = await this.modalService.OpenModalSubirLogoConCurvas(this.pedido.id, this.cantidadDeLogos)
                await confirmado.subscribe(async (value) =>{
                  if(value){
                  this.subioLogosCurvas = value;
                //this.modalService.openModalComprobanteSubido();
                
                this.router.navigateByUrl('/mis-pedidos');
              }
              this.router.navigateByUrl('/mis-pedidos');
            })
            }
            else{
              this.backendService.update("/new-pedidos/checkout-success/" + this.pedido.id + "/" + 8, null);
              this.router.navigateByUrl('/mis-pedidos');
            }
          }
          });
          }


        }
      })


  }

  async deleteProd(id: number) {
      swal({
      title: "Eliminar!",
      text: "¿Deseas eliminar el producto?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sí, eliminar!",
      cancelButtonText: "No, cancelar!",
      closeOnConfirm: false,
      closeOnCancel: true },
        (async function (isConfirm) {
          if (isConfirm) {

            await this.borrar(id);

            await this.backendService.getNumber(Pedido.new_path + 'estado-activo-simple/'+ this.selectPais).then(cantidad =>{ 
              this.hayProductos = cantidad as number;
            });
            this.hayProductos = this.notificacionesCarrito.getCantidadProductosCarrito();
            this.notificacionesCarrito.emitChange(this.hayProductos-1);
            this.actualizarCantidadLogosConCurvas();
          } else {
          swal("Cancelado", "No se há eliminado el producto", "error");
          }
    }).bind(this));

  }

  public errorHandling = (control: string, error: string) => {
    if(this.tipoUsuario ==1){
      return this.form.controls[control].hasError(error);
    }else if(this.tipoUsuario == 4){
      return this.formEmpresa.controls[control].hasError(error);
    }

  }

  async onChange(event: any) {
    if (event) {
      this.spinner.show();
      this.notificacionesComunicationService.notificacionesChange();
      //this.pedido = await this.backendService.get(Pedido, Pedido.new_path + 'estado-activo').toPromise();
      this.productos = this.pedidoSimple.productos;
      if (this.productos.length !== 0) {
        this.subtotal = 0;
        //await this.getSubtotal();
        //await this.getMaximoGasto();
      }
      this.spinner.hide();
    }
  }

  /*async getMaximoGasto() {
    await this.backendService.get(CantidadesTotal, Pedido.path + 'maximo-gasto').toPromise().then(
      data => this.cantTotalGasto = data
    );
  }*/

  async getTiposEnvio() {
    await this.backendService.get(TipoEnvio, TipoEnvio.path + 'all', null).toPromise().then(
      data => {
        this.tiposEnvio = data;
      });
  }

  editar(id: number) {
    //this.router.navigate(['/editar-producto', id, ]);
  }

  getDescriptions(colores) {
    if(colores)
      return colores.map(c => c.description).join(', ');

    return "No hay colores."
  }

  async borrar(id) {
    try {
      let borrado;
      this.spinner.show();
      await this.backendService.delete(ProductoEnviar.path + id).then((res: Response) => {
        borrado = res.json();
      });
      if (borrado) {

        this.router.navigateByUrl('/');
      } else {
        this.pedidoSimple = await this.backendService.get(PedidoSimple, Pedido.new_path + 'estado-activo').toPromise();
        this.productos = this.pedidoSimple.productos;
        this.subtotal = 0;
        this.subtotalSinIva = 0;
        await this.getSubtotal();
        //await this.getMaximoGasto();
      }
      this.notificacionesComunicationService.notificacionesChange();
      swal("Eliminado!", "El producto ha sido eliminado.", "success");
    } catch (error) {
      swal("Error", "Error al borrar el producto", "error");
    } finally {

      this.spinner.hide();
    }
  }

  finalizarPedidoModal(form: string) {
    swal({
      title: "Gracias por hacer tu pedido.",
      text: "Nos estaremos poniendo en contacto para coordinar el envío.",
      type: "success",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Ir al inicio",
      cancelButtonText: "VER PEDIDO",
      closeOnConfirm: true,
      closeOnCancel: true },
      (() => this.router.navigateByUrl('/')).bind(this)
    );

  }
  async getProvincias() {
    this.provincias = await this.apiService.get(Parametric, Parametric.path + 'provincias/'+PaisEnum.ARGENTINA, null).toPromise();
  }

  async getDepartamentos(provName){
    this.spinner.show();
    this.backendService.get(Partido, CostoEnvio.path + "departamentos/" + provName, null).toPromise().then(part =>{
      this.partidos = part as Partido[];
      this.spinner.hide();
    });
    this.partidos = null;
    this.localidades = null;
    this.form.patchValue({localidad: null})
    this.form.patchValue({partido: null})
    this.formEmpresa.patchValue({localidad: null})
    this.formEmpresa.patchValue({partido: null})
    //this.spinner.hide();

  }

  async getLocalidades(deptoId){
    this.spinner.show();
    this.backendService.get(Localidad, CostoEnvio.path + "localidades/" + deptoId, null).toPromise().then(a =>{
      this.localidades = a as Localidad[];
      this.spinner.hide();
    });
    this.form.patchValue({localidad: null})
    this.formEmpresa.patchValue({localidad: null})

    //this.spinner.hide();
    
  }

  async openModal(productoId) {
    this.modalService.previsualizarModal(productoId);
  }

  cambiarCostoEnvio(codPostal){
    if(codPostal != 0){
    this.backendService.get(CostoEnvio,CostoEnvio.path+codPostal).toPromise()
      .then((costo: CostoEnvio) =>{
        this.costoEnvio = costo.costoEnvio;

        this.costoEnvioIva = this.getPrecioConIva(this.costoEnvio);

        this.alertaCambioCostoEnvio(this.costoEnvioIva);
      })
      .catch((e) =>{
        this.costoEnvio= null
        this.toast.openErrorGenerico("CÓDIGO POSTAL INVALIDO");
      });
    }

  }
  alertaCambioCostoEnvio(costoEnvio: number){

    if(this.costoEnvio !=null){
      this.toast.openSuccessGenerico("AHORA EL COSTO DE ENVIO ES: $"+ costoEnvio);
      /*
      if(this.valorSituacionIvaId == 14){
        this.toast.openSuccessGenerico("AHORA EL COSTO DE ENVIO ES: $"+ costoEnvio/1.21)
      }else{
        this.toast.openSuccessGenerico("AHORA EL COSTO DE ENVIO ES: $"+ costoEnvio)
      }*/
    }else{
      this.toast.openErrorGenerico("CÓDIGO POSTAL INVALIDO");
    }
  }
  abrirModal(src, productoModal){
    this.modalService.abrirImagenResultadoProducto(src, productoModal);
  }
  seteoDatosPedido(){

    this.pedido.nombreEnvio = this.form.controls.nombreEnvio.value;
        this.pedido.apellidoEnvio = this.form.controls.apellidoEnvio.value;
        this.pedido.telefono = this.form.controls.telefono.value;
        this.pedido.email = this.form.controls.email.value;
        this.pedido.direccion = this.form.controls.direccion.value;
        this.pedido.altura = this.form.controls.altura.value;
        this.pedido.cp = this.form.controls.cp.value;

        this.pedido.piso = this.form.controls.piso.value;
        this.pedido.documento = this.form.controls.documento.value;
        this.pedido.dpto = this.form.controls.dpto.value;
        this.pedido.localidad = this.form.controls.localidad.value;
        this.pedido.partido = this.form.controls.partido.value;
        this.pedido.provincia = this.form.controls.prov.value;
        this.pedido.entreCalle1 = this.form.controls.entreCalle1.value;
        this.pedido.entreCalle2 = this.form.controls.entreCalle2.value;
        //console.log(this.form.controls.tipoDocumento.value);
        this.pedido.tipoDocumentoId = this.form.controls.tipoDocumentoId.value;
  }

  seteoDatosPedidoEmpresa(){
    this.pedido.cuit = this.formEmpresa.controls.cuit.value;
    this.pedido.razonSocial = this.formEmpresa.controls.razonSocial.value;

    this.pedido.telefono = this.formEmpresa.controls.telefono.value;
    this.pedido.email = this.formEmpresa.controls.email.value;
    this.pedido.direccion = this.formEmpresa.controls.direccion.value;
    this.pedido.altura = this.formEmpresa.controls.altura.value;
    this.pedido.cp = this.formEmpresa.controls.cp.value;

    this.pedido.piso = this.formEmpresa.controls.piso.value;
    this.pedido.dpto = this.formEmpresa.controls.dpto.value;

    this.pedido.localidad = this.formEmpresa.controls.localidad.value;
    this.pedido.partido = this.formEmpresa.controls.partido.value;
    this.pedido.provincia = this.formEmpresa.controls.prov.value;
    this.pedido.entreCalle1 = this.formEmpresa.controls.entreCalle1.value;
    this.pedido.entreCalle2 = this.formEmpresa.controls.entreCalle2.value;

    this.pedido.situacionIva = this.formEmpresa.controls.situacionIva.value;
    
  }

  async validarPrecios(){
    if(this.codigoIngresado){
      try{

        await this.backendService.update(Producto.new_path + 'validar-precios', this.productos);
        this.toast.openSuccessGenerico("Validación concretada");
      }catch (ex) {
                if (ex[0]) {
                  
                } else if (ex.errors) {
                  
                } else {
                  
                }
                window.scrollTo(0,0);
      }
    }
    
  }

  validateForm(){
    const invalid = [];
    const controls = this.form.controls;
    for(const name in controls){
      if(controls[name].invalid){
        invalid.push(name);
      }
    }
    if(invalid.length != 0){
      var errMsg = "Ingrese correctamente el campo: ";
      for(var a = 0; invalid.length > a; a++){
        errMsg += invalid[a] + ", ";
      }
      this.formatAndShowErrMsg(errMsg);
      return;
    }
    else if(this.form.controls.documento.value.toString().length < 8){
      var errMsg = "Ingrese correctamente el campo: Documento";
      this.toast.openErrorGenerico(errMsg);
      return;
    }
    if(this.form.valid && this.costoEnvio != null){
      this.seteoDatosPedido();
      var t = ""+ this.pedido.documento;

      this.validarPrecios();

      /*if(this.cantidadDeLogos > 0 && !this.subioLogosCurvas){
        this.toast.openErrorGenerico("Por favor suba los logos con curvas antes de finalizar el pedido")
      }*/
      //if(this.cantidadDeLogos == 0 || this.subioLogosCurvas){
        this.finalizarPedido();
      //} 
    }
  }
  validateFormEmpresa(){
    const invalid = [];
    const controls = this.formEmpresa.controls;
    for(const name in controls){
      if(controls[name].invalid){
        invalid.push(name);
      }
    }
    if(invalid.length != 0){
      var errMsg = "Ingrese correctamente el campo: ";
      for(var a = 0; invalid.length > a; a++){
        errMsg += invalid[a] + ", ";
      }
      this.formatAndShowErrMsg(errMsg);
      return;
    }
    if(this.formEmpresa.valid && this.costoEnvio != null){
      this.seteoDatosPedidoEmpresa();
      this.validarPrecios();
      /*if(this.cantidadDeLogos > 0 && !this.subioLogosCurvas){
        this.toast.openErrorGenerico("Por favor suba los logos con curvas antes de finalizar el pedido")
      }
      if(this.cantidadDeLogos == 0 || this.subioLogosCurvas){*/
        this.finalizarPedidoEmpresa();
      //}
    }
  }

  async getFiles(d: Imagen) {
    await this.clienteService.getFileById(d.id).subscribe(data => {
      this.createImageFromBlob(data, d);
    }, error => {
      console.log(error);
    });
  }

  async createImageFromBlob(image: Blob, d: Imagen) {
    const reader = new FileReader();
    await reader.addEventListener('load', () => {
      d.url = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  validateCuit(cuit:string){

    if (cuit.length != 11) {
      return false;
  }

  const cuitArray = cuit.split("");
  const serie = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  let aux = 0;
  for (var i = 0; i < 10; i++) {
      aux += Number.parseInt(cuitArray[i]) * serie[i];
  }
  aux = 11 - (aux % 11);

  if (aux == 11) {
      aux = 0;
  }
  return Number.parseInt(cuitArray[10]) === aux;

  }

  async abrirModalSeleccionarDireccion(){
    const confirm = await this.modalService.openModalSeleccionarDireccion();
    confirm.subscribe( async (result) =>{
      if(result){
        if(result.direccion){

          this.direccionElegida = result;

          if(this.tipoUsuario == 1){
            this.form.patchValue({

              direccion : this.direccionElegida.direccion,
              altura : this.direccionElegida.numero,
              cp : this.direccionElegida.cp !=null ? this.direccionElegida.cp.toString() : '',
              piso : this.direccionElegida.piso,
              dpto : this.direccionElegida.dpto,
              localidad : this.direccionElegida.localidad,
              partido : this.direccionElegida.partido,
              prov : this.direccionElegida.provincia,
              entreCalle1 : this.direccionElegida.entreCalle1,
              entreCalle2 : this.direccionElegida.entreCalle2,
              });
          }else if(this.tipoUsuario == 4){
            this.formEmpresa.patchValue({

              direccion : this.direccionElegida.direccion,
              altura : this.direccionElegida.numero,
              cp : this.direccionElegida.cp !=null ? this.direccionElegida.cp.toString() : '',
              piso : this.direccionElegida.piso,
              dpto : this.direccionElegida.dpto,
              localidad : this.direccionElegida.localidad,
              partido : this.direccionElegida.partido,
              prov : this.direccionElegida.provincia,
              entreCalle1 : this.direccionElegida.entreCalle1,
              entreCalle2 : this.direccionElegida.entreCalle2,
              });
          }

          this.cambiarCostoEnvio(this.direccionElegida.cp)

        }
      }
    });
  }
  validateDocumento(numero:number){


    return  (numero > 4 || numero < 20)

    //return true
  }

  getPrecioConIva(precio:number){
    
    var numeroIva = this.round(precio * this.iva)
    
    return numeroIva
  }

  round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
  }

  removeFromCart(producto){
    this.gtag.event('remove_from_cart', {
      items: [{
        item_name: producto.nombre,
        item_id: producto.id,
        price: producto.precioUnitario,
        quantity: producto.cantidad
      }]
    });
  }

  purchase(){
    let itemsPurchase: any[] = [];
    
    this.productos.forEach((producto)=>{
      itemsPurchase.push({
        item_name: producto.nombre,
        item_id: producto.id,
        price: producto.precioUnitario,
        quantity: producto.cantidad
      });
    });

    this.gtag.event('purchase', {
      transaction_id: this.pedido.id,
      value: this.subtotal + this.costoEnvio,
      shipping: this.costoEnvio,
      currency: "ARS",
      items: itemsPurchase
    });
  }

 async subirLogosConCurvas(pedidoId:number){

  this.cantidadDeLogos = 0;

    this.productos.forEach(prod =>{
      this.cantidadDeLogos = prod.cantidadLogos + this.cantidadDeLogos
    })

    
    const confirmado = await this.modalService.OpenModalSubirLogoConCurvas(this.pedido, this.cantidadDeLogos);
    
    confirmado.subscribe(async (value) =>{
      if(value){
        this.subioLogosCurvas = value;
      }
    })

  }

  actualizarCantidadLogosConCurvas(){
    this.cantidadDeLogos =0;
    this.productos.forEach(prod =>{
      this.cantidadDeLogos = prod.cantidadLogos + this.cantidadDeLogos;
    })
  }

  async obtenerPdfPresupuesto(){
    
     if(this.descuentoAplicablePedido){
      this.pedido.descuentoPedido = this.descuentoAplicablePedido;
      this.spinner.show();
      await this.backendService.getPresupuesto(Pedido.new_path +'presupuestoConDescuento/'+ this.pedido.id, this.pedido).toPromise().then(a =>{
      this.createBlob(a);
      this.spinner.hide();
      
      })
    }else{
      this.spinner.show();
      //this.clienteService.getPresupuesto(this.pedido.id).toPromise().then(a =>{
      await this.backendService.getPresupuesto(Pedido.new_path +'presupuestoConDescuento/'+ this.pedido.id, this.pedido).toPromise().then(a =>{
      this.createBlob(a);
      this.spinner.hide();
     })
    }

     await this.backendService.update(Pedido.new_path + 'presupuesto-descargado/' + this.pedido.id, Boolean);
    
    /*this.backendService.update(Pedido.new_path + 'presupuesto', this.pedido)
    this.backendService.update(Pedido.new_path + 'presupuesto', this.pedido).then(
      (pdfComprobante: Blob) => {
        console.log(pdfComprobante)
        this.createBlob(pdfComprobante);
      }, error => {
        console.log(error);
      });*/
  }

  createBlob(pdf: any) {
    const blob = new Blob([pdf], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  aplicarDescuento(descuentoPedido: DescuentoPedido){
    this.spinner.show();
    try{

      this.descuentoAplicablePedido = descuentoPedido;

      var descuento = ((descuentoPedido.descuento*-1 /100) +1);
  
      descuentoPedido.productosAplicablesId.forEach(e => {
          
          this.productos.forEach(prod =>{
            
             if(e == prod.id){
               prod.precioConIva = this.round(prod.precioConIva * descuento);
               prod.precioUnitario = this.round(prod.precioUnitario * descuento);
             }
          })
        });
  
      this.subtotal = 0;
      this.subtotalSinIva= 0;
  
      this.getSubtotal();

      this.pedido.descuentoPedido = this.descuentoAplicablePedido;

      this.toast.openSuccessGenerico("Código de Descuento Aplicado con Exito " + this.descuentoAplicablePedido.descuento + "%");
      this.codigoIngresado = false;

    }catch(ex){

      this.toast.openErrorGenerico("El Código Ingresado es Inválido");

    }finally{

      this.spinner.hide();

    }
  }

  async verificarCodigo(){
    this.codigoDescuentoEnviar = this.formCodigoDescuento.controls.codigoDescuento.value;
    this.spinner.show();
    try{

      await this.backendService.get(DescuentoPedido, "/new-pedidos/codigo-valido/" + this.pedidoSimple.id + "/"+this.codigoDescuentoEnviar).toPromise().then(part =>{


        this.descuentoAplicablePedido = part;

        var descuento = ((part.descuento*-1 /100) +1);
  
        part.productosAplicablesId.forEach(e => {
          
          this.productos.forEach(prod =>{
            
             if(e == prod.id){
               prod.precioConIva = this.round(prod.precioConIva * descuento);
               prod.precioUnitario = this.round(prod.precioUnitario * descuento);
             }
          })
        });
  
        this.subtotal = 0;
        this.subtotalSinIva= 0;
  
        this.getSubtotal();
  
      })
      this.pedido.descuentoPedido = this.descuentoAplicablePedido;

      this.toast.openSuccessGenerico("Código de Descuento Aplicado con Exito " + this.descuentoAplicablePedido.descuento + "%");
      this.codigoIngresado = false;
    }catch(ex){

      this.toast.openErrorGenerico("El Código Ingresado es Inválido");

    }finally{

      this.spinner.hide();

    }
  }

  formatAndShowErrMsg(errMsg: string){

    errMsg = errMsg.replace("nombreEnvio","Nombre");
    errMsg = errMsg.replace("apellidoEnvio","Apellido");
    errMsg = errMsg.replace("telefono","Teléfono");
    errMsg = errMsg.replace("email","Email");
    errMsg = errMsg.replace("direccion","Dirección");
    errMsg = errMsg.replace("altura","Altura");
    errMsg = errMsg.replace("cp","Código postal");
    errMsg = errMsg.replace("entreCalle1","Entre Calle 1");
    errMsg = errMsg.replace("entreCalle2","Entre Calle 2");
    errMsg = errMsg.replace("prov","Provincia");
    errMsg = errMsg.replace("localidad","Localidad");
    errMsg = errMsg.replace("tipoDocumentoId","Tipo de Documento");
    errMsg = errMsg.replace("documento","Documento");
    errMsg = errMsg.replace("cuit","Cuit");
    errMsg = errMsg.replace("situacionIva","Situación Iva");
    errMsg = errMsg.replace("razonSocial","Razón Social");
    errMsg = errMsg.slice(0, errMsg.length -2);

    this.toast.openErrorGenerico(errMsg);

  }

  selectCheckBox(targetType: CheckBoxType){
    if(this.currentlyChecked === targetType){
      this.currentlyChecked = CheckBoxType.NONE;
      return;
    }
    this.currentlyChecked = targetType;
  }

  async changeTipoUsuario(){
    if(this.currentlyChecked != CheckBoxType.NONE){
      try{
        this.spinner.show();
        const user = this.seguridadService.user;
        await this.backendService.update(Cliente.path + "change-tipo-usuario/" + user.id + "/" + this.currentlyChecked, null);
        this.perfil = await this.backendService.get(Perfil, Perfil.path).toPromise();
        this.tipoUsuario = this.perfil.tipoUsuario;
        if(this.tipoUsuario == 1){

      this.tiposDocumentos = await this.apiService.get(Parametric, Parametric.path + 'tipos_documentos/', null).toPromise();

      this.form.patchValue({
        nombreEnvio :  this.perfil.name,
        apellidoEnvio : this.perfil.lastname,
        telefono : this.pedidoCompletado.telefono!=null ? this.pedidoCompletado.telefono.toString() : '',
        email : this.pedidoCompletado.email,
        direccion : this.pedidoCompletado.direccion,
        altura : this.pedidoCompletado.altura,
        cp : this.pedidoCompletado.cp !=null ? this.pedidoCompletado.cp.toString() : '',
        piso : this.pedidoCompletado.piso,
        dpto : this.pedidoCompletado.dpto,
        localidad : this.pedidoCompletado.localidad,
        partido : this.pedidoCompletado.partido,
        prov : this.pedidoCompletado.provincia,
        entreCalle1 : this.pedidoCompletado.entreCalle1,
        entreCalle2 : this.pedidoCompletado.entreCalle2,
        documento : this.perfil.documento,
        tipoDocumentoId : this.perfil.tipoDocumentoId!=null ? this.perfil.tipoDocumentoId.toString() : '0',
        });

        this.seteoDatosPedido();

    }else if(this.tipoUsuario == 4){

      this.situacionesIva = await this.apiService.get(Parametric, '/newParametricas/' + 'situaciones-iva/', null).toPromise();


      this.formEmpresa.patchValue({
        cuit :  this.perfil.cuit,
        razonSocial : this.perfil.razonSocial,
        telefono : this.pedidoCompletado.telefono!=null ? this.pedidoCompletado.telefono.toString() : '',
        email : this.pedidoCompletado.email,
        direccion : this.pedidoCompletado.direccion,
        altura : this.pedidoCompletado.altura,
        cp : this.pedidoCompletado.cp !=null ? this.pedidoCompletado.cp.toString() : '',
        piso : this.pedidoCompletado.piso,
        dpto : this.pedidoCompletado.dpto,
        localidad : this.pedidoCompletado.localidad,
        partido : this.pedidoCompletado.partido,
        prov : this.pedidoCompletado.provincia,
        entreCalle1 : this.pedidoCompletado.entreCalle1,
        entreCalle2 : this.pedidoCompletado.entreCalle2,
        situacionIva : this.perfil.situacionIva,
        });

        this.seteoDatosPedidoEmpresa();

    }
      }catch(ex){
        if (ex[0]) {
          this.toast.openErrorGenerico(ex[0].details);
        } else if (ex.errors) {
          this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
        } else {
          this.toast.openErrorGenerico(ex.message);
        }
      }
      finally{
        this.spinner.hide();
        this.tipoUsuarioNotSelected = false;
      }
    }else{
      this.tipoUsuarioNotSelected = true;
    }
  }

  metodosDePagoAdicionales(){
    console.log(this.pedido)
  }

}


