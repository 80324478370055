import { Component, OnInit } from '@angular/core';
import { BsModalRef } from '../../../../../node_modules/ngx-bootstrap';
import { Subject } from '../../../../../node_modules/rxjs';

@Component({
  selector: 'app-envio-mail',
  templateUrl: './envio-mail.component.html',
  styleUrls: ['./envio-mail.component.scss']
})
export class EnvioMailComponent implements OnInit {

  titulo: string;
  mensaje: string;
  no: string;
  yes: string;

  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  noConfirma() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  confirma() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
