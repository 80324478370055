import { NumberSymbol } from "@angular/common";
import { Parametric } from "./parametric.model";

export class Direccion {

  id :number;
  direccion: string;
  numero: string;
  piso: string;
  departamento: string;
  ciudad: string;
  provinciaId: number;
  paisId: number;
  codigoPostal: string;
  pais: Parametric;
  provincia: {id: null, nombre: '', codigoAfip: null};

  constructor() {

  }
}
