import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BackendService } from 'src/app/core/backend.service';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { NotificacionService } from '../services/notificacion.service';
import { Notifications } from './notifications.model';
import { NotificationsWho } from './notificationsWho.model';

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrls: ['./all-notifications.component.scss']
})
export class AllNotificationsComponent implements OnInit {

  notificaciones: Notifications[] = [];
  notificacionesWho: NotificationsWho[] = [];
  respuesta: Partial<NotificationsWho> = {};
  textPattern = /.*\S.*/;

  constructor(private backendService: BackendService,
    private seguridadService: SeguridadService,
    private toastService: ToastService,
    private spinnerService: NgxSpinnerService,
    private notificacionService: NotificacionService) { }

  async ngOnInit() {
    await this.getNotificaciones();
    await this.setNotificacionesNoLeida();
  }

  async getNotificaciones(){
    await this.backendService.get(Notifications, '/notificaciones/' + this.seguridadService.user.id, null)
    .toPromise().then((data)=>{
      this.notificaciones = data;
    });
  }

  async responder(notification: Notifications){
    let textoRespuesta = this.getTextoRespuesta(notification.id);
    if(this.validarRespuesta(textoRespuesta.value)){
      this.setRespuesta(notification.id, textoRespuesta.value);
      await this.enviarRespuesta();
      await this.getNotificaciones();
      this.limpiarTextoRespuesta(notification.id);
    } else {
      this.toastService.openErrorGenerico("La respuesta no puede estar vacía")
    }
    
  }

  getTextoRespuesta(id){
    return <HTMLInputElement> document.getElementById(id.toString());
  }
  
  validarRespuesta(textoRespuesta){
    if(!this.textPattern.test(textoRespuesta)){
      return false;
    }
    return true;
  }

  setRespuesta(id, texto){
    this.respuesta.respuesta = texto;
    this.respuesta.notificacionId = id;
    this.respuesta.leido = true;
  }

  async enviarRespuesta(){
    this.spinnerService.show();
    await this.backendService.create("/notificaciones/respuesta/", this.respuesta).then((data)=>
    {
      if(data){
        this.toastService.openSuccessGenerico("Se envió la respuesta correctamente")
      } else{
        this.toastService.openErrorGenerico("Hubo un error en el envio")
      }
      this.spinnerService.hide();
    }); 
  }

  async selectNotificacion(id){
    this.saveNotificacionLeida(id);
    this.setNotificacionLeida(id);
  }
  
  limpiarTextoRespuesta(id){
    (<HTMLInputElement> document.getElementById(id.toString())).value = "";
  }

  async saveNotificacionLeida(id){
    let cantidadNotificaciones: number;
    await this.backendService.update("/notificaciones/leida/" + id, Boolean);
    this.backendService.getNumber('/notificaciones/estado-notificaciones').then(cantidad =>{ 
      cantidadNotificaciones = cantidad as number;
      this.notificacionService.emitChange(cantidadNotificaciones);
    });
  }

  setNotificacionLeida(id){
    this.notificaciones.forEach(notificacion => {
      if(notificacion.id === id){
        notificacion.leida = true;
      }
    });
  }

  setNotificacionesNoLeida(){
    this.notificaciones.forEach((notificacion)=>{
      if(notificacion.notificacionesWho){
        notificacion.notificacionesWho.forEach((notificacionWho)=>{
          if(notificacionWho.leido == false){
            notificacion.leida = false;
          }
        });
      }
    })
  }
}