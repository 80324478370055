import { ProductoEnviar } from "../../viejo/productoEnviar.model";
import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { BackendService } from "../../../core/backend.service";
import { Pedido } from "../models/pedido.model";
import { ToastService } from "src/app/shared-services/toast/toast.service";

@Injectable()
export class CarritoResolver {

    constructor(
        protected router: Router,
        protected backendService: BackendService,
        private toast: ToastService,
      ) {}

      async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        try {
          return await  this.backendService.get(Pedido, Pedido.new_path + 'estado-activo').toPromise();
        } catch (ex) {
            this.toast.openErrorGenerico(ex.message);
            if (this.router.url === '/') {
              this.router.navigateByUrl('/lista-productos');
            } else {
              this.router.navigateByUrl('/');
            }
        }
      }

}
