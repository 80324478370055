import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import * as moment from 'moment';

@Directive({
  selector: '[appValidateNoMenorEdad][ngModel],[appValidateNoMenorEdad][formControl]',
  providers: [{
    provide: NG_VALIDATORS, multi: true,
    useExisting: forwardRef(() => MenorEdadValidator),
  }],
})
export class MenorEdadValidator implements Validator {

    validate(control: AbstractControl) {

        if (!control.value) {
            return null;
        }

        const fechaNacimiento = moment(control.value, 'DD/MM/YYYY');

        if (moment().isBefore(fechaNacimiento)) {
            return null;
        }

        const anios = (moment.duration(moment().diff(fechaNacimiento)));

        return anios.as('year') > 18 ? null : {menorEdad: control.value};
    }
}
