import { BackendModel, BackendService } from "src/app/core/backend.service";
import { NotificationsWho } from "./notificationsWho.model";

export class Notifications extends BackendModel{
    
    texto:any;
    fechaEnvio:Date;
    asunto:string;
    leida:boolean;
    fechaDesde: Date;
    notificacionesWho: Partial<NotificationsWho[]>;
    
    constructor(protected backendService: BackendService, model?: Notification) {
        super(backendService, model);
      }
  }