import { BackendModel } from "../core/backend.service";
import { Parametric } from "./parametric.model";

export class DireccionCompleta extends BackendModel{

    id :number;
    direccion: string;
    numero: string;
    piso: string;
    dpto: string;
    cp: string;
    provincia: string;
    entreCalle1:string;
    entreCalle2:string;
    partido:string;
    localidad:string;
        
  }