import { Producto } from "../models/producto.model";
import { Cliente } from "../../cliente/cliente.model";
import { Color } from "../color.model";
import { Logo } from "../models/logo.model";
import { Parametric } from "../../cliente/parametric.model";
import { BackendModel, BackendService } from "../../core/backend.service";
import { ImagenLogo } from "./imagen-logo.model";
import { TarifarioAdicional } from "./tarifario-adicional.model";
import { PrecioCantidad } from "./precio-cantidad.model";
import {TipoModena} from '../../core/tipo-moneda.model';

export class ProductoEnviar extends BackendModel {

    static path = '/producto-pedido/';

    id: number;
    producto: Producto;
    color: Color;
    colores: Color[];
    cantidad: number;
    tipoImpresion: Parametric;
    talle: string;
    imagenes: ImagenLogo[];
    logo: Logo;
    precioUnitario: number;
    precioAdicional: number;
    precioConIva:number;
    tipoMoneda: TipoModena;
    adicionales: TarifarioAdicional[];
    preciosXCantidad: PrecioCantidad[];
    cantidadLogos: number;
    cotizacionMoneda: number;
    nombre:string;

    constructor(protected backendService: BackendService, model?: ProductoEnviar) {
        super(backendService, model);
        this.imagenes = [];
    }
}
