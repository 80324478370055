import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollerService {

  constructor() { }

  scrollToTop() {
    //Scroller top for safari 17+
    setTimeout(function() {
      window.scrollTo(0, 0);
    }, 0);
  }
}
