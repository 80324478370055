import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormValidatorServiceService } from './form-validator-service.service';

import { VendorModule } from '../core/vendor.module';
import { NavigationService } from './navigation.service';
import { UpdateEventService } from './update-event.service';
import { ScrollComunicationService } from './scroll-comunication.service';
import { ScrollDirective } from './scroll.directive';
import { EmailPatternValidator } from './email-pattern.validator';
import { TextTrimWhiteSpaceValidator } from './text-trim-white-space.validator';
import { PasswordStrengthValidator } from './password-strength.validator';
import { StrengthPasswordService } from './strength-password.service';
import { DateValidator } from './date.validator';
import { MenorEdadValidator } from './menor-edad.validator';
import { DateNoBeforeNowValidator } from './date-no-before-now.validator';
import { AddCarritoToastComponent } from './toast/add-carrito-toast/add-carrito-toast.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CantidadMinimaToastComponent } from './toast/cantidad-minima-toast/cantidad-minima-toast.component';
import { StockInsuficienteToastComponent } from './toast/stock-insuficiente-toast/stock-insuficiente-toast.component';
import { PrecioEnvioComponent } from './toast/precio-envio-toast/precio-envio.component';
import { CpInvalidoToastComponent } from './toast/cp-invalido-toast/cp-invalido-toast.component';
import { ImagenSubidaToastComponent } from './toast/imagen-subida-toast/imagen-subida-toast.component';
import { TipoDeImpresionToastComponent } from './toast/tipo-de-impresion-toast/tipo-de-impresion-toast.component';
import { ErrorGenericoComponent } from './toast/error-generico/error-generico.component';
import { SuccessGenericoToastComponent } from './toast/success-generico-toast/success-generico-toast.component';
import { UsernameInvalidComponent } from './toast/username-invalid/username-invalid.component';

const imports = [
  CommonModule,
  VendorModule,
];

@NgModule({
  imports,
  providers: [
    FormValidatorServiceService,
    NavigationService,
    UpdateEventService,
    ScrollComunicationService,
    StrengthPasswordService,
    BrowserAnimationsModule
  ],
  exports: [
    ...imports,
  ],
  declarations: [AddCarritoToastComponent, CantidadMinimaToastComponent, StockInsuficienteToastComponent,
    ScrollDirective,
    EmailPatternValidator,
    TextTrimWhiteSpaceValidator,
    PasswordStrengthValidator,
    DateValidator,
    MenorEdadValidator,
    DateNoBeforeNowValidator,
    PrecioEnvioComponent,
    CpInvalidoToastComponent,
    ImagenSubidaToastComponent,
    TipoDeImpresionToastComponent,
    ErrorGenericoComponent,
    SuccessGenericoToastComponent,
    UsernameInvalidComponent],
  entryComponents:[AddCarritoToastComponent,CantidadMinimaToastComponent,
    StockInsuficienteToastComponent, PrecioEnvioComponent, CpInvalidoToastComponent,
    ImagenSubidaToastComponent,TipoDeImpresionToastComponent, ErrorGenericoComponent, SuccessGenericoToastComponent,UsernameInvalidComponent]
})
export class SharedServicesModule { }
