import { BackendModel, BackendService } from '../core/backend.service';

export class Parametric extends BackendModel {

  static path = '/parametricas/';

  id: number;
  description: string;
/*
  constructor(protected backendService: BackendService, model?: Parametric) {
    super(backendService, model);
  }*/

}

