import { Component, OnInit } from '@angular/core';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-add-carrito-toast',
  templateUrl: './add-carrito-toast.component.html',
  styleUrls: ['./add-carrito-toast.component.scss'],
  preserveWhitespaces: false,
})
export class AddCarritoToastComponent extends Toast{

  constructor(protected toastrService: ToastrService,
              public toastPackage: ToastPackage,) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
