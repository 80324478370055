import { Injectable } from "../../../../node_modules/@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "../../../../node_modules/@angular/router";
import { BackendService } from "../../core/backend.service";
import { Categoria } from "./categoria.model";
import { PaisService } from 'src/app/shared-services/pais.service';

@Injectable()
export class DashboardResolver {

    selectPais: string;

    constructor(
        protected router: Router,
        protected backendService: BackendService,
        private paisService: PaisService,
      ) {}

      async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

          

        try {
          this.selectPais = localStorage.getItem('pais');
          if(!this.selectPais){
            this.selectPais = 'ARGENTINA';
          }
          return await this.backendService.get(Categoria, "/new_categorias/activas/" + this.selectPais, null).toPromise();
        } catch (error) {
            console.log(error);
        }
      }
}
