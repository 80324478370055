import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appEmailPattern][ngModel],[appEmailPattern][formControl]',
  providers: [{
    provide: NG_VALIDATORS, multi: true,
    useExisting: forwardRef(() => EmailPatternValidator),
  }],
})
export class EmailPatternValidator implements Validator {
  emailPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
  validate(control: AbstractControl) {

    if (!control.value) {
      return null;
    }

    return this.emailPattern.test(control.value)
      ? null : { emailPattern: control.value };
  }
}
