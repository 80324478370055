import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {BackendService} from '../../core/backend.service';
import {ProductoParticularPedido} from '../models/producto-particular-pedido.model';

@Injectable()
export class ProductoInfoResolverService {

  constructor(
    protected router: Router,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    try {
      //const id = route.params.id;
      const slug = route.params.slug;

      //return await  this.backendService.get(ProductoParticularPedido, '/producto-stock/' + id).toPromise();
      return await  this.backendService.get(ProductoParticularPedido, '/producto-stock/slug/' + slug).toPromise();
    } catch (error) {
      this.router.navigate(['/']);
    }
  }
}
