import { Injectable, Inject } from '@angular/core';
import { Http, RequestOptionsArgs, Headers, Response, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { isEmpty, isEqual, forEach, isArray, isFunction, isString } from 'lodash';

import { Environment } from '../../environments/enviroment.model';
import { EnvironmentProvider, StorageProvider } from './browser-providers';
/*import { SnackService } from '../shared-services/snack.service';*/
import { NavigationService } from '../shared-services/navigation.service';
import {catchError, map} from "rxjs/operators";

export type QueryParams = null | string | URLSearchParams | {
  [key: string]: any | any[]
};

export type BackendModelFactory<M extends BackendModel> = (
  new(backendService: BackendService, obj?: Object) => M
);

export abstract class BackendModel {
  protected '@id' = '';
  get iri() { return this['@id']; }
  protected '@type' = '';
  get class() { return this['@type']; }
  get id() {
    return +this['@id'].split('/').pop();
  }
  constructor(
    protected backendService: BackendService, model?: any
  ) { }

  protected isNull(model: any): boolean {
    return model === undefined || model === null;
  }

  delete() {
    this.backendService.delete(this.iri);
  }

  protected preSave() {
    forEach(this, p => {
      if (p instanceof BackendModel) {
        p.preSave();
      } else if (isArray(p) && p.length && p[0] instanceof BackendModel) {
        p.forEach(m => isFunction(m.preSave) && m.preSave());
      }
    });
  }
  async save() {
    this.preSave();
    try {
      if (this.id) {
        return await this.backendService.update(this.iri, this);
      } else {
        this['_id'] = this['@id'];
        delete this['@id'];
        return await this.backendService.create(this['_id'], this);
      }
    } catch (e) {
      this['@id'] = this['@id'] || this['_id'];
      throw e;
    } finally {
      this.postSave();
    }
  }
  protected postSave() {
    forEach(this, p => {
      if (p instanceof BackendModel) {
        p.postSave();
      } else if (isArray(p) && p.length && p[0] instanceof BackendModel) {
        p.forEach(m => isFunction(m.postSave) && m.postSave());
      }
    });
  }
}

@Injectable()
export class BackendService {

  private tokenId = 'jwtToken';
  protected backendUrl: string;
  constructor(
    @Inject(EnvironmentProvider) env: Environment,
    @Inject(StorageProvider) protected storage: Storage,
    protected navigationService: NavigationService,
    protected http: Http,
  ) {
    this.backendUrl = env.endpoints.apiFront;
  }

  public buildUrl(path: string) {
    return this.backendUrl + encodeURI(path);
  }

  public buildRequestOptions(options: RequestOptionsArgs = {}) {
    options.headers = options.headers || new Headers();
    options.headers.append('Content-Type', 'application/ld+json');
    const token = this.token;
    if (token) {
      options.headers.append('Authorization', 'Bearer ' + token);
    }
    return options;
  }

  private buildRequestMultiPartOptions(options: RequestOptionsArgs = {}) {
    options.headers = options.headers || new Headers();
    const token = this.token;
    if (token) {
      options.headers.append('Authorization', 'Bearer ' + token);
    }
    return options;
  }

  public getData(response: Response) {
    const data = response.json();
    if (response.ok) { return data; }
    throw data['hydra:description'] || data['message'];
  }

  public handleError(path: string, response: Response, isGet: boolean = false) {
    /*if (response.status === 401) {
      this.authService.logout();
    }*/

    if (response.status === 403 && isGet) {
      setTimeout(() => this.navigationService.back(), 2000);
      // this.snackService.error('Usted no tiene permisos para visualizar o modificar estos datos');
    }

    throw response.json();
  }

  new<M extends BackendModel>(modelFactory: BackendModelFactory<M>, obj = { }) {
    return Object.setPrototypeOf(obj, new modelFactory(this, obj)) as M;
  }

  cast<M extends BackendModel>(list: M[], modelFactory: BackendModelFactory<M>, ...defaults: any[]) {
    return (list && list.length >= defaults.length ? list : defaults)
    .map(item => isString(item) ? item : this.new(modelFactory, item)) as M[];
  }

  newSubject<S>() {
    return new Subject<S>()
      .debounceTime(666)
      .distinctUntilChanged(isEqual);
  }

  newSubjectWithoutDistinct<S>() {
    return new Subject<S>()
      .debounceTime(666);
  }

  get<M extends BackendModel>(modelFactory: BackendModelFactory<M>, path: string): Observable<M>;
  get<M extends BackendModel>(modelFactory: BackendModelFactory<M>, path: string, params: QueryParams): Observable<M[]>;
  get<M extends BackendModel>(modelFactory: BackendModelFactory<M>, path: string, params?: QueryParams) {
    return this.http.get(
      this.buildUrl(path),
      this.buildRequestOptions(
        isEmpty(params) ? undefined : { params }
      )
    ).map(response => {
      const data = this.getData(response);
      if (data.data !== undefined) {
        const models = data.data.map(
          model => this.new(modelFactory, model)
        );
        models.totalItems = data.totalElements;
        return models as M[];
      }
      return this.new(modelFactory, data) as M;
    })
    .catch(e => this.handleError(path, e, true) || null);
  }

  create<M extends Object>(path: string, model: M) {
    return this.http.post(
      this.buildUrl(path),
      model,
      this.buildRequestOptions()
    )
    .catch(e => this.handleError(path, e) || null)
    .toPromise();
  }

  createFile<M extends Object>(path: string, model: M) {
    return this.http.post(
      this.buildUrl(path),
      model,
      this.buildRequestMultiPartOptions()
    )
    .catch(e => this.handleError(path, e) || null)
    .toPromise();
  }

  update<M extends Object>(path: string, model: M) {
    return this.http.put(
      this.buildUrl(path),
      model,
      this.buildRequestOptions()
    ).map(res => this.getData(res) as M)
    .catch(e => this.handleError(path, e) || null)
    .toPromise();
  }

  delete<M extends Object>(path: string) {
    return this.http.delete(
      this.buildUrl(path),
      this.buildRequestOptions()
    ).catch(e => this.handleError(path, e) || null)
    .toPromise();
  }

  saveConfig<M extends Object>(path: string, model: M) {
    return this.http.post(
      this.buildUrl(path),
      model,
      this.buildRequestOptions()
    ).catch(e => this.handleError(path, e) || null)
    .toPromise();
  }

  get token() {
    return this.getCookie(this.tokenId);
  }

  getCookie(value:string) {
    const cookies = document.cookie.split(';');
    const myCookie = cookies.find(cookie => cookie.trim().startsWith(value +'='));
    
    if (myCookie) {
      const value = myCookie.split('=')[1];
      return value;
    } 
    return myCookie;
  }

  get hasDocumentacionPendiente(): any {
    return this.http.get(
      this.buildUrl('/clientes/has_documentacion_pendiente'),
      this.buildRequestOptions(undefined)
    ).map(response => {
      const data = this.getData(response);
      return data;
    })
    .catch(e => this.handleError('/clientes/has_documentacion_pendiente', e, true) || null);
  }

  createWithResponse<M extends Object>(path: string, model: M): Promise<{} | Response> {
    return this.http.post(
      this.buildUrl(path),
      model,
      this.buildRequestOptions()
    )
    .catch(e => this.handleError(path, e) || null)
    .toPromise();
  }

  getNumber<M extends Object>(path: string) {
    return this.http.get(
      this.buildUrl(path),
      this.buildRequestOptions()
    ).map(res => this.getData(res) as M)
    .catch(e => this.handleError(path, e) || null)
    .toPromise();
  }

  getString<M extends Object>(path: string) {
    return this.http.get(
      this.buildUrl(path),
      this.buildRequestOptions()
    ).map(res => res)
    .catch(e => this.handleError(path, e) || null)
    .toPromise();
  }
  consumirHash<M extends Object>(path: string, hash: string) {
    return this.http.get(
      this.buildUrl(path),
      this.buildRequestOptions()
    );
  }
  sendEmailRecoverPassword<M extends Object>(path: string, model: M) {
    return this.http.post(
      this.buildUrl(path),
      model,
      this.buildRequestOptions()
    ).pipe( map(res => this.getData(res) as M)
      , catchError(e => this.handleError(path, e) || null))
      .toPromise();
  }

  getPdf<M extends Object>(path: string, model: M): Observable<Blob> {
    return this.http.put(this.buildUrl( path ), model,
      this.buildRequestOptions({ responseType: ResponseContentType.Blob }))
      .map((res: Response) => res.blob());
  }

  getPresupuesto<M extends Object>(path: string, model: M): Observable<Blob> {
    return this.http.put(this.buildUrl( path ), model,
      this.buildRequestOptions({ responseType: ResponseContentType.Blob }))
      .map((res: Response) => res.blob());
  }

  getBoolean(path: string): any {
    return this.http.get(
      this.buildUrl(path),
      this.buildRequestOptions(  )
    ).map(response => this.getData(response))
    .catch(e => Observable.throw(this.handleError(path, e, true)) || null)
    .toPromise();
  }

}
