import { Component, OnInit, ViewChild } from '@angular/core';
import { Sesion } from '../core/sesion.model';
import { Operacion } from '../core/operacion.model';
import { BackendService } from '../core/backend.service';
import { SeguridadService } from '../seguridad/seguridad.service';
import { Anuncio } from '../core/anuncio.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Saldo } from '../core/saldo.model';
import { ModalsService } from '../shared-services/modals.service';
import { UltimasSesionesComponent } from '../shared-components/ultimas-sesiones/ultimas-sesiones.component';
import { DashboardComponent } from '../shared-components/dashboard/dashboard.component';
import { Categoria } from '../shared-components/dashboard/categoria.model';
import { Imagen } from '../productos/viejo/imagen.model';
import { DomSanitizer } from '../../../node_modules/@angular/platform-browser';
import { ClienteService } from '../core/cliente.service';
import { ImagenBanner } from '../shared-components/banners/imagenBanner.model';
import { Banner } from '../shared-components/banners/banner.model';
import { NgxSpinnerService } from '../../../node_modules/ngx-spinner';
import { AfterViewInit } from '@angular/core';
import { ApiService } from '../core/api.service';
import { Parametric } from '../cliente/parametric.model';
import {ProductoUnicaImagen} from './model/producto-unica-imagen';
import {Producto} from '../productos/models/producto.model';
import { PortalHostDirective } from '@angular/cdk/portal';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Gtag } from 'angular-gtag';
import { BannerDto } from '../shared-components/banners/bannerDto.model';
import { PaisEnum } from '../core/constants/pais-enum';
import { PaisService } from '../shared-services/pais.service';
import { ScrollerService } from '../core/services/scroller.service';

export class ResultHomeResolver {
  sesiones: Array<Sesion> = [];
  operaciones: Operacion[];
  anuncios: Anuncio[];
  billetera: Saldo[];
}

declare const $: any;

@Component({
  selector: 'app-home-component',
  templateUrl: './home-component.component.html',
  styleUrls: ['./home-component.component.scss']
})
export class HomeComponentComponent implements OnInit {

  info: ResultHomeResolver;
  public bannersActivos: BannerDto[];
  public primerBanner:ImagenBanner = new ImagenBanner();
  public bannerDesktop:BannerDto = null;
  public bannerMobile:BannerDto = null;
  public banners:ImagenBanner[] = [];

  public top5Productos: ProductoUnicaImagen[] = [];
  public carousel1:ProductoUnicaImagen[] = [];
  public carousel2: ProductoUnicaImagen[] = [];
  public productosDestacadosFila: ProductoUnicaImagen[] = [];
  /*public prod: ProductoUnicaImagen;
  public prod0: ProductoUnicaImagen;
  public prod1: ProductoUnicaImagen;
  public prod2: ProductoUnicaImagen;
  public prod3: ProductoUnicaImagen;
  public prod4:ProductoUnicaImagen;
  public prod5:ProductoUnicaImagen;
  public prod6:ProductoUnicaImagen;
  public prod7:ProductoUnicaImagen;
  public prod8:ProductoUnicaImagen;
  public prod9:ProductoUnicaImagen;*/


  @ViewChild(UltimasSesionesComponent)
  private ultimasSesiones: UltimasSesionesComponent;

  loaded = false;
  categorias: Categoria[];
  categoriasPopulares = ['Drinkware', 'Escritorio', 'Escritura', 'Home Office', 'Bolsos y Mochilas', 'Hogar y Tiempo Libre', 'Gorros', 'Remeras', 'Tazas', 'Relojes', 'Tecnología', 'Cuidado Personal COVID-19', 'Paraguas', 'Llaveros'];
  categoriasPopularesfiltradas: Categoria[];
  categoriasMasBuscadas: Categoria[];
  categoriasPopularesFuncional =  [ 'Escritorio', 'Tecnología','Bolsos y Mochilas', 'Drinkware', 'Hogar y Tiempo Libre'];
  drinkware:Categoria;
  bolsosMochilas:Categoria;
  tecnologia:Categoria;
  hogarTiempoLibre:Categoria;
  escritorio: Categoria;
  remeras:Categoria;
  diaDelPadre:Categoria;
  homeOffice:Categoria;
  gorros:Categoria;
  apparel:Categoria;
  escritura:Categoria;
  gourmet:Categoria;
  novedades2022:Categoria;
  mundial2022:Categoria;

  selectPais: string;
  iva:number = 0;


  constructor(
    private backendService: BackendService,
    private apiService: ApiService,
    private seguridadService: SeguridadService,
    private route: ActivatedRoute,
    public dashboard: DashboardComponent,
    private sanitizer: DomSanitizer,
    private clienteService: ClienteService,
    private spinner: NgxSpinnerService,
    private sanitazer : DomSanitizer,
    private gtag:Gtag,
    private router: Router,
    private paisService: PaisService,
    private scrollerService:ScrollerService
  ) {
  }

  async ngOnInit() {

    this.spinner.show();
    this.scrollerService.scrollToTop();
    this.selectPais = localStorage.getItem('pais');

    if(this.selectPais == null){
      await this.paisService.detectCountry().then(a=>{
        this.selectPais = localStorage.getItem('pais');
        if(!this.selectPais){
          this.router.navigateByUrl('/select-country')
        }
      });
    }

    if(!this.seguridadService.user){
      //window.localStorage.clear();
    }
    
    this.top5Productos = await this.backendService.get(ProductoUnicaImagen, Producto.new_path + 'top/' + this.selectPais, null).toPromise();
    //await this.recuperarImagenes();
    
    this.separarProd(this.top5Productos);
    this.route.data.subscribe(data => {
      this.info = data.informacion;
    });
    await this.getCategorias();
    await this.getBanners();

    //filtro de categorías para q aparezcan los mostrados solamente
    //this.categorias = this.categorias.filter(c => this.categoriasPopulares.includes(c.description));

    this.spinner.hide();

    //localStorage.setItem('tutorial', 'Como utilizar el LocalStorage en Angular');

    //var dato = localStorage.getItem('tutorial');

    //console.log(dato)
  }


  /*No se usa más
  async recuperarImagenes() {
    for (let i = 0; i < this.top5Productos.length; i++) {
      for (let r = 0; r < this.top5Productos[i].listaImagenes.length; r++){
        this.getFiles(this.top5Productos[i].listaImagenes[r]);
      }
    }
  }
  async getFiles(d: Imagen) {
    await this.clienteService.getFileById(d.id).subscribe(data => {
      this.createImageFromBlob(data, d);
    }, error => {
      console.log(error);
    });
  }
  async createImageFromBlob(image: Blob, d: Imagen) {
    const reader = new FileReader();
    await reader.addEventListener('load', () => {
      d.url = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }*/

  /*ngAfterViewInit() {
    this.spinner.show();
    let owl = $('.owl-carousel');
    // owl.trigger('play.owl.carousel');

    owl.owlCarousel({
      loop: true,
    margin: 10,
    nav: true,
    dots: false,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:3
        },
        1000:{
            items:4
        }
    }
    });
    
    $('#carouselExampleIndicators').carousel({
    interval: 6000
    }); 
    this.spinner.hide();
  }*/

  async getCategorias() {
    if (!this.categorias) {
      await this.backendService.get(Categoria, Categoria.path + 'full', null).toPromise().then((categorias)=>{
        this.categorias = categorias;
        this.categoriasPopularesfiltradas = categorias.filter((c)=> this.categoriasPopularesFuncional.includes(c.description));

        this.drinkware = categorias.find((c) => c.description == 'Drinkware');
        this.bolsosMochilas = categorias.find((c) => c.description == 'Bolsos y Mochilas');
        this.tecnologia = categorias.find((c) => c.description == 'Tecnología');
        this.hogarTiempoLibre = categorias.find((c) => c.description == 'Hogar y Tiempo Libre');
        this.escritorio = categorias.find((c) => c.description == 'Escritorio');
        //7this.remeras = categorias.find((c) => c.description == 'Remeras');
        //this.diaDelPadre = categorias.find((c) => c.description == '2022 Día del padre');
        
        
      });
      /*USAR CUANDO SE ESPECIALICEN LAS CATEGORIAS
      await this.backendService.get(Categoria, Categoria.path + 'mas-buscadas', null).toPromise().then((catMasBuscadas)=>{
        this.categoriasMasBuscadas= catMasBuscadas;
      })*/
      //this.categorias.forEach(c => c.imagen ='data:image/svg+xml;base64,'+c.imagen);
    }
  }

  /*OBTENER BANNERS*/
  async getBanners() {
    if(this.selectPais == "CHILE"){
      await this.backendService.get(BannerDto, Banner.path + 'bannersActivos/' + PaisEnum.CHILE, null).toPromise().then(a =>{
        this.bannersActivos = a as BannerDto[];
      })
    }else{
      await this.backendService.get(BannerDto, Banner.path + 'bannersActivos/' + PaisEnum.ARGENTINA, null).toPromise().then(a =>{
        this.bannersActivos = a as BannerDto[];
      })
    }

    
    this.loaded = true;

    for(var i = 0;i < this.bannersActivos.length; i++){
      try{
      if(this.bannersActivos[i].es_desktop == "Desktop"){
        this.bannerDesktop = this.bannersActivos[i];
      }else{
        this.bannerMobile = this.bannersActivos[i];
      }
    }catch(ex){
      console.log(ex.message)
    }
    }
    
  }

  /*separarBanners(){
    for(var i = 0;i < this.bannersActivos.length; i++){
      try{
      if(this.bannersActivos[i].es_desktop == "Desktop"){
        this.bannersDesktop.push(this.bannersActivos[i]);
      }else{
        this.bannersMobile.push(this.bannersActivos[i]);
      }
    }catch(ex){
      console.log(ex.message)
    }
    }
      this.primerBanner.pathDesktop = this.bannersDesktop[0].path;
      this.primerBanner.categoriaDesktop = this.bannersDesktop[0].categoriaProducto;
      this.primerBanner.pathMobile = this.bannersMobile[0].path;
      this.primerBanner.categoriaMobile = this.bannersMobile[0].categoriaProducto;
    for(var i = 1; i < this.bannersDesktop.length; i++){
      var bannerShow: ImagenBanner = new ImagenBanner();
      try{
        bannerShow.pathDesktop = this.bannersDesktop[i].path;
        bannerShow.categoriaDesktop = this.bannersDesktop[i].categoriaProducto;
        bannerShow.pathMobile = this.bannersMobile[i].path;
        bannerShow.categoriaMobile = this.bannersMobile[i].categoriaProducto;
        this.banners.push(bannerShow);
      }catch (ex){
        console.log(ex.message)
      }
    }
  }*/

  /*async crearUrlsImagenes() {
    for (let i = 0; i < this.imagenes.length; i++) {
      this.getImages(this.imagenes[i]);
    }
  }

  getImages(imagen: ImagenBanner) {
    this.clienteService.getBannerById(imagen.id).subscribe(data => {
      const urlCreator = window.URL;
      const safeUrl: any = this.sanitizer.bypassSecurityTrustUrl(
          urlCreator.createObjectURL(data));
      imagen.url = safeUrl;
    });
  }*/

  /*No se usa
  async changeImage1(prod: ProductoUnicaImagen){
    prod.numImagen = 0;
  }
  async changeImage2(prod: ProductoUnicaImagen){
    prod.numImagen = 1;
  }
  async changeImage3(prod: ProductoUnicaImagen){
    prod.numImagen = 2;
  }*/
  separarProd(top10){

    //this.setearPreciosConIva();
    this.productosDestacadosFila[0] = top10[0];
    this.productosDestacadosFila[1] = top10[1];
    this.productosDestacadosFila[2] = top10[2];
    this.productosDestacadosFila[3] = top10[3];
    this.productosDestacadosFila[4] = top10[4];
    this.productosDestacadosFila[5] = top10[5];
    this.productosDestacadosFila[6] = top10[6];
    this.productosDestacadosFila[7] = top10[7];
    this.productosDestacadosFila[8] = top10[8];
    this.productosDestacadosFila[9] = top10[9];

    /*this.carousel1[1] = (top10[6]);
    this.carousel1[2] = (top10[7]);
    this.carousel1[3] = (top10[8]);
    this.carousel2[0] = (top10[9]);
    this.carousel2[1] = (top10[10]);
    this.carousel2[2] = (top10[11]);
    this.carousel2[3] = (top10[12]);*/
  }

  getPrecioConIva(precio:number){

    if(this.selectPais == "CHILE"){
      this.iva = 1.19
    }else{
      this.iva = 1.21
    }
    
    var numeroIva = this.round(precio * this.iva)
    
    return numeroIva

  }

  round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
  }

  /*No se usa
  setearPreciosConIva(){
    this.top5Productos.forEach(prod => {
      prod.precio = this.getPrecioConIva(prod.precio)
    })
  }*/

  onProductClick(producto) {
    this.gtag.event('select_item', {
      items: [{
        item_name: producto.nombre,
        item_id: producto.id,
        price: producto.precio
      }]
    });
  }

  onCategoryClick(categoria) {
    this.gtag.event('select_category', {
      items: [{
        item_name: categoria.description,
        item_id: categoria.id,
      }]
    });
  }

  onVerProductosClick(){
    this.gtag.event('boton_banner_home', {
      items: [{
        item_name: "Botón Banner Home",
      }]
    });
  }

  homeView(){
    this.gtag.event('view_home', {
      items: [{
        item_name: "Home View"
      }]
    });
  }

  /*arreglarPrecio(precioViejo){
    const nuevoPrecio = precioViejo.toString().substring(0, precioViejo.toString().length - 2);
    return nuevoPrecio;
  }*/

}
