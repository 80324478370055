import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appTextTrimWhiteSpace][ngModel],[appTextTrimWhiteSpace][formControl]',
  providers: [{
    provide: NG_VALIDATORS, multi: true,
    useExisting: forwardRef(() => TextTrimWhiteSpaceValidator),
  }],
})
export class TextTrimWhiteSpaceValidator implements Validator {

  validate(control: AbstractControl) {
     if (!control.value ) {
      return null;
     }

     if (control.value.toString().trim() === '') {
       return { enBlanco: { valor: 'No puede tener espacios en blanco'} };
     }

     return null;
  }
}
