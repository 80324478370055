import { Component, OnInit, Input } from '@angular/core';
import { ProductoEnviar } from '../../../productos/viejo/productoEnviar.model';
import { ImagenLogo } from '../../../productos/viejo/imagen-logo.model';
import { PrecioCantidad } from '../../../productos/viejo/precio-cantidad.model';
import { Subject } from '../../../../../node_modules/rxjs';
import { BackendService } from '../../../core/backend.service';
import { ClienteService } from '../../../core/cliente.service';
import { DomSanitizer } from '../../../../../node_modules/@angular/platform-browser';
import { Router } from '@angular/router';
import { BsModalRef } from '../../../../../node_modules/ngx-bootstrap';
import { NgxSpinnerService } from '../../../../../node_modules/ngx-spinner';

@Component({
  selector: 'app-previsualizacion',
  templateUrl: './previsualizacion.component.html',
  styleUrls: ['./previsualizacion.component.scss']
})
export class PrevisualizacionComponent implements OnInit {

  productoMostrar: ProductoEnviar;
  slides: ImagenLogo[] = [];
  total: number;
  preciosXCantidad: PrecioCantidad[] = [];
  imagenes: ImagenLogo[] = [];

  @Input() id: number;
  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef,
    private backendService: BackendService,
    private clienteService: ClienteService,
    private sanitizer: DomSanitizer,
    public router: Router,
    private spinner: NgxSpinnerService
  ) {
  }

  hidden = true;
  slideConfigFor = {  'slidesToShow': 1,
  'slidesToScroll': 1,
  'arrows': false,
  'fade': true,
  'asNavFor': '.slider-nav'
  };
  slideConfigNav = {  'slidesToShow': 3,
    'slidesToScroll': 1,
    'asNavFor': '.slider-for',
    'dots': false,
    'centerMode': true,
    'focusOnSelect': true,
    'infinite': false
  };

  async ngOnInit() {
    this.onClose = new Subject();
    this.spinner.show();
    await this.getProductoMostrar(this.id);
    this.slides = await this.getImages(this.imagenes);
    this.spinner.hide();
  }

  async getProductoMostrar(id) {
    await  this.backendService.get(ProductoEnviar, ProductoEnviar.path + 'detalles-' + id).toPromise().then( p => {
      this.productoMostrar = this.backendService.new(ProductoEnviar);
      this.productoMostrar.cantidad = p.cantidad;
      this.productoMostrar.talle = p.talle;
      this.productoMostrar.tipoImpresion = p.tipoImpresion;
      this.productoMostrar.precioUnitario = p.precioUnitario;
      this.productoMostrar.precioAdicional = p.precioAdicional;
      this.productoMostrar.tipoMoneda = p.tipoMoneda;
      this.productoMostrar.cantidadLogos = p.cantidadLogos;
      this.productoMostrar.cotizacionMoneda = p.cotizacionMoneda;
      this.productoMostrar.producto = p.producto;
      p.imagenes.forEach(img => {
        img.imagen.path = 'data:image/png+xml;base64,'+img.imagen.path;
      })
      this.imagenes = p.imagenes;

    });
    this.calcularTotal();
  }

  getImages(imagenes: ImagenLogo[]) {
    const imgs = [];
    imagenes.forEach(i => {
      this.clienteService.getImagenPedidoById(i.id).subscribe(data => {
        const urlCreator = window.URL;
        const safeUrl: any = this.sanitizer.bypassSecurityTrustUrl(
            urlCreator.createObjectURL(data));
        i.imagen.url = safeUrl;
      });

      imgs.push(i);
    });

    return imgs;
  }

  calcularTotal() {
    this.total = this.productoMostrar.cotizacionMoneda * this.productoMostrar.cantidad *
    ((this.productoMostrar.precioAdicional * this.productoMostrar.cantidadLogos) + this.getPrecio());
  }


  getPrecio() {
    const pxc =  this.preciosXCantidad.filter(p => this.productoMostrar.cantidad > p.cantidad)
                    .sort( function(a, b) {return a.precio - b.precio; })[0];

    return pxc ? pxc.precio : this.productoMostrar.precioUnitario;
  }

  close() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}
