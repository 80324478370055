import { Injectable } from "../../../../node_modules/@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "../../../../node_modules/@angular/router";
import { BackendService } from "../../core/backend.service";
import { Pedido } from "../../productos/resultado-producto/models/pedido.model";

@Injectable()
export class MisPedidosResolver {

    constructor(
        protected router: Router,
        protected backendService: BackendService,
      ) {}

      async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        try {
          const id = route.params.id || undefined;

          if (id === undefined) {
            return await  this.backendService.get(Pedido, Pedido.new_path + 'mis-pedidos').toPromise();

          }

          return await this.backendService.get(Pedido, Pedido.new_path + id).toPromise();
        } catch (error) {
          this.router.navigate(['/']);
        }
      }

}
