import { Component, OnInit, Input } from '@angular/core';
import { Parametric } from '../../cliente/parametric.model';
import { ApiService } from '../../core/api.service';
import { Mail } from '../../cliente/mail.model';

@Component({
  selector: 'app-mail-form',
  templateUrl: './mail-form.component.html',
  styleUrls: ['./mail-form.component.scss']
})
export class MailFormComponent implements OnInit {

  public tiposCorreos: Parametric[];
  @Input() mail: Mail;
  @Input() isFirst: boolean;

  constructor(
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.getTiposCorreos();
  }

  async getTiposCorreos() {
    this.tiposCorreos = await this.apiService.get(Parametric, Parametric.path + 'tipos_email/', null).toPromise();
  }

}
