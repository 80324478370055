import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medios-prensa',
  templateUrl: './medios-prensa.component.html',
  styleUrls: ['./medios-prensa.component.scss']
})
export class MediosPrensaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
