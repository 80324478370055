import { Mail } from '../../cliente/mail.model';
import { Telefono } from '../../cliente/telefono.model';

export class DatosContacto {

  static path = '/registro/paso_3';

  emails: Mail[];
  telefonos: Telefono[];

  constructor() {
    this.telefonos = [];
    this.emails = [];
  }

  /*
  constructor(protected backendService: BackendService, model?: RegistroPaso3) {
    super(backendService, model);
  }
  */

}
