import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ProductoCarrito} from '../models/producto-carrito';

@Injectable()
export class ProductoService {

  private checkSubject = new BehaviorSubject<ProductoCarrito>(undefined);
  private metodoDePago: string;
  private subioLogo: boolean;
  private nombreUltimoProducto:string;

  public producto = this.checkSubject.asObservable();
  constructor() { }

  emitChange(tradingData: ProductoCarrito) {
    this.checkSubject.next(tradingData);
  }

  setMetodoDePago(metodo: string){
    this.metodoDePago = metodo;
  }

  getMetodoDepago(){
    return this.metodoDePago;
  }

  setSubioLogosCurvas(estado: boolean){
    this.subioLogo = estado;
  }

  getSubioLogoCurvas(){
    return this.subioLogo;
  }

  setnombreUltimoProducto(nombre: string){
    this.nombreUltimoProducto = nombre;
  }

  getnombreUltimoProducto(){
    return this.nombreUltimoProducto;
  }
}
