import { Component, OnInit, Input } from '@angular/core';
import { Parametric } from '../../cliente/parametric.model';
import { ApiService } from '../../core/api.service';
import { Telefono } from '../../cliente/telefono.model';
import { NgForm, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-telefono-form',
  templateUrl: './telefono-form.component.html',
  styleUrls: ['./telefono-form.component.scss']
})
export class TelefonoFormComponent implements OnInit {

  public tiposTelefonos: Parametric[];
  @Input() telefono: Telefono;

  constructor(
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.getTiposTelefonos();
  }

  async getTiposTelefonos() {
    this.tiposTelefonos = await this.apiService.get(Parametric, Parametric.path + 'tipos_telefonos/', null).toPromise();
  }

}
