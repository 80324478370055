import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-personalizar',
  templateUrl: './personalizar.component.html',
  styleUrls: ['./personalizar.component.scss']
})
export class PersonalizarComponent implements OnInit {

  selectPais: string;
  constructor(private location: Location) { }

  ngOnInit() {
    this.selectPais = localStorage.getItem('pais');
  }

  goBack() {
    this.location.back();
  }
}
