import { Component, OnInit } from '@angular/core';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-success-generico-toast',
  templateUrl: './success-generico-toast.component.html',
  styleUrls: ['./success-generico-toast.component.scss']
})
export class SuccessGenericoToastComponent extends Toast {
  public message;
  constructor(protected toastrService: ToastrService,
    public toastPackage: ToastPackage,) {
super(toastrService, toastPackage);
}

action(event: Event) {
event.stopPropagation();
this.toastPackage.triggerAction();
return false;
}

}
