import { Component, OnInit } from '@angular/core';
import { Pedido } from '../../resultado-producto/models/pedido.model';

@Component({
  selector: 'app-base-checkout-adicionales',
  templateUrl: './base-checkout-adicionales.component.html',
  styleUrls: ['./base-checkout-adicionales.component.scss']
})
export class BaseCheckoutAdicionalesComponent implements OnInit {

  pasoUno: boolean = true;
  pasoDos: boolean = false;
  pedido: Partial<Pedido>;

  constructor() { }

  ngOnInit() {
  }

  continuarCompra(pedido){
    this.pasoUno = false;
    this.pasoDos = true;
    this.pedido = pedido;
  }

  volverAPasoUno(){
    this.pasoUno = true;
    this.pasoDos = false;
  }

}
