import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirmar-muestra-modal',
  templateUrl: './confirmar-muestra-modal.component.html',
  styleUrls: ['./confirmar-muestra-modal.component.scss']
})
export class ConfirmarMuestraModalComponent implements OnInit {

  public onClose: Subject<boolean>;
  
  constructor(public bsModalRef: BsModalRef) { }
  
  async ngOnInit() {
    this.onClose = new Subject();
  }

  aprobar(){
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  rechazar(){
    this.onClose.next(false);
    this.bsModalRef.hide();
  }


}
