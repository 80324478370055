import { Injectable, Inject } from '@angular/core';
import { BackendService } from './backend.service';
import { Http } from '@angular/http';
import { NavigationService } from '../shared-services/navigation.service';
import { EnvironmentProvider, StorageProvider } from './browser-providers';
import { Environment } from '../../environments/enviroment.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends BackendService {

  constructor(
  @Inject(EnvironmentProvider) env: Environment,
  @Inject(StorageProvider) protected storage: Storage,
  protected navigationService: NavigationService,
  protected http: Http) {
    super(env, storage, navigationService, http);
    this.backendUrl = env.endpoints.api;
  }
}
