import {Producto} from './producto.model';
import {Color} from '../color.model';
import {LogoPedido} from '../viejo/logo-pedido.model';
import {ProductoStock} from '../dtos/producto-stock';
import {Parametric} from '../../cliente/parametric.model';
import {AreasImpresion} from './areas-impresion';
import {TipoImpresion} from './tipo-impresion';
import {LogoAreaImpresion} from './logo-area-impresion';
import {Render} from '../producto-particular-logo/model/render';
import {TalleConStock} from '../producto-particular/dtos/talle-con-stock';
import {ProductoDisplay} from '../producto-particular/dtos/producto-display';
import {VarianteXColor} from '../producto-particular/dtos/variante-x-color';

export class ProductoCarrito {

  //GENERICO
  infoProducto: ProductoDisplay;
  cantidad: number;
  precioFinal: number;
  precioIva:number;
  varianteSeleccionada : VarianteXColor;
  tiempoEntrega:number;

  //PRODUCTO CON LOGO
  tipoImpresionSeleccionada : TipoImpresion;
  logosAreas:  LogoAreaImpresion[];
  renderes: Render[];



  //TALLES
  talleElegido: Parametric;
  talleDtoList: Parametric;
  tallesSeleccionadosConStock:TalleConStock[];

  slug:string;
  paisId:number;
}
