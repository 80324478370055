import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }
  static saveLocalStorage(key: string, item: string) {
    localStorage.setItem(key, JSON.stringify(item));
  }

  static getObject(key: string) {
    try {
      const object = localStorage.getItem(key);
      if (object) {
        return JSON.parse(object);
      }
    } catch (e) {
      localStorage.clear();
    }
    return undefined;
  }

  /*static closeSession() {
    localStorage.removeItem(Rext.TOKEN);
  }*/

  static removeObject(key: string) {
    localStorage.removeItem(key);
  }
}
