import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { environment } from '../environments/environment';
import { Gtag } from 'angular-gtag';
import { ProductoService } from './productos/service/producto.service';
import { PaisEnum } from './core/constants/pais-enum';
import { PaisService } from './shared-services/pais.service';
import { VersionCheckService } from './shared-services/version-check/version-check.service';

declare let gtag: (property: string, value: any, configs: any) => {};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'viacotonefrontend';
  selectPais: string = '';
  condicionCumplida: boolean = true;
  vito: any;
  numeroArg: string = "https://wa.me/+5491132776395"
  numeroCh: string = "https://wa.me/+56934569252"
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private versionCheckService: VersionCheckService,
    private renderer2: Renderer2,
    private gtg: Gtag,
    private productoService: ProductoService,
    private paisService: PaisService,
  ) {

    router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        gtag('config', environment.googleAnalyticsId, {
          page_path: event.urlAfterRedirects
        });
      }
      if (event instanceof NavigationStart) {
        //Revisar spinner general de navegación porque ocurre
        //this.spinner.show();
      }

      if (event instanceof NavigationEnd) {
        this.spinner.hide();
      }

      if (event instanceof NavigationError) {
        this.spinner.hide();

        // Present error to user
        // console.log(event.error);
      }
    });
  }
  async ngOnInit() {
    this.selectPais = localStorage.getItem('pais');
    if (!this.selectPais) {
      await this.paisService.pais.subscribe(u => {
        this.selectPais = u;
      })
    }
    //this.versionCheckService.checkearVersion(document.location.origin + environment.versionCheckURL);
    this.versionCheckService.checkVersionInProduction(document.location.origin + environment.versionCheckURL);
    /*if (this.selectPais == 'ARGENTINA') {
      //this.condicionCumplida = false;
      this.vito = document.createElement('script');
      this.vito.src = 'https://forms.app/static/embed.js';
      this.vito.async = true;
      document.head.appendChild(this.vito);
      
    }else{
      document.head.removeChild(this.vito);
    }*/

  }

  wspEventCl() {
    this.gtg.event('boton_whatsapp_chile', {
      items: [{
        item_name: "boton whatsapp chile"
      }]
    });
  }

  wspEventAr() {
    this.gtg.event('boton_whatsapp_argentina', {
      items: [{
        item_name: "boton whatsapp argentina"
      }]
    });
  }

  wspEvent() {
    var aditionalName = "";
    if (this.router.url.includes("/producto")) {
      aditionalName = "?text=Hola%2C%20me%20interesa%20el%20producto%20" + this.productoService.getnombreUltimoProducto().replace(" ", "%20");
    } else aditionalName = "?text=Hola%21%20Me%20interesa%20averiguar%20sobre%20productos%20con%20logo";

    if (this.selectPais == 'CHILE') {
      this.wspEventCl();
      window.open(this.numeroCh + aditionalName, "_blank");
    } else if (this.selectPais == 'ARGENTINA') {
      this.wspEventAr();
      window.open(this.numeroArg + aditionalName, "_blank");
    }
  }


}
