import { Email } from "./details/email.model";
import { Imagen } from "../../productos/viejo/imagen.model";
import { Telefono } from "../../cliente/telefono.model";
import { BackendModel, BackendService } from "../../core/backend.service";

export class ContactoUsuario extends BackendModel {

    id: number;
    lastname: string;
    name: string;
    username: string;
    emails: Email[];
    fotos: Imagen[];
    telefonos: Telefono[];

    constructor(protected backendService: BackendService, model?: ContactoUsuario) {
        super(backendService, model);
    }

}
