import { BackendModel } from "src/app/core/backend.service";
import { Categoria } from "src/app/shared-components/dashboard/categoria.model";
import { TipoImpresion } from "../models/tipo-impresion";
import { Imagen } from "../viejo/imagen.model";
import { ProductoConStockListado } from "./dtos/producto-con-stock-listado";

export class ProductoTop3 extends BackendModel{
    id: number;
    nombre: string;
    imagenes: Imagen[];
    precioDefault: number;
    precio: number;
    precioFinal: number;
    categorias:Categoria[];
    tiposImpresion: TipoImpresion[];
    productosparticulares: ProductoConStockListado[];
    slug: string;
}