import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import * as moment from 'moment';

@Directive({
  selector: '[appDateNoBeforeNow][ngModel],[appDateNoBeforeNow][formControl]',
  providers: [{
    provide: NG_VALIDATORS, multi: true,
    useExisting: forwardRef(() => DateNoBeforeNowValidator),
  }],
})
export class DateNoBeforeNowValidator implements Validator {

    validate(control: AbstractControl) {

        if (!control.value) {
            return null;
        }

        const fechaNacimiento = moment(control.value, 'DD/MM/YYYY');

        return  moment().isBefore(fechaNacimiento) ? { dateBeforeNow: control.value } : null;

    }
}
