import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {HttpClientModule} from '@angular/common/http';

import { LoginComponent } from './login/login.component';
import { VendorModule } from '../core/vendor.module';

import { CoreModule } from '../core/core.module';
import { LoguedGuardService } from './logued-guard.service';
import { UsuarioComponent } from './usuario/usuario.component';
import { SeguridadService } from './seguridad.service';
import { ClienteDetailsComponent } from './usuario/details/cliente-details.component';
import { ClienteResolver } from './usuario/details/cliente.resolver';
import { DocumentacionComponent } from './usuario/documentacion/documentacion.component';
import { RecuperarClaveComponent } from './recuperar-clave/recuperar-clave.component';
import { CambiarPasswordComponent } from './cambiar-password/cambiar-password.component';
import { ClienteService } from '../core/cliente.service';
import { ProductoResolver } from '../productos/producto.resolver';
import { CarritoResolver } from '../productos/resultado-producto/resolver/carrito.resolver';
import { MisPedidosResolver } from '../shared-components/mis-pedidos/mis-pedidos.resolver';
import { ListaProductosResolver } from '../productos/lista-productos/lista-productos.resolver';
import { DashboardResolver } from '../shared-components/dashboard/dashboard.resolver';
import { ProductoPedidoResolver } from '../productos/viejo/productoPedido.resolver';
import {ClienteResetPasswordResolver} from "./cambiar-password/services/cliente-reset-password-resolver";
import {ReactiveFormsModule} from "@angular/forms";
import { RecuperarClaveExitosoComponent } from './recuperar-clave/recuperar-clave-exitoso/recuperar-clave-exitoso.component';
import {ProductoInfoResolverService} from '../productos/resolver/producto-info-resolver.service';
import { DireccionInfoResolver } from '../shared-components/perfil-usuario/resolver/direccion-info-resolver.service';
import { PagoAdicionalResolver } from '../productos/resultado-producto/resolver/pago-adicional.resolver';
import { CountryPhoneResolver } from '../core/resolvers/country-phone.resolver';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    CoreModule,
    VendorModule,
    ReactiveFormsModule,
  ],
  declarations: [
    LoginComponent,
    UsuarioComponent,
    ClienteDetailsComponent,
    DocumentacionComponent,
    RecuperarClaveComponent,
    CambiarPasswordComponent,
    RecuperarClaveExitosoComponent,
  ],
  exports: [
    LoginComponent
  ],
  providers: [
    SeguridadService,
    ClienteResetPasswordResolver,
    LoguedGuardService,
    ClienteResolver,
    ClienteService,
    ProductoResolver,
    ProductoInfoResolverService,
    DireccionInfoResolver,
    CarritoResolver,
    MisPedidosResolver,
    ListaProductosResolver,
    DashboardResolver,
    ProductoPedidoResolver,
    DireccionInfoResolver,
    PagoAdicionalResolver,
    CountryPhoneResolver
  ]
})
export class SeguridadModule { }
