import { Injectable } from "../../../../node_modules/@angular/core";
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from "../../../../node_modules/@angular/router";
import { BackendService } from "../../core/backend.service";
import { Producto } from "../models/producto.model";
import { Imagen } from "../viejo/imagen.model";
import { ClienteService } from "../../core/cliente.service";
import { DomSanitizer } from "../../../../node_modules/@angular/platform-browser";
import { Observer, Observable } from 'rxjs';
import { Categoria } from "../../shared-components/dashboard/categoria.model";

interface Filters {
  page: number;
  size: number;
}

@Injectable()
export class ListaProductosResolver {

    private searchText: string;
    private colorText: string;
    private precioMinText: string;
    private precioMaxText: string;
    private subCatText: string;
    private productosLista;
    isLoading = false;
    private busqueda = {searchText: '', colorText: '', precioMinText: '', precioMaxText: '', subCatText: '', categoria: undefined, orden: ''};
    categoria: Categoria;

    constructor(
        protected router: Router,
        protected backendService: BackendService,
        protected clienteService: ClienteService,
        protected sanitizer: DomSanitizer,
      ) {}

      async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        try {

            this.busqueda.searchText = route.queryParams['search'];
            this.busqueda.subCatText = route.queryParams['subCat'];
            this.busqueda.colorText = route.queryParams['color'];
            this.busqueda.precioMinText = route.queryParams['precioMin'];
            this.busqueda.precioMaxText = route.queryParams['precioMax'];
            this.busqueda.orden = route.queryParams['orden'];
            
            return this.busqueda;

        } catch (error) {
          this.router.navigate(['/']);
        }
      }



}
