import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { SeguridadService } from '../seguridad/seguridad.service';

@Injectable()
export class RedirectGuard implements CanActivate {
  constructor(
    private seguridadService: SeguridadService,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.seguridadService.redirectToDefault();
    return false;
  }
}
