import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/api.service';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalsService } from 'src/app/shared-services/modals.service';
import { ToastService } from 'src/app/shared-services/toast/toast.service';

@Component({
  selector: 'app-desactivar-cuenta',
  templateUrl: './desactivar-cuenta.component.html',
  styleUrls: ['./desactivar-cuenta.component.scss']
})
export class DesactivarCuentaComponent implements OnInit {

  public confirmacion: false;

  constructor(
    private apiService: ApiService,
    private seguridadService: SeguridadService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private modalService: ModalsService,
    private toast: ToastService,
  ) { }


  ngOnInit() {
  }

  async desactivar(){

    const confirm = await this.modalService.openConfirmModal(
      // tslint:disable-next-line:max-line-length
      '¿Confirma que desea desactivar su cuenta? Recuerde que esta es una acción permanente, en caso de querer deshacerla se deberá comunicar con soporte.', 
    'Desactivación de cuenta', 'Si', 'No');

    confirm.subscribe( async result =>{
      if(result){
        this.spinner.show();
        try{
          await this.apiService.update('/frontend/subclientes/desactivar_cuenta', null);
          this.seguridadService.logout();
          this.toast.openSuccessGenerico('Su cuenta ha sido desactivada con exito.');
        } catch(ex) {
          this.toastr.error(ex.message);
        } finally {
          this.spinner.hide();
          this.confirmacion = false;
        }
      } else {
        this.confirmacion = false;
      }
    });
  }

}
