import { Component, OnInit } from '@angular/core';
import { Color } from 'src/app/productos/color.model';
import { VarianteXColor } from '../../dtos/variante-x-color';
import { SeleccionaVariante } from '../../dtos/selecciona-variante';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { ProductoStock } from 'src/app/productos/dtos/producto-stock';
import { SeleccionaVarianteTalle } from '../../dtos/selecciona-variante-talle';

@Component({
  selector: 'app-seleccionar-talle-modal',
  templateUrl: './seleccionar-talle-modal.component.html',
  styleUrls: ['./seleccionar-talle-modal.component.scss']
})
export class SeleccionarTalleModalComponent implements OnInit {

  public selectPais: string;
  public varianteSeleccionada: VarianteXColor;
  public variantes: VarianteXColor[];
  public cantidadTotalTalles:number;
  public textoTallesSeleccionados:string = "";
  public response: SeleccionaVarianteTalle = new SeleccionaVarianteTalle;
  public onClose: Subject<SeleccionaVarianteTalle>;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.selectPais = localStorage.getItem('pais');
      this.onClose = new Subject();
  }

  seleccionarVariante() {
    this.response.variante = this.varianteSeleccionada;
    this.response.cantidadTotal = this.cantidadTotalTalles;
    this.response.textoTallesSeleccionados = this.textoTallesSeleccionados;
    this.response.selecciona = true
    this.onClose.next(this.response);
    this.bsModalRef.hide();
  }

  noConfirmaAceptado() {
    this.response.selecciona = false;
    this.response.cantidadTotal = 0;
    this.onClose.next(this.response);
    this.bsModalRef.hide();
    debugger
  }

  agregarCantidadTalles(variante: ProductoStock, event, indice) {
    if(!event.target.value)
      event.target.value = 0;

    if (event.target.value > variante.stock)
      event.target.value = variante.stock;
    
    if (event.target.value < 0)
      event.target.value = 0;

    if (!(parseFloat(event.target.value) == parseInt(event.target.value) && !isNaN(event.target.value)))
      event.target.value = parseInt(event.target.value);

    this.cantidadTotalTalles = 0;

    variante.cantidadActual = event.target.value as number;

    this.varianteSeleccionada.variantes.forEach(talle => {
      this.cantidadTotalTalles = Number(this.cantidadTotalTalles) + Number(talle.cantidadActual);
    });
    //this.form.patchValue({ cantidad: this.cantidadTotalTalles });
    this.textoTallesSeleccionados += variante.talle.description + ", ";
  }

}
