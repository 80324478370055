import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { BackendService } from 'src/app/core/backend.service';

@Component({
  selector: 'app-comprobante-subido-modal',
  templateUrl: './comprobante-subido-modal.component.html',
  styleUrls: ['./comprobante-subido-modal.component.scss']
})

export class ComprobanteSubidoModalComponent implements OnInit {

  public onClose: Subject<boolean>;
  
  constructor(public bsModalRef: BsModalRef,
    public spinner: NgxSpinnerService,
    public backendService: BackendService,
    public sanitizer: DomSanitizer
    
    ) { }
  
  async ngOnInit() {
    this.spinner.hide()
    this.onClose = new Subject();
  }

  noConfirma() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
  
  confirma() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

}