import { BackendModel, BackendService } from "../../../core/backend.service";
import { Documento } from "./documento.model";

export class TipoDocumentacion extends BackendModel {

  static path = 'documentacion';
  id: number;
  description: string;
  documentos: Documento[] = [];

  constructor(protected backendService: BackendService, model?: TipoDocumentacion) {
    super(backendService, model);
  }
}