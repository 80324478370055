import { Component, OnInit } from '@angular/core';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-imagen-subida-toast',
  templateUrl: './imagen-subida-toast.component.html',
  styleUrls: ['./imagen-subida-toast.component.scss']
})
export class ImagenSubidaToastComponent extends Toast {

  public message;
  constructor(protected toastrService: ToastrService,
              public toastPackage: ToastPackage) {super (toastrService, toastPackage)
              }

  action(event: Event){
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
