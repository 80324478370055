import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Direccion } from 'src/app/cliente/direccion.model';
import { ProductoSimple } from '../../models/producto-simple.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { PedidoSimple } from '../../models/pedido-simple.model';
import { Pedido } from '../../models/pedido.model';
import { BackendService } from 'src/app/core/backend.service';
import { PedidoChileDto } from '../../models/pedido-chile-dto';
import { Parametric } from 'src/app/cliente/parametric.model';
import { Localidad } from '../../models/localidades.model';
import { Partido } from '../../models/partidos.model';
import { ApiService } from 'src/app/core/api.service';
import { PaisEnum } from 'src/app/core/constants/pais-enum';
import { CostoEnvio } from '../../models/costo-envio';
import { Producto } from 'src/app/productos/models/producto.model';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { ModalsService } from 'src/app/shared-services/modals.service';
import { WebpayUrlToken } from '../../webpay-urlToken.model';
import { MercadoPagoPreferenceDto } from '../../models/mercado-pago-preference-dto';
import { Gtag } from 'angular-gtag';
import { ProductoService } from 'src/app/productos/service/producto.service';
import { DescuentoPedido } from '../../models/descuento-pedido.model';
import { ProductoEnviar } from 'src/app/productos/viejo/productoEnviar.model';
import { NotificacionesComunicationService } from 'src/app/shared-services/notificaciones-comunication.service';
import { PaisService } from 'src/app/shared-services/pais.service';
import { Perfil } from 'src/app/shared-components/perfil-usuario/models/perfil-usuario.model';

declare const swal: any;
enum CheckBoxType {FACTURA = 1, BOLETA = 2, NONE= null};

@Component({
  selector: 'app-resultado-producto-pago-adicionales-cl',
  templateUrl: './resultado-producto-pago-adicionales-cl.component.html',
  styleUrls: ['./resultado-producto-pago-adicionales-cl.component.scss']
})
export class ResultadoProductoPagoAdicionalesClComponent implements OnInit {

  form: FormGroup;
  formCodigoDescuento: FormGroup;
  telefonoPattern = "^\\d+$";
  textoPattern= "[A-Za-z]+[ A-za-z]*";
  numberPattern = /^([0-9][0-9]*)$/;
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,20}\s*$/;

  direccionesPerfil: Direccion[] = null;

  pedido: Partial<PedidoChileDto> = {};
  productos: ProductoSimple[];
  pedidoSimple: PedidoSimple;
  public perfil: Perfil;

  check_box_type = CheckBoxType;
  currentlyChecked: CheckBoxType;

  public descuentoAplicablePedido: DescuentoPedido = null;

  public regiones: Parametric[];
  public provincias: Partido[];
  public comunas: Localidad[];

  codigoIngresado = true;
  public tipoUsuarioNotSelected = true;
  public iva: number = 1.19;
  costoEnvio: number;
  costoEnvioIva: number;
  estadoAdicionales:number = 14;
  estadoPagado:number=8;
  rutFacturadorSize: number;
  rutSolicitanteSize: number;
  public selectPais:string;
  public subioLogosCurvas: boolean = false;
  public cantidadDeLogos:number = 0;
  linkProductoAgregado : string = '/new-pedidos/checkout-success/';
  codigoDescuentoEnviar: string;
  pedidoCompletado:Pedido;

  public subtotal = 0;
  public subtotalSinIva = 0;

  webpayUrlToken: WebpayUrlToken;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private seguridadService: SeguridadService,
    private backendService: BackendService,
    private apiService: ApiService,
    private toast: ToastService,
    private modalService: ModalsService,
    private gtag: Gtag,
    private productoService: ProductoService,
    private notificacionesComunicationService: NotificacionesComunicationService,
    private paisService:PaisService
  ) { }

  async ngOnInit() {

    this.selectPais = localStorage.getItem('pais');
    if(this.selectPais == null){
      await this.paisService.detectCountry().then(a=>{
        this.selectPais = localStorage.getItem('pais');
      });
    }

    this.spinner.show();

    this.buildForm();
    const user = this.seguridadService.user;

    await this.backendService.get(PedidoSimple, Pedido.new_path + 'pedido-simple/'+ this.route.snapshot.paramMap.get("id")).toPromise().then(a =>{
      this.pedidoSimple = a as PedidoSimple;
    });
    this.setFormCompleted();
    await this.setDatosIniciales();
    this.getRegiones();

    if(this.pedidoCompletado.descuentoPedido){
      this.aplicarDescuento(this.pedidoCompletado.descuentoPedido)
    }

    this.spinner.hide();
  }

  buildForm(){
    this.form = this.formBuilder.group({
      nombreEnvio: [null, [Validators.required]],
      apellidoEnvio: [null, [Validators.required]],
      email:  [null, [Validators.required,Validators.pattern(this.emailPattern)]],
      documentoSolicitante: [null, [Validators.required,Validators.pattern(this.numberPattern),Validators.minLength(8)]],
      documentoFacturador: [null, [Validators.required,Validators.pattern(this.numberPattern),Validators.minLength(8)]],
      telefono: [null, [Validators.required,Validators.pattern(this.numberPattern),Validators.minLength(6)]],
      direccion: [null, [Validators.required]],
      altura:  [null, [Validators.required]],
      piso:[null, ],
      dpto:[null, ],
      entreCalle1:[null, [Validators.required]],
      entreCalle2:[null, [Validators.required]],
      region:[null, [Validators.required]],
      provincia: [null, [Validators.required]],
      comuna: [null, ],
      tipoFacturacion:[null, Validators.required],
      obs: [null, ]
    });

    this.formCodigoDescuento = this.formBuilder.group({
      codigoDescuento:[null, ],
    });
  }

  async setFormCompleted(){
    this.route.data.subscribe(data => {
      this.pedidoCompletado = data.pedido as Pedido;
    });

    await this.getProvincias(this.pedidoCompletado.provincia);
    await this.getComunas(this.pedidoCompletado.partido);

    this.form.patchValue({
      telefono : this.pedidoCompletado.telefono!=null ? this.pedidoCompletado.telefono.toString() : '',
      email : this.pedidoCompletado.email,
      direccion : this.pedidoCompletado.direccion,
      altura : this.pedidoCompletado.altura,
      cp : this.pedidoCompletado.cp !=null ? this.pedidoCompletado.cp.toString() : '',
      piso : this.pedidoCompletado.piso,
      dpto : this.pedidoCompletado.dpto,
      region : this.pedidoCompletado.provincia,
      provincia : this.pedidoCompletado.partido,
      comuna : this.pedidoCompletado.localidad,
      entreCalle1 : this.pedidoCompletado.entreCalle1,
      entreCalle2 : this.pedidoCompletado.entreCalle2,
    })
  }

  public errorHandling = (control: string, error: string) => { 
    return this.form.controls[control].hasError(error);
  }

  getDescriptions(colores) {
    if(colores)
      return colores.map(c => c.description).join(', ');

    return "No hay colores."
  }

  validateDocumento(numero:number){
    return  (numero > 4 || numero < 20)
  }

  seteoDatosPedido(){
    this.pedido.nombreEnvio = this.form.controls.nombreEnvio.value;
    this.pedido.apellidoEnvio = this.form.controls.apellidoEnvio.value;
    this.pedido.telefono = this.form.controls.telefono.value;
    this.pedido.email = this.form.controls.email.value;
    this.pedido.direccion = this.form.controls.direccion.value;
    this.pedido.altura = this.form.controls.altura.value;
    this.pedido.piso = this.form.controls.piso.value;
    this.pedido.rutFacturador = this.form.controls.documentoFacturador.value;
    this.pedido.rutSolicitante = this.form.controls.documentoSolicitante.value;
    this.pedido.dpto = this.form.controls.dpto.value;
    this.pedido.region = this.form.controls.region.value;
    this.pedido.provincia = this.form.controls.provincia.value;
    this.pedido.comuna = this.form.controls.comuna.value;
    this.pedido.entreCalle1 = this.form.controls.entreCalle1.value;
    this.pedido.entreCalle2 = this.form.controls.entreCalle2.value;
    this.pedido.tipoFacturacionId = this.form.controls.tipoFacturacion.value;
  }
  
  async getSubtotal() {

    for (let i = 0; i < this.productos.length; i++) {
      this.subtotal = this.subtotal + this.productos[i].cantidad * (this.productos[i].precioUnitario * this.iva + (this.productos[i].precioAdicional * this.productos[i].cantidadLogos));
      this.subtotalSinIva = this.subtotalSinIva + this.productos[i].cantidad * (this.productos[i].precioUnitario + (this.productos[i].precioAdicional * this.productos[i].cantidadLogos));
    }
    this.subtotal = Math.ceil(this.subtotal);
    this.subtotalSinIva = Math.ceil(this.subtotalSinIva);
  }

  async setDatosIniciales(){
    this.pedido.id = this.pedidoSimple.id;
    this.productos = this.pedidoSimple.productos
    this.getSubtotal();
    this.cambiarCostoEnvio(0);
  }

  async getRegiones() {
    this.regiones = await this.apiService.get(Parametric, Parametric.path + 'provincias/'+PaisEnum.CHILE, null).toPromise();
  }

  async getProvincias(regionName){
    this.spinner.show();
    this.backendService.get(Partido, CostoEnvio.path + "departamentos/" + regionName, null).toPromise().then(part =>{
      this.provincias = part as Partido[];
      this.spinner.hide();
    });
    this.provincias = null;
    this.comunas = null;
    this.form.patchValue({provincia: null})
    this.form.patchValue({comuna: null})
  }

  async getComunas(provinciaId){
    this.spinner.show();
    this.backendService.get(Localidad, CostoEnvio.path + "localidades/" + provinciaId, null).toPromise().then(a =>{
      this.comunas = a as Localidad[];
      this.spinner.hide();
    });
    this.form.patchValue({comuna: null})
  }

  cambiarCostoEnvio(codPostal){
    if(codPostal != null){
    this.backendService.get(CostoEnvio,CostoEnvio.path+codPostal).toPromise()
      .then((costo: CostoEnvio) =>{
        this.costoEnvio = costo.costoEnvio;
        this.costoEnvioIva = this.getPrecioConIva(this.costoEnvio);
        this.alertaCambioCostoEnvio(this.costoEnvio);
      })
      .catch((e) =>{
        this.costoEnvio= null
        this.toast.openErrorGenerico("CÓDIGO POSTAL INVALIDO");
      });
    }
  }

  getPrecioConIva(precio:number){
    var numeroIva = this.round(precio * this.iva);
    return numeroIva;
  }

  round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
  }

  alertaCambioCostoEnvio(costoEnvio: number){
    if(this.costoEnvio !=null){
      //this.toast.openSuccessGenerico("AHORA EL COSTO DE ENVIO ES: $"+ costoEnvio);
      this.toast.openSuccessGenerico("BONIFICAMOS TU COSTO DE ENVÍO");
    }else{
      this.toast.openErrorGenerico("CÓDIGO POSTAL INVALIDO");
    }
  }

  validateForm(){
    const invalid = [];
    const controls = this.form.controls;
    for(const name in controls){
      if(controls[name].invalid){
        invalid.push(name);
      }
    }
    if(invalid.length != 0){
      var errMsg = "Ingrese correctamente el campo: ";
      for(var a = 0; invalid.length > a; a++){
        errMsg += invalid[a] + ", ";
      }
      this.formatAndShowErrMsg(errMsg);
      return;
    }else if((this.form.controls.documentoFacturador.value.toString().length < 9) || (this.form.controls.documentoSolicitante.value.toString().length < 9)){
      var errMsg = "Ingrese correctamente el campo: ";
      if(this.form.controls.documentoFacturador.value.toString().length < 9){
        errMsg += "documentoFacturador, ";
      }
      if(this.form.controls.documentoSolicitante.value.toString().length < 9){
        errMsg += "documentoSolicitante, ";
      }
      this.formatAndShowErrMsg(errMsg);
      return;
    }
    if(this.form.valid && this.costoEnvio != null){

      this.seteoDatosPedido();
      this.validarPrecios();
      this.finalizarPedido()
      
    }
  }

async validarPrecios(){
    if(this.codigoIngresado){
      try{
        await this.backendService.update(Producto.new_path + 'validar-precios', this.productos);
        this.toast.openSuccessGenerico("Validación concretada");
      }catch (ex) {
        console.log(ex.message)
        window.scrollTo(0,0);
      }
    }
  }

  async finalizarPedido() {

    const tipo = null;
    this.pedido.tipoEnvio = null;
    this.pedido.costoEnvio = this.costoEnvio;
    this.pedido.subtotal = this.subtotal + this.costoEnvio;
    if((this.subtotal+this.costoEnvioIva) <30000){
      this.toast.openErrorGenerico("Recordá que nuestra compra mínima es de $30.000. si querés comprar otros productos agregalos a tu carrito");
      return;
    }

    var t = this.pedido.rutSolicitante;
    var u = this.pedido.rutFacturador;
    this.rutFacturadorSize = t.length;
    this.rutSolicitanteSize = u.length;

  if(t.length < 8 || t.length>9){
    return;
  }
  if(u.length < 8 || u.length>9){
    return;
  }
    if (this.pedido.direccion !== undefined && this.pedido.direccion !== '' && this.pedido.direccion !== null) {  
      this.procesoPagoFinalizarPedido(tipo);
    } else {
      this.toast.openErrorGenerico('Por favor, introduzca la dirección donde se enviará el pedido.');
    }
  }

  procesoPagoFinalizarPedido(tipo){
    const res = this.modalService.elegirMetodoDePago(PaisEnum.CHILE);
      res.subscribe(async (value) => {
        if(value){
          const metodoDePago = this.productoService.getMetodoDepago();
          if(metodoDePago == "transferencia"){
            this.pedido.transferenciaAprobada = false;
            this.spinner.show();

            try{
              await this.backendService.update(Pedido.new_path + 'cerrar-pedido-chile-adicionales', this.pedido).then(a=>{
                this.pedido = a;
              });

              const ress = await this.modalService.transferenciaBancaria(this.pedido, this.pedido.nombreEnvio + " " + this.pedido.apellidoEnvio, null);
              ress.subscribe(async (value) =>{
                if(value){
                  this.purchase();
                  this.actualizarCantidadLogosConCurvas();
                  if(this.cantidadDeLogos > 0){
                    const confirmado = await this.modalService.OpenModalSubirLogoConCurvas(this.pedido.id, this.cantidadDeLogos)
                    await confirmado.subscribe(async (value) =>{
                      if(value){
                        this.subioLogosCurvas = value;
                      }
                      this.router.navigateByUrl('/mis-pedidos');
                    })
                  }else{
                    this.modalService.openModalComprobanteSubido();
                    this.router.navigateByUrl('/mis-pedidos');
                  }
                }
              })
            }
            catch (ex) {
              if (ex[0]) {
                this.toast.openErrorGenerico(ex[0].details);
              } else if (ex.errors) {
                this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
              } else {
                this.toast.openErrorGenerico(ex.message);
              }
            }
            finally{
              window.scrollTo(0,0);
              this.spinner.hide();
            }
            
          } else if(metodoDePago == "mercadoPago"){
            try {
              this.pedido.tipoEnvio = tipo && tipo.id.toString();
              this.spinner.show();
              
              await this.backendService.update(Pedido.new_path + 'cerrar-pedido-chile-adicionales', this.pedido).then((data: Pedido) => {
                this.pedido = data;
                this.pedido.subtotal = this.subtotal;
                if(this.descuentoAplicablePedido){
                  this.pedido.descuentoPedido = this.descuentoAplicablePedido;
                }
              });

              await this.backendService.update(Pedido.new_path +'cerrar-pedido/mercado-pago', this.pedido).then((data: MercadoPagoPreferenceDto) =>
              {
                this.purchase();
                window.location.href= data.initPoint;
              });
              } catch (ex) {
                if (ex[0]) {
                  this.toast.openErrorGenerico(ex[0].details);
                } else if (ex.errors) {
                  this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
                } else {
                  this.toast.openErrorGenerico(ex.message);
                }
              } finally {
                window.scrollTo(0,0);
                this.spinner.hide();
              }
          }
          else if(metodoDePago == 'webpay'){

            try {
              this.pedido.tipoEnvio = tipo && tipo.id.toString();
              this.spinner.show();
              
              await this.backendService.update(Pedido.new_path + 'cerrar-pedido-chile-adicionales', this.pedido).then((data: Pedido) => {
                this.pedido = data;
                this.pedido.subtotal = this.subtotal;
                if(this.descuentoAplicablePedido){
                  this.pedido.descuentoPedido = this.descuentoAplicablePedido;
                }
              });

              this.purchase();
              await this.backendService.get(WebpayUrlToken,'/webpay/create/' + this.pedido.id).toPromise().then(a =>{
                  this.webpayUrlToken = a as WebpayUrlToken;
                  window.location.href = this.webpayUrlToken.url+'?token_ws='+this.webpayUrlToken.token;
                });
              } catch (ex) {
                if (ex[0]) {
                  this.toast.openErrorGenerico(ex[0].details);
                } else if (ex.errors) {
                  this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
                } else {
                  this.toast.openErrorGenerico(ex.message);
                }
              } finally {
                window.scrollTo(0,0);
                this.spinner.hide();
              }
          }

        }
      })
  }

  actualizarCantidadLogosConCurvas(){
    this.cantidadDeLogos =0;
    this.productos.forEach(prod =>{
      this.cantidadDeLogos = prod.cantidadLogos + this.cantidadDeLogos;
    })
  }

  purchase(){
    let itemsPurchase: any[] = [];
    this.productos.forEach((producto)=>{
      itemsPurchase.push({
        item_name: producto.nombre,
        item_id: producto.id,
        price: producto.precioUnitario,
        quantity: producto.cantidad
      });
    });

    this.gtag.event('purchase', {
      transaction_id: this.pedido.id,
      value: this.subtotal + this.costoEnvio,
      shipping: this.costoEnvio,
      currency: "CLP",
      items: itemsPurchase
    });
  }

  async verificarCodigo(){
    this.codigoDescuentoEnviar = this.formCodigoDescuento.controls.codigoDescuento.value;
    this.spinner.show();
    try{

      await this.backendService.get(DescuentoPedido, "/new-pedidos/codigo-valido/" + this.pedidoSimple.id + "/"+this.codigoDescuentoEnviar + "/" + this.selectPais).toPromise().then(part =>{


        this.descuentoAplicablePedido = part;

        var descuento = ((part.descuento*-1 /100) +1);
  
        part.productosAplicablesId.forEach(e => {
          
          this.productos.forEach(prod =>{
             if(e == prod.id){
               prod.precioConIva = this.round(prod.precioConIva * descuento);
               prod.precioUnitario = this.round(prod.precioUnitario * descuento);
             }
          })
        });
        this.subtotal = 0;
        this.subtotalSinIva= 0;
        this.getSubtotal();
      })
      this.pedido.descuentoPedido = this.descuentoAplicablePedido;
      this.toast.openSuccessGenerico("Código de Descuento Aplicado con Exito " + this.descuentoAplicablePedido.descuento + "%");
      this.codigoIngresado = false;
    }catch(ex){
      this.toast.openErrorGenerico(ex.message);
    }finally{
      this.spinner.hide();
    }
  }

  selectCheckBox(targetType: CheckBoxType){
    if(this.currentlyChecked === targetType){
      this.currentlyChecked = CheckBoxType.NONE;
      this.form.patchValue({tipoFacturacion: null})
      return;
    }
    this.currentlyChecked = targetType;
    this.form.patchValue({tipoFacturacion: this.currentlyChecked})
  }

  formatAndShowErrMsg(errMsg: string){

    errMsg = errMsg.replace("nombreEnvio","Nombre");
    errMsg = errMsg.replace("apellidoEnvio","Apellido");
    errMsg = errMsg.replace("email","Email");
    errMsg = errMsg.replace("documentoSolicitante","Rut Solicitante");
    errMsg = errMsg.replace("documentoFacturador","Rut Facturador");
    errMsg = errMsg.replace("telefono","Teléfono");
    errMsg = errMsg.replace("direccion","Dirección");
    errMsg = errMsg.replace("altura","Altura");
    errMsg = errMsg.replace("entreCalle1","Entre Calle 1");
    errMsg = errMsg.replace("entreCalle2","Entre Calle 2");
    errMsg = errMsg.replace("region","Región");
    errMsg = errMsg.replace("provincia","Provincia");
    errMsg = errMsg.replace("tipoFacturacion","Tipo de Facturación");
    errMsg = errMsg.slice(0, errMsg.length -2);

    this.toast.openErrorGenerico(errMsg);

  }

  aplicarDescuento(descuentoPedido: DescuentoPedido){
    this.spinner.show();
    try{

      this.descuentoAplicablePedido = descuentoPedido;

      var descuento = ((descuentoPedido.descuento*-1 /100) +1);
  
      descuentoPedido.productosAplicablesId.forEach(e => {
          
          this.productos.forEach(prod =>{
            
             if(e == prod.id){
               prod.precioConIva = this.round(prod.precioConIva * descuento);
               prod.precioUnitario = this.round(prod.precioUnitario * descuento);
             }
          })
        });
  
      this.subtotal = 0;
      this.subtotalSinIva= 0;
  
      this.getSubtotal();

      this.pedido.descuentoPedido = this.descuentoAplicablePedido;

      this.toast.openSuccessGenerico("Código de Descuento Aplicado con Exito " + this.descuentoAplicablePedido.descuento + "%");
      this.codigoIngresado = false;

    }catch(ex){

      this.toast.openErrorGenerico("El Código Ingresado es Inválido");

    }finally{

      this.spinner.hide();

    }
  }

  roundUp(price:number){
    return Math.ceil(price);
  }

  async changeTipoUsuario(){
    try{
      this.spinner.show();
      const user = this.seguridadService.user;
      this.perfil = await this.backendService.get(Perfil, Perfil.path).toPromise();
      this.form.patchValue({
        nombreEnvio :  this.perfil.name,
        apellidoEnvio : this.perfil.lastname,
        telefono : this.perfil.telefonoPrincipal!=null ? this.perfil.telefonoPrincipal.toString() : '',
        email : user.email,
        direccion : this.perfil.direccion,
        altura : this.perfil.numero,
        piso : this.perfil.piso,
        dpto : this.perfil.dpto,
        comuna : this.perfil.localidad,
        provincia : this.perfil.partido,
        region : this.perfil.provincia,
        entreCalle1 : this.perfil.entreCalle1,
        entreCalle2 : this.perfil.entreCalle2,
        documentoSolicitante : this.perfil.documento,
        tipoDocumentoId : this.perfil.tipoDocumentoId!=null ? this.perfil.tipoDocumentoId.toString() : '0',
        });

        this.seteoDatosPedido();
    }catch(ex){
      if (ex[0]) {
        this.toast.openErrorGenerico(ex[0].details);
      } else if (ex.errors) {
        this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
      } else {
        this.toast.openErrorGenerico(ex.message);
      }
    }
    finally{
      this.spinner.hide();
      this.tipoUsuarioNotSelected = false;
    }
}

}
