import { Component, HostListener, OnInit } from '@angular/core';
import { Producto } from '../models/producto.model';
import { Observer, Observable, Subscription, fromEvent } from '../../../../node_modules/rxjs';
import { SeguridadService } from '../../seguridad/seguridad.service';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';
import { BackendService } from '../../core/backend.service';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { Imagen } from '../viejo/imagen.model';
import { ClienteService } from '../../core/cliente.service';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';
import { NotificacionesComunicationService } from '../../shared-services/notificaciones-comunication.service';
import { Color } from '../color.model';
import { Cliente } from 'src/app/cliente/cliente.model';
import { MinimoMaximo } from 'src/app/shared-components/header/minimo-maximo.model';
import { CantidadesTotal } from 'src/app/shared-components/header/cantidades-total.model';
import { Router } from '@angular/router';
import {ImagenBanner} from '../../shared-components/banners/imagenBanner.model';
import { Categoria } from '../../shared-components/dashboard/categoria.model';
import {ProductoStock} from '../dtos/producto-stock';
import {ProductoParticularStock} from '../producto-particular/dtos/producto-particular-stock';
import {ProductoListado} from './dtos/producto-listado';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { Gtag } from 'angular-gtag';
import { ProductoTop3 } from './producto-top3';
import { PaisService } from 'src/app/shared-services/pais.service';
import { debounceTime } from 'rxjs/operators';



interface Filters {
  page: number;
  size: number;
}

interface Page {
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-lista-productos',
  templateUrl: './lista-productos.component.html',
  styleUrls: ['./lista-productos.component.scss']
})
export class ListaProductosComponent implements OnInit {
  

  subscriptions: Subscription[] = [];
  varianteSeleccionada : ProductoStock;
  productoParticular: ProductoParticularStock;
  private imagenesLista;
  private loaded = false;
  private colorFilter: string;
  isLoading = false;
  totalItems = 0;
  private productosLista;
  public top3productos: ProductoTop3[];
  public productos: ProductoListado[] = [];
  categoria : Categoria;
  public busqueda = {searchText: '', colorText: '', precioMinText: '', precioMaxText: '', subCatText: '', categoria: undefined, orden: ''};

  //Propiedades de filtrado
  rangoSeleccionado: MinimoMaximo = {minimo: undefined, maximo: undefined, seleccionado: true };
  link = this.router.url + '?';
  newLink: string;
  cantTotalGasto: CantidadesTotal;
  subcategoria: number;
  home: boolean;
  scrollDistance = 2;
  
  previousUrl: string;
  //rangoDePrecio
  public precioMinMax: MinimoMaximo = {minimo: 0, maximo: 999999, seleccionado: false}


  rangoParticular: MinimoMaximo = {minimo: undefined, maximo: undefined, seleccionado: false };

  user: any;
  hayProductos = 0;

  isMobile: boolean;
  
  color: string;
  colores: Color[];
  //colores5primeros: any;
  coloresAMostrar: Color[];

  private filters: Filters = {
    page: 0,
    size: 10,
  };

  orden: string = "Destacados";
  private currentPage = this.filters.page + 1;
  pageSizeOptions: number[] = [20, 25, 30];
  selectPais: string;
  productoIntermedio: ProductoListado[] = [];
  productoViewItemList: ProductoListado[] = [];
  mostrarMensaje: boolean = false;
  loading = false;

  paginaAnterior = null;

  constructor(
    public router: Router,
    public seguridadService: SeguridadService,
    private spinner: NgxSpinnerService,
    private backendService: BackendService,
    private route: ActivatedRoute,
    private toast: ToastService,
    private sanitizer: DomSanitizer,
    private clienteService: ClienteService,
    public notificacionesComunicationService: NotificacionesComunicationService,
    private gtag: Gtag,
    private paisService:PaisService
  ) {
  }

  async ngOnInit() {

    this.isMobile = window.innerWidth < 768;

    window.scrollTo(0, 0);

    this.selectPais = localStorage.getItem('pais');

    if(this.selectPais == null){
      await this.paisService.detectCountry().then(a=>{
        this.selectPais = localStorage.getItem('pais');
      });
    }

    await this.route.data.subscribe(data => {
      this.busqueda = data.productos;
      this.busqueda.categoria = undefined;
    });
    
    await this.getProductoList();
    //COLORES await this.getColores();

    this.precioMinMax.minimo = null;
    this.precioMinMax.maximo = null;

    //COLORES this.colores = this.colores.slice(0,5);
    this.productosLista.subscribe(t => {
      this.isLoading = false;
      this.productoIntermedio = t;
      this.productos = this.productos.concat(this.productoIntermedio);
      this.totalItems = t['totalItems'];
      for(var i = 0; i < 5; i++){

        this.onScrollDownOnInit();
      }
    });

    if (this.productosLista.length == 1) {
      this.varianteSeleccionada = this.productoParticular.lista[0];
    }

    /*if (this.color) {
      this.colores.map(c => c.colorSeleccionado = false);
      const colorSeleccionado = this.color.replace('-', ' ');
      const color = this.colores.find(c => c.description === colorSeleccionado);
      color.colorSeleccionado = true;
      const colo = document.getElementById('header' + color.id);
      if(colo.style){
        colo.style.backgroundImage = 'url(/assets/images/check-icn.svg)';
        colo.style.backgroundRepeat = 'no-repeat';
        colo.style.backgroundPosition = 'center';
      }
    }*/
    await this.filtrosBusqueda();
    await this.getCategoria();
    await this.refreshListOnInit().then(a=>{
      
    });
    
    
   // this.setupScrollEvent();
  }

  changePagesize(pageSize) {

    this.filters.size = pageSize;
    this.refreshList();
  }

  getFilters() {
    return this.filters;
  }

  async refreshList() {
    this.mostrarMensaje = false;
    await this.productosLista.next(this.getFilters());
    //await this.filtrosBusqueda();
    //await this.setTop3();
    this.viewItemList();
    this.currentPage = 0;
  }

  async refreshListOnInit(){
    await this.productosLista.next(this.getFilters());
    //await this.filtrosBusqueda();
    //await this.setTop3();
    this.viewItemList();
    //this.currentPage = 0;
  }

  refreshRoleList() {
    this.loading = true;
    //this.spinner.show();
    //window.scrollTo(0,0);
    this.productosLista.next(this.getFilters());
    this.viewItemList();
  }

  onPageChange(page: number) {
    this.filters.page = page - 1;
    this.gtag.event('change_page', {
      items: [{
        item_name: "change_page lista producto"
      }]
    });
    this.refreshRoleList();
  }


  async getProductoList() {
    
    this.productosLista = await this.backendService.newSubjectWithoutDistinct<Filters>()
      .switchMap(filters => {
        const usersPromise = this.backendService
          .get(Producto, '/productos/' + this.selectPais + "/" +
            this.busqueda.searchText + '&' + this.busqueda.subCatText + '&' + this.busqueda.colorText + '&'
            + this.busqueda.precioMinText + '&' + this.busqueda.precioMaxText + '&' + this.busqueda.orden, filters).toPromise();
        return (async () => {
          this.isLoading = true;
          try {
            const permissions = await usersPromise;
            return permissions;
          } catch (error) {
            return [];
          } finally {
            this.listaEstaVacia();
            this.isLoading = false;
            this.loading = false;
          }
        })();
      }).share() as Observer<Filters> & Observable<Producto[]>;
  }

  getImages(imagen) {
    this.clienteService.getImagenById(imagen.id).subscribe(data => {
      const urlCreator = window.URL;
      const safeUrl: any = this.sanitizer.bypassSecurityTrustUrl(
        urlCreator.createObjectURL(data));
      imagen.url = safeUrl;
    });
  }

  //Logica filtro colores
  async getColores() {
    await this.backendService.getNumber(Cliente.path + 'colores').then(
      data => {
        this.colores = data as Color[];
        this.colores.forEach(element => {
          element.colorSeleccionado = false;
        });
      }
    );
  }

  async onSearch() {
    this.currentPage = 0;
    this.actualizarLink();
    this.actualizarNewLink();
    const reload = this.router.url.startsWith('/lista-productos');
    await this.router.navigateByUrl(this.newLink);
    if (reload) {
      const filtros = this.busqueda.searchText + '&' + this.busqueda.subCatText + '&' + this.color + '&'
      + this.busqueda.precioMinText + '&' + this.busqueda.precioMaxText + '&' + this.busqueda.orden;
      this.notificacionesComunicationService.filtersChange(filtros);
    }    
  }

  elegirColor(color: Color) {
    this.colores.map(c => c.colorSeleccionado = false);
    this.colores.find(c => c.id === color.id).colorSeleccionado = true;
    this.color = color.description;
    this.colores.forEach(c => {
      const colo = document.getElementById('header' + c.id);
      if (c.id === color.id) {
        if(colo.style){
        colo.style.backgroundImage = 'url(/assets/images/check-icn.svg)';
      }
      } else {
        colo.style.backgroundImage = 'none';
      }
      colo.style.backgroundColor = c.code;
    });
    this.onSearch();
  }

  changePrecio() {
    
    if(this.precioMinMax.minimo == null || isNaN(this.precioMinMax.minimo)){
      this.busqueda.precioMinText = undefined;
      this.precioMinMax.minimo = 0;
    } else{
      this.busqueda.precioMinText = this.precioMinMax.minimo.toString();
    }
    
    if(this.precioMinMax.maximo == null || isNaN(this.precioMinMax.maximo)){
      this.busqueda.precioMaxText = undefined;
      this.precioMinMax.maximo = 999999;
    } else {
      this.busqueda.precioMaxText = this.precioMinMax.maximo.toString();
    }
    
    if(this.precioMinMax.minimo < 0 || this.precioMinMax.maximo < 0 ){

      this.toast.openErrorGenerico('Los precios no pueden ser menores a 0');

    }else if( this.precioMinMax.minimo > this.precioMinMax.maximo){

      this.toast.openErrorGenerico('El precio mínimo debe ser menor que el máximo');

    }else{
      this.onSearch();
    }

  }

  borrarFiltros() {
    this.currentPage = 0;
    this.clearColor();
    this.clearRangoPrecio();
    this.clearNombre();
    this.clearCategoria();
    this.onSearch();
  }

  clearColor() {
    this.busqueda.colorText = '';
    this.color=undefined;
  }

  clearRangoPrecio() {
    this.clearPrecioMinimo();
    this.clearPrecioMaximo();
  }

  clearPrecioMinimo(){
    this.busqueda.precioMinText = undefined;
    this.precioMinMax.minimo = null;
  }

  clearPrecioMaximo(){
    this.busqueda.precioMaxText = undefined;
    this.precioMinMax.maximo = null;
  }

  clearNombre(){
    this.busqueda.searchText = undefined;
  }

  clearCategoria(){
    this.busqueda.categoria = undefined;
    this.busqueda.subCatText = undefined;
  }

  actualizarLink(){
    this.link = '/lista-productos?';
    if(this.busqueda.searchText !== undefined && this.busqueda.searchText !== 'undefined'){
      this.link += 'search=' + this.busqueda.searchText + '&';
    }
    if(this.busqueda.subCatText !== undefined && this.busqueda.searchText !== 'undefined'){
      this.link += 'subCat=' + this.busqueda.subCatText + '&';
    }
  }

  actualizarNewLink() {
    this.newLink = this.link;
    this.newLink += this.color ? 'color=' + this.color.replace(' ', '-') + '&' : '';
    if(this.busqueda.precioMinText && this.busqueda.precioMinText !== 'undefined'){
      this.newLink += 'precioMin=' + this.busqueda.precioMinText + '&';
    }
    if(this.busqueda.precioMaxText && this.busqueda.precioMaxText !== 'undefined'){
      this.newLink += 'precioMax=' + this.busqueda.precioMaxText;
    }
    if(this.busqueda.orden && this.busqueda.orden !== 'undefined'){
      this.newLink += 'orden=' + this.busqueda.orden;
    }
  }

  async getCategoria(){
    this.filters.page = 0;
    this.mostrarMensaje = false;
    if(this.busqueda.subCatText && this.busqueda.subCatText !== 'undefined'){
      await this.backendService.get(Categoria,'/new_categorias/descripcion/'+ this.busqueda.subCatText).toPromise().then(
        c => {
          if(c){
            this.busqueda.categoria = c as Categoria;
          }
      });
    } else {
      this.busqueda.categoria = null;
    }
    this.productos = [];
  }

  async getFiles(d: Imagen) {
    await this.clienteService.getFileById(d.id).subscribe(data => {
      this.createImageFromBlob(data, d);
    }, error => {
      console.log(error);
    });
  }

  async createImageFromBlob(image: Blob, d: Imagen) {
    const reader = new FileReader();
    await reader.addEventListener('load', () => {
      d.url = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  //No se usa
  getPrecioConIva(precio:number){
    
    
    var numeroIva = this.round(precio * 1.21)
    
    return numeroIva

  }

  round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
  }

  onProductClick(producto: ProductoListado) {
    this.gtag.event('select_item', {
      items: [{
        item_name: producto.nombre,
        item_id: producto.id,
        price: producto.precio
      }]
    });
  }

  viewItemList(){
    let itemList: any[] = [];
    this.productosLista.subscribe(t => {
      this.productoViewItemList = t;
      if(this.productoViewItemList.length > 0){
        this.productoViewItemList.forEach((producto)=>{
          itemList.push({
            item_name: producto.nombre,
            item_id: producto.id,
            price: producto.precioFinal
          });
        });
        this.gtag.event('view_item_list', { items: itemList });
      }
    });
  }

  limitarCaracteres(string:string){
    var nuevoString:string;
    if(string.length > 20){
      nuevoString = string.substring(1,20) + "..."
    }else{
      nuevoString = string;
    }

    return nuevoString;

  }

  async filtrosBusqueda(){
    
    await this.subscriptions.push(this.notificacionesComunicationService.onCambioFiltros.subscribe(async filtros => {
      this.loaded = false;
      if (filtros !== null) {
        this.colorFilter = filtros.split('&')[2];
        this.colorFilter = this.colorFilter === 'undefined' ? null : this.colorFilter;
        this.busqueda.colorText = this.colorFilter;
        this.busqueda.searchText = filtros.split('&')[0];
        this.busqueda.subCatText = filtros.split('&')[1];
        this.busqueda.precioMinText = filtros.split('&')[3];
        this.precioMinMax.minimo = +this.busqueda.precioMinText;
        this.busqueda.precioMaxText = filtros.split('&')[4];
        this.precioMinMax.maximo = +this.busqueda.precioMaxText;
        await this.getCategoria();
      } else {
        this.busqueda = {searchText: '', colorText: '', precioMinText: '', precioMaxText: '', subCatText: '', categoria: undefined, orden: ''};
      }
      this.refreshList();
      this.loaded = true;
    }
  ));
  }

  changeOrden(orden:string){
    this.orden = orden;
    this.filters.page = 0;
    ///this.currentPage = 0; --> Se repite el 0 2 veces después de actualizar los productos y cambiar el filtro.
    if(this.orden == "Destacados"){
      this.busqueda.orden = 'destacados'
    }
    
    if(this.orden == 'Menor precio'){
      this.busqueda.orden = 'asc';
    } 
    if(this.orden == 'Mayor precio'){
      this.busqueda.orden = 'desc';
    }
    
    this.productos = [];
    this.refreshList();
  }

  async setTop3(){
    var subcategoriaText = this.busqueda.subCatText;
    if(this.busqueda.subCatText == "undefined" || this.busqueda.subCatText == "null" || this.busqueda.subCatText == null || this.busqueda.subCatText == undefined){
      subcategoriaText = '0'
    }
    await this.backendService
          .get(ProductoTop3, '/new-productos/top3/' + this.selectPais + "/" +
            this.busqueda.searchText + '&' + subcategoriaText + '&' + this.busqueda.colorText + '&'
            + this.busqueda.precioMinText + '&' + this.busqueda.precioMaxText + '&' + this.busqueda.orden, null).toPromise().then( a => {
              this.top3productos = a as ProductoTop3[];
            });
    if(this.top3productos.length != 3){
      this.top3productos = undefined;
    }
  }

  onScrollDownOnInit(){
    debugger
    if(!this.loading){
      if (this.productos.length < this.totalItems) {
        this.loading = true;
        this.currentPage = this.currentPage+1;
        if (this.currentPage == 1)
          this.currentPage = 2;
        this.onPageChange(this.currentPage);
      }
    }
    
  }
  
  async onScrollDown() {
    if(!this.loading){
      if (this.productos.length < this.totalItems) {
        this.loading = true;
        this.currentPage = this.currentPage+1;
        if (this.currentPage == 1)
          this.currentPage = 2;
        this.onPageChange(this.currentPage);
      }
    }
    
  }

  listaEstaVacia () {
    if (this.productos.length === 0) 
      this.mostrarMensaje = true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth < 768;
  }

  /*@HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollY = window.scrollY || window.pageYOffset;
    const visibleHeight = window.innerHeight;
    const pageHeight = document.documentElement.scrollHeight;
    const remainingScroll = pageHeight - (scrollY + visibleHeight);

    const scrollThreshold = 10;

    if (remainingScroll < scrollThreshold) {
      this.onScrollDown();
    }
  }*/

  /*private setupScrollEvent() {
    const scroll$ = fromEvent(window, 'scroll').pipe(debounceTime(200)); // Adjust debounce time as needed
  
    this.subscriptions.push(
      scroll$.subscribe(() => {
        this.onWindowScroll();
      })
    );
  }*/
}
