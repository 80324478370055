import { BackendModel } from "src/app/core/backend.service";
import { Categoria } from "../dashboard/categoria.model";

export class BannerDto extends BackendModel{
    id:number;
    path:string;
    nombre:string;
    es_desktop: string;
    categoriaProducto:Categoria;

}