import { Component, OnInit } from '@angular/core';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';
@Component({
  selector: 'app-precio-envio',
  templateUrl: './precio-envio.component.html',
  styleUrls: ['./precio-envio.component.scss']
})
export class PrecioEnvioComponent extends Toast {

  constructor(protected toastrService: ToastrService,
    public toastPackage: ToastPackage,) {
super(toastrService, toastPackage);
}

action(event: Event) {
event.stopPropagation();
this.toastPackage.triggerAction();
return false;
}

}
