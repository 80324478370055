import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { BackendService } from 'src/app/core/backend.service';
import { PaymovilResponse } from 'src/app/productos/resultado-producto/models/paymovil-response-dto';
import { PaymovilPago } from 'src/app/productos/resultado-producto/models/tarjeta-pago-dto';
import { NgxSpinnerService } from 'node_modules/ngx-spinner';
import { Pedido } from 'src/app/productos/resultado-producto/models/pedido.model';
import { Router } from '@angular/router';
import { NotificacionesCarrito } from 'src/app/shared-services/notificacion-carrito.service';
import { ToastService } from 'src/app/shared-services/toast/toast.service'; 

@Component({
  selector: 'app-tarjeta',
  templateUrl: './tarjeta.component.html',
  styleUrls: ['./tarjeta.component.scss']
})
export class TarjetaComponent implements OnInit {

  public onClose: Subject<boolean>;

  datosPago: PaymovilPago = new PaymovilPago();

  amount:number;
  pedido:Pedido;

  numberPattern = /^([0-9][0-9]*)$/;
  textPattern = /^([A-z]*)$/;

  response:PaymovilResponse;

  constructor(public bsModalRef: BsModalRef,
    private backendService: BackendService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private notificacionesCarrito: NotificacionesCarrito,
    private toast: ToastService) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  verificarDatos(){

    let error = '';

    if (!this.datosPago.metodoPago) {
      error += ', ingrese un método de pago';
    }

    if (!this.datosPago.tarjetaNum) {
      error += ', ingrese un número de tarjeta';
    }else{
      if(!this.numberPattern.test(this.datosPago.tarjetaNum.toString())){
        error += ', ingrese un numero de tarjeta con formato válido';
      }
    }

    if(!this.datosPago.tarjetaExpiration){
      error += ', ingrese una fecha de expiración';
    }else{
      if(this.datosPago.tarjetaExpiration.length < 4){
        error += ', ingrese una fecha de expiración válida';
      }
    }

    if(!this.datosPago.tarjetaCode){
      error += ', ingrese el código de la tarjeta';
    }else{
      if(this.datosPago.tarjetaCode.length < 3 || !this.numberPattern.test(this.datosPago.tarjetaCode.toString())){
        error += ', ingrese un código válido';
      }
    }

    if (!this.datosPago.titularDni) {
      error += ', ingrese el DNI del titular de la tarjeta';
    }else{
      if(!this.numberPattern.test(this.datosPago.titularDni.toString())){
        error += ', ingrese un DNI con formato válido';
      }

      if (this.datosPago.titularDni.length < 5) {
        error += ', ingrese un DNI con minimo 5 digitos';
      }
    }

    if(!this.datosPago.cuotas){
      error += ', ingrese una cantidad de cuotas';
    }

    if (error.length > 0) {
      this.toast.openErrorGenerico('Por favor' + error);
      return;
    }

    this.datosPago.amount = this.amount.toString();
    this.datosPago.numPedido = this.pedido.id.toString();
    this.datosPago.tarjetaExpiration.toString();

    this.submitPago();

  }

  async submitPago(){
    this.spinner.show();

    try{

      await this.backendService.update('/paymovil/pago', this.datosPago).then((a: PaymovilResponse)=>{
        this.response = a as PaymovilResponse;
      });

    }catch(e){

      console.log(e.message);
      
    } finally{

      if(this.response.msg=="APROBADA" && this.response.status=="true"){

        this.notificacionesCarrito.emitChange(0);
        this.onClose.next(true);
        
      }else{
        this.toast.openErrorGenerico('Ha ocurrido un error en el pago.');
        this.onClose.next(false);
      }
      this.spinner.hide();
      this.bsModalRef.hide();
      
    }
  }

  setTipoTarjeta(tipoTarjeta){
    this.datosPago.metodoPago = tipoTarjeta;
    if(tipoTarjeta == 'debit'){
    this.datosPago.cuotas = '1';
    }
  }

  setCuotas(cuotas){
    this.datosPago.cuotas = cuotas;
  }

  isCredito(tipoPago){
    if(tipoPago == 'credit')
    return true;
  }

  cerrar(){
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  tarjetaNumChange(e){
    this.datosPago.tarjetaNum = e.toString();
  }

  tarjetaExpChange(e){
    this.datosPago.tarjetaExpiration = e;
  }

  titularNameChange(e){
    this.datosPago.titularName = e;
  }

  tarjetaCodeChange(e){
    this.datosPago.tarjetaCode = e.toString();
  }

  titularDniChange(e){
    this.datosPago.titularDni = e.toString();
  }

}
