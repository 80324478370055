import { Component, OnInit } from '@angular/core';
import { Anuncio } from '../../core/anuncio.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-anuncios-detalles',
  templateUrl: './anuncios-detalles.component.html',
  styleUrls: ['./anuncios-detalles.component.scss']
})
export class AnunciosDetallesComponent implements OnInit {

  public anunciosList: Anuncio[] = [];
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.anunciosList.push(data.anuncio);
    });
  }

}
