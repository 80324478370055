import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Color } from 'src/app/productos/color.model';
import { VarianteXColor } from '../../dtos/variante-x-color';
import { BsModalRef } from 'ngx-bootstrap';
import { SeleccionaVariante } from '../../dtos/selecciona-variante';

@Component({
  selector: 'app-seleccionar-variante-modal',
  templateUrl: './seleccionar-variante-modal.component.html',
  styleUrls: ['./seleccionar-variante-modal.component.scss']
})
export class SeleccionarVarianteModalComponent implements OnInit {
  public selectPais: string;
  public varianteSeleccionada: Color;
  public variantes: VarianteXColor[];
  public response: SeleccionaVariante = new SeleccionaVariante;
  public onClose: Subject<SeleccionaVariante>;
  constructor(public bsModalRef: BsModalRef) { }

  async ngOnInit() {
      this.selectPais = localStorage.getItem('pais');
      this.onClose = new Subject();
      
  }

  seleccionarVariante() {
    this.response.variante = this.varianteSeleccionada;
    this.response.selecciona = true
    this.onClose.next(this.response);
    this.bsModalRef.hide();
  }

  noConfirmaAceptado() {
    this.response.selecciona = false;
    this.onClose.next(this.response);
    this.bsModalRef.hide();
  }

}
