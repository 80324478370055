import { BackendService, BackendModel } from 'src/app/core/backend.service';

export class Categoria extends BackendModel {

  static path = '/categorias/';

  id: number;
  description: string;
  imagen: string;
  banner: string;
  subcategorias = [];

  constructor(protected backendService: BackendService, model?: Categoria) {
    super(backendService, model);
  }

}
