import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit, ElementRef, AfterContentInit, Renderer2, AfterViewChecked } from '@angular/core';
import { UltimasSesionesComponent } from '../ultimas-sesiones/ultimas-sesiones.component';
import { BackendService } from '../../core/backend.service';
import { FormValidatorServiceService } from '../../shared-services/form-validator-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observer, Observable, Subject, Subscription } from 'rxjs';
import { Sesion } from '../../core/sesion.model';
import { SeguridadService } from '../../seguridad/seguridad.service';
import { Operacion } from '../../core/operacion.model';
import { Saldo } from '../../core/saldo.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../core/api.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ModalsService } from 'src/app/shared-services/modals.service';
import { Anuncio } from 'src/app/core/anuncio.model';
import { NotificacionesComunicationService } from 'src/app/shared-services/notificaciones-comunication.service';
import { Categoria } from './categoria.model';
import { EmailContacto } from './email-contacto.model';
import { Cliente } from '../../cliente/cliente.model';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { ContactoUsuario } from 'src/app/seguridad/usuario/contacto-usuario.component';
import { Usuario } from 'src/app/cliente/usuario.model';

declare var jquery: any;
declare var $: any;

interface Filters {
  name: string;
  page: number;
  size: number;
  email?: string;
  search?: string;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, AfterContentInit {

  private mypURL = '/mediosyprensa';
  private soporteURL = '/contacto';
  private comisionesURL = '/comisiones';
  private notificacionesSinLeerAlerta = true;
  user: any;
  private notificaciones: any;
  private notificacionesImportantes: any[] = [];
  private avisarMasInfo = false;
  private notificacionesPopup: any[] = [];
  subscriptions: Subscription[] = [];

  categorias: any[];
  hideButton = false;

  emailContacto: EmailContacto;
  mensaje: string;
  email: string;
  dataUsuario: any = null;
  ejecutivo: ContactoUsuario;

  @ViewChild('notificacionesSinLeer') campanita: ElementRef;
  @ViewChild('avatar') avatar: ElementRef;

  ngAfterContentInit(): void {

  }

  constructor(
    private apiService: ApiService,
    private backendService: BackendService,
    private seguridadService: SeguridadService,
    private formValidatorService: FormValidatorServiceService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    public ngxSmartModalService: NgxSmartModalService,
    public modalService: ModalsService,
    private notificacionesComunicationService: NotificacionesComunicationService,
    private renderer: Renderer2,
    private toast: ToastService,
  ) {}

  ngAfterViewInit() {
    // this.avatar.nativeElement.click();
    // this.campanita.nativeElement.click();
    $.App.init();
  }

  async ngOnInit() {
    this.route.data.subscribe( data => {
      this.categorias = data.categorias;
    });
    /*await this.backendService.getString(Subcliente.path + 'info-email').then(data => {
      this.dataUsuario = data;
    });*/
    /*if (this.dataUsuario.email) {
      this.email = this.dataUsuario.email.direccion;
    }*/

    /*await this.backendService.get(ContactoUsuario, Usuario.path ).toPromise().then(
      data => this.ejecutivo = data
    );

    this.user = this.seguridadService.user;
    this.emailContacto = this.backendService.new(EmailContacto);*/
  }


  openNav() {
    document.getElementById("mySidepanel").style.width = "auto";
    document.getElementById('mySidepanel').style.borderColor = 'orange';
    document.getElementById('mySidepanel').style.borderStyle = 'solid';
    this.hideButton = true;
  }

  /* Set the width of the sidebar to 0 (hide it) */
  closeNav() {
    document.getElementById("mySidepanel").style.width = "0";
    document.getElementById('mySidepanel').style.borderColor = '';
    document.getElementById('mySidepanel').style.borderStyle = '';
    this.hideButton = false;
  }

  async enviarMail() {
    try {
      this.spinner.show();
      this.emailContacto.mensaje = this.mensaje;
      this.emailContacto.email = this.email;
      await this.backendService.update(Cliente.path + 'email-contacto', this.emailContacto );
      this.closeNav();
      this.modalService.aceptarModal('Mensaje enviado correctamente', 'Envio satisfactorio', 'Aceptar');
      this.mensaje = '';
      if (this.dataUsuario.email) {
        this.email = this.dataUsuario.email.direccion;
      }
    } catch (error) {
      this.toast.openErrorGenerico('Error al enviar el email.');
    } finally {
      this.spinner.hide();
    }
  }

  async userHasNotificaciones() {
    // this.notificaciones = await this.backendService.get(Anuncio, Anuncio.path + 'notificaciones/last').toPromise();

/*     for (const notificacion of this.notificaciones) {
      if (notificacion && !notificacion.leida) {
        if (notificacion.notificacionMasInfo) {
          this.avisarMasInfo = true;
          this.notificacionesImportantes.push(notificacion);
        } else {
          this.notificacionesPopup.push(notificacion);
        }
        this.notificacionesSinLeerAlerta = true;
      }
    }

    if (this.notificacionesPopup.length > 0) {
      await this.modalService.notificacionesPopup(this.notificacionesPopup, this.avisarMasInfo);
    }

    if (this.avisarMasInfo && this.notificacionesPopup.length < 1) {
      await this.modalService.masInformacion();
    } else {
      if (!this.avisarMasInfo) {
        this.notificacionesSinLeerAlerta = false;
      }
    }

    // this.notificacionesSinLeerAlerta = false;
    this.notificaciones = null;
    this.notificacionesPopup = null; */

  }

  logout() { this.seguridadService.logout(); }

}
