import { Component, OnInit } from '@angular/core';
import { ApiService } from '../core/api.service';

@Component({
  selector: 'app-landing-page-component',
  templateUrl: './landing-page-component.component.html',
  styleUrls: ['./landing-page-component.component.scss']
})
export class LandingPageComponentComponent implements OnInit {

  private mypURL = '/mediosyprensa';
  private soporteURL = '/contacto';
  private comisionesURL = '/comisiones';

  constructor(private apiService: ApiService) { }

  ngOnInit() {

  }

}
