
import { Logo } from '../models/logo.model';

export class LogoPedido {
  id: number;
  logo: Logo;
  posicion_x: number;
  posicion_y: number;
  width: number;
  height: number;

  widthcm: number;
  heighthcm: number;

  size: number;
  fuente: string;
  color: string;
  texto: string;

  constructor(logo: Logo) {
    this.logo = logo;
    this.posicion_x = 0;
    this.posicion_y = 0;
  }
}
