import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { VersionType } from './models/version-type';
import { VersionEnum } from 'src/app/core/constants/version-enum';


@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {

  constructor(
    private http: HttpClient,
  ) {
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url url
   */
  private checkVersion(url: string) {
    this.http.get<VersionType>(url).pipe(first())
      .subscribe({
        next: (possibleNewVersion: VersionType) => {

          const savedVersion = localStorage.getItem(VersionEnum.HASH);
          console.log(savedVersion);

          if (!savedVersion) {
            this.updateHash(possibleNewVersion);
            console.log('You have the latest version of VCSmart');
          } else {
            const currentVersion = JSON.parse(savedVersion);

            if (!this.isSameVersion(possibleNewVersion, currentVersion)) {
              console.log('New version detected, refreshing....');
              location.reload();
              this.updateHash(possibleNewVersion);
            } else {
              console.log('You have the latest version of VCSmart');
            }
          }
          debugger
        },
        error: err => console.error(err, 'Could not get version')
      });
  }

  private updateHash(possibleNewVersion: VersionType) {
    localStorage.setItem(VersionEnum.HASH, JSON.stringify(possibleNewVersion));
  }

  private isSameVersion(possibleNewVersion: VersionType, savedVersion: VersionType): boolean {
    return possibleNewVersion.appVersion === savedVersion.appVersion
      && possibleNewVersion.bundles.eager.main === savedVersion.bundles.eager.main;
  }

  public checkVersionInProduction(url: string) {
    //if (environment.production) {
      this.checkVersion(url);
    //}
  }
}
