import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import { BackendService } from 'src/app/core/backend.service';
import { FormValidatorServiceService } from 'src/app/shared-services/form-validator-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Perfil } from './models/perfil-usuario.model';
import { ToastrService } from 'ngx-toastr';
import { Cliente } from 'src/app/cliente/cliente.model';
import { Parametric } from 'src/app/cliente/parametric.model';
import { ApiService } from 'src/app/core/api.service';
import { Telefono } from 'src/app/cliente/telefono.model';
import { getFullYear } from 'ngx-bootstrap';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { Direccion } from 'src/app/cliente/direccion.model';
import { PaisEnum } from 'src/app/core/constants/pais-enum';


@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.scss']
})
export class PerfilUsuarioComponent implements OnInit {

  perfil: Perfil;
  tiposDocumentos: Parametric[]
  generos: Parametric[]
  email: string;
  nombreUser:string;
  apellidoUser:string;

  provincias: Parametric[]

  questionGroups:any;
  pvqForm:any;

  direccionesPerfil: Direccion[];

  today: Date;
  fechaLimite: Date;
  fechaLimiteMax: string;

  nuevaFechaNacimiento: string= undefined;
  form: FormGroup;
  formEmpresa: FormGroup;
  formDireccion: FormGroup;
  situacionesIva:Parametric[];
  tipoUsuario;
  telefonoPattern = "^\\d+$";
  textoPattern= "[A-Za-z]+[ A-za-z]*";
  constructor(
    private router: Router,
    private backendService: BackendService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private toast: ToastService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private seguridadService: SeguridadService,
  ) {
  }

  async ngOnInit() {
    //intento de form reactive con for
    /*this.questionGroups = this.formBuilder.array(this.getQuestions().map(question => this.formBuilder.group(question)));

     this.pvqForm = this.formBuilder.group({
    questions: this.questionGroups
      });*/


    this.spinner.show();
    this.email = this.seguridadService.user.email;
    this.perfil = await this.backendService.get(Perfil, Perfil.path).toPromise();
    
    this.nombreUser = this.perfil.name;
    this.apellidoUser = this.perfil.lastname;

    this.form = this.formBuilder.group({
      name: [undefined, [Validators.required]],
      lastname: [undefined, [Validators.required]],
      tipoDocumentoId: [undefined, [Validators.required]],
      documento: [undefined, [Validators.required,Validators.pattern(this.telefonoPattern),Validators.minLength(5)]],
      genero: [undefined, [Validators.required]],
      telefonoPrincipal: [undefined, [Validators.required,Validators.pattern(this.telefonoPattern),Validators.minLength(6)]],
      telefonoAlternativo: [undefined, [Validators.pattern(this.telefonoPattern),Validators.minLength(6)]],
      telefonoOtro: [undefined, [Validators.pattern(this.telefonoPattern),Validators.minLength(6)]],
      numero:  [undefined, ],
      direccion:[undefined, ],
      cp: [undefined, ],
    });
    this.formEmpresa = this.formBuilder.group({
      cuit: [undefined, ],
      razonSocial: [undefined,],
      telefonoPrincipal: [undefined, [Validators.required,Validators.pattern(this.telefonoPattern),Validators.minLength(6)]],
      telefonoAlternativo: [undefined, [Validators.pattern(this.telefonoPattern),Validators.minLength(6)]],
      situacionIva: [undefined, ],
    });
  
    this.formDireccion = this.formBuilder.group({
      numero:  [undefined, [Validators.required,Validators.pattern(this.telefonoPattern)]],
      direccion:[undefined, [Validators.required]],
      cp: [undefined, [Validators.required,Validators.pattern(this.telefonoPattern)]],
      piso: [undefined],
      dpto: [undefined],
      provincia: [undefined],
      partido: [undefined],
      localidad: [undefined],
      entreCalle1: [undefined],
      entreCalle2: [undefined],
    }); 
    

    //Elijo que tipo de form mostrar
    

    this.tipoUsuario = this.perfil.tipoUsuario;
    this.direccionesPerfil = this.perfil.direcciones;

    this.tiposDocumentos = await this.apiService.get(Parametric, Parametric.path + 'tipos_documentos/', null).toPromise();
    this.generos = await this.apiService.get(Parametric, Parametric.path + 'generos/', null).toPromise();

    if(this.perfil.tipoUsuario == 1){

    
      this.form.patchValue({
        name: this.perfil.name,
        lastname: this.perfil.lastname,
        tipoDocumentoId: this.perfil.tipoDocumentoId,
        documento:this.perfil.documento,
        telefonoPrincipal: this.perfil.telefonoPrincipal !=null ? this.perfil.telefonoPrincipal : undefined,
        telefonoAlternativo: this.perfil.telefonoAlternativo !=null ? this.perfil.telefonoAlternativo : undefined,
        telefonoOtro: this.perfil.telefonoOtro !=null ? this.perfil.telefonoOtro : undefined,
        genero: this.perfil.genero,
      });
    }else if(this.perfil.tipoUsuario == 4){
      this.situacionesIva = await this.apiService.get(Parametric, '/newParametricas/' + 'situaciones-iva/', null).toPromise();

      this.formEmpresa.patchValue({
        cuit: this.perfil.cuit,
        razonSocial: this.perfil.razonSocial,
        situacionIva: this.perfil.situacionIva,
        telefonoPrincipal: this.perfil.telefonoPrincipal !=null ? this.perfil.telefonoPrincipal : undefined,
        telefonoAlternativo: this.perfil.telefonoAlternativo !=null ? this.perfil.telefonoAlternativo : undefined,
        telefonoOtro: this.perfil.telefonoOtro !=null ? this.perfil.telefonoOtro : undefined,
      });
    }

    this.formDireccion.patchValue({
      numero: this.perfil.numero,
      direccion: this.perfil.direccion,
      cp: this.perfil.cp,
      dpto: this.perfil.dpto,
      piso: this.perfil.piso,
      provincia: this.perfil.provincia,
      localidad: this.perfil.localidad,
      partido: this.perfil.partido,
      entreCalle1: this.perfil.entreCalle1,
      entreCalle2: this.perfil.entreCalle2,
    });
    /*await this.backendService.get(Perfil, Perfil.path).toPromise()
      .then(c =>{
        this.perfil = c as Perfil;
        this.form.patchValue({
          name: this.perfil.name,
          lastname: this.perfil.lastname,
          tipoDocumentoId: this.perfil.tipoDocumentoId,
          documento:this.perfil.documento,
          telefonoPrincipal: this.perfil.telefonoPrincipal !=null ? this.perfil.telefonoPrincipal : undefined,
          telefonoAlternativo: this.perfil.telefonoAlternativo !=null ? this.perfil.telefonoAlternativo : undefined,
          telefonoOtro: this.perfil.telefonoOtro !=null ? this.perfil.telefonoOtro : undefined,
          genero: this.perfil.genero,
        });
        this.formDireccion.patchValue({
          numero: this.perfil.numero,
          direccion: this.perfil.direccion,
          cp: this.perfil.cp,
          dpto: this.perfil.dpto,
          piso: this.perfil.piso,
          provincia: this.perfil.provincia,
          localidad: this.perfil.localidad,
          partido: this.perfil.partido,
          entreCalle1: this.perfil.entreCalle1,
          entreCalle2: this.perfil.entreCalle2,
        });
      });*/
      this.today = new Date;
      this.fechaLimite = new Date(this.today.setFullYear(this.today.getFullYear()-18));
    await this.getProvincias();  
    this.spinner.hide();
    
  }

  //intento de form reactive con for

 /* getQuestions() {
    const questionControlArray = [];
    
    for (let i = 0; i < this.direccionesPerfil.length; i++) {
      questionControlArray.push({ 
      numero:  [undefined, [Validators.required,Validators.pattern(this.telefonoPattern)]],
      direccion:[undefined, [Validators.required]],
      cp: [undefined, [Validators.required,Validators.pattern(this.telefonoPattern)]],
      piso: [undefined],
      dpto: [undefined],
      provincia: [undefined],
      partido: [undefined],
      localidad: [undefined],
      entreCalle1: [undefined],
      entreCalle2: [undefined],
      });
    }
    return questionControlArray;
  }*/

  async save(form: string) {
    this.spinner.show();
    let perfilUpdate: Perfil;
    if(form == "datos"){
      perfilUpdate = this.form.value;
      perfilUpdate.cp = this.perfil.cp;
      perfilUpdate.direccion = this.perfil.direccion;
      perfilUpdate.numero = this.perfil.numero;
      perfilUpdate.cuit = "";
    } else if(form == "direccion"){
      perfilUpdate = this.formDireccion.value;
    }else if(form == "empresa"){
      perfilUpdate = this.formEmpresa.value;
      perfilUpdate.cp = this.perfil.cp;
      perfilUpdate.direccion = this.perfil.direccion;
      perfilUpdate.numero = this.perfil.numero;
      const perfilNuevo = Object.assign(this.perfil, perfilUpdate);
      if(!this.validateCuit(perfilNuevo.cuit)){
        this.toast.openErrorGenerico("EL NÚMERO DE CUIT ES INVALIDO")
        this.spinner.hide();
        return
      }
    }
    const perfilNuevo = Object.assign(this.perfil, perfilUpdate);
    
    perfilNuevo.telefonoAlternativo = perfilNuevo.telefonoAlternativo !=null ? perfilNuevo.telefonoAlternativo : null;

    perfilNuevo.cuit

    this.backendService.update(Perfil.path + "update",perfilNuevo)
      .then(()=>{
        this.router.navigate(['/perfil']);
        this.toast.openSuccessGenerico("Se Actualizaron los Datos Correctamente")
        this.spinner.hide();
      })
      .catch(err =>{
        console.log(err.message)
        this.spinner.hide();
        this.toast.openErrorGenerico("No Se Pudieron Actualizar los Datos")
      })
    
    
  }
  cambiarFecha(event) {
    this.nuevaFechaNacimiento = event.target.value;
  }
  public errorHandling = (control: string, error: string) => {
    return this.form.controls[control].hasError(error);
  }

  public errorHandlingEmpresa = (control: string, error: string) => {
    return this.formEmpresa.controls[control].hasError(error);
  }



  async getProvincias() {
    this.provincias = await this.apiService.get(Parametric, Parametric.path + 'provincias/'+PaisEnum.ARGENTINA, null).toPromise();
  }

  validateCuit(cuit:string){

    if (cuit.length != 11) {
      return false;
  }

  const cuitArray = cuit.split("");
  const serie = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  let aux = 0;
  for (var i = 0; i < 10; i++) {
      aux += Number.parseInt(cuitArray[i]) * serie[i];
  }
  aux = 11 - (aux % 11);
  
  if (aux == 11) {
      aux = 0;
  }
  return Number.parseInt(cuitArray[10]) === aux;

  }

  borrarDireccion(direccionId:Number){

    this.spinner.show();

    this.backendService.delete("/perfil/borrar-direccion/"+ direccionId)
      .then(()=>{
        //this.router.navigate(['/perfil']);
        this.toast.openSuccessGenerico("Se elimino la dirección correctamente")
        this.direccionesPerfil = this.direccionesPerfil.filter( obj => obj.id !== direccionId)
        this.spinner.hide();
      })



  }

}



