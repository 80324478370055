import { BackendModel, BackendService } from '../core/backend.service';
import { Parametric } from './parametric.model';
import { UsuarioInterno } from './usuarioInterno.model';
import { Usuario } from './usuario.model';
import { Mail } from './mail.model';
import { Telefono } from './telefono.model';
import { Role } from './role.model';
import { Direccion } from './direccion.model';

export class Cliente extends BackendModel {

  static path = '/clientes/';
  static path_seguridad = '/seguridad_clientes/';

  id: number;
  cuitCuil: string;
  motivoRechazo: string;
  ocupacion: string;
  key: string;
  fechaAprobacion: string;
  fechaNacimiento: string;
  personaPoliticamenteExpuesta: boolean;
  situacionIva: Parametric;
  estado: Parametric;
  usuarioAprobador: UsuarioInterno;
  username: string;
  name: string;
  lastname: string;
  emails: Mail[];
  telefonos: Telefono[];
  roles: Role[];
  enable: boolean;
  qrUrl: string;
  genero: Parametric;
  direcciones: Direccion[];
  tipoDocumento: Parametric;

  constructor(protected backendService: BackendService, model?: Parametric) {
    super(backendService, model);
  }
}
