import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BackendService } from '../core/backend.service';
import { Pedido } from '../productos/resultado-producto/models/pedido.model';
import { Categoria } from '../shared-components/dashboard/categoria.model';

// Este servicio comunica si el cliente tiene notificaciones sin leer.
@Injectable({
  providedIn: 'root'
})
export class NotificacionesComunicationService {

  constructor(
    private backendService: BackendService,
  ) { }

  private cambioFiltros = new Subject<string>();
  onCambioFiltros = this.cambioFiltros.asObservable();

  private poseeNotificacionesSinLeer = new Subject<number>();
  onNotificacionesChange = this.poseeNotificacionesSinLeer.asObservable();

  private cambioCategoria = new Subject<Categoria>();
  onCambioCategoria = this.cambioCategoria.asObservable();

  // True: posee notificaciones sin leer
  // False: no posee notificaciones sin leer
  async notificacionesChange() {
    let num;
    await this.backendService.getNumber(Pedido.path + 'hay-productos').then(data => {
      num = data as number;
    });
    this.poseeNotificacionesSinLeer.next(num);
  }

  async filtersChange(filtros) {
    this.cambioFiltros.next(filtros);
  }

  async categoriaChange(categoria) {
    this.cambioCategoria.next(categoria);
  }

}
