import { Injectable, Inject } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Sesion } from '../core/sesion.model';
import { Operacion } from '../core/operacion.model';
import { Anuncio } from '../core/anuncio.model';
import { BackendService } from '../core/backend.service';
import { Saldo } from '../core/saldo.model';
import { ResultHomeResolver } from './home-component.component';



@Injectable()
export class HomeResolver implements Resolve<ResultHomeResolver> {
  constructor(
    protected router: Router,
    protected backendService: BackendService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let resultado = new ResultHomeResolver();

    let obj: any;

    try {

      /* const sessionPromise = await this.backendService.get(Sesion, Sesion.path + 'lasts', null).toPromise();

      await (async () => {
        try {
          obj = await sessionPromise;
          return;
        } catch (error) {
          return [];
        }
        finally {}
      })();

      resultado.sesiones = Object.keys(obj).map(key => (obj[key]));

      const operacionesPromise = await this.backendService.get(Operacion, Operacion.path + 'lasts', null).toPromise();

      await (async () => {
        try {
          obj = await operacionesPromise;
          return;
        } catch (error) {
          return [];
        }
        finally {}
      })();

      resultado.operaciones = Object.keys(obj).map(key => (obj[key]));

      const anunciosPromise = await this.backendService.get(Anuncio, Anuncio.path + 'lasts', null).toPromise();

      await (async () => {
        try {
          obj = await anunciosPromise;
          return;
        } catch (error) {
          return [];
        }
        finally {}
      })();

      resultado.anuncios = Object.keys(obj).map(key => (obj[key]));

      const billeteraPromise = await this.backendService.get(Saldo, Saldo.path + 'me', null).toPromise();

      await (async () => {
        try {
          obj = await billeteraPromise;
          return;
        } catch (error) {
          return [];
        }
        finally {}
      })();

      resultado.billetera = Object.keys(obj).map(key => (obj[key])); */


      return resultado;

    } catch (error) {
      alert(error);
      console.log(error);
      this.router.navigate(['/']);
    }
  }
}
