import { Injectable, Inject } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BackendService } from '../../core/backend.service';
import { Cliente } from '../../cliente/cliente.model';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { EnvironmentProvider } from '../../core/browser-providers';
import { Environment } from '../../../environments/enviroment.model';
import { Http, Headers } from '@angular/http';

@Injectable()
export class CuentaCongeladaResolver implements Resolve<Cliente> {
  private frontendUrl: string;
  constructor(
    @Inject(EnvironmentProvider) env: Environment,
    protected router: Router,
    protected backendService: BackendService,
    private toast: ToastService,
    protected http: Http
  ) {
    this.frontendUrl = env.endpoints.apiFront;
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const hash = route.params.hash;
    this.congelarCuenta(Cliente.path + 'congelar-cuenta/' + hash).then((data) => {
      return;
    })
    .catch((ex) => {
      this.toast.openErrorGenerico(ex.message);
      this.router.navigate(['/']);
    });
    return null;
  }

  async congelarCuenta(path) {
    const url = this.frontendUrl + path;

    return this.http.post(url, null, { headers: this.loginHeaders() }).map(res => {
      return res.status === 200;
    }).catch(res => {
      throw res.json();
    }).toPromise();
  }

  private loginHeaders() {

    return new Headers(
      { 'Content-Type': 'application/ld+json' }
    );
  }
}
