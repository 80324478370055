export class Registro {

  static path = '/registro';

  username: string;

  nombre:string;
  apellido:string;
  
  email: string;
  password: string;

  /*
  constructor(protected backendService: BackendService, model?: RegistroPaso1) {
    super(backendService, model);
  }
  */

}
