import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DireccionCompleta } from 'src/app/cliente/direccionCompleta.model';

import { Parametric } from 'src/app/cliente/parametric.model';

import { ApiService } from 'src/app/core/api.service';
import { Perfil } from '../models/perfil-usuario.model';

import { ActivatedRoute } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';

import { BackendService } from 'src/app/core/backend.service';
import { ToastService } from 'src/app/shared-services/toast/toast.service';

import { Router } from '@angular/router';
import { Localidad } from 'src/app/productos/resultado-producto/models/localidades.model';
import { Partido } from 'src/app/productos/resultado-producto/models/partidos.model';
import { CostoEnvio } from 'src/app/productos/resultado-producto/models/costo-envio';
import { PaisEnum } from 'src/app/core/constants/pais-enum';

@Component({
  selector: 'app-perfil-direccion',
  templateUrl: './perfil-direccion.component.html',
  styleUrls: ['./perfil-direccion.component.scss']
})
export class PerfilDireccionComponent implements OnInit {

  formDireccion: FormGroup;
  provincias: Parametric[];
  localidades: Localidad[];
  partidos: Partido[];
  perfil:Perfil;
  direccion: DireccionCompleta;
  

  telefonoPattern = "^\\d+$";
  textoPattern= "[A-Za-z]+[ A-za-z]*";

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private backendService:BackendService,
    private toast: ToastService,
    private router: Router,
  ) { }

  

async ngOnInit() {
  

    this.formDireccion = this.formBuilder.group({
      numero:  [undefined, [Validators.required,Validators.pattern(this.telefonoPattern)]],
      direccion:[undefined, [Validators.required]],
      cp: [undefined, [Validators.required,Validators.pattern(this.telefonoPattern)]],
      piso: [undefined],
      dpto: [undefined],
      provincia: [undefined],
      partido: [null],
      localidad: [null],
      entreCalle1: [undefined],
      entreCalle2: [undefined],
    });

    this.route.data.subscribe(data => {
      this.direccion = data.direccion as DireccionCompleta;
      


      this.formDireccion.patchValue({
        numero: this.direccion.numero,
        direccion: this.direccion.direccion,
        cp: this.direccion.cp,
        dpto: this.direccion.dpto,
        piso: this.direccion.piso,
        provincia: this.direccion.provincia,
        localidad: this.direccion.localidad,
        partido: this.direccion.partido,
        entreCalle1: this.direccion.entreCalle1,
        entreCalle2: this.direccion.entreCalle2
      });
    
    })
    
    await this.getProvincias();
    await this.getDepartamentos(this.direccion.provincia)
    await this.getLocalidades(this.direccion.partido)

    this.formDireccion.patchValue({localidad: this.direccion.localidad})
    this.formDireccion.patchValue({partido: this.direccion.partido})
  }

  public errorHandling = (control: string, error: string) => {
    return this.formDireccion.controls[control].hasError(error);
  }

  async getProvincias() {
    this.provincias = await this.apiService.get(Parametric, Parametric.path + 'provincias/'+PaisEnum.ARGENTINA, null).toPromise();
  }

  async getDepartamentos(provName){
    this.spinner.show();
    this.backendService.get(Partido, CostoEnvio.path + "departamentos/" + provName, null).toPromise().then(part =>{
      this.partidos = part as Partido[];
      this.spinner.hide();
    });
    this.partidos = null;
    this.localidades = null;
    this.formDireccion.patchValue({localidad: null})
    this.formDireccion.patchValue({partido: null})
    
  }

  async getLocalidades(deptoId){
    this.spinner.show();
    this.backendService.get(Localidad, CostoEnvio.path + "localidades/" + deptoId, null).toPromise().then(a =>{
      this.localidades = a as Localidad[];
      this.spinner.hide();
    });
    this.formDireccion.patchValue({localidad: null})
    
  }

  async save(){
    this.spinner.show();

    let direccionUpdate: DireccionCompleta;
    direccionUpdate = this.formDireccion.value
    const direccionActualizada = Object.assign(this.direccion, direccionUpdate);

    this.backendService.update("/perfil/update-direccion",direccionActualizada)
      .then(()=>{
        this.router.navigate(['/perfil']);
        this.toast.openSuccessGenerico("Se Actualizaron los Datos Correctamente")
        this.spinner.hide();
      })
      

  }

  

}
