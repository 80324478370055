import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carrito-vacio',
  templateUrl: './carrito-vacio.component.html',
  styleUrls: ['./carrito-vacio.component.scss']
})
export class CarritoVacioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
