import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-advertencia-render-modal',
  templateUrl: './advertencia-render-modal.component.html',
  styleUrls: ['./advertencia-render-modal.component.scss']
})
export class AdvertenciaRenderModalComponent implements OnInit {

  public onClose: Subject<boolean>;

  constructor(
    public bsModalRef: BsModalRef
  ) {
    
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  noConfirma() {
    this.onClose.next(false);

    this.bsModalRef.hide();
  }

  noConfirmaAceptado() {
    
    
    this.bsModalRef.hide();
  }

}
