import { Component, OnInit } from '@angular/core';
import { Sesion } from '../../core/sesion.model';
import { BackendService } from '../../core/backend.service';

@Component({
  selector: 'app-listado-sesiones',
  templateUrl: './listado-sesiones.component.html',
  styleUrls: ['./listado-sesiones.component.scss']
})
export class ListadoSesionesComponent implements OnInit {

  public sesionesList: Sesion[];
  constructor(private backendService: BackendService) { }

  ngOnInit() {
    this.getSesionesList();
  }

  async getSesionesList() {
    const sesionesPromise = await this.backendService.get(Sesion, Sesion.path + 'all', null).toPromise();

    const obj = await (async () => {
      try {
        return await sesionesPromise;
      } catch (error) {
        return [];
      }
      finally {}
    })();

    this.sesionesList = Object.keys(obj).map(key => (obj[key]));

  }

}
