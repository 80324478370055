import { NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';

import { FormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  RECAPTCHA_SETTINGS,
  RECAPTCHA_LANGUAGE,
  RecaptchaSettings,
  RecaptchaModule,
} from 'ng-recaptcha';

import { RecaptchaFormsModule } from 'ng-recaptcha/forms';

import 'rxjs/add/observable/of';
import 'rxjs/add/observable/zip';
import 'rxjs/add/operator/bufferCount';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/toPromise';

import { MyDatePickerModule } from 'mydatepicker';
import {MomentModule} from 'angular2-moment/moment.module';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap';

const recaptcha = RecaptchaModule.forRoot();

const exports = [
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  recaptcha.ngModule,
  RecaptchaFormsModule,
  MyDatePickerModule,
  MomentModule,
  PasswordStrengthBarModule,
  NgbModule,
];

const imports = [
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  recaptcha.ngModule,
  RecaptchaFormsModule,
  MyDatePickerModule,
  MomentModule,
  PasswordStrengthBarModule,
  RecaptchaModule.forRoot(),
  NgbModule,
  ModalModule.forRoot(),
];

@NgModule({
  imports: imports,
  exports: exports,
  providers: [
    recaptcha.providers
  ]
})

export class VendorModule {
  constructor(
    sanitizer: DomSanitizer,
    // dateAdapter: DateAdapter<NativeDateAdapter>,
  ) {
    // dateAdapter.setLocale('es' || navigator.language);
  }
}
