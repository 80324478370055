import { BackendModel, BackendService } from './backend.service';

export class Anuncio extends BackendModel {

    static path = '/novedades/';

    id: number;
    asunto: string;
    texto: string;
    fechaEnvio: Date;
    leida: boolean;
    notificacionMasInfo: boolean;
    generica: boolean;

    constructor(protected backendService: BackendService, model?: Anuncio) {
        super(backendService, model);
    }
}
