import {ProductoStock} from '../../dtos/producto-stock';
import {Color} from '../../color.model';
import {TalleConStock} from './talle-con-stock';

export class VarianteXColor {

  color:Color;
  variantes: ProductoStock[];
  //talles: TalleConStock[];
}
