import { Environment } from './enviroment.model';

export const defaultEnv: Environment = {
  production: false,
  captcha: false,
  endpoints: {
    apiFront: 'http://localhost:8080/1.0/frontend',
    api: 'http://localhost:8080/1.0',
    clientes: '/clientes',
  },
  versionCheckURL: '/version.json',
  googleAnalyticsId : 'G-ENF1Z1EWWC'
};
