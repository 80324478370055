import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { BackendService } from 'src/app/core/backend.service';
import { Pedido } from '../resultado-producto/models/pedido.model';
import { ToastService } from 'src/app/shared-services/toast/toast.service'; 
import { NotificacionesCarrito } from 'src/app/shared-services/notificacion-carrito.service';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';
import { DireccionCompleta } from 'src/app/cliente/direccionCompleta.model';
import { DireccionCompletaTB } from 'src/app/cliente/direccionCompletaTB.model';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'app-transferencia-bancaria-modal',
  templateUrl: './transferencia-bancaria-modal.component.html',
  styleUrls: ['./transferencia-bancaria-modal.component.scss']
})
export class TransferenciaBancariaModalComponent implements OnInit {

  dniTransferencia: string;
  pedido: Pedido;
  comprobante: File;
  formData: FormData;
  razonSocial: string;
  selectPais: string;

  direccionAtualizada: DireccionCompletaTB = new DireccionCompletaTB();

  //direccionEnviar: DireccionCompleta;

  nomb:string;
  dni:string;


  numberPattern = /^([0-9][0-9]*)$/;
  textPattern = /^([A-z]*)$/;

  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef,
    private backendService: BackendService,
    private toast: ToastService,
    private notificacionesCarrito : NotificacionesCarrito,
    private router: Router,
    private spinner: NgxSpinnerService,
    private gtag: Gtag
    ) { }

  async ngOnInit() {
    this.selectPais = localStorage.getItem('pais');
    this.onClose = new Subject();
    
  }

  selectFile(event) {
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }
    var mimeType = event.target.files[0].type;
    //if (mimeType.match(/image\/*/) == null) {
    //  return;
    //}
    if (!mimeType.match(/image\/(png|jpeg|jpg|ai)|(application\/pdf)/)) {
      this.toast.openErrorGenerico("Formato incorrecto. Solo se permiten archivos PNG, JPG, AI, JPEG y PDF.");
      return;
    }
    this.comprobante = event.target.files[0];
    this.toast.openSuccessGenerico("ARCHIVO SUBIDO EXITOSAMENTE!");

  }

  async enviarComprobante(){ 
    
    let error = '';

    if (!this.pedido.documento) {
      error += ', ingrese el DNI o un CUIT del titular de la transferencia';
    }else{
      if(!this.numberPattern.test(this.pedido.documento.toString())){
        error += ', ingrese un DNI o un CUIT con formato válido';
      }

      if (this.pedido.documento.length < 5) {
        error += ', ingrese un DNI o un CUIT con minimo 5 digitos';
      }
    }

    if (!this.pedido.nombreEnvio) {
      error += ', ingrese el nombre o la Razón Social del titular de la transferencia';
    }

    if (error.length > 0) {
      this.toast.openErrorGenerico('Por favor' + error);
      return;
    }

    this.spinner.show();

    try{
      this.formData = new FormData();
      this.formData.append('file', this.comprobante);
      this.pedido.transferenciaAprobada = false;
      await this.backendService.createFile('/new-pedidos/cerrar-pedido/transferencia-bancaria/' + this.pedido.id + '/'+ this.pedido.documento + '/' + this.nomb, this.formData).then(()=>{
        //this.router.navigateByUrl('/mis-pedidos');
      });
      this.comprobante = null;

      this.notificacionesCarrito.emitChange(0);

    } catch(e){
      console.log(e.message);
    } finally{
      this.onClose.next(true);
      this.bsModalRef.hide();
    }

    this.spinner.hide();

  }

  async enviarComprobanteEmpresa(){

    
    let error = '';

    if (!this.pedido.cuit) {
      error += ', ingrese el DNI o un CUIT del titular de la transferencia';
    }else{
      if(!this.numberPattern.test(this.pedido.cuit.toString())){
        error += ', ingrese un DNI o un CUIT con formato válido';
      }

      if (this.pedido.cuit.length < 5) {
        error += ', ingrese un DNI o un CUIT con minimo 5 digitos';
      }
    }

    if (!this.pedido.razonSocial) {
      error += ', ingrese el nombre o la Razón Social del titular de la transferencia';
    }

    if (error.length > 0) {
      this.toast.openErrorGenerico('Por favor' + error);
      return;
    }

    this.spinner.show()


    try{
      this.formData = new FormData();
      this.formData.append('file', this.comprobante);
      this.pedido.transferenciaAprobada = false;
      await this.backendService.createFile('/new-pedidos/cerrar-pedido/transferencia-bancaria/' + this.pedido.id + '/'+ this.pedido.cuit + '/' + this.pedido.razonSocial, this.formData).then(()=>{
        this.router.navigateByUrl('/mis-pedidos');
      });
      this.comprobante = null;

      this.notificacionesCarrito.emitChange(0);

    } catch(e){
      console.log(e.message);
    } finally{
      this.onClose.next(true);
      this.bsModalRef.hide();
    }

    this.spinner.hide();

  }

  async cerrarPedido(){
    
    try{
      this.setearDireccionActualizada();

      this.backendService.create("/perfil/crear-direccion",this.direccionAtualizada).then(()=>{
        //this.router.navigateByUrl('/mis-pedidos');
      });
      
    }catch(e){
      console.log(e.message);
    }finally{
      this.gtag.event('send_later_transfer', {
        items: [{
          item_name: "enviar mas tarde"
        }]
      });
    this.router.navigateByUrl('/mis-pedidos');
     this.cerrar();
    }
    
  }

  cerrar(){
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  setearDireccionActualizada(){
    
    this.direccionAtualizada.direccion = this.pedido.direccion;
    this.direccionAtualizada.numero = this.pedido.altura.toString();
    
    this.direccionAtualizada.piso = this.pedido.piso;
    this.direccionAtualizada.dpto = this.pedido.dpto;
    this.direccionAtualizada.cp= this.pedido.cp;
    this.direccionAtualizada.provincia = this.pedido.provincia;
    this.direccionAtualizada.entreCalle1 = this.pedido.entreCalle1;
    this.direccionAtualizada.entreCalle2 = this.pedido.entreCalle2;
    this.direccionAtualizada.localidad = this.pedido.localidad;
    this.direccionAtualizada.partido = this.pedido.partido;
  }

}
