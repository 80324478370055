import { Directive, forwardRef, Inject } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { StrengthPasswordService } from './strength-password.service';

@Directive({
  selector: '[appPasswordStrength][ngModel],[appPasswordStrength][formControl]',
  providers: [{
    provide: NG_VALIDATORS, multi: true,
    useExisting: forwardRef(() => PasswordStrengthValidator),
  }],
})
export class PasswordStrengthValidator implements Validator {

  constructor(@Inject (StrengthPasswordService) private passwordStrength) {

  }
  validate(control: AbstractControl) {
     if (!control.value ) {
      return null;
     }

     if (this.passwordStrength.measureStrength(control.value.toString()) < 2) {
       return { strength: { valor: 'La contraseña ingresada debe tener letras y números'} };
     }

     return null;
  }
}
