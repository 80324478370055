import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { BackendService } from '../../core/backend.service';
import { NotificacionesCarrito } from 'src/app/shared-services/notificacion-carrito.service';
import { ModalsService } from 'src/app/shared-services/modals.service';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  success : boolean;

  subioLogosCurvas: boolean;

  link : string = '/new-pedidos/checkout-success/';

  pedidoId: number;

  cantLogos:number;
  
  constructor(
    private route: ActivatedRoute,
    private backendService: BackendService,
    private notificacionesCarrito : NotificacionesCarrito,
    private modalService: ModalsService,
    private router: Router
  ) { }

  async ngOnInit() {

    this.route.data.subscribe(data =>{
      this.success = data.success;   
    });

    this.pedidoId = this.route.snapshot.params.id;
    
    this.backendService.getNumber('/new-pedidos/cantidad-logos/' + this.pedidoId).then(cantidad =>{ 
      this.cantLogos = cantidad as number;
      if(this.success){
        this.backendService.update('/new-pedidos/log-mercado-pago/' + this.pedidoId,null);
        if(this.cantLogos > 0){
          this.backendService.update(this.link + this.pedidoId+ '/' + 12, null);
          this.subirLogosConCurvas(this.pedidoId);
        }else if(this.cantLogos ==0){
          this.backendService.update(this.link + this.pedidoId+ '/' + 8, null);
        }
        
      }
    });
    
    //this.notificacionesCarrito.emitChange(0);
  }

  async subirLogosConCurvas(pedidoId:number){

      
      const confirmado = await this.modalService.OpenModalSubirLogoConCurvas(this.pedidoId, 1);
      
      confirmado.subscribe(async (value) =>{
        if(value){
          this.subioLogosCurvas = value;
        }
      })
  
    }

}
