import { Component, OnInit,  AfterViewInit } from '@angular/core';
import {FormBuilder, FormGroup,  Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SeguridadService } from '../seguridad.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {UserCredentials} from "./models/user-credentials";
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { Location } from '@angular/common';
import { BackendService } from 'src/app/core/backend.service';
import { Pedido } from 'src/app/productos/resultado-producto/models/pedido.model';
import { NotificacionesCarrito } from 'src/app/shared-services/notificacion-carrito.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  redirectUrl = '/registro/completar-documentacion';
  redirectUrlDashboard = '';
  requiereCode = false;
  visibilidad:any;

  form: FormGroup;
  selectPais:string
  hayProductos:number;

  constructor(
    private router: Router,
    private seguridadService: SeguridadService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toast:ToastService,
    private backendService: BackendService,
    private notificacionesCarrito:NotificacionesCarrito,
    private location: Location
  ) {}

  ngOnInit() {
    this.visibilidad = document.getElementById("loginPass");
    this.form = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: [undefined, [Validators.required]]
    });

    if(this.route.snapshot.params.id == null){
      this.redirectUrlDashboard = ''
    } else{
      this.redirectUrlDashboard = 'producto/' + this.route.snapshot.params.id;
    }
    this.selectPais = localStorage.getItem('pais');
  }

  async login() {
    try {
      this.spinner.show();
      var credentials =  Object.assign(new UserCredentials(), this.form.value);
      const retorno = await this.seguridadService.login(credentials);

      if (retorno === null) {
        this.requiereCode = true;
        this.spinner.hide();
        return;
      }

      let prevUrl = document.referrer;
      if(prevUrl.length > 0) {
      // Ir a la página anterior
        if(prevUrl.includes("/previsualizacion-compra")){
          await this.redireccionPrevisualizacionCompra();
        }else if(prevUrl.includes("/previsualizacion-compra-chile")){
          await this.redireccionPrevisualizacionCompra();
        }else{
          window.location.href = prevUrl;
        }
      }else{
        this.router.navigateByUrl("/");
      }

      //this.location.back();
      //this.router.navigateByUrl(this.redirectUrlDashboard);

    } catch (error) {
      this.spinner.hide();
      const body = JSON.parse(error._body)
      if(body.message.includes("Invalid username")){
        this.toast.openErrorGenerico("Usuario o contraseña incorrecta.");
      }
      else{
        this.toast.openErrorGenerico(body.message);
      }
    }
  }

  public errorHandling = (control: string, error: string) => {
    return this.form.controls[control].hasError(error);
  }

  passwordVisible(){

    if (this.visibilidad.type === "password") {
      this.visibilidad.type = "text";
    } else {
      this.visibilidad.type = "password";
    }
      
  }

  async redireccionPrevisualizacionCompra(){
    await this.backendService.getNumber(Pedido.new_path + 'estado-activo-simple/'+ this.selectPais).then(cantidad =>{ 
      this.hayProductos = cantidad as number;
      this.notificacionesCarrito.emitChange(this.hayProductos);
    });
    if(this.hayProductos > 0){
      if(this.selectPais=="ARGENTINA"){
        //this.router.navigateByUrl('/previsualizacion-compra');
        window.location.href = '/previsualizacion-compra';
      }else if (this.selectPais=="CHILE"){
        //this.router.navigateByUrl('/previsualizacion-compra-cl');
        window.location.href = '/previsualizacion-compra-cl';
      }
    }else{
      this.router.navigateByUrl("/carrito-vacio")
    }
  }
}
