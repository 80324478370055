import { Injectable } from "../../../../node_modules/@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "../../../../node_modules/@angular/router";
import { Observable } from 'rxjs';
import { CountryPhone } from '../models/country-phone.model';
import { BackendService } from '../backend.service';

@Injectable()
export class CountryPhoneResolver implements Resolve<any>{

  constructor(
    protected router: Router,
    protected backendService: BackendService,
  ) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    try {

    return await  this.backendService.get(CountryPhone, CountryPhone.path).toPromise();

    } catch (error) {
      this.router.navigate(['/']);
    }
  }

}
