import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  selectPais: string;
  constructor(private location: Location) { }

  ngOnInit() {
    this.selectPais = localStorage.getItem('pais');
  }


  goBack() {
    this.location.back();
  }

}
