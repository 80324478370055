import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { UsuarioService } from 'src/app/core/usuario.service';
import { UserCredentials } from 'src/app/seguridad/login/models/user-credentials';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { ToastService } from 'src/app/shared-services/toast/toast.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
  
  public onClose: Subject<boolean>;
  redirectUrlDashboard = '';
  form: FormGroup;
  requiereCode = false;
  
  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private seguridadService: SeguridadService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toast:ToastService,
    private usuarioService:UsuarioService
  ) { }

  ngOnInit() {
      //this.visibilidad = document.getElementById("loginPass");
      this.onClose = new Subject();
      this.form = this.formBuilder.group({
        username: ['', [Validators.required]],
        password: [undefined, [Validators.required]]
      });
  
      if(this.route.snapshot.params.id == null){
        this.redirectUrlDashboard = ''
      } else{
        this.redirectUrlDashboard = 'producto/' + this.route.snapshot.params.id;
      }

      this.form.patchValue({
        username: this.usuarioService.getUsuarioCliente()
      })
  }

  async login() {
    try {
      this.spinner.show();
      var credentials =  Object.assign(new UserCredentials(), this.form.value);
      //this.seguridadService.logoutTemporalSesion();
      const retorno = await this.seguridadService.login(credentials);

      if (retorno === null) {
        this.requiereCode = true;
        this.spinner.hide();
        return;
      }

      /*let prevUrl = document.referrer;
      if(prevUrl.length > 0) {
      // Ir a la página anterior
       window.location.href = prevUrl;
      }else{
        this.router.navigateByUrl("/");
      }*/

      this.onClose.next(true);
      this.bsModalRef.hide();

      //this.location.back();
      //this.router.navigateByUrl(this.redirectUrlDashboard);

    } catch (error) {
      this.spinner.hide();
      const body = JSON.parse(error._body)
      if(body.message.includes("Invalid username")){

        this.toast.openErrorGenerico("Usuario o contraseña incorrecta.");
      }
      else{
        this.toast.openErrorGenerico(body.message);
      }
    }
  }

  public errorHandling = (control: string, error: string) => {
    return this.form.controls[control].hasError(error);
  }

  cerrar(){
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

}
