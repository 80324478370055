import { Component, OnInit } from '@angular/core';
import { DireccionCompleta } from 'src/app/cliente/direccionCompleta.model';
import { Parametric } from 'src/app/cliente/parametric.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BackendService } from 'src/app/core/backend.service';
import { DireccionCompletaDto } from '../models/direccion-completa-dto-model';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { Partido } from 'src/app/productos/resultado-producto/models/partidos.model';
import { CostoEnvio } from 'src/app/productos/resultado-producto/models/costo-envio';
import { Localidad } from 'src/app/productos/resultado-producto/models/localidades.model';
import { PaisEnum } from 'src/app/core/constants/pais-enum';

@Component({
  selector: 'app-nueva-direccion',
  templateUrl: './nueva-direccion.component.html',
  styleUrls: ['./nueva-direccion.component.scss']
})
export class NuevaDireccionComponent implements OnInit {

  formDireccion: FormGroup;
  provincias: Parametric[];
  partidos: Partido[];
  localidades: Localidad[];
  
  direccion: DireccionCompleta;

  telefonoPattern = "^\\d+$";
  textoPattern= "[A-Za-z]+[ A-za-z]*";

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router:Router,
    private toast: ToastService,
    private spinner: NgxSpinnerService,
    private backendService:BackendService,
  ) { }

  

async ngOnInit() {
  

    this.formDireccion = this.formBuilder.group({
      numero:  [undefined, [Validators.required,Validators.pattern(this.telefonoPattern)]],
      direccion:[undefined, [Validators.required]],
      cp: [undefined, [Validators.required,Validators.pattern(this.telefonoPattern)]],
      piso: [undefined],
      dpto: [undefined],
      provincia: [undefined],
      partido: [undefined],
      localidad: [undefined],
      entreCalle1: [undefined],
      entreCalle2: [undefined],
    });

    

    
    await this.getProvincias(); 
  }

  public errorHandling = (control: string, error: string) => {
    return this.formDireccion.controls[control].hasError(error);
  }

  async getProvincias() {
    this.provincias = await this.apiService.get(Parametric, Parametric.path + 'provincias/'+PaisEnum.ARGENTINA, null).toPromise();
  }

  async getDepartamentos(provName){
    this.spinner.show();
    this.backendService.get(Partido, CostoEnvio.path + "departamentos/" + provName, null).toPromise().then(part =>{
      this.partidos = part as Partido[];
      this.spinner.hide();
    });
    this.partidos = null;
    this.localidades = null;
    this.formDireccion.patchValue({localidad: null})
    this.formDireccion.patchValue({partido: null})
    
  }

  async getLocalidades(deptoId){
    this.spinner.show();
    this.backendService.get(Localidad, CostoEnvio.path + "localidades/" + deptoId, null).toPromise().then(a =>{
      this.localidades = a as Localidad[];
      this.spinner.hide();
    });
    this.formDireccion.patchValue({localidad: null})
    
  }

  async save(){
    try{
      this.spinner.show();
    const direccionActualizada = Object.assign(new DireccionCompletaDto(), this.formDireccion.value);

    if(direccionActualizada.provincia == null){
      this.toast.openErrorGenerico("Ingrese una provincia")
      this.spinner.hide()
      return
    } 

    this.backendService.create("/perfil/crear-direccion",direccionActualizada).then(()=>{
      this.router.navigate(['/perfil']);
      this.toast.openSuccessGenerico("Se Creo la Dirección Correctamente")
      this.spinner.hide();
    })
      
    
    }catch{
      this.toast.openErrorGenerico("No se puede guardar la dirección")
      this.spinner.hide()
    }  

  }

}
