export class Telefono {

  tipoTelefonoId: number;
  numero: string;
  id: number;

  constructor(tipoId: number, numero: string) {
    this.tipoTelefonoId = tipoId;
    this.numero = numero;
  }

}
