import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Logo} from '../../models/logo.model';
import {LogoPedido} from '../../viejo/logo-pedido.model';

const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2
}

@Component({
  selector: 'app-logo-resizable-component',
  templateUrl: './logo-resizable-component.component.html',
  styleUrls: ['./logo-resizable-component.component.scss']
})
export class LogoResizableComponentComponent implements OnInit,AfterViewInit {

  @Input('muestraAprobada')  muestraAprobada: boolean;
  @Input('width')  width: number;
  @Input('height')  height: number;
  @Input('left')  left: number;
  @Input('top')  top: number;
  @Input('imagen') imagen;
  @Input('widthCM')  widthCM: number;
  @Input('heightCM')  heightCM: number;
  @Input('widthOriginal')  widthOriginal: number;
  @Input('heightOriginal')  alturaOriginal: number;
  @Input('posX')  posX: number;
  @Input('posY')  posY: number;
  @Input('leftOriginal') leftOriginal: number;
  @Input('topOriginal') topOriginal: number;
  @Input('proveedorId') proveedorId: number;

  @ViewChild("box")  box: ElementRef;
  @ViewChild("box_imagen")  box_imagen: ElementRef;

  @Output() emitirValores = new EventEmitter<LogoPedido>();

  private boxPosition: { left: number, top: number };
  private containerPos: { left: number, top: number, right: number, bottom: number };
  public mouse: { x: number, y: number };
  public status: Status = Status.OFF;
  private mouseClick: { x: number, y: number, left: number, top: number }

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.loadBox();
    this.loadContainer();

    /*console.log(this.imagen)

    var CANVAS_WIDTH = 500;
    var CANVAS_HEIGHT = 500;
    var cx = CANVAS_WIDTH / 2;
    var cy = CANVAS_HEIGHT / 2;
    var image_width = this.width;
    var image_height = this.height
    var r = image_width / 2;
    var maxrad = Math.PI * 2;
  
    var canvas = document.querySelector('canvas');
    var context = canvas.getContext('2d');

    var image = new Image();
    var state = {
      isDown: false,
      lastAngle: 0,
      angleStart: 0,
      angle: 0
    };

    function angleFromEvent(e){
      const x = e.clientX - canvas.offsetLeft;
      const y = e.clientY - canvas.offsetTop;

      return Math.atan2(
        (x - cx) /r,
        (y - cy) /r
      );
    }

    function draw(){
      context.clearRect(0,0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.translate(cx, cy);
      context.rotate(-state.angle);
      context.drawImage(image, -image_width /2, -image_height /2, image_width, image_height);

      context.setTransform(1, 0, 0, 1, 0, 0);
    }

    canvas.onmousedown = function(e){
      const angle = angleFromEvent(e);
      state.angleStart = angle;
      state.isDown = true;
      canvas.onmousemove(e);
    }

    canvas.onmousemove = function(e){
      if(!state.isDown){
        return;
      }

      state.angle = angleFromEvent(e) + state.lastAngle - state.angleStart;
      draw();
    }

    canvas.onmouseup = function(e){
      state.lastAngle = state.angle;
      state.isDown = false;
      draw();
    }

    image.onload = function(){
      draw();
    }

    image.src = this.logosAreas[this.indice].url;*/

  }

  enviarValores() {
    let logo: Logo = new Logo();
    let logoPedido: LogoPedido = new LogoPedido(logo);
    logoPedido.posicion_x = this.left;
    logoPedido.posicion_y = this.top;
    logoPedido.height = this.box.nativeElement.offsetHeight;
    logoPedido.width = this.box.nativeElement.offsetWidth;
    this.emitirValores.emit(logoPedido);
  }

  private loadBox() {
    const {left, top} = this.box.nativeElement.getBoundingClientRect();
    this.boxPosition = {left, top};
  }

  private loadContainer() {
    const left = this.boxPosition.left - this.left;
    const top = this.boxPosition.top - this.top;
    const right = left + this.widthOriginal;
    const bottom = top + this.alturaOriginal;
    this.containerPos = {left, top, right, bottom};
  }

  setStatus(event: MouseEvent, status: number) {
    if (status === 1 /*|| !(this.left + this.width < 600 && this.top + this.height < 450 && this.left >0 && this.top > 0)*/) event.stopPropagation();
    else if (status === 2) this.mouseClick = {x: event.clientX, y: event.clientY, left: this.left, top: this.top};
    else this.loadBox();
    this.status = status;

    this.enviarValores();
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.mouse = {x: event.clientX, y: event.clientY};

    if (this.status === Status.RESIZE) this.resize();
    else if (this.status === Status.MOVE) this.move();
  }

  private resize() {
    const proximaAnchura = Number(this.mouse.x > this.boxPosition.left) ? this.mouse.x - this.boxPosition.left : 0;
    const proximaAltura = Number(this.mouse.y > this.boxPosition.top) ? this.mouse.y - this.boxPosition.top : 0;

    var condicionY = this.box_imagen.nativeElement.offsetHeight + 1 <= this.alturaOriginal && proximaAltura > 54;
    var condicionX = proximaAnchura < this.widthOriginal && proximaAnchura > 54;

    var widthAnterior = this.width;
    var movimiento = this.mouse.x - this.boxPosition.left;
    if (movimiento <= this.width) {
      if (proximaAnchura > 54 && proximaAltura > 54 && this.box_imagen.nativeElement.offsetHeight > 54) {
        this.width = Number(this.mouse.x > this.boxPosition.left) ? this.mouse.x - this.boxPosition.left : 0;
        this.height = this.box_imagen.nativeElement.offsetHeight;
      }
    } else {
      if (condicionX && condicionY) {
        this.width = Number(this.mouse.x > this.boxPosition.left) ? this.mouse.x - this.boxPosition.left : 0;
        this.height = this.box_imagen.nativeElement.offsetHeight;
      }
    }
  }

  private resizeCondMeet() {
    if (!this.muestraAprobada) {
      return (this.mouse.x < this.containerPos.right && this.mouse.y < this.containerPos.bottom);
    }
  }

  private move() {
    if (!this.muestraAprobada) {
      /*if(this.moveCondMeet() && (this.left >0 && this.top > 0)){*/
      const proximoLeftFinal = (this.mouseClick.left + (this.mouse.x - this.mouseClick.x)) + this.width;
      const proximoLeftInicio = (this.mouseClick.left + (this.mouse.x - this.mouseClick.x));
      const final = this.leftOriginal + this.widthOriginal;

      const proximoAlturatecho = this.mouseClick.top + (this.mouse.y - this.mouseClick.y) + this.height;
      const finalTop = this.topOriginal + this.alturaOriginal;
      const mouse = (this.mouseClick.top + (this.mouse.y - this.mouseClick.y));

      if (proximoLeftInicio >= this.leftOriginal && proximoLeftFinal <= final) {
        this.left = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
      }

      if (proximoAlturatecho <= finalTop && mouse >= this.topOriginal) {
        this.top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
      }
      //}
    }
  }

  private moveCondMeet() {
    if (!this.muestraAprobada) {
      const offsetLeft = this.mouseClick.x - this.boxPosition.left;
      const offsetRight = this.width - offsetLeft;
      const offsetTop = this.mouseClick.y - this.boxPosition.top;
      const offsetBottom = this.height - offsetTop;
      return (
        this.mouse.x > this.containerPos.left + offsetLeft &&
        this.mouse.x < this.containerPos.right - offsetRight &&
        this.mouse.y > this.containerPos.top + offsetTop &&
        this.mouse.y < this.containerPos.bottom - offsetBottom
      );
    }
  }
}
