import { Component, OnInit, ViewChild, Inject, EventEmitter, Output, Input } from '@angular/core';
import { DatosContacto } from './datosContacto.model';
import { Parametric } from '../../cliente/parametric.model';
import { Telefono } from '../../cliente/telefono.model';
import { Cliente } from '../../cliente/cliente.model';
import { NgForm } from '@angular/forms';
import { EnvironmentProvider } from '../../core/browser-providers';
import { Environment } from '../../../environments/enviroment.model';
import { Mail } from '../../cliente/mail.model';
import { BackendService } from '../../core/backend.service';
import { ApiService } from '../../core/api.service';
import { FormValidatorServiceService } from '../../shared-services/form-validator-service.service';
import { ToastService } from 'src/app/shared-services/toast/toast.service';

@Component({
  selector: 'app-datos-contacto',
  templateUrl: './datos-contacto.component.html',
  styleUrls: ['./datos-contacto.component.scss']
})
export class DatosContactoComponent implements OnInit {
  private clientesPath: string;
  private usuario = new DatosContacto();
  public mails: Mail[];
  public telefonos = [new Telefono(undefined, '')];

  @Input() clientePromise: Promise<Cliente>;

  @ViewChild('paso3Form') public userForm: NgForm;
  @Output() change = new EventEmitter<number>();
  @Output() previous = new EventEmitter<number>();

  private proximoPaso = 2;
  private pasoAnterior = 1;

  constructor(
    @Inject(EnvironmentProvider) env: Environment,
    private backendService: BackendService,
    private apiService: ApiService,
    private formValidatorService: FormValidatorServiceService,
    private toast: ToastService) {
      this.clientesPath = env.endpoints.clientes;
  }

  ngOnInit() {
    this.clientePromise.then(cli => {
      this.mails = cli.emails.length > 0 ? cli.emails : [];
      this.telefonos = cli.telefonos.length > 0 ? cli.telefonos : [new Telefono(undefined, '')];
    });
  }

  async save() {
    if (this.validateForm()) {
      this.invokeSave();
    }
  }
  agregarTelefono() {
    this.telefonos.push(new Telefono(undefined, ''));
  }
  agregarMail() {
    this.mails.push(new Mail(undefined, '', false));
  }
  quitarTelefono(index: number) {
    this.telefonos.splice(index, 1);
  }
  quitarMail(index: number) {
    this.mails.splice(index, 1);
  }

  validateForm() {
    let validationsOK = false;
    if (this.formValidatorService.isFormValid(this.userForm)) {
      validationsOK = true;
    }
    return validationsOK;
  }

  checkMailsTelefonos() {
    for (const mail of this.mails) {
      if (mail.direccion !== '' && mail.tipoEmailId !== 0) {
        this.usuario.emails.push(mail);
      }
    }

    for (const telefono of this.telefonos) {
      if (telefono.numero !== ''  && telefono.tipoTelefonoId !== 0) {
        this.usuario.telefonos.push(telefono);
      }
    }
  }

  private async invokeSave() {
    if (this.validateForm()) {
      this.checkMailsTelefonos();
      this.saveUsuarioPaso2();
    }
    return;
  }

  async saveUsuarioPaso2() {
    try {
      await this.backendService.create(this.clientesPath + DatosContacto.path, this.usuario);
      this.change.emit(this.proximoPaso);
    } catch (ex) {
      if (ex[0]) {
        this.toast.openErrorGenerico(ex[0].details);
      } else if (ex.errors) {
        this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
      } else {
        this.toast.openErrorGenerico(ex.message);
      }
    } finally {
      this.usuario.telefonos = [];
      this.usuario.emails = [];
    }
  }

  public volverPasoAnterior() {
    this.previous.emit();
  }

}
