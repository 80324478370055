import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-areas-impresion',
  templateUrl: './areas-impresion.component.html',
  styleUrls: ['./areas-impresion.component.scss']
})
export class AreasImpresionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
