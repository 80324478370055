import { Component, OnInit } from '@angular/core';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-cp-invalido-toast',
  templateUrl: './cp-invalido-toast.component.html',
  styleUrls: ['./cp-invalido-toast.component.scss']
})
export class CpInvalidoToastComponent extends Toast {

  constructor(protected toastrService: ToastrService,
    public toastPackage: ToastPackage) {
super(toastrService, toastPackage);
}

action(event: Event) {
event.stopPropagation();
this.toastPackage.triggerAction();
return false;
}

}
