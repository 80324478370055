import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BackendService } from 'src/app/core/backend.service';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  private cantidadNotificacionesSinLeer: number = 0;
  private checkSubject = new BehaviorSubject<number>(undefined);
  public notificacion = this.checkSubject.asObservable();

  constructor(private backendService: BackendService) { }

  emitChange(num : number) {
    this.cantidadNotificacionesSinLeer = num;
    this.checkSubject.next(num);
  }

  getCantidadNotificacionesSinLeer(){
    return this.cantidadNotificacionesSinLeer
  }
    
}
