import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BackendService } from '../core/backend.service';
import { EnvironmentProvider } from '../core/browser-providers';
import { Environment } from '../../environments/enviroment.model';
import { FormValidatorServiceService } from '../shared-services/form-validator-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { Registro } from './registro.model';
import { ToastService } from '../shared-services/toast/toast.service';
import { ModalsService } from '../shared-services/modals.service';
import { UserCredentials } from '../seguridad/login/models/user-credentials';
import { SeguridadService } from '../seguridad/seguridad.service';
import { PaisService } from '../shared-services/pais.service';
import {isValidPhoneNumber} from 'libphonenumber-js';
import { CountryPhone } from '../core/models/country-phone.model';
import { RegistroManualService } from './services/registro-manual.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {
  selectPais: string;
  public passwordStrengthColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#3ADCD6'];

  public visibilidad:any;
  public visibilidad2:any;
  form: FormGroup;

  @ViewChild('registroForm') public userForm: NgForm;

  public usuario = { username: '', nombre: '', apellido: '', email: '', retryEmail: '', password: '', retryPassword: '', TYC: '', captcha: ''};

  countries: CountryPhone[];

  errorBoolean: boolean = true;

  private usuarioPaso1 = new Registro();
  private clientesPath: string;
  public captcha: boolean;
  
  private errorPassword = "Las Contraseñas no coinciden, vuelve a intentar !";
  private passwordValidations = [
    {
      check: () => (!(this.usuario.retryPassword != this.usuario.password)),
      error: "Error: Las contraseñas deben ser iguales"
    },
    {
      check: (password) => (password.length > 7 && password.length < 17),
      error: "Error: La contraseña debe tener entre 8 y 16 caracteres"
    },
    {
      check: (password) => (/[A-Za-z]+/.test(password)),
      error: "Error: En la contraseña faltan letras"
    },
    {
      check: (password) => (/[0-9]+/.test(password)),
      error: "Error: En la contraseña faltan números"
    },
    /* VERIFICACION CARACTERES ESPECIALES
    {
      check: (password) => (/[!\"$%&/()=?@~`\\.\';:+=^*_-]+/.test(password)),
      error: "Error: En la contraseña faltan caracteres especiales"
    },*/
    {
      check: () => (this.formValidatorService.isFormValid(this.userForm)),
      error: "Error: El formulario es invalido"
    }]

  constructor(
    @Inject(EnvironmentProvider) env: Environment,
    private router: Router,
    private modalService: ModalsService,
    private backendService: BackendService,
    private formValidatorService: FormValidatorServiceService,
    private spinner: NgxSpinnerService,
    private toast: ToastService,
    private paisService: PaisService,
    private seguridadService: SeguridadService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private registroManualService: RegistroManualService
    ) {
      this.clientesPath = env.endpoints.clientes;
      this.captcha = env.captcha;
    }

  async ngOnInit() {
    this.route.data.subscribe(data => {
      this.countries = data.countries as CountryPhone[];
    });

    this.selectPais = localStorage.getItem('pais');
    if(this.selectPais == null){
        await this.paisService.detectCountry().then(a=>{
        this.selectPais = localStorage.getItem('pais');
      });
    }

    this.form = this.formBuilder.group({
      phoneNumber: [undefined, [Validators.required, this.createPhoneNumberValidator()]],
      countryId: [10, [Validators.required]],
      recaptcha: [undefined, [Validators.required]],
      hasReferralCode: [false],
      referralCode: [undefined],
    });

    this.visibilidad = document.getElementById("registerPass");
    this.visibilidad2 = document.getElementById("registerPass2");

  }

  openTyCModal() {
    this.modalService.openTyC();
  }

  async save() {

    let password = this.usuario.password;

    const validationNotFulfilled = 
    this.passwordValidations.find(validation =>
      !validation.check(password)
    );
    
    if(validationNotFulfilled){
      this.toast.openErrorGenerico(validationNotFulfilled.error)
    } 
    else{this.invokeSave();}
    
  }

  validateForm() {
    
    return this.formValidatorService.isFormValid(this.userForm)
    
  }

  private async invokeSave() {

    if (this.validateForm()) {
      this.spinner.show();
      this.saveUsuarioPaso1();
    }

    return;
  }

  public resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response ${captchaResponse}:`);
  }

  async saveUsuarioPaso1() {
  
    this.usuarioPaso1.username = this.usuario.email;

    this.usuarioPaso1.nombre = this.usuario.nombre;
    this.usuarioPaso1.apellido = this.usuario.apellido;
    
    this.usuarioPaso1.email = this.usuario.email;
    this.usuarioPaso1.password = this.usuario.password;

    try {
      await this.backendService.create(this.clientesPath + Registro.path + "/" + this.selectPais, this.usuarioPaso1);
      
      await this.autoLogin();

      
    } catch (ex) {
      console.log(ex);
      if (ex instanceof Array) {
          ex.forEach(element => {
            this.toast.openErrorGenerico(element.details);
          });
      } else {
        this.toast.openErrorGenerico(ex.message);
      }
    } finally {
      this.spinner.hide();
    }
  }

  passwordVisible(){

  if (this.visibilidad.type === "password") {
    this.visibilidad.type = "text";
  } else {
    this.visibilidad.type = "password";
  }
    
  }

  passwordVisible2(){

    if (this.visibilidad2.type === "password") {
      this.visibilidad2.type = "text";
    } else {
      this.visibilidad2.type = "password";
    }
      
  }

  async autoLogin(){
    var credentials =  new UserCredentials();
      credentials.username = this.usuarioPaso1.username;
      credentials.password =this.usuarioPaso1.password;
      const retorno = await this.seguridadService.login(credentials).then(a=>{
        this.spinner.hide();
        //this.router.navigateByUrl('/producto-logo/' + productoCarrito.infoProducto.id);
        this.router.navigate(['/registro/cuenta-creada']);
      });
  }

  createPhoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      const userPhoneNumber = this.form.get('phoneNumber').value.toString();
      //@ts-ignore
      return isValidPhoneNumber(userPhoneNumber, this.getIsoCodeCountry()) ? null : {phoneInvalid: true};
    };

  }

  getIsoCodeCountry() {
    return //this.countries.find(country => country.id === this.form.controls.countryId.value)!.iso;
  }

  changeError(a: boolean) {
    this.errorBoolean = a;
  }

  validacionRegion() {
    this.form.controls.phoneNumber.updateValueAndValidity();
  }

  registerPhone() {
    if (!this.form.controls.referralCode.value) {
      // @ts-ignore
      this.form.controls.referralCode.setValue(this.activatedRoute.snapshot.queryParams.code)
    }

    this.registroManualService.registroTelefono(this.form.getRawValue());
  }


}
