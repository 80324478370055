import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Partido } from '../../resultado-producto/models/partidos.model';
import { CostoEnvio } from '../../resultado-producto/models/costo-envio';
import { Parametric } from 'src/app/cliente/parametric.model';
import { PaisEnum } from 'src/app/core/constants/pais-enum';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/core/backend.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalsService } from 'src/app/shared-services/modals.service';
import { ApiService } from 'src/app/core/api.service';
import { NotificacionesCarrito } from 'src/app/shared-services/notificacion-carrito.service';
import { NotificacionesComunicationService } from 'src/app/shared-services/notificaciones-comunication.service';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { ProductoService } from '../../service/producto.service';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { ClienteService } from 'src/app/core/cliente.service';
import { Gtag } from 'angular-gtag';
import { Cliente } from 'src/app/cliente/cliente.model';
import { Direccion } from 'src/app/cliente/direccion.model';
import { Localidad } from '../../resultado-producto/models/localidades.model';
import { DireccionCompleta } from 'src/app/cliente/direccionCompleta.model';
import { WebpayUrlToken } from '../../resultado-producto/webpay-urlToken.model';
import { PaymovilPago } from '../../resultado-producto/models/tarjeta-pago-dto';
import { Perfil } from 'src/app/shared-components/perfil-usuario/models/perfil-usuario.model';
import { TipoEnvio } from 'src/app/shared-components/header/tipo-envio';
import { PaymovilResponse } from '../../resultado-producto/models/paymovil-response-dto';
import { PedidoSimple } from '../../resultado-producto/models/pedido-simple.model';
import { Pedido } from '../../resultado-producto/models/pedido.model';
import { DescuentoPedido } from '../../resultado-producto/models/descuento-pedido.model';
import { UserCredentials } from 'src/app/seguridad/login/models/user-credentials';
import { DatosPersonalesModel } from '../../resultado-producto/models/datos-personales.model';
import { UsuarioService } from 'src/app/core/usuario.service';
import { ScrollerService } from 'src/app/core/services/scroller.service';

enum CheckBoxType {CONSUMIDOR_FINAL = 1, EMPRESA = 4,EMPRESA_EXENTO=5, NONE= null};

@Component({
  selector: 'app-checkout-dos',
  templateUrl: './checkout-dos.component.html',
  styleUrls: ['./checkout-dos.component.scss']
})
export class CheckoutDosComponent implements OnInit {

  @Input() pedido:Partial<Pedido>;
  @Output() continuarCompraDosEvent = new EventEmitter<Partial<Pedido>>();
  @Output() volverAPasoUnoEvent = new EventEmitter<Boolean>();

  check_box_type = CheckBoxType;
  currentlyChecked: CheckBoxType;
  public provincias: Parametric[];
  public valorSituacionIvaId;
  public direcciones: string[] = [];
  public tiposDocumentos: Parametric[];
  public documentoSize: number;
  public direccionesPerfil: Direccion[];
  public costoEnvioIva: number;
  public localidades: Localidad[];
  public partidos: Partido[];
  public perfil: Perfil;
  public tipoUsuario;
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,20}\s*$/;
  linkProductoAgregado : string = '/new-pedidos/checkout-success/';
  estadoAdicionales:number = 14;
  estadoPagado:number=8;
  numberPattern = /^([0-9][0-9]*)$/;
  telefonoPattern = "^\\d+$";
  textoPattern= "[A-Za-z]+[ A-za-z]*";
  costoEnvio: number;
  formFacturacion: FormGroup;
  formEnvio: FormGroup;
  formDatosPersonales: FormGroup;
  formCodigoDescuento: FormGroup;
  randomPassword: string;
  datosPersonales: DatosPersonalesModel;
  situacionesIva:Parametric[];
  response:PaymovilResponse;
  public pedidoSimple: PedidoSimple;
  public subtotal = 0;
  public cantidadDeLogos:number = 0;
  public iva: number = 0;
  public selectPais:string;
  precioTotal:number;
  hayTalles: Boolean = false;
  codigoDescuentoEnviar: string;
  codigoIngresado:boolean = true;
  public descuentoAplicablePedido: DescuentoPedido = null;
  public tipoUsuarioNotSelected = true;
  public tempUser: boolean;
  private user;
  private codigoPostalValido: boolean = false;
    

  constructor(
    private backendService: BackendService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: ModalsService,
    private apiService: ApiService,
    public notificacionesCarrito: NotificacionesCarrito,
    private seguridadService: SeguridadService,
    private toast: ToastService,
    private gtg: Gtag,
    private usuarioService: UsuarioService,
    private scrollerService: ScrollerService
  ) { }

  async ngOnInit() {
    this.user = this.seguridadService.user;
    await this.createForms();
    await this.getUserProfile();
    await this.getProvinciasYDepartamentos();
    this.valorSituacionIvaId = await this.backendService.getNumber(Cliente.path + 'obtener-situacionIva/' + this.pedido.id);
    this.formEnvio.controls.cp.valueChanges.subscribe();
    await this.loadUserData();
    await this.getTempUser();
    this.subtotal = this.pedido.subtotal;
    this.datosPersonales = new DatosPersonalesModel();
    await this.usuarioService.carritoVolver.subscribe(u => {
      if(u){
        this.volverAPasoUnoEvent.emit(true);
      }
    })
  }

  createForms(){
    this.formEnvio = this.formBuilder.group({
      direccion: [undefined, [Validators.required]],
      altura:  [undefined, [Validators.required]],
      piso:[undefined, ],
      dpto:[undefined, ],
      cp: [undefined, [Validators.required,Validators.pattern(this.telefonoPattern),Validators.minLength(4)]],
      entreCalle1:[undefined, [Validators.required]],
      entreCalle2:[undefined, [Validators.required]],
      prov:[undefined, [Validators.required]],
      localidad: [undefined, [Validators.required]],
      partido: [undefined, ]
    });
    this.formFacturacion = this.formBuilder.group({
      nombre: [undefined, [Validators.required]],
      email:  [undefined, [Validators.required,Validators.pattern(this.emailPattern)]],
      documento: [undefined, [Validators.required,Validators.pattern(this.numberPattern),Validators.minLength(5)]],
      telefono: [undefined, [Validators.required,Validators.pattern(this.numberPattern),Validators.minLength(6)]],
      tipoFacturacion:[undefined, [Validators.required]]
    })
    this.formDatosPersonales = this.formBuilder.group({
      nombre: [undefined, [Validators.required]],
      empresa: [undefined, [Validators.required]],
      email:  [undefined, [Validators.required,Validators.pattern(this.emailPattern)]],
      telefono: [undefined, [Validators.required,Validators.pattern(this.numberPattern),Validators.minLength(6)]],
    })
  }

  async getProvinciasYDepartamentos(){
    this.spinner.show();
    this.provincias = await this.apiService.get(Parametric, Parametric.path + 'provincias/' + PaisEnum.ARGENTINA, null).toPromise();
    for (const prov of this.provincias){
      // @ts-ignore
      if(prov.nombre == this.perfil.provincia){
        await this.getDepartamentos(this.perfil.provincia);
        await this.getLocalidades(this.perfil.partido);
      }
    }
    this.spinner.hide();
  }

  async getDepartamentos(provName){
    this.spinner.show();
    await this.backendService.get(Partido, CostoEnvio.path + "departamentos/" + provName, null).toPromise().then(part =>{
      this.partidos = part as Partido[];
      this.spinner.hide();
    });
    this.formEnvio.patchValue({localidad: null});
    this.formEnvio.patchValue({partido: null});
  }

  async getLocalidades(deptoId){
    this.spinner.show();
    await this.backendService.get(Localidad, CostoEnvio.path + "localidades/" + deptoId, null).toPromise().then(a =>{
      this.localidades = a as Localidad[];
      this.spinner.hide();
    });
    this.formEnvio.patchValue({localidad: null});
  }

  async getUserProfile(){
    this.spinner.show();
    this.perfil = await this.backendService.get(Perfil, Perfil.path).toPromise();
    this.direccionesPerfil = this.perfil.direcciones;
    this.tipoUsuario = this.perfil.tipoUsuario;
    this.spinner.hide();
  }

  async getTempUser(){
    await this.backendService.getBoolean(Cliente.path + 'getTempUser').then(a => {
      this.tempUser = a
    });
  }

  async saveDatosPersonales(){
    this.datosPersonales.nombreCompleto = this.formDatosPersonales.controls.nombre.value;
    this.datosPersonales.empresa = this.formDatosPersonales.controls.empresa.value;
    this.datosPersonales.email = this.formDatosPersonales.controls.email.value;
    this.datosPersonales.numTel = this.formDatosPersonales.controls.telefono.value;
  }

  async cambiarCostoEnvio(codPostal){
    this.spinner.show();
    if(codPostal != 0){
    await this.backendService.get(CostoEnvio,CostoEnvio.path+codPostal).toPromise()
      .then((costo: CostoEnvio) =>{
        this.costoEnvio = costo.costoEnvio;
        var iva = 1.21;
        this.costoEnvioIva = this.round(this.costoEnvio * iva);
        this.alertaCambioCostoEnvio(this.costoEnvioIva);
        this.codigoPostalValido = true;
      })
      .catch((e) =>{
        this.costoEnvio= null;
        this.toast.openErrorGenerico("CÓDIGO POSTAL INVALIDO");
        this.codigoPostalValido = false;
      });
    }
    this.spinner.hide();
  }

  round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
  }

  alertaCambioCostoEnvio(costoEnvio: number){
    if(this.costoEnvio !=null){
      this.toast.openSuccessGenerico("AHORA EL COSTO DE ENVIO ES: $"+ costoEnvio)
    }else{
      this.toast.openErrorGenerico("CÓDIGO POSTAL INVALIDO");
    }
  }

  selectCheckBox(targetType: CheckBoxType){
    if(this.currentlyChecked === targetType){
      this.currentlyChecked = CheckBoxType.NONE;
      this.formFacturacion.patchValue({tipoFacturacion:null});
      return;
    }
    this.currentlyChecked = targetType;
    this.formFacturacion.patchValue({tipoFacturacion: targetType})
  }

  async continuarCompra(){
    //Emit de volver
    this.usuarioService.emitChangeVolver(false);
    this.spinner.show();
    
    if(!(this.validateForm(this.formDatosPersonales) && this.validateForm(this.formEnvio) && this.validateForm(this.formFacturacion))){
      this.spinner.hide();
      return;
    };
    if(!this.codigoPostalValido){
      this.toast.openErrorGenerico("CÓDIGO POSTAL INVALIDO");
      this.spinner.hide();
      return;
    }
    try{
      await this.saveDatosPersonales();
      if(this.tempUser){
          await this.backendService.create(Cliente.path + 'datos-personales-temp-user/' + this.seguridadService.user.id, this.datosPersonales).then( a => {
            // @ts-ignore
            this.randomPassword = a._body as string;
          });
          var credentials =  new UserCredentials();
          credentials.username = this.datosPersonales.email;
          credentials.password = this.randomPassword;
          this.seguridadService.logoutTemporalSesion();
          const retorno = await this.seguridadService.login(credentials)
      }else{
        await this.backendService.create(Cliente.path + 'update-datos-personales/', this.datosPersonales)
      }
      this.seteoDatosPedido();
      this.pedido.subtotal = this.subtotal + this.costoEnvioIva;
      let tiempoentrega = this.pedido.tiempoEntrega;
      this.pedido = await this.backendService.update(Pedido.new_path + 'cerrar-pedido/' + this.formFacturacion.controls.tipoFacturacion.value, this.pedido);
      await this.adicionales();
      this.spinner.hide();
      this.pedido.tiempoEntrega = tiempoentrega;
      this.continuarCompraDosEvent.emit(this.pedido);
    }catch (ex) {
      this.spinner.hide();
      if(ex.message == "El usuario ya esta registrado"){
        this.usuarioService.setUsuarioCliente(this.datosPersonales.email);
        const resLogin = await this.modalService.openLoginModal();
        resLogin.subscribe(async value => {
          if (value) {
            await this.backendService.getBoolean(Pedido.new_path + 'remplazar-pedido-abierto/'+this.pedido.id).then(a => {
              if(a){
                this.getTempUser().then(a=>{
                  this.continuarCompra();
                })
              }else{
                console.log("fallo")
              }
            });
          }
        })}
        this.spinner.hide();
        this.toast.openErrorGenerico(ex.message)
    }finally{
      this.gtagContinuar2();
      this.spinner.hide()
    }
  }

  validateForm(form) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
        if (name !== 'empresa' && controls[name].invalid) {
            invalid.push(name);
        }
    }
    if (invalid.length !== 0) {
        var errMsg = "Ingrese correctamente el campo: ";
        for (var a = 0; invalid.length > a; a++) {
            errMsg += invalid[a] + ", ";
        }
        this.formatAndShowErrMsg(errMsg);
        return false;
    } else {
        return true;
    }
  }

  seteoDatosPedido(){
    this.pedido.direccion = this.formEnvio.controls.direccion.value;
    this.pedido.altura = this.formEnvio.controls.altura.value;
    this.pedido.cp = this.formEnvio.controls.cp.value;
    this.pedido.piso = this.formEnvio.controls.piso.value;
    this.pedido.dpto = this.formEnvio.controls.dpto.value;
    this.pedido.localidad = this.formEnvio.controls.localidad.value;
    this.pedido.partido = this.formEnvio.controls.partido.value;
    this.pedido.provincia = this.formEnvio.controls.prov.value;
    this.pedido.entreCalle1 = this.formEnvio.controls.entreCalle1.value;
    this.pedido.entreCalle2 = this.formEnvio.controls.entreCalle2.value;
    this.pedido.nombreEnvio = this.formFacturacion.controls.nombre.value;
    this.pedido.documento = this.formFacturacion.controls.documento.value;
    this.pedido.tipoDocumentoId = '6';
    this.pedido.email = this.formFacturacion.controls.email.value;
    this.pedido.telefono = this.formFacturacion.controls.telefono.value;
    this.pedido.costoEnvio = this.costoEnvio;
  }

  formatAndShowErrMsg(errMsg: string){
    errMsg = errMsg.replace("nombreEnvio","Nombre");
    errMsg = errMsg.replace("apellidoEnvio","Apellido");
    errMsg = errMsg.replace("telefono","Teléfono");
    errMsg = errMsg.replace("email","Email");
    errMsg = errMsg.replace("direccion","Dirección");
    errMsg = errMsg.replace("altura","Número");
    errMsg = errMsg.replace("cp","Código postal");
    errMsg = errMsg.replace("entreCalle1","Entre Calle 1");
    errMsg = errMsg.replace("entreCalle2","Entre Calle 2");
    errMsg = errMsg.replace("prov","Provincia");
    errMsg = errMsg.replace("localidad","Localidad");
    errMsg = errMsg.replace("tipoDocumentoId","Tipo de Documento");
    errMsg = errMsg.replace("documento","Documento");
    errMsg = errMsg.replace("cuit","Cuit");
    errMsg = errMsg.replace("situacionIva","Situación Iva");
    errMsg = errMsg.replace("razonSocial","Razón Social");
    errMsg = errMsg.slice(0, errMsg.length -2);
    this.toast.openErrorGenerico(errMsg);
  }

  loadUserData(){
    if(this.perfil.cp)
      this.cambiarCostoEnvio(this.perfil.cp);
    this.formEnvio.patchValue({
      direccion: this.perfil.direccion,
      altura: this.perfil.numero,
      piso: this.perfil.piso,
      dpto: this.perfil.dpto,
      cp: this.perfil.cp !=null ? this.perfil.cp.toString() : '',
      entreCalle1: this.perfil.entreCalle1,
      entreCalle2: this.perfil.entreCalle2,
      prov: this.perfil.provincia,
      localidad: this.perfil.localidad,
      partido: this.perfil.partido,
    });
    var nombreEnvio;
    var documentoEnvio;
    if(this.perfil.tipoUsuario == 1){
      nombreEnvio = this.perfil.name;
      documentoEnvio = this.perfil.documento;
    }else{
      nombreEnvio = this.perfil.razonSocial;
      documentoEnvio = this.perfil.cuit;
    }
    this.formFacturacion.patchValue({
      tipoFacturacion: this.perfil.tipoUsuario,
      nombre: nombreEnvio,
      documento: documentoEnvio,
      telefono : this.perfil.telefonoPrincipal!=null ? this.perfil.telefonoPrincipal.toString() : '',
    });
    this.selectCheckBox(this.perfil.tipoUsuario);
    if(this.validarEmail(this.user.email)){
      this.formFacturacion.patchValue({
        email: this.user.email,
      })
    }
    if(this.validarEmail(this.user.email)){
      this.formDatosPersonales.patchValue({
        email: this.user.email,
      })
    }
    this.formDatosPersonales.patchValue({
      nombre: nombreEnvio,
      empresa: this.perfil.empresa,
      telefono: this.perfil.telefonoPrincipal!=null ? this.perfil.telefonoPrincipal.toString() : '',
    })
  }

  adicionales(){
    const resAdicionales = this.modalService.OpenModalConfirmacionAdicionales();
    resAdicionales.subscribe(async (valueAdicional) => {
      if(valueAdicional){
        try{ 
          this.spinner.show();
          this.backendService.update(this.linkProductoAgregado + this.pedido.id + '/' + this.estadoAdicionales, null);
          this.notificacionesCarrito.emitChange(0);
          this.router.navigateByUrl('/mis-pedidos');
        }catch (ex) {
          if (ex[0]) {
            this.toast.openErrorGenerico(ex[0].details);
          } else if (ex.errors) {
            this.toast.openErrorGenerico(ex.errors[0].defaultMessage);
          } else {
            this.toast.openErrorGenerico(ex.message);
          }
        }
        finally{
          this.scrollerService.scrollToTop();
          this.spinner.hide();
        }
      }
    })
  }

  wspEventAr(){
    this.gtg.event('boton_whatsapp_argentina_checkout', {
      items: [{
        item_name: "boton whatsapp argentina checkout"
      }]
    });
  }

  async obtenerPdfPresupuesto(){
    if(this.descuentoAplicablePedido){
     this.pedido.descuentoPedido = this.descuentoAplicablePedido;
     this.spinner.show();
     await this.backendService.getPresupuesto(Pedido.new_path +'presupuestoConDescuento/'+ this.pedido.id, this.pedido).toPromise().then(a =>{
     this.createBlob(a);
     this.spinner.hide();
     })
   }else{
     this.spinner.show();
     await this.backendService.getPresupuesto(Pedido.new_path +'presupuestoConDescuento/'+ this.pedido.id, this.pedido).toPromise().then(a =>{
     this.createBlob(a);
     this.spinner.hide();
    })}
    await this.backendService.update(Pedido.new_path + 'presupuesto-descargado/' + this.pedido.id, Boolean);
     this.gtg.event('budget_checkout', {
       items: [{
         item_name: "pdf_checkout"
       }]
     });
  }

 createBlob(pdf: any) {
    const blob = new Blob([pdf], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  validarEmail(email) {
    var regex = this.emailPattern;
    return regex.test(email);
  }

  onCpBlur() {
    const codPostal = this.formEnvio.controls.cp.value;
    this.cambiarCostoEnvio(codPostal);
  }

  volver(){
    this.volverAPasoUnoEvent.emit(true);
  }

  gtagContinuar2(){
    this.gtg.event('continua_paso_2_checkout', {
      items: [{
        item_name: "Continua paso 2 checkout"}]
    });
  }

}
