export class Logo {
  id: number;
  name: string;
  path: string;
  url?: string;

  size: number;
  fuente: string;
  color: string;
  texto: string;
}
