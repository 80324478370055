import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-mensaje-recuperar-password',
  templateUrl: './mensaje-recuperar-password.component.html',
  styleUrls: ['./mensaje-recuperar-password.component.scss']
})
export class MensajeRecuperarPasswordComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    
  }

}
