import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AddCarritoToastComponent} from './add-carrito-toast/add-carrito-toast.component';
import {CantidadMinimaToastComponent} from './cantidad-minima-toast/cantidad-minima-toast.component';
import {StockInsuficienteToastComponent} from './stock-insuficiente-toast/stock-insuficiente-toast.component';
import { PrecioEnvioComponent } from './precio-envio-toast/precio-envio.component';
import { CpInvalidoToastComponent } from './cp-invalido-toast/cp-invalido-toast.component';
import { ImagenSubidaToastComponent } from './imagen-subida-toast/imagen-subida-toast.component';
import { TipoDeImpresionToastComponent } from './tipo-de-impresion-toast/tipo-de-impresion-toast.component';
import { ErrorGenericoComponent } from './error-generico/error-generico.component';
import { SuccessGenericoToastComponent } from './success-generico-toast/success-generico-toast.component';
import {UsernameInvalidComponent} from './username-invalid/username-invalid.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toast: ToastrService) { }

  //desuso
  openToastCarrito(){
    this.toast.show(null,null,{
      toastClass: "alert alert-gray alert-dismissible alert-vc opacity-100 toast",
      toastComponent: AddCarritoToastComponent,
      timeOut:5000
    })
  }
  //desuso
  openToastCantidadMinima(cantidadMinima: number){
    this.toast.show("LA CANTIDAD MÍNIMA ES "+ cantidadMinima,null,{
      toastClass: "alert alert-red alert-dismissible alert-vc opacity-100 toast",
      toastComponent: CantidadMinimaToastComponent,
      timeOut:5000
    })
  }
  //desuos
  openStockInsuficiente(){
    this.toast.show(null,null,{
      toastClass: "alert alert-red alert-dismissible alert-vc opacity-100 toast",
      toastComponent: StockInsuficienteToastComponent,
      timeOut:5000
    })
  }
  //desuso
  openCambioPrecioEnvio(costoEnvio: number){
    this.toast.show("AHORA EL COSTO DE ENVIO ES: $"+ costoEnvio,null,{
      toastClass: "alert alert-gray alert-dismissible alert-vc opacity-100 toast",
      toastComponent: PrecioEnvioComponent,
      timeOut:5000
    })
  }
  //desuso
  openCpInvalido(){
    this.toast.show(null,null,{
      toastClass: "alert alert-red alert-dismissible alert-vc opacity-100 toast",
      toastComponent: CpInvalidoToastComponent,
      timeOut:5000
    })
  }
  //desuso
  openCompraMinima(){
    this.toast.show("Recordá que nuestra compra mínima es de $50.000. si querés comprar otros productos agregalos a tu carrito",null,{
      toastClass: "alert alert-red alert-dismissible alert-vc opacity-100 toast",
      toastComponent: CantidadMinimaToastComponent,
      timeOut:5000
    })
  }
  //desuso
  openImagenSubida(){
    this.toast.show(null, null,{
      toastClass: "alert alert-gray alert-dismissable alert-vc opacity-100 toast",
      toastComponent: ImagenSubidaToastComponent,
      timeOut:5000
    })
  }
  //desuso
  openTipoImpresion(){
    this.toast.show(null, null, {
      toastClass: "alert alert-red alert-dismissible alert-vc opacity-100 toast",
      toastComponent: TipoDeImpresionToastComponent,
      timeOut:5000
    })
  }
  //Error generico nuevo
  openErrorGenerico(string: string){
    this.toast.show(string.toUpperCase(), null, {
      toastClass: "alert error alert-dismissible alert-fix fade show toast mesajeErrorFormularioCompraMobile",
      toastComponent: ErrorGenericoComponent,
      timeOut:10000
    })
  }
  openSuccessGenerico(string:string){
    this.toast.show(string.toUpperCase(),null,{
      toastClass: "alert success alert-dismissible alert-fix fade show mensajeMobile toast",
      toastComponent: SuccessGenericoToastComponent,
      timeOut:10000
    })
  }

  //desuso
  openErrorUsernameInvalid(){
    this.toast.show(null,null,{
      toastClass: "alert alert-red alert-dismissible alert-vc opacity-100 toast",
      toastComponent: UsernameInvalidComponent,
      timeOut:500000
    })
  }
}
