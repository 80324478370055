import { Injectable } from '@angular/core';

import {BehaviorSubject} from 'rxjs';
import { ProductoCarrito } from '../productos/models/producto-carrito';
import { BackendService } from '../core/backend.service';
import { Pedido } from '../productos/resultado-producto/models/pedido.model';
import { Categoria } from '../shared-components/dashboard/categoria.model';

@Injectable({
    providedIn: 'root'
  })
  export class NotificacionesCarrito {

    private cantidadProductosCarrito: number = 0;
    private checkSubject = new BehaviorSubject<number>(undefined);
    public producto = this.checkSubject.asObservable();
    pedido: Pedido;

    constructor(private backendService: BackendService) { }
    

  emitChange(num : number) {
    this.cantidadProductosCarrito = num;
    this.checkSubject.next(num);
  }

  setAgregar(){
    this.cantidadProductosCarrito = this.cantidadProductosCarrito + 1;
  }

  setQuitar(){
    this.cantidadProductosCarrito = this.cantidadProductosCarrito - 1;
  }

  getCantidadProductosCarrito(){
    return this.cantidadProductosCarrito
  }


  }