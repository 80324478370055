import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Google2faComponent } from '../google2fa/google2fa.component';

@Component({
  selector: 'app-google2fa-modal',
  templateUrl: './google2fa-modal.component.html',
  styleUrls: ['./google2fa-modal.component.scss']
})
export class Google2faModalComponent implements OnInit {

  public google2fa: string;
  constructor(
    private ngxSmartModalService: NgxSmartModalService
  ) { }

  ngOnInit() {
  }

  async setModalData() {
    await this.ngxSmartModalService.setModalData(this.google2fa, 'google2faModal');
    this.ngxSmartModalService.close('google2faModal');
  }

  async validate2fa() {
    this.ngxSmartModalService.open('google2faModal');
    await this.ngxSmartModalService.getModal('google2faModal').onCloseFinished.subscribe((modal: Google2faComponent) => {
      return this.ngxSmartModalService.getModalData('google2faModal');
    });
  }

}
