import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirmacion-adicionales-modal',
  templateUrl: './confirmacion-adicionales-modal.component.html',
  styleUrls: ['./confirmacion-adicionales-modal.component.scss']
})
export class ConfirmacionAdicionalesModalComponent implements OnInit {

  public onClose: Subject<boolean>;
  selectPais: string;


  constructor(
    public bsModalRef: BsModalRef
  ) {
    
  }

  ngOnInit() {
    this.onClose = new Subject();
    this.selectPais = localStorage.getItem('pais');
  }

  noConfirma() {
    
    this.onClose.next(false);
    this.bsModalRef.hide();

  }

  confirma(){
    this.onClose.next(true);
    
    this.bsModalRef.hide();
  }

  noConfirmaAceptado() {    
    this.bsModalRef.hide();
  }

  cerrar(){
    this.bsModalRef.hide();
  }

}
