import { Component, OnInit, Input } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ModalsService } from 'src/app/shared-services/modals.service';
import { BackendService } from '../../core/backend.service';
import { SeguridadService } from '../../seguridad/seguridad.service';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { ContactoUsuario } from '../../seguridad/usuario/contacto-usuario.component';
import { Usuario } from '../../cliente/usuario.model';
import { EmailContacto } from '../dashboard/email-contacto.model';
import { Cliente } from '../../cliente/cliente.model';
import { NotificacionService } from 'src/app/notificaciones/services/notificacion.service';
import { Gtag } from 'angular-gtag';
declare const swal: any;
declare const $: any;


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  /*@Input() mypURL: string;
  @Input() soporteURL: string;
  @Input() comisionesURL: string;*/
  dataUsuario: any;
  email: string;
  ejecutivo: ContactoUsuario;
  emailContacto: EmailContacto;
  user: any;
  mensaje: string;
  cantidadNotificaciones: number = 0;
  selectPais: string;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private modalService: ModalsService,
    private backendService: BackendService,
    private seguridadService: SeguridadService,
    private spinner: NgxSpinnerService,
    private toast: ToastService,
    private gtag: Gtag,
    private notificacionService: NotificacionService) { }
    

  async ngOnInit() {
    
    this.selectPais = localStorage.getItem('pais');
    // await this.backendService.getString(Subcliente.path + 'info-email').then(data => {
    //   this.dataUsuario = data;
    // });
    // if (this.dataUsuario.email) {
    //   this.email = this.dataUsuario.email.direccion;
    // }

    /*await this.backendService.get(ContactoUsuario, Usuario.path ).toPromise().then(
      data => this.ejecutivo = data
    );

    this.user = this.seguridadService.user;
    this.emailContacto = this.backendService.new(EmailContacto);*/

    /*await this.backendService.getNumber('/notificaciones/estado-notificaciones').then(cantidad =>{ 
      this.cantidadNotificaciones = cantidad as number;
    });

    this.notificacionService.emitChange(this.cantidadNotificaciones);
    this.cantidadNotificaciones = this.notificacionService.getCantidadNotificacionesSinLeer();

    this.notificacionService.notificacion.subscribe(val =>{
      this.cantidadNotificaciones = val;
    })*/
  }

  wspEventFooter(){
    this.gtag.event('whatsapp_footer', {
      items: [{
        item_name: "boton whatsapp del footer"
      }]
    });
  }

  /*No se usa
  async enviarMail() {
    if (!this.email) {
      this.toast.openErrorGenerico('Debe ingresar una dirección de correo.');
      return;
    }
    try {
      this.spinner.show();
      this.emailContacto.mensaje = this.mensaje;
      this.emailContacto.email = this.email;
      await this.backendService.update(Cliente.path + 'email-contacto', this.emailContacto );
      swal({
        title: 'Mensaje enviado',
        text: 'Su mensaje ha sido enviado correctamente.',
        type: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonText: 'Cerrar',
        closeOnConfirm: true });
      this.mensaje = '';
      if (this.dataUsuario && this.dataUsuario.email) {
        this.email = this.dataUsuario.email.direccion;
      }
      $('#myModal').modal('hide');
      $('.modal-backdrop').remove();
    } catch (error) {
      console.log(error);
      this.toast.openErrorGenerico('Error al enviar el email.');
    } finally {
      this.spinner.hide();
    }
  }*/
}
