import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentProvider } from '../../core/browser-providers';
import { Environment } from '../../../environments/enviroment.model';

@Injectable()
export class LoginResolver {

  constructor(
    @Inject(EnvironmentProvider) env: Environment,
    protected router: Router,
  ) {}

  async resolve() {
    if (this.getCookie('jwtToken')) {
        this.router.navigateByUrl('/');
    }
  }

  
  getCookie(value:string) {
    const cookies = document.cookie.split(';');
    const myCookie = cookies.find(cookie => cookie.trim().startsWith(value +'='));
    
    if (myCookie) {
      const value = myCookie.split('=')[1];
      return value;
    } 
    return myCookie;
  }

}
