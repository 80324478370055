import { Component, OnInit } from '@angular/core';
import { TipoDocumentacion } from './tipoDocumentacion.model';
import { BackendService } from '../../../core/backend.service';
import { Cliente } from '../../../cliente/cliente.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cliente-details',
  templateUrl: './cliente-details.component.html',
  styleUrls: ['./cliente-details.component.scss']
})
export class ClienteDetailsComponent implements OnInit {

  public tipoDocumentacion: TipoDocumentacion[];
  hasDocumentacionPendiente: boolean;

  constructor(
    private backendService: BackendService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.getTipoDocumentacion();
    this.hasDocPendiente();
  }

  async getTipoDocumentacion() {
    const tipoDocPromise = await this.backendService.get(TipoDocumentacion, Cliente.path + TipoDocumentacion.path, null).toPromise();

    const obj = await (async () => {
      try {
        return await tipoDocPromise;
      } catch (error) {
        return [];
      }
      finally {}
    })();

    this.tipoDocumentacion = Object.keys(obj).map(key => (obj[key]));

  }

  navigateDocumentacion() {
    this.router.navigate(['/documentacion']);
    window.scrollTo(0, 0);
  }

  async hasDocPendiente() {
    this.hasDocumentacionPendiente = await this.backendService.hasDocumentacionPendiente.toPromise();
  }

}
