import { BackendModel, BackendService } from '../../core/backend.service';

export class ProductosBuscados extends BackendModel {

    static path:string = '/new-productos/';
     nombre:string;
     slug:string;

  constructor(protected backendService: BackendService, model?: ProductosBuscados) {
      super(backendService, model);
  }
}
