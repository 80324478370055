import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  titulo: string;
  mensaje: string;
  no: string;
  yes: string;

  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  noConfirma() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  confirma() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

}
