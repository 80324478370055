import { NumberValueAccessor } from "@angular/forms/src/directives";
import { ProductoParticularPedido } from "../../models/producto-particular-pedido.model";
import { Parametric } from "../../../cliente/parametric.model";
import { ProductoEnviar } from "../../viejo/productoEnviar.model";
import { BackendService, BackendModel } from "../../../core/backend.service";
import { Acuerdo } from "../../viejo/acuerdo.model";
import { Direccion } from "src/app/cliente/direccion.model";
import {Render} from '../../producto-particular-logo/model/render';
import { DescuentoPedido } from "./descuento-pedido.model";
import { Pais } from "src/app/shared-services/models/pais.model";

export class Pedido  extends BackendModel {
    static path = '/pedidos/';
  static new_path = '/new-pedidos/';
    id: number;
    usuarioAprobador: any;
    //usuarioCliente: any;
    acuerdo: Acuerdo;
    numero: number;
    estado: Parametric;
    fecha: Date;
    fechaAprobacion: Date;
    productos: ProductoEnviar[];
    observaciones: string;
    direccion: string;
    direcciones: Direccion[];
    nombreEnvio: string;
    pais: Pais;
    apellidoEnvio: string;
    tipoEnvio: string;
    tipoEnvioCosto: number;
    tipoEnvioDetalle: string;
    email: string;
    telefono: string;
    altura: number;
    localidad: string;
    dpto: string;
    piso: string;
    provincia: string;
    cp: string;
    partido: string;
    comprobante: string;
    transferenciaAprobada: Boolean;
    subtotal: number;
    tipoDocumentoId:string;
    entreCalle1: string;
    entreCalle2: string;
    documento:string;
    costoEnvio: number;
    renders: Render[];
    //Empresa
    cuit:string;
    razonSocial:string;
    situacionIva:string;
    tiempoEntrega: number;
    facturaUrl:string;
    //Descuento
    descuentoPedido: DescuentoPedido;
    subeLogoCurvas: boolean;
    precioFinal: number;

    constructor(protected backendService: BackendService, model?: Pedido) {
        super(backendService, model);
    }
}
