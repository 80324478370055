import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BackendService } from 'src/app/core/backend.service';
import { ModalsService } from 'src/app/shared-services/modals.service';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { Pedido } from '../../productos/resultado-producto/models/pedido.model';
import {Imagen} from '../../productos/viejo/imagen.model';
import {Cliente} from '../../cliente/cliente.model';
import {ClienteService} from '../../core/cliente.service';
import {Render} from '../../productos/producto-particular-logo/model/render';
import { Perfil } from '../perfil-usuario/models/perfil-usuario.model';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { PaisEnum } from 'src/app/core/constants/pais-enum';

@Component({
  selector: 'app-mis-pedidos',
  templateUrl: './mis-pedidos.component.html',
  styleUrls: ['./mis-pedidos.component.scss']
})
export class MisPedidosComponent implements OnInit {

  public misPedidos: Pedido[];
  public modalAbierto: Boolean;
  public perfil: Perfil;
  public tipoUsuario;
  public result;
  public subioLogosCurvas: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalsService,
    private router: Router,
    private backendService: BackendService,
    private spinner: NgxSpinnerService,
    private clienteService: ClienteService,
    private toast: ToastService
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.misPedidos = data.pedidos as Pedido[];
    });
    this.modalAbierto = false;

  }

  getObservacion(pedido) {
    document.getElementById('observaciones' + pedido.id ).innerHTML = pedido.observaciones.slice(0, 50) + '...';
  }

  async subirComprobante(id: number){
    this.spinner.show();
    this.modalAbierto = true;
    const pedidoSeleccionado = await this.backendService.get(Pedido, Pedido.new_path + id).toPromise();
    this.perfil = await this.backendService.get(Perfil, Perfil.path).toPromise();
    this.tipoUsuario = this.perfil.tipoUsuario;
        
    pedidoSeleccionado.subtotal = this.getSubtotal(pedidoSeleccionado);
    this.spinner.hide();
    if(this.tipoUsuario==1){
      pedidoSeleccionado.documento = this.perfil.documento;
      this.result = this.modalService.transferenciaBancaria(pedidoSeleccionado, pedidoSeleccionado.nombreEnvio,null);
    }else if(this.tipoUsuario==4){
      pedidoSeleccionado.cuit = this.perfil.cuit;
      this.result = this.modalService.transferenciaBancaria(pedidoSeleccionado, null,pedidoSeleccionado.cuit);
    }
    
    

    this.result.subscribe(async (value)=>{
      if(value){
        this.modalService.openModalComprobanteSubido();
        window.location.reload();
      }
    })
  }

  getSubtotal(pedido:Pedido) {
    let subtotal = 0;
    for (let i = 0; i < pedido.productos.length; i++) {
      subtotal = subtotal + pedido.productos[i].cantidad * (pedido.productos[i].precioUnitario + (pedido.productos[i].precioAdicional * pedido.productos[i].cantidadLogos));
    }
    return subtotal;
  }
  downloadRenders(renders){
    for(let i = 0; i < renders.length; i++){
      this.getFiles(renders[i]);
    }
  }
  async getFiles(d: Render) {
    await this.clienteService.getRenderById(d.id).subscribe(data => {
      const link = document.createElement('a');
      const blob = new Blob([data], {type: data.type});
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = "render.jpg";
      link.click();
    }, error => {
      console.log(error);
    });
  }

  async subirLogosConCurvas(pedidoId:number,pedido){
      
      const confirmado = await this.modalService.OpenModalSubirLogoConCurvas(pedidoId, 1);
      
      confirmado.subscribe(async (value) =>{
       
        if(value){
          this.spinner.show()
          this.subioLogosCurvas = value;
          this.toast.openSuccessGenerico("Se subió con exito")
          this.router.navigateByUrl('/mis-pedidos/'+pedidoId);
          //window.location.reload();
          this.spinner.hide()
        }
        
      })
      
    }

    descargarFactura(pedidoId){
      
      try{
        this.spinner.show();
        this.backendService.getPdf(Pedido.new_path +'factura/'+ pedidoId, null).toPromise().then(a =>{
          this.createBlob(a);
          
          
          })
      }catch(ex){
          console.log("Error Factura")
      }finally{
        this.spinner.hide();
      }
     
      
    }

    createBlob(pdf: any) {
      const blob = new Blob([pdf], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    }

    cambioPais(paisId:number){
      if(PaisEnum.ARGENTINA == paisId){
        localStorage.setItem('pais', "ARGENTINA");
        window.location.href = "/previsualizacion-compra";
      }else if(PaisEnum.CHILE == paisId){
        localStorage.setItem('pais', "CHILE");
        window.location.href = "/previsualizacion-compra-cl";
      }else{
        this.toast.openErrorGenerico("Ocurrio un error al redirigir al CHECKOUT")
      }
      
    }

}
