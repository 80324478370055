import { Injectable } from '@angular/core';
import { ProductoCarrito } from '../models/producto-carrito';
import { ProductoService } from './producto.service';
import { Router } from '@angular/router';
import { Gtag } from 'angular-gtag';
import { RegistroTemporal } from '../producto-particular/dtos/registo-temporal';
import { BackendService } from 'src/app/core/backend.service';
import { UserCredentials } from 'src/app/seguridad/login/models/user-credentials';
import { SeguridadService } from 'src/app/seguridad/seguridad.service';
import { UsuarioService } from 'src/app/core/usuario.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { ProductoEnviar } from '../viejo/productoEnviar.model';

@Injectable({
  providedIn: 'root'
})
export class ProductoParticularService {

  constructor(
    private router:Router,
    private productoService:ProductoService,
    private gtag:Gtag,
    private backendService: BackendService,
    private seguridadService: SeguridadService,
    private usuarioService:UsuarioService,
    private spinner: NgxSpinnerService,
    private toastService:ToastService
  ) { }


  async redirectToProductoLogo (productoCarrito: ProductoCarrito) {
    /*if (productoCarrito.cantidad < this.productoParticular.infoProducto.cantidadMinimaOrden) {
      this.toastService.openSuccessGenerico("LA CANTIDAD MÍNIMA PARA BONIFICAR TU LOGO ES: "+ this.productoParticular.infoProducto.cantidadMinimaOrden + ", SE TE COBRARÁ UN ADICIONAL.");
      //this.toastService.openErrorGenerico("LA CANTIDAD MÍNIMA PARA BONIFICAR TU LOGO ES: "+this.cantidadMinima);
      //this.confirma();
      //return;
    }*/
    this.productoService.emitChange(productoCarrito);
    //this.router.navigateByUrl('/producto-logo/' + productoCarrito.slug);
    
  }

  gtagPresupuestoProductoAgregar(){
    this.gtag.event('presupuesto_producto', {
      items: [{
        item_name: "Descargar Presupuesto Producto"}]
    });
  }

  gtagContinuarProducto(){
    this.gtag.event('continuar_producto', {
      items: [{
        item_name: "Continuar Producto"}]
    });
  }

  gtagParte2AplicarLogo(){
    this.gtag.event('parte_2_aplicar_logo', {
      items: [{
        item_name: "Parte 2 aplicar logo"}]
    });
  }

  comprarAhoraLogoGtag() {
    this.gtag.event('comprar_ahora_checkout', {
      items: [{
        item_name: "comprar_ahora_logo"
      }]
    });
  }

  logoErrorGtag(){
    this.gtag.event('logo_error', {
      items: [{
        item_name: "ERROR producto cargado sin logo"
      }]
    });
  }

  tipoImpresionLogoGtag(){
    this.gtag.event('tipo_impresion_error', {
      items: [{
        item_name: "ERROR producto sin tipo de impresiÃ³n"
      }]
    });
  }
  
  parseFloat(numero){
    return parseFloat(numero);
  }
  parseInt(numero){
    var numeroParseado = parseInt(numero);
    if(isNaN(numeroParseado) || numeroParseado == undefined || numeroParseado == null){
      return 0;
    }
    return numeroParseado;
  }
  isNaN(numero){
    return isNaN(numero);
  }

  async registroTemporalSimple(pais:string){

    try {
      this.spinner.show();
      await this.backendService.get(RegistroTemporal,"/clientes" + RegistroTemporal.path + "/" + pais, null).toPromise().then( a=>{
        //@ts-ignore
        var registroTemporal = a as RegistroTemporal;
        var credentials =  new UserCredentials();
        
        credentials.username = registroTemporal.username;
        credentials.password = registroTemporal.password

        const retorno =  this.seguridadService.login(credentials).then(a=>{
          this.usuarioService.emitChange(this.seguridadService.user);
          //window.location.href = '/producto/' + productoCarrito.slug;
          //this.router.navigateByUrl('/producto-logo/' + productoCarrito.slug);
        });
      });
    } catch (ex) {
      this.spinner.hide();
      console.log(ex);
      if (ex instanceof Array) {
          ex.forEach(element => {
            this.toastService.openErrorGenerico(element.details);
          });
      } else {
        this.toastService.openErrorGenerico(ex.message);
      }
    } finally {
      //this.spinner.hide();
    }

  }

  redirectPaisCheckout(pais){
    if(pais=="ARGENTINA"){
      this.router.navigateByUrl('/previsualizacion-compra');
    }else if (pais=="CHILE"){
      window.location.href = '/previsualizacion-compra-cl';
    };
  }

  round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
  }

  createBlob(pdf: any) {
    const blob = new Blob([pdf], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  async borrarProductoPedido(idProductoPedido) {
    this.spinner.show();
    let borrado;
    await this.backendService.delete(ProductoEnviar.path + idProductoPedido).then((res: Response) => {
      borrado = res.json();
    });
    this.spinner.hide();
  }

  addToCart(producto) {
    this.gtag.event('add_to_cart', {
      items: [{
        item_name: producto.infoProducto.nombre,
        item_id: producto.infoProducto.id,
        price: producto.infoProducto.precio,
        item_category: producto.infoProducto.categorias[0],
        item_variant: producto.varianteSeleccionada,
        quantity: producto.cantidad
      }]
    });
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
