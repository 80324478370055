import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ProductoEnviar } from 'src/app/productos/viejo/productoEnviar.model';
import { ProductoSimple } from '../../models/producto-simple.model';

@Component({
  selector: 'app-resultado-producto-modal',
  templateUrl: './resultado-producto-modal.component.html',
  styleUrls: ['./resultado-producto-modal.component.scss']
})
export class ResultadoProductoModalComponent implements OnInit {

  src: string;
  productoModal: ProductoSimple;

  public onClose: Subject<boolean>;
  

constructor(public bsModalRef: BsModalRef,
  public sanitizer: DomSanitizer,
    ) { }

ngOnInit() {

  this.onClose = new Subject();


}

noConfirma() {
  this.onClose.next(false);
  this.bsModalRef.hide();
}

confirma() {
  this.onClose.next(true);
  this.bsModalRef.hide();
}

getDescriptions(colores) {
  if(colores)
    return colores.map(c => c.description).join(', ');

  return "No hay colores."
}

hayTalles(){
  return this.productoModal.talle != null
}

//No se usa
getPrecioConIva(precio:number){
    
    
  var numeroIva = this.round(precio * 1.21)
  
  return numeroIva

}

round(num) {
var m = Number((Math.abs(num) * 100).toPrecision(15));
return Math.round(m) / 100 * Math.sign(num);
}

}
