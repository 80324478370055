import { BackendModel, BackendService } from '../core/backend.service';
import { Email } from '../seguridad/usuario/details/email.model';

export class Subcliente extends BackendModel {

    public static path = '/subclientes/';

    id: number;
    username: string;
    password: string;
    retryPassword: string;
    clientePadreId: number;
    sector: string;
    email: Email;
    montoMax: number;
    idTipoMontoMax: number;
    pagaEnvio: boolean;

}
