import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import * as moment from 'moment';

@Directive({
  selector: '[appValidateDate][ngModel],[appValidateDate][formControl],[appValidateDate][formControlName]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DateValidator), multi: true }
  ]
})
export class DateValidator implements Validator {
  validate(control: AbstractControl) {
    if (!control.value) {
      return null;
    }
    if (control.value.length !== 8) {
      return { date: {date: 'Fecha invalida'}};
    }
    const fecha = control.value.substring(0, 2) + '/' + control.value.substring(2, 4) + '/' + control.value.substring(4, 8);

    const dateTestValue = moment(fecha, 'DD/MM/YYYY');


    // if (fecha.length == 10) {
      // const dateTestValue = moment(fecha, 'DD/MM/YYYY')
      if (dateTestValue.isValid() === false) {
        return { date: {date: 'Fecha invalida'}};
      }
    // }

    const time = control.value.valueOf() - new Date().valueOf();

    if (time > 0) {
      return { date: {
        date: time
      } };
    }

    const year = dateTestValue.year(); // (<Date> control.value).getFullYear()
    if (year < 1800) {
      return {
        date: {
          date: control.value.valueOf()
        }
      };
    }

    return null;
  }
}
