import { Component, OnInit } from '@angular/core';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-cantidad-minima-toast',
  templateUrl: './cantidad-minima-toast.component.html',
  styleUrls: ['./cantidad-minima-toast.component.scss']
})
export class CantidadMinimaToastComponent extends Toast{

  public message;
  constructor(protected toastrService: ToastrService,
              public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

}
