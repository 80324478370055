import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';

import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from '../../environments/environment';
import { AppRoutingModule } from '../router/app-routing.module';
import { TranslateService } from './translate.service';

import { TRANSLATION_PROVIDERS } from './translations';
import { BackendService } from './backend.service';

import {
  EnvironmentProvider,
  StorageProvider,
  HistoryProvider,
  LocationProvider,
} from './browser-providers';
import { UsuarioService } from './usuario.service';

const modules = [
  HttpModule,
  FormsModule,
  RouterModule,
  AppRoutingModule,
  ReactiveFormsModule,
];

@NgModule({
  imports: modules,
  exports: modules,
  providers: [
    { provide: EnvironmentProvider, useValue: environment },
    { provide: StorageProvider, useValue: localStorage },
    { provide: HistoryProvider, useValue: history },
    { provide: LocationProvider, useValue: location },
    TranslateService,
    TRANSLATION_PROVIDERS,
    BackendService,
    UsuarioService
  ]
})
export class CoreModule { }
