import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toast } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { BackendService } from 'src/app/core/backend.service';
import { FormValidatorServiceService } from 'src/app/shared-services/form-validator-service.service';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { RegistroSimple } from '../../dtos/registo-simple';
import { usuarioRegistroSimple } from '../../dtos/usuario-registro-simple';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'app-registro-simple-modal',
  templateUrl: './registro-simple-modal.component.html',
  styleUrls: ['./registro-simple-modal.component.scss']
})
export class RegistroSimpleModalComponent implements OnInit {
  selectPais: string;
  public onClose: Subject<usuarioRegistroSimple>;

  public usuario = { email: '', tipoUsuario: ''};
  public tiposDeUsuarios : string[]= ['Usuario Particular','Empresa']
  public registroSimple = new RegistroSimple();
  public usuarioRegistro = new usuarioRegistroSimple;

  @ViewChild('registroForm') public userForm: NgForm;
  @ViewChild('modalBackdrop') modalBackdrop: ElementRef;


  constructor(
    public bsModalRef: BsModalRef,
    private formValidatorService: FormValidatorServiceService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private backendService: BackendService,
    private toast: ToastService,
    private gtag: Gtag
  ) {
    
  }

  ngOnInit() {
    this.onClose = new Subject();
    this.usuario.tipoUsuario = this.tiposDeUsuarios[0];
    this.selectPais = localStorage.getItem('pais');
    // Agrega un evento click al elemento del DOM y cierra el modal
    /*this.modalBackdrop.nativeElement.addEventListener('click', () => {
      this.bsModalRef.hide();
    });*/
  }

  async save() {

    
    this.invokeSave();
    
  }

  private async invokeSave() {

    if (this.validateForm()) {
      this.spinner.show();
      this.saveUsuarioPaso1();
    }

    return;
  }

  async saveUsuarioPaso1() {
  
    this.registroSimple.email = this.usuario.email;
    this.registroSimple.tipoUsuario = this.usuario.tipoUsuario;
    this.usuarioRegistro.usuario = this.registroSimple.email;
    try {
      await this.backendService.create("/clientes" + RegistroSimple.path + "/" + this.selectPais, this.registroSimple).then( a=>{
        this.usuarioRegistro.pass = a as string;
        
      });
      this.usuarioRegistro.completado = true;
      this.gtag.event('successful_registro_simple', {
        items: [{
          item_name: "Registro Simple",
        }]
      });
      this.onClose.next(this.usuarioRegistro);
      //this.router.navigate(['/producto/2']);
      this.bsModalRef.hide();
    } catch (ex) {
      console.log(ex);
      if (ex instanceof Array) {
          ex.forEach(element => {
            this.toast.openErrorGenerico(element.details);
          });
      } else {
        this.toast.openErrorGenerico(ex.message);
      }
    } finally {
      //this.spinner.hide();
      this.spinner.hide();
    }
  }

  validateForm() {
    
    return this.formValidatorService.isFormValid(this.userForm)
    
  }

  noConfirma() {
    this.gtag.event('close_registro_simple', {
      items: [{
        item_name: "Cierra registro simple",
      }]
    });
    this.usuarioRegistro.completado = false;
    //this.onClose.next(false);
    this.onClose.next(this.usuarioRegistro);
    this.bsModalRef.hide();

  }

  confirma(){
    //this.onClose.next(true);
    this.usuarioRegistro.completado = false;
    this.gtag.event('registro_simple_satisfactorio', {
      items: [{
        item_name: "Registro Simple",
      }]
    });
    this.onClose.next(this.usuarioRegistro);
    this.bsModalRef.hide();
  }

  noConfirmaAceptado() {
    
    this.gtag.event('close_registro_simple', {
      items: [{
        item_name: "Cierra registro simple",
      }]
    });
    this.bsModalRef.hide();
  }

}