import { Injectable } from '@angular/core';
import {Subject } from 'rxjs/Subject';


@Injectable()
export class UpdateEventService {
  private updateEvent = new Subject<any>();
  updateEvent$ = this.updateEvent.asObservable();
  constructor() {}

  notifyUpdateEvent(updateEvent: any) {
    this.updateEvent.next(updateEvent);
  }
}
