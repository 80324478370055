import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormValidatorServiceService } from 'src/app/shared-services/form-validator-service.service';
import { Router } from '@angular/router';
import { SeguridadService } from '../seguridad.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/shared-services/toast/toast.service';
import { BackendService } from 'src/app/core/backend.service';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {RecuperarClave} from "./models/recuperar-clave";

@Component({
  selector: 'app-recuperar-clave',
  templateUrl: './recuperar-clave.component.html',
  styleUrls: ['./recuperar-clave.component.scss']
})
export class RecuperarClaveComponent implements OnInit, AfterViewInit {
  requiereCode = false;

  form: FormGroup;
  emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;

  selectPais:string;

  constructor(private formValidatorService: FormValidatorServiceService,
              private router: Router,
              private seguridadService: SeguridadService,
              private spinner: NgxSpinnerService,
              private toast: ToastService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.selectPais = localStorage.getItem('pais');
    this.form = this.formBuilder.group({
      email: [undefined, [Validators.required, Validators.pattern(this.emailPattern)]],
    });
  }

  ngAfterViewInit() {
    let w = document.documentElement.clientHeight;

    let containerLogin = document.getElementById("containerLogin");
    let verticalMiddleH = containerLogin.clientHeight;
    containerLogin.style.marginTop = -(verticalMiddleH / 2) - 80 + 'px';
    let containerResponsive = document.getElementById("containerResponsive");
    containerResponsive.style.height = w + 'px';
  }

  async save() {
    this.spinner.show();
    const usuarioLogueado = Object.assign(new RecuperarClave(), this.form.value);
    this.seguridadService.recuperarPassword(usuarioLogueado)
      .then(()=>{
      this.spinner.hide();
      this.router.navigateByUrl('login/recuperar-password/mensaje');
      })
      .catch(err =>{
        this.toast.openErrorGenerico("Usuario Invalido");
        this.spinner.hide();

      });
  }

  public errorHandling = (control: string, error: string) => {
    return this.form.controls[control].hasError(error);
  }
}
