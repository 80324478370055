import { Component, OnInit, ViewChild } from '@angular/core';
import { WizardComponent } from 'angular-archwizard';
import { BackendService } from '../../core/backend.service';
import { Cliente } from '../../cliente/cliente.model';
import { Router } from '@angular/router';
import {ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-completar-documentacion',
  templateUrl: './completar-documentacion.component.html',
  styleUrls: ['./completar-documentacion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompletarDocumentacionComponent implements OnInit {

  @ViewChild(WizardComponent)
  public wizard: WizardComponent;
  redirectUrlDashboard = '/';

  public cliente: Promise<Cliente>;

  constructor(
    private backendService: BackendService,
    private router: Router,
    ) {}

  ngOnInit() {

    this.getPerfil().then(cliente => {
      if (cliente.estado.id === 3 || cliente.estado.id === 4 || cliente.estado.id === 5) {
        this.router.navigateByUrl(this.redirectUrlDashboard);
      }

    });

  }

  onChange(paso) {
    this.wizard.model.navigationMode.goToStep(paso);
    if (paso === 1  || paso === 2) {
      window.scrollTo(0, 0);
    }
  }

  onPreviousStep() {
    this.wizard.model.navigationMode.goToPreviousStep();
    window.scrollTo(0, 0);
  }

  async getPerfil() {
    this.cliente = this.backendService.get(Cliente, Cliente.path + 'perfil').toPromise();
    return this.cliente;
  }

}
